import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getDoctorsTasks } from '../../actions/dashboard';
import remoteLog from '../../common/logging';
import PersonName from "../common/PersonName";
import FormatNumber from "../common/FormatNumber";
import {deployedRussia} from "../../common/utils";


const mapStateToProps = (state) => {
    return {
        user: state.user,
        tasks: state.doctorTasks
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getTasks: () => dispatch(getDoctorsTasks())
    }
}


class PatientsTasksList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tasks: props.tasks.patients,
            paymentState: false,
            payment_alert: false,
            sort: {
                order: 'desc',
                name: 'patient_id'
            },
        }

        this.togglePaymentInfo = this.togglePaymentInfo.bind(this);
        this.togglePayment = this.togglePayment.bind(this);
        this.renderPrice = this.renderPrice.bind(this);
    }


    componentDidCatch(e) {
        remoteLog(e, 'patients_list');
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        if (this.props.user.account_id && !next_props.user.account_id) {
            this.props.history.push('/');
        }
        this.setState({tasks: next_props.tasks.patients})
        if (next_props.tasks.payment_alert) this.setState({payment_alert: true})

    }

    setSortOrder(newName) {
        this.setState((prevState, props) => {
            if (prevState.sort.name === newName) { // same column, just change sort order
                return {sort: {name: newName, order: prevState.sort.order === 'desc' ? 'asc' : 'desc'}}
            } else {
                return {sort: {name: newName, order: 'desc'}}
            }
        })
    }

    handleFilterChange(str) {
        let tasks = this.props.tasks.patients;
        let fm = this.props.intl.formatMessage;
        if (str) {
            tasks = tasks.filter((p) => {
                return (p.first_name.toString().toLowerCase().indexOf(str) > -1) ||
                    (p.last_name.toString().toLowerCase().indexOf(str) > -1) ||
                    (p.patient_id.toString().toLowerCase().indexOf(str) > -1) ||
                    (fm({id: p.latest_correction.status}).toLowerCase().indexOf(str) > -1);
            })
        }
        this.setState({tasks: tasks})
    }

    sortedPatients() {
        if (this.state.tasks.length < 2) {
            return this.state.tasks;
        }

        let fm = (id) => this.props.intl.formatMessage({id: id});
        let sort_order = this.state.sort.order === 'asc' ? 1 : -1;
        return this.state.tasks.sort((p1, p2) => {
            if (this.state.sort.name === 'patient_id') {
                return sort_order * (p1.patient_id - p2.patient_id);
            } else if (this.state.sort.name === 'latest_correction') {
                let p1t = fm(p1.latest_correction.status)
                let p2t = fm(p2.latest_correction.status)
                return sort_order * (p1t.localeCompare(p2t));
            } else {
                return sort_order * p1[this.state.sort.name].localeCompare(p2[this.state.sort.name]);
            }
        })
    }

    componentDidMount() {
        this.props.getTasks();
        window.scrollTo(0, 0)
    }

    sortClass(colname) {
        if (colname === this.state.sort.name) {
            return this.state.sort.order === 'desc' ? 'sorting_desc' : 'sorting_asc';
        }
        return 'sorting';
    }

    togglePayment() {
        this.setState({
            paymentState: !this.state.paymentState
        });
    }

    togglePaymentInfo() {
        if (this.state.paymentState) {
            return (
                <div>
          <span onClick={this.togglePayment} className={this.state.payment_alert ? "btn red" : "btn green"}
                id="test-btn" style={{}} ref="toggle-button">
            <FormattedMessage id="general.payment.close"/>
         </span>
                </div>
            );
        } else {
            return (
                <div>
          <span onClick={this.togglePayment} className={this.state.payment_alert ? "btn red" : "btn green"}
                id="test-btn" style={{}} ref="toggle-button">
            <FormattedMessage id="general.payment.show"/>
          </span>
                </div>
            );
        }
    }

    outOf(data) {
        if (data.steps_count_total !== 'None' && data.steps_count_completed !== 'None') {
            return (
                <span>
          {data.steps_count_completed + " "}
                    <FormattedMessage id="pat_table.of"/>
                    {" " + data.steps_count_total}
        </span>
            )
        } else {
            return (null)
        }
    }

    renderPrice(value) {
        if ((value && value === 'None') || (!value && value !== 0)) {
            return '';
        }
        return <FormatNumber value={value}/>;
    }

    render() {
        const showStepsDelivered = deployedRussia() === true;

        return (
            <div className="page-content-wrapper">
                { /* pacient table */}
                <div className="page-content" id="patients-table">
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className="icon-users font-green"/>
                                <span className="caption-subject font-green bold uppercase"
                                      id="patients-table-block-title">
                                   <FormattedMessage id="pat_table_tasks.block_head"/>
                                </span>
                            </div>
                        </div>
                        <div className="portlet-body">
                            <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="dt-buttons">
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                        {this.togglePaymentInfo()}
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                        <div id="doctor_pacients_table_filter" className="dataTables_filter"
                                             id="patient-search-form">
                                            <label><FormattedMessage id="table.search"/>&nbsp;&nbsp;
                                                <input
                                                    onChange={(e) => this.handleFilterChange(e.target.value.toString().toLowerCase())}
                                                    type="search"
                                                    className="form-control input-sm input-small input-inline"
                                                    aria-controls="doctor_pacients_table"
                                                    id="patient-search-form-input"/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-scrollable">
                                    <table className="table table-bordered table-hover dataTable no-footer"
                                           id="doctor_pacients_table" role="grid">
                                        <thead id="patients-table-head">
                                        <tr role="row">
                                            <th className={this.sortClass('patient_id')} style={{width: 14}}
                                                onClick={(e) => this.setSortOrder('patient_id')}> #
                                            </th>
                                            <th className={this.sortClass('last_name')} style={{width: 111}}
                                                onClick={(e) => this.setSortOrder('last_name')}
                                            >
                                                <span style={{ marginRight: '12px' }}><FormattedMessage id="pat_table.name"/></span>
                                            </th>
                                            {showStepsDelivered
                                            ? (
                                                <th style={{width: 150}}><FormattedMessage
                                                  id="pat_table.delivered"/></th>
                                              ): null
                                            }
                                            <th style={{width: 79}}><FormattedMessage
                                                id="pat_table.received"/></th>
                                            <th style={{width: 113}}><FormattedMessage
                                                id="pat_table.tot_payment"/></th>
                                            <th className={this.sortClass('status')} style={{width: 141}}
                                                onClick={(e) => this.setSortOrder('latest_correction')}>
                                                <FormattedMessage id="pat_table.status"/></th>
                                        </tr>
                                        </thead>
                                        <tbody id="patients-table-body">
                                        {this.sortedPatients().map((p, i) => {
                                                let lc = p.latest_correction;
                                                const {total, paid} = p.total_payments;

                                                return (
                                                    <tr key={'p' + i}
                                                        className={'clickable-row ' + (i % 2 ? 'even' : 'odd')}
                                                        role="row">
                                                        <td className="sorting_1">{p.patient_id}</td>
                                                        <td><NavLink
                                                            to={`/pages/patient/${p.patient_id}`}><PersonName
                                                            person={p}/></NavLink></td>
                                                        {showStepsDelivered
                                                        ? (
                                                            <td>{(lc.steps_count_total && (lc.steps_count_completed || lc.steps_count_completed === 0)) ? this.outOf(lc) : null}</td>
                                                          ): null
                                                        }
                                                        <td>{this.state.paymentState ? this.renderPrice(paid) : ''}</td>
                                                        <td>{this.state.paymentState ? this.renderPrice(total) : ''}</td>
                                                        <td><span className="label label-sm bg-red">
                                                        {p.latest_correction.status &&
                                                        <FormattedMessage id={p.latest_correction.status}/>}</span></td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { /* pacient table */}
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientsTasksList));
