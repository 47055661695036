const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const required = (value) => {
    if (value) {
        return false
    }
    return true
}

export const isPhoneNumber = (value) => {
    var phoneNumberPattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
    if (typeof value !== 'undefined' && phoneNumberPattern.test(value)) {
        return false
    }
    return true
}

export const isLogisticPhoneNumber = (value) => {
    if (value && value.match(new RegExp('^([+]{1}[0-9]{0,14}|[0-9]{0,15})$'))) {
        return false
    }
    return true
}

export const validateZIPCode = (value) => {
    const reg = /^[a-z0-9\-\s\[\]]{1,10}$/i;
    if (!value || value.length > 6 || (typeof value === 'string' && !reg.test(value))) {
        return true
    }
    return false
}

export const validEmail = value => {
    if (value && value.match(new RegExp(EMAIL_REGEX))) {
        return false
    }
    return true
}