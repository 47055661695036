import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getClinics } from '../../../actions/clinics';
import { addInstructions } from '../../../actions/patient_new_instructions';
import remoteLog from '../../../common/logging';
import PersonName from "../../common/PersonName";


const mapStateToProps = (state) => {
  return {
    user: state.user,
    clinics: state.clinics
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getClinics: () => dispatch(getClinics()),
    addInstruction: (data) => dispatch(addInstructions(data))
  }
}


class PatientNewDoctorInfo extends Component {
  
  constructor(props) {
    super(props);
    this.addComments = this.addComments.bind(this);
    this.selectClinic = this.selectClinic.bind(this);
    this.selected = -1;
  }


  componentDidCatch(e) {
    remoteLog(e, 'patient_new_doctor_info');
  }

  UNSAFE_componentWillMount() {
    this.props.getClinics();
  }

  componentDidMount() {
    if (this.props.clinics.length === 1) {
      this.selectClinic(this.props.clinics[0].clinic_id, 0)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.user.account_id) {
      this.props.addInstruction({'doctor_id': newProps.user.account_id})
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.clinics && Array.isArray(this.props.clinics) && this.props.clinics.length == 1
      && prevProps.clinics && Array.isArray(prevProps.clinics) && prevProps.clinics.length == 0
      && this.props.clinics !== prevProps.clinics
    ) {
        this.selectClinic(this.props.clinics[0].clinic_id, 0)
    }
  }

  addComments(data) {
    this.props.addInstruction({'logistics_comment':data}) 
  }

  selectClinic(data, i) {
    this.selected = i;
    this.props.addInstruction({'clinic_id': parseInt(data)});
    if (this.props.clinics.length > 1) {
      this.props.setDirty('clinic_id', data);
    }
    this.forceUpdate();
  }


  render() {
    return (
      <div>
        <h3 className="block" style={{ fontWeight: '900', marginTop: "0" }}><FormattedMessage id="BLOCKHEAD_DOCTOR" /></h3>
        <div className="form-group" id="clinic-section">
          <label className="control-label" id="validation-clinic_id" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_CLINIC" />
            <span className="required" aria-required="true">*</span>
          </label>
          <div className="radio-list" id="clinic-name-value">
            {this.props.clinics.map((c,i) => {
                return (<label key={c.clinic_id}>
                  <div className="radio">
                    <span><input type="radio" checked={i === this.selected} name="clinic" key={c.clinic_id} value={c.clinic_id} onChange={(e) => this.selectClinic(e.target.value, i)}/></span>
                  </div> {c.title} 
                </label>)
            }
            )}
          </div>
          <div id="form_clinic_error"> </div>
        </div>
        {/*<div className="form-group" id="delivery-commentary-section">
          <label className="control-label" id="delivery-commentary-label"><FormattedMessage id="CLINIC_SHIP_COMMENTS" /></label>
          <textarea className="form-control" id="delivery-commentary-value" rows={5} name="logistics_comment" placeholder={this.props.intl.formatMessage({id: "CLINIC_SHIP_COMMENTS"})} defaultValue={""} onChange={(e) => this.addComments(e.target.value)} />
          </div>*/}
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientNewDoctorInfo));
