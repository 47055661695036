import clsx from "clsx";
import React from "react";

export function Portlet({
  as: Comp = "section",
  className,
  children,
}: {
  as?: "main" | "section";
  className?: string;
  children?: React.ReactNode;
}) {
  return <Comp className={clsx("portlet light bordered", className)}>{children}</Comp>;
}

export function PortletTitle({
  iconClassName,
  children,
}: {
  iconClassName: string;
  children: React.ReactNode;
}) {
  return (
    <div className="portlet-title">
      <div className="caption">
        <i className={clsx(iconClassName, "font-green")} aria-hidden="true" />
        <h1 className="caption-subject font-green bold uppercase" style={{ display: "inline" }}>
          {children}
        </h1>
      </div>
    </div>
  );
}
