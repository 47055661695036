import React, {Component} from 'react';

import PatientMenu from '../patient_menu';
import MeetingsPage from './meetings_page';
import PageFooter from '../page_footer';
import PageHeader from '../page_header';
import Notification from '../notification';
import Tour from '../patient/tour';


class MeetingsPageBody extends Component {

  render() {
    return (
      <div>
        <PageHeader {...this.props}/>
        <Tour />
        <div className="container">
          <div className="page-container">
            <PatientMenu/>
            <Notification {...this.props}/>
            <div className="page-content-wrapper">
              <div className="page-content" style={{minHeight: 584}}>
                <div className="row">
                  <div className="col-md-12">
                    <MeetingsPage {...this.props}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PageFooter/>
        </div>
      </div>
    );
  }
}


export default MeetingsPageBody;
