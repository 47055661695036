import { API_GET_SMILES } from "../config";
import type { TSmiles } from "../reducers/dashboard";
import type { TUser } from "../reducers/login";
import type { AppDispatch } from "../store";

export function getSmilesAction(json: Partial<TSmiles>) {
  return { type: "GET_SMILES", json } as const;
}

export function getSmiles(doctor_id: TUser["account_id"]) {
  return (dispatch: AppDispatch) => {
    fetch(API_GET_SMILES(doctor_id), { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to fetch smiles");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => dispatch(getSmilesAction(json)))
      .catch(() => dispatch(getSmilesAction({})));
  };
}
