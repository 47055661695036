import { configureStore as toolkitConfigureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { type TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { type AnyAction } from "redux";
import { createLogger } from "redux-logger";
import { type ThunkDispatch } from "redux-thunk";

import { api } from "./api";
import { isDevelopment } from "./common/utils";
import rootReducer from "./reducers";

export default function configureStore(initialState?: Partial<RootState>) {
  const store = toolkitConfigureStore({
    preloadedState: initialState,
    reducer: rootReducer,
    middleware(getDefaultMiddleware) {
      // Logs a warning to the console saying that immutability or seriazability check
      // took too much time, and should probably be disabled as it might cause too much
      // of a slowdown.
      const middleware = getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(api.middleware);

      if (process.env.LOGGER == "true") {
        const logger = createLogger({ collapsed: true });
        middleware.push(logger);
      }

      return middleware;
    },
    devTools: isDevelopment,
  });
  setupListeners(store.dispatch);
  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>; // NOTE: temporary, should be replaced with `typeof store.dispatch`

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
