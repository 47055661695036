import React, {Component} from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import PageHeader from './page_header';
import PageFooter from './page_footer';
import { deployedRussia } from '../../common/utils';
import { sendPatientPaymentConfirm } from '../../actions/invoice';
import Tour from '../patient/tour';


const mapDispatchToProps = (dispatch) => {
  return {
    sendPatientPaymentConfirm: (patient_id, token, order_id, hash) =>
      dispatch(sendPatientPaymentConfirm(patient_id, token, order_id, hash)),
  }
}


class PatientPaymentSuccess extends Component {

    componentDidMount() {
        if (this.props.match && this.props.match.params && deployedRussia()) {
            const token = this.props.match.params.token;
            const patient_id = this.props.match.params.patient_id;
            const hash = this.props.match.params.hash;
            let order_id = this.props.location.search.replace('?orderId=','');
            this.props.sendPatientPaymentConfirm(patient_id, token, order_id, hash);
        }
    }

    render() {
        if (deployedRussia()) {
            return (
                <div>
                    <PageHeader {...this.props}/>
                    <Tour />
                    <div className="container">
                        <div className="page-container">
                            <div className="page-content-wrapper">
                                <div className="page-content" style={{minHeight: 584}}>
                                <div className="row">
                                    <div className="col-md-8">
                                    <div className="portlet light bordered">
                                        <div className="portlet-title">
                                        <div className="caption">
                                            <i className="icon-book-open font-green" />
                                            <span className="caption-subject font-green bold uppercase"><FormattedMessage id="pat.payments.online.payment" /></span>
                                        </div>
                                        </div>
                                        <div className="portlet-body">
                                        <div className="form-body">
                                            <div className="row fileupload-buttonbar">
                                            <div className="col-lg-7">
                                                <FormattedMessage id="patient.area.payments.paid" />
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    <PageFooter/>
                </div>
            )
        }
        return null;
    }
}


export default connect(null, mapDispatchToProps)(PatientPaymentSuccess);
