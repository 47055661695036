import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";

import ClinicInput from "../validation/ValidateNewClinic";
import { deployedRussia } from "../../common/utils";
import Schedule, { DEFAULT_SCHEDULE } from "./schedule";
import { addClinic } from '../../actions/clinics';
import remoteLog from '../../common/logging';
import {
  validEmail, isPhoneNumber, isLogisticPhoneNumber,
  validateZIPCode, required
} from '../../common/validations/clinic';
import { Redirect } from "react-router-dom";


const mapStateToProps = (state) => ({
    user: state.user,
    clinic: state.clinic,
});


const mapDispatchToProps = (dispatch) => ({
    addData: (formData, schedule) => dispatch(addClinic(formData, schedule))
});


class ClinicNew extends Component {
    constructor(props){
        super(props)
        this.state = {
            region: [],
            cities: [],
            city_id: null,
            workingDays: { ...DEFAULT_SCHEDULE }
        }
        this.submit = this.submit.bind(this);
        this.onWorkingDayStatusChanged = this.onWorkingDayStatusChanged.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
    }

    componentDidCatch(e) {
        remoteLog(e, 'clinic_new_page');
    }

    componentDidMount() {
        const { user } = this.props;
    }

    componentDidUpdate(prevProps) {
        const {clinic} = this.props;
        if(clinic.clinic_id && clinic.clinic_id !== prevProps.clinic.clinic_id){
            const id = this.props.clinic.clinic_id;
            this.props.history.push(`/pages/doctor/clinics/${id}`);
        }
    }

    onWorkingDayStatusChanged(event) {
        const { workingDays } = this.state;
        const weekday = (event.currentTarget.name)
            workingDays[weekday] = {
            ...workingDays[weekday],
            checked: !workingDays[weekday].checked
        }
        this.setState(workingDays);
    }

    onStartTimeChanged(weekday, time) {
        const { workingDays } = this.state;
        workingDays[weekday] = {
            ...workingDays[weekday],
            from: time["startTime"]
        };
        this.setState(workingDays);
    }

    onEndTimeChanged(weekday, time) {
        const { workingDays } = this.state;
        workingDays[weekday] = {
            ...workingDays[weekday],
            to: time["endTime"]
        };
        this.setState(workingDays);
    }

    submit(formData) {
        const schedule = this.state.workingDays;
        this.props.addData(formData, schedule);
    }

    render() {
        const fm = id => this.props.intl.formatMessage({id: id});
        const { handleSubmit, invalid } = this.props;

        return (
            <div className="page-content-wrapper">
                <div className="page-content" style={{minHeight: 584}}>
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className="icon-plus font-green"/>
                                <span className="caption-subject font-green bold uppercase">
                                    <FormattedMessage id="clin_view.add.header"/>
                                </span>
                            </div>
                        </div>
                        { deployedRussia() === true
                        ? ( <div className="portlet-body form">
                            <form onSubmit={handleSubmit(this.submit)}>
                                <div className="alert alert-danger display-hide">
                                    <button className="close" data-close="alert"/>
                                    <span id="error-msg"/>
                                </div>
                                <div className="form-body">
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.name.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            name="title"
                                            placeholder={fm('clin_view.name.placeholder')}
                                            validate={[required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.email.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            name="email"
                                            placeholder={fm('clin_view.email.placeholder')}
                                            validate={[validEmail]}
                                            message="clin_view.error.email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.address.line.1"/>
                                            <FormattedMessage id="clin_view.address.not.more.then.35"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            maxLength={35}
                                            name="address_line_1"
                                            placeholder={fm('clin_view.address.line.1.placeholder')}
                                            validate={[required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.address.line.2"/>
                                            <FormattedMessage id="clin_view.address.not.more.then.35"/>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            maxLength={35}
                                            name="address_line_2"
                                            placeholder={fm('clin_view.address.line.2.placeholder')}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.address.line.3"/>
                                            <FormattedMessage id="clin_view.address.not.more.then.35"/>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            maxLength={35}
                                            name="address_line_3"
                                            placeholder={fm('clin_view.address.line.3.placeholder')}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.zip.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            name="zip_code"
                                            placeholder={fm('clin_view.zip.placeholder')}
                                            validate={[validateZIPCode]}
                                            message="clin_view.error.index"
                                        />
                                    </div>
                                    <h4 style={{marginTop: 32, fontWeight: 600}}>
                                        <FormattedMessage id="clin_view.administrative.contacts"/>
                                    </h4>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.phone.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            name="phone"
                                            placeholder={fm('clin_view.phone.placeholder')}
                                            validate={[isPhoneNumber]}
                                            message="clin_view.error.phone"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.contacts.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="textarea"
                                            component={ClinicInput}
                                            className="form-control"
                                            rows={5}
                                            name="contact"
                                            placeholder={fm('clin_view.contacts.placeholder')}
                                            validate={[required]}
                                        />
                                    </div>
                                    <h4 style={{marginTop: 32, fontWeight: 600}}>
                                        <FormattedMessage id="clin_view.delivery.contacts"/>
                                    </h4>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.logistic_phone.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            name="logistic_phone"
                                            placeholder={fm('clin_view.logistic_phone.placeholder')}
                                            validate={[isLogisticPhoneNumber]}
                                            message="clin_view.error.phone"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            <FormattedMessage id="clin_view.logistic_contact.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            name="logistic_contact"
                                            placeholder={fm('clin_view.logistic_contact.placeholder')}
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">
                                        <FormattedMessage id="clin_view.schedule.schedule"/>
                                        <span className="required" aria-required="true">*</span>
                                    </label>
                                </div>
                                    <Schedule
                                        workingDays={this.state.workingDays}
                                        onWorkingDayStatusChanged={this.onWorkingDayStatusChanged}
                                        onStartTimeChanged={this.onStartTimeChanged}
                                        onEndTimeChanged={this.onEndTimeChanged}
                                    />
                                <div className="form-actions">
                                    <button type="submit" disabled={invalid} className="btn green">
                                        <FormattedMessage id="clin_view.add.button"/>
                                    </button>
                                </div>
                            </form>
                        </div> )
                          : <Redirect to="/pages/doctor/clinics"/> }
                    </div>
                </div>
            </div>
        )
    }
};


ClinicNew = reduxForm ({ form : 'clinic_new' })(ClinicNew);


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ClinicNew));
