import React from 'react';

import { deployedUSA } from '../common/utils';


const Logo = (props) => {
  if (deployedUSA() === true) {
    return (
      <div className="logo" style={{ width: '250px' }}>
          <a href="/" target="_blank">
            <img src="/img/3d_predict.svg" alt="" />
          </a>
      </div>
    )
  } else {
    return (
      <div className="logo">
          <a href="/" target="_blank">
            <img src="/img/3d_smile_logo_rgb.svg" alt="" style={{ width: '200px' }}/>
          </a>
      </div>
    )
  }
}

export default Logo;
