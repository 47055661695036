import React, {Component} from 'react';
import { connect } from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import FormatDate from '../../common/FormatDate';
import remoteLog from '../../../common/logging';
import {
  DELIVERY_DESTINATION_CLINIC,
  DELIVERY_DESTINATION_LABORATORY,
  getDeliveries,
  UPS_TRACK_LOCALE_MAP,
  trackingLink
} from '../../../actions/deliveries';


const mapStateToProps = (state) => {
  return {
    patient: state.patient,
    lang: state.intl,
    deliveries: state.delivery.deliveries,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveries: patient_id => dispatch(getDeliveries(patient_id)),
  }
}


class PatientDeliveryList extends Component {

  componentDidCatch(e) {
    remoteLog(e, 'patient_deliveries');
  }

  componentDidMount() {
    const { patient, getDeliveries } = this.props;
    if (patient && patient.patient_id) {
      getDeliveries(patient.patient_id);
    }
  }

  renderTable(deliveries, header=null, shipment_date_column=true) {
    const { lang } = this.props;
    const upsLocale = UPS_TRACK_LOCALE_MAP[lang.locale];

    return (
      <div className="page-content-wrapper">
            <div className="portlet light bordered">
              <div className="portlet-title">
                <div className="caption">
                  <i className="icon-envelope font-green" />
                  <span className="caption-subject font-green bold uppercase">
                    <FormattedMessage id={header}/>
                  </span>
                </div>
              </div>

              <div className="portlet-body">
                <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
                  <div className="table-scrollable">
                    <table className="table table-bordered table-hover dataTable no-footer" role="grid">
                      <thead>
                        <tr role="row">
                          <th style={ {  width: 150} }> <FormattedMessage id="pat.block.deliveries.waybills" /> </th>
                          {shipment_date_column === true ?
                            (
                              <th style={ {  width: 79 } } >
                                  <FormattedMessage id="pat.block.deliveries.shipment.date" />
                              </th>
                            ) : null
                          }
                          <th style={ {  width: 79} } > <FormattedMessage id="pat.block.right.correction" /> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveries.map((delivery, index) => {
                            return (
                              <tr key={delivery.track_number} className={'clickable-row ' + (index%2 ? 'even' : 'odd')} role="row">
                                <td>
                                  <a
                                    href={trackingLink(delivery.track_number, delivery.type, upsLocale)}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    {delivery.track_number}
                                  </a>
                                </td>
                                {shipment_date_column == true
                                  ? (
                                    <td>
                                      <FormatDate value={delivery.pickup_date} date/>
                                    </td>
                                  ) : null
                                }
                                <td>
                                  {delivery.correction_number > 0
                                    ? <div><FormattedMessage id="pat.block.right.correction"/> {delivery.correction_number}</div>
                                    : <FormattedMessage id="pat.block.right.general.treatment" />
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </div>
    )
  }


  render() {
    const { deliveries, patient } = this.props;

    const alignerDeliveries = deliveries.filter(delivery => delivery.destination === DELIVERY_DESTINATION_CLINIC);
    const impressionShipments = deliveries.filter(delivery => delivery.destination === DELIVERY_DESTINATION_LABORATORY);
    const upsPrivilege = patient.course.correction.slice(-1)[0].order_options.ups_deliveries_privilege;

    return (
      <div>
        {this.renderTable(alignerDeliveries, "pat.block.deliveries.shipments.aligners", false)}
        {upsPrivilege ? this.renderTable(impressionShipments, "pat.block.deliveries.deliveries.impressions") : null}
      </div>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientDeliveryList));
