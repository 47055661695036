import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {getPaymentData, deletePaymentsData} from "../../actions/doctor_payments";
import {deployedUSA} from "../../common/utils";
import Loader from "../common/loadingInProgress";


const mapDispatchToProps = dispatch => {
    return {
        onDeleteAccountErrorMsg: () => dispatch(deletePaymentsData())
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class DoctorPaymentDataPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            payments: [],
            deleted: false,
            loading: false
        }
    }

    componentDidMount() {
        if (!deployedUSA()) this.props.history.push('/pages/patients');
        getPaymentData().then(res => {
            if (res instanceof Array && res.length > 0) {
                this.setState({
                    payments: res,
                    loading: true
                })
            } else {
                this.setState({payments: null})
            }
        })
            .catch(() => {
                this.props.onDeleteAccountErrorMsg()
            })
    }

    deletePaymentInfo() {
        this.props.onDeleteAccountErrorMsg().then(res => {
            if (res === true) {
                this.setState({
                    payments: null,
                    deleted: true
                })
            }
        })
    }

    render() {
        const {user} = this.props;

        if (!user.login) {
            return <div>loading...</div>
        }
        if (deployedUSA() && location.protocol === 'https:') {
            return (
                <div className="page-content-wrapper">
                    <div className="page-content" style={{minHeight: 628}}>
                        <div className="portlet light bordered">
                            <div className="portlet-title">
                                <div className="caption">
                                    <i className="icon-wallet font-green"/>
                                    <span className="caption-subject font-green bold uppercase"><FormattedMessage
                                        id="top.menu.payment"/></span>
                                </div>
                            </div>
                            <div className="portlet-body form">
                                <div className="form-body">
                                    {!this.state.loading && this.state.payments instanceof Array ?
                                        <Loader/> : null}

                                    {this.state.payments === null ?

                                        <h5><i>No records found</i></h5>

                                        : this.state.payments.map((card, index) => {
                                            return (
                                                <div key={index}>
                                                    <label className="control-label"><strong>Card
                                                        Number:</strong> {card.cardNumber}</label>
                                                    <p><strong>Card Type:</strong> {card.cardType}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    {this.state.payments !== null && this.state.payments.length > 0 ?
                                        <button className="btn red"
                                                onClick={() => this.deletePaymentInfo()}>Delete Cards
                                        </button>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    }


}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorPaymentDataPage);
