import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { eraseStateProp } from '../../actions/dashboard';
import { changeAccountData } from '../../actions/edit_doctor';
import { addUserActionNotification } from '../../actions/user_notification';
import { DN_TYPE_FDI, DN_TYPE_PALMER,DN_TYPE_UNIVERSAL } from '../../common/constants';
import remoteLog from '../../common/logging';
import { deployedRussia, deployedUSA, sleep } from '../../common/utils';


const MAX_STAGES = 99;


const mapStateToProps = (state) => {
  return {
    user: state.user,
    doctor: state.doctor,
    clinic: state.clinic,
    lang: state.intl,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    changeAccountData: (account_data) => dispatch(changeAccountData(account_data)),
    eraseStateProp: (props) => dispatch(eraseStateProp(props)),
    addUserActionNotification: (notificationData) => dispatch(addUserActionNotification(notificationData)),
  }
}


class DoctorUpdatePage extends Component {
  constructor(props) {
    super(props);
    this.updated_doctor = {};
    this.state = {
      preferences: props.user.preferences || {},
    };

    this.updatePreferences = this.updatePreferences.bind(this);
    this.updatePreferenceRadio = this.updatePreferenceRadio.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'doctor_update_page');
  }

  componentDidMount() {
    const { user } = this.props;
    this.props.eraseStateProp('doctor');
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user.preferences !== prevProps.user.preferences) {
      this.setState({ preferences: {...user.preferences} });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let {doctor} = nextProps;
    if (doctor && doctor.account_id) this.props.history.push('/pages/my_account');
  }

  addPropsToUpdateDoctor(field_name, data) {
    this.updated_doctor[field_name] = data
  }

  updatePreferences(e) {
    let { target } = e;
    let { value } = target;
    let key = target.name.split(".")[1];

    if (target.type === "checkbox") value = target.checked;
    if (target.type === "number" || $(target).data("type") === "number") {
      if (value === null || value === undefined || value === "") {
        value = null;
      } else {
        value = +value;
      }
    }

    let preferences = {...this.state.preferences, [key]: value};

    this.setState({preferences});
    this.addPropsToUpdateDoctor("preferences", preferences);
  }

  updatePreferenceRadio(e) {
    const {target} = e;
    let key = target.name.split(".")[1];
    let value = target.value ? +target.value : null;
    let oldValue = this.state.preferences[key];

    if (key === 'default_payment_method') {
      if (value == oldValue) {
        value = null;
        target.checked = false;
      }
    } else {
      if ((value == oldValue) || (value !== null && oldValue !== null && Boolean(parseInt(value)) === Boolean(oldValue))) {
        value = null;
        target.checked = false;
      }
    }
    let preferences = {...this.state.preferences, [key]: value};
    this.setState({preferences});
    this.addPropsToUpdateDoctor("preferences", preferences);
  }

  async submitButton() {
    if (!this.refs.form.checkValidity()) {
      $(this.refs.form).find("input[required]").each((i, el) => {
        if (el.type === "checkbox" && el.checked) return;
        if (el.value) return;
        const $group = $(el).closest(".form-group").addClass("has-error");
        $(el).one("focus", () => {
          $group.removeClass("has-error");
        });
      });

      $(".floatPositive").each((i, el) => {
        const value = el.value;
        if (!value || value == '' || value == undefined) return;
        if (parseFloat(value) >= 1.0) return;
        const $groupFloatPositive = $(el).closest(".form-group").addClass("has-error");
        $(el).one("focus", () => {
          $groupFloatPositive.removeClass("has-error");
        });
      });

      $(".intPositive").each((i, el) => {
        const value = el.value;
        if (!value || value == '' || value == undefined) return;
        if (parseInt(value) >= 1 && parseInt(value) <= MAX_STAGES) return;
        const $groupIntPositive = $(el).closest(".form-group").addClass("has-error");
        $(el).one("focus", () => {
          $groupIntPositive.removeClass("has-error");
        });
      });

      $('#submit-pacient-btn').removeClass('-error');
      await sleep(0);
      $('#submit-pacient-btn').addClass('-error');
      return;
    };
    this.props.changeAccountData(this.updated_doctor)
  }

  render() {
    const {user} = this.props;
    if(!user.login) {
      return <div>loading...</div>
    }

    return(
    <div className="page-content-wrapper">
      <div className="page-content" style={{minHeight: 628}}>
        <div className="portlet light bordered">
          <div className="portlet-title">
            <div className="caption">
              <i className="icon-user font-green" />
              <span className="caption-subject font-green bold uppercase"><FormattedMessage id="top.menu.profile"/></span>
            </div>
          </div>
          <form className="portlet-body form" ref="form">
            <h3><FormattedMessage id="doctor.profile.info"/></h3>
            <div className="form-body">
              <div className="alert alert-danger display-hide">
                <button type="button" className="close" data-close="alert" />
                <span id="error-msg" />
              </div>
              <div className="form-group row">
                <div className="col-md-4">
                  <label className="control-label" htmlFor="login">
                    <FormattedMessage id="login.login" />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="login"
                    name="login"
                    required
                    disabled={true} // deployedUSA()}
                    defaultValue={ user.login }
                    onChange={(e) => this.addPropsToUpdateDoctor('login',e.target.value)}
                  />
                </div>
              </div>
              <div>
                <NavLink to="/pages/account/password/update">
                  <button type="button" className="btn green"><FormattedMessage id="BTN_CHANGE_PASSWORD" /></button>
                </NavLink>
                <br/><br/>
              </div>
              <div className="row">
                  <div className="form-group col-md-4">
                    <label className="control-label" htmlFor="last_name">
                      <FormattedMessage id="PAT_INFO_LAST_NAME" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      name="last_name"
                      required
                      defaultValue={ user.last_name }
                      onChange={(e) => this.addPropsToUpdateDoctor('last_name',e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="control-label" htmlFor="first_name">
                      <FormattedMessage id="PAT_INFO_FIRST_NAME" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      name="first_name"
                      required
                      defaultValue={ user.first_name }
                      onChange={(e) => this.addPropsToUpdateDoctor('first_name',e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="control-label" htmlFor="middle_name">
                      <FormattedMessage id="PAT_INFO_MIDDLE_NAME" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="middle_name"
                      name="middle_name"
                      defaultValue={ user.middle_name && user.middle_name != "None" ? user.middle_name : null }
                      onChange={(e) => this.addPropsToUpdateDoctor('middle_name',e.target.value)}
                    />
                  </div>
              </div>
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="form-group col-md-4">
                  <label className="control-label" htmlFor="email">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    required
                    disabled={deployedUSA()}
                    defaultValue={ user.email }
                    onChange={(e) => this.addPropsToUpdateDoctor('email',e.target.value)}
                  />
                  { deployedUSA()
                    ? <span className="help-block text-danger"><FormattedMessage id="profile.unable_change_email" /></span>
                    : null
                  }
                </div>
                <div className="form-group col-md-4">
                  <label className="control-label" htmlFor="phone_number">
                    <FormattedMessage id="clin_table.phone" />
                  </label>
                  <input
                    type="text"
                    maxLength={40}
                    className="form-control"
                    id="phone_number"
                    name="phone_number"
                    required
                    defaultValue={ user.phone }
                    onChange={(e) => this.addPropsToUpdateDoctor('phone',e.target.value)}
                  />
                </div>
                {deployedRussia() === true
                  ? (
                    <div className="form-group col-md-4">
                      <label className="control-label" htmlFor="telegram_id">
                        Telegram ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="telegram_id"
                        name="telegram_id"
                        defaultValue={ user.telegram_id }
                        onChange={(e) => this.addPropsToUpdateDoctor('telegram_id',e.target.value)}
                      />
                    </div>
                  ) : null
                }
              </div>
              <div
                className="form-group"
                style={{ marginTop: "25px", display: "flex", flexDirection: "column", gap: 8 }}
              >
                <p className="control-label" style={{ margin: 0 }}>
                  <FormattedMessage id="notify.doctor.subscribe.edit" />
                </p>

                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  {deployedRussia() ? (
                      <div className="row">
                        <div
                          className="сol-lg-4 col-md-4 col-sm-4 col-xs-4"
                          style={{ display: "flex", alignItems: "center", gap: 8, marginBottom: 0 }}
                        >
                          <input
                            type="checkbox"
                            name=""
                            id="subscribe-telegram"
                            style={{ marginTop: 0 }}
                            defaultValue={0}
                            defaultChecked={user.telegram_notify == "None" ? 0 : user.telegram_notify}
                            onClick={(e) => this.addPropsToUpdateDoctor("telegram_notify", Number(e.target.checked))}
                          />
                          <label
                            htmlFor="subscribe-telegram"
                            className="control-label"
                            style={{ marginBottom: 0 }}
                          >
                            Telegram
                          </label>
                        </div>
                      </div>
                  ) : null}

                  <div className="row">
                    <div
                      className="offset-md-4 col-lg-4 col-md-4 col-sm-4 col-xs-4"
                      style={{ display: "flex", alignItems: "center", gap: 8, marginBottom: 0 }}
                    >
                      <input
                        type="checkbox"
                        id="subscribe-email"
                        name=""
                        style={{ marginTop: 0 }}
                        defaultValue={0}
                        defaultChecked={ user.email_notify == "None" ? 0 : user.email_notify }
                        onClick={(e) => this.addPropsToUpdateDoctor("email_notify", Number(e.target.checked))}
                      />
                      <label
                        htmlFor="subscribe-email"
                        className="control-label"
                        style={{ marginBottom: 0 }}
                      >
                        Email
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr style={{margin: 0}}/>
            {this.renderPrefs()}

            <div className="form-actions">
              <button type="button" id="submit-pacient-btn" className="btn green" onClick={()=>this.submitButton()}><FormattedMessage id="BTN_SUBMIT" /></button>
            </div>
          </form>
        </div>
      </div>
    </div>
    )
  }

  renderDentalNotation() {
    const {user} = this.props;
    const preferences = {...user.preferences, ...this.state.preferences};
    return (
    <div className="form-group">
      <h4 style={{marginTop: 32}}><b><FormattedMessage id="DENTAL_NOTATION_HEADER"/></b></h4>
      {/* <label className="control-label" style={{marginBottom: 0}}><FormattedMessage id="DENTAL_NOTATION_HEADER"/></label> */}
      <div className="radio-list" style={{marginTop: 0}}>
        <label className="radio">
          <input type="radio" data-type="number"
            name="preferences.dental_notation"
            defaultValue={DN_TYPE_FDI}
            onChange={this.updatePreferences}
            defaultChecked={preferences.dental_notation === DN_TYPE_FDI}
          /> <FormattedMessage id="DENTAL_NOTATION_TYPE_FDI" />
        </label>
        <label className="radio">
          <input type="radio" data-type="number"
            name="preferences.dental_notation"
            defaultValue={DN_TYPE_PALMER}
            onChange={this.updatePreferences}
            defaultChecked={preferences.dental_notation === DN_TYPE_PALMER}
          /> <FormattedMessage id="DENTAL_NOTATION_TYPE_PALMER" />
        </label>
        <label className="radio">
          <input type="radio" data-type="number"
            name="preferences.dental_notation"
            defaultValue={DN_TYPE_UNIVERSAL}
            onChange={this.updatePreferences}
            defaultChecked={preferences.dental_notation === DN_TYPE_UNIVERSAL}
          /> <FormattedMessage id="DENTAL_NOTATION_TYPE_UNIVERSAL" />
        </label>
      </div>
    </div>
    )
  }

  renderPrefs() {
    const fm = (id) => this.props.intl.formatMessage({id});
    const {user} = this.props;
    const preferences = {...user.preferences, ...this.state.preferences};
    return (
    <div className="form-body">
      <h3><FormattedMessage id="preferences"/></h3>

      {/* <h4 style={{marginTop: 32}}><b><FormattedMessage id="preferences.common"/></b></h4> */}
      {deployedUSA() ? this.renderDentalNotation() : null}

      {/*<h4 style={{marginTop: 32}}><b><FormattedMessage id="preferences.ipr"/></b></h4>
      <div className="form-group">
        <label className="control-label">
          <input type="checkbox" name="preferences.perform_ipr"
            defaultChecked={preferences.perform_ipr}
            onInput={this.updatePreferences}
          />
          &nbsp;<FormattedMessage id="preferences.ipr_enabled"/>
        </label>
      </div>
    */}
      <h4 style={{marginTop: 32}}><b><FormattedMessage id="preferences.ipr"/></b></h4>
      <FormattedMessage id="preferences.ipr_enabled" />
      <div className="row">
          <div className="radio-list col-md-3" style={{ marginTop: "10px" }}>
            <label>
              <div className="radio">
                <span><input type="radio" name="preferences.perform_ipr"
                  defaultValue={1} onChange={this.updatePreferenceRadio} defaultChecked={[1, true].includes(preferences.perform_ipr)}/>
                </span>
              </div><FormattedMessage id="bool.true" />
            </label>
            <label>
              <div className="radio">
                <span><input type="radio" name="preferences.perform_ipr"
                  defaultValue={0} onChange={this.updatePreferenceRadio} defaultChecked={[0, false].includes(preferences.perform_ipr)}/>
                </span>
              </div><FormattedMessage id="bool.false" />
            </label>
          </div>

          <div className="form-group col-md-4">
            <label className="control-label"><FormattedMessage id="preferences.ipr_start_step"/></label>
            <input className="form-control intPositive" type="number" min="1" max={MAX_STAGES} step="1"
              disabled={!preferences.perform_ipr}
              defaultValue={preferences.ipr_start_step}
              name="preferences.ipr_start_step" id=""
              onInput={this.updatePreferences}
            />
          </div>
      </div>

      <h4 style={{marginTop: 32}}><b><FormattedMessage id="preferences.attachments"/></b></h4>
      <div className="form-group">
        <label className="control-label"><FormattedMessage id="preferences.attachments_delay"/></label>
        <input className="form-control intPositive" type="number" min="1" max={MAX_STAGES} step="1"
          name="preferences.place_attachments_on_step" id=""
          defaultValue={preferences.place_attachments_on_step}
          onInput={this.updatePreferences}
          style={{width: "30%"}}
        />
      </div>

      <h4 style={{marginTop: 32}}><b><FormattedMessage id="preferences.expansion"/></b></h4>
      <FormattedMessage id="preferences.always_expand" />
      <div className="radio-list" style={{ marginTop: "10px" }}>
        <label>
          <div className="radio">
            <span><input type="radio" name="preferences.always_expand"
              defaultValue={1} onChange={this.updatePreferenceRadio} defaultChecked={[1, true].includes(preferences.always_expand)}/>
            </span>
          </div><FormattedMessage id="bool.true" />
        </label>
        <label>
          <div className="radio">
            <span><input type="radio" name="preferences.always_expand"
              defaultValue={0} onChange={this.updatePreferenceRadio} defaultChecked={[0, false].includes(preferences.always_expand)}/>
            </span>
          </div><FormattedMessage id="bool.false" />
        </label>
      </div>
      {(deployedUSA() && user.specialty_id === 1) || deployedRussia()  ? (
      <div>
      <h4 style={{marginTop: 32}}><b><FormattedMessage id="preferences.incisor_relationship"/></b></h4>
      <div className="row">
        <div className="form-group col-md-4">
          <label className="control-label"><FormattedMessage id="preferences.incisor_relationship.overjet"/></label>
          <input className="form-control floatPositive" type="number" min="0" step="any"
            name="preferences.incisor_relationship_overjet" id=""
            defaultValue={preferences.incisor_relationship_overjet}
            onInput={this.updatePreferences}
          />
        </div>
        <div className="form-group col-md-4">
          <label className="control-label" style={{ maxWidth: '90%' }}>
            <FormattedMessage id="preferences.incisor_relationship.overbite"/>
          </label>
          <input className="form-control floatPositive" type="number" min="1" step="any"
            name="preferences.incisor_relationship_overbite" id=""
            defaultValue={preferences.incisor_relationship_overbite}
            onInput={this.updatePreferences}
          />
        </div>
        <div className="form-group col-md-4">
          <label className="control-label"><FormattedMessage id="preferences.upper_incisor_angulation"/></label>
          <input className="form-control" type="text"
            name="preferences.upper_incisor_angulation" id=""
            defaultValue={preferences.upper_incisor_angulation}
            onInput={this.updatePreferences}
          />
        </div>
      </div>

      <h4 style={{marginTop: 32}}><b><FormattedMessage id="preferences.overcorrection"/></b></h4>
      <div className="row">
        <div className="col-md-4">
          <FormattedMessage id="preferences.deep_bite_intrusion" />
          <div className="radio-list" style={{ marginTop: "10px" }}>
            <label>
              <div className="radio">
                <span><input type="radio" name="preferences.deep_bite_intrusion"
                  defaultValue={1} onChange={this.updatePreferenceRadio} defaultChecked={[1, true].includes(preferences.deep_bite_intrusion)}/>
                </span>
              </div><FormattedMessage id="bool.true" />
            </label>
            <label>
              <div className="radio">
                <span><input type="radio" name="preferences.deep_bite_intrusion"
                  defaultValue={0} onChange={this.updatePreferenceRadio} defaultChecked={[0, false].includes(preferences.deep_bite_intrusion)}/>
                </span>
              </div><FormattedMessage id="bool.false" />
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <FormattedMessage id="preferences.incisors_extrusion" />
          <div className="radio-list" style={{ marginTop: "10px" }}>
            <label>
              <div className="radio">
                <span><input type="radio" name="preferences.incisors_extrusion"
                  defaultValue={1} onChange={this.updatePreferenceRadio} defaultChecked={[1, true].includes(preferences.incisors_extrusion)}/>
                </span>
              </div><FormattedMessage id="bool.true" />
            </label>
            <label>
              <div className="radio">
                <span><input type="radio" name="preferences.incisors_extrusion"
                  defaultValue={0} onChange={this.updatePreferenceRadio} defaultChecked={[0, false].includes(preferences.incisors_extrusion)}/>
                </span>
              </div><FormattedMessage id="bool.false" />
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <FormattedMessage id="preferences.rotation_over_30dg" />
          <div className="radio-list" style={{ marginTop: "10px" }}>
            <label>
              <div className="radio">
                <span><input type="radio" name="preferences.rotation_over_30dg"
                  defaultValue={1} onChange={this.updatePreferenceRadio} defaultChecked={[1, true].includes(preferences.rotation_over_30dg)}/>
                </span>
              </div><FormattedMessage id="bool.true" />
            </label>
            <label>
              <div className="radio">
                <span><input type="radio" name="preferences.rotation_over_30dg"
                  defaultValue={0} onChange={this.updatePreferenceRadio} defaultChecked={[0, false].includes(preferences.rotation_over_30dg)}/>
                </span>
              </div><FormattedMessage id="bool.false" />
            </label>
          </div>
        </div>
      </div>
      </div>
      ) : null
      }

      <h4 style={{marginTop: 32}}><b><FormattedMessage id="preferences.additional_notes"/></b></h4>
      <div className="form-group">
        {/* <label className="control-label"><FormattedMessage id="preferences.additional_notes"/></label> */}
        <textarea className="form-control" rows="5" placeholder={fm("preferences.additional_notes.sub")}
          name="preferences.special_instructions" id=""
          defaultValue={preferences.special_instructions}
          onInput={this.updatePreferences}
        />
      </div>

      {deployedUSA()
        ? (
            <div>
              <h4 style={{ marginTop: 32 }}>
                <b><FormattedMessage id="HEADER_PAYMENT_METHOD" /></b>
              </h4>
              <div className="radio-list" style={{ marginTop: "10px" }}>
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        name="preferences.default_payment_method"
                        defaultValue={1}
                        onChange={this.updatePreferenceRadio}
                        defaultChecked={[1].includes(preferences.default_payment_method)}
                      />
                    </span>
                  </div>
                  <FormattedMessage id="PM_INVOICE" />
                </label>
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        name="preferences.default_payment_method"
                        defaultValue={3}
                        onChange={this.updatePreferenceRadio}
                        defaultChecked={[3].includes(preferences.default_payment_method)}
                      />
                    </span>
                  </div>
                  <FormattedMessage id="PM_CARD.usa" />
                </label>
              </div>
            </div>
          ) : null
        }

    </div>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(injectIntl(DoctorUpdatePage));
