import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { deployedUSA } from "../../../../common/utils";
import PageFooter from "../../../page_footer";
import PageHeader from "../../../page_header";
import Tour from "../../../patient/tour";
import PatientMenu from "../../../patient_menu";
import PaymentInvoice from "./payment_invoice";
import PaymentInvoiceUSA from "./payment_invoice_usa";

export default function PaymentInvoiceBody(props: RouteComponentProps<{ patient_id: string }>) {
  return (
    <div>
      <PageHeader />
      <Tour />
      <div className="container">
        <div className="page-container">
          <PatientMenu />
          {deployedUSA() ? <PaymentInvoiceUSA {...props} /> : <PaymentInvoice {...props} />}
        </div>
        <PageFooter />
      </div>
    </div>
  );
}
