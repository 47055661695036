import React from 'react';
import { connect } from 'react-redux';

import PatientMenu from '../patient_menu';
import PatientUpdate from './patient_update';
import PageFooter from '../page_footer';
import PageHeader from '../page_header';
import Tour from './tour';


const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}


const PatientUpdatePageBody = (props) => (
  <div>
    <PageHeader {...props}/>
    <Tour />
    <div className="container">
      <div className="page-container">
        <PatientMenu {...props}/>
        <div className="page-content-wrapper">
          <div className="page-content" style={{minHeight: 584}}>
            <div className="row">
              <div className="col-md-12">
                <PatientUpdate {...props}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageFooter {...props}/>
    </div>
  </div>
);


export default connect(mapStateToProps)(PatientUpdatePageBody);
