import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import RenderBanners from "../promotion/banners";
import { getBanners } from "../../actions/banners";

const mapStateToProps = (state) => {
  return {
    banners: state.banners,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBanners: () => dispatch(getBanners()),
  };
};

class Homepage extends Component {
  componentDidMount() {
    this.props.getBanners();
  }

  render() {
    const bannersHome = [];
    this.props.banners && this.props.banners.length !== 0 && this.props.banners.map((b) => (b.align === "center" ? bannersHome.push(b) : null));
    return (
      <div className="page-content-wrapper">
        {/* pacient table */}
        <div className="page-content" id="patients-table">
          <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <i className="icon-pin font-green" />
                <span
                  className="caption-subject font-green bold uppercase"
                  id="patients-table-block-title"
                >
                  <FormattedMessage id="pat_table.block_banners" />
                </span>
              </div>
            </div>
            <div id="carousel-home" className="carousel slide">
              <div className="carousel-inner" role="listbox">
                <RenderBanners banners={bannersHome} mobile={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
