import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { addInstructions, removeInstructions } from '../../../actions/patient_new_instructions';
import remoteLog from '../../../common/logging';
import { deployedUSA, deployedRussia } from '../../../common/utils';
import { formatDatetime, DATE_FORMAT } from '../../../common/datetime';
import { PAYERS, GENDER_F, GENDER_M } from '../../../common/constants';
import '../../../assets/css/react-datepicker.css';
import Loader from "../../common/loadingInProgress";


const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    lang: state.intl,
    instructions: state.instructions
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    addInstruction: (data) => dispatch(addInstructions(data)),
    removeInstruction: (data) => dispatch(removeInstructions(data)),
  }
}


class PatientUpdatePatientInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: null
    };
    this.addFirstName = this.addFirstName.bind(this);
    this.addLastName = this.addLastName.bind(this);
    this.addMiddleName = this.addMiddleName.bind(this);
    this.selectSex = this.selectSex.bind(this);
    this.addBdate = this.addBdate.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.renderPayerFields = this.renderPayerFields.bind(this);
    this.addPayerFirstName = this.addPayerFirstName.bind(this);
    this.addPayerLastName = this.addPayerLastName.bind(this);
    this.addPayerMiddleName = this.addPayerMiddleName.bind(this);
  }


  componentDidCatch(e) {
    remoteLog(e, 'patient_update_patient_info');
  }

  componentDidMount() {
    const { patient } = this.props;
    this.addFirstName(patient.first_name);
    this.addLastName(patient.last_name);
    this.addMiddleName(patient.middle_name.toLowerCase() === 'none' ? null : patient.middle_name);
    this.selectSex(patient.sex);
    (patient.bdate && patient.bdate.toLowerCase() !== 'none') ? this.addBdate(moment(patient.bdate)) : 0;
    if (deployedRussia() && this.props.intl.locale == 'ru') {
      this.addEmail(patient.email ? patient.email : null);
      this.addPayerFirstName(patient.payer_first_name ? patient.payer_first_name : null);
      this.addPayerLastName(patient.payer_last_name ? patient.payer_last_name : null);
      this.addPayerMiddleName(patient.payer_patronymic ? patient.payer_patronymic : null);

    }
  }

  addFirstName(data) {
    this.props.addInstruction({'first_name':data});
    this.props.setDirty('first_name', data);
  }

  addLastName(data) {
    this.props.addInstruction({'last_name':data});
    this.props.setDirty('last_name', data);
  }

  addMiddleName(data) {
    this.props.addInstruction({'middle_name':data});
    this.props.setDirty('middle_name', data);
  }

  addPayerFirstName(data) {
    this.props.addInstruction({'payer_first_name':data});
    this.props.setDirty('payer_first_name', data);
  }

  addPayerLastName(data) {
    this.props.addInstruction({'payer_last_name':data});
    this.props.setDirty('payer_last_name', data);
  }

  addPayerMiddleName(data) {
    this.props.addInstruction({'payer_patronymic':data});
    this.props.setDirty('payer_patronymic', data);
  }

  selectSex(data) {
    if (data == GENDER_F || data == GENDER_M) {
      this.props.addInstruction({'sex':parseInt(data)});
      this.props.setDirty('sex', parseInt(data));
    }
  }

  addBdate(data) {
    if (data) {
      this.setState({
        startDate: data
      });
      this.props.addInstruction({'bdate':moment(data).format('YYYY-MM-DD')});
      this.props.setDirty('bdate', moment(data).format('YYYY-MM-DD'));
    }
  }

  addEmail(data) {
    this.props.addInstruction({'email':data});
    this.props.setDirty('email', data);
  }

  renderPayerFields() {
    const locale = this.props.intl.locale;  
    const { instructions } = this.props;
    const { patient } = this.props;
    const fm = (id) => this.props.intl.formatMessage({id: id});

    if (deployedRussia() && locale == 'ru' && instructions && instructions.payer_id == PAYERS['PATIENT']) {
      if (patient.status === 'S_UNFILLED_CASE') {
          return (
            <div className="form-group">
              <label className="control-label" id="validation-email" style={{ fontWeight: '900' }}><FormattedMessage id="PAT_EMAIL"  />
                <span className="required" aria-required="true">*</span>
              </label>
              <input
                data-matomo-mask
                type="text"
                className="form-control"
                id="validation-email"
                name="email" 
                placeholder={fm("PAT_INFO_EMAIL_PLACEHOLDER")}
                onChange={(e) => this.addEmail(e.target.value)}
                value={instructions && instructions.email ? instructions.email : ""}
              />
              <br />
              <div className="row">
                <label className="control-label col-md-8" id="payer-another-person-label" style={{ fontWeight: '600' }}>
                  <FormattedMessage id="another.payer.person"  />
                </label>
              </div>
              <div className="row" id="patient-block-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" id="payer-lastname-label" style={{ fontWeight: '600' }}>
                      <FormattedMessage id="PAYER_LAST_NAME"  />
                    </label>
                    <input
                      data-matomo-mask
                      type="text"
                      className="form-control"
                      id="payer-lastname-value"
                      name="payer_last_name"
                      placeholder={fm("PAYER_LAST_NAME_PLACEHOLDER")}
                      onChange={(e) => this.addPayerLastName(e.target.value)}
                      value={instructions && instructions.payer_last_name ? instructions.payer_last_name : ""}
                      maxLength={64}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" id="payer-firstname-label" style={{ fontWeight: '600' }}>
                      <FormattedMessage id="PAYER_FIRST_NAME" />
                    </label>
                    <input
                      data-matomo-mask
                      type="text"
                      className="form-control"
                      id="payer-firstname-value"
                      name="payer_first_name"
                      placeholder={fm("PAYER_FIRST_NAME_PLACEHOLDER")}
                      onChange={(e) => this.addPayerFirstName(e.target.value)}
                      value={instructions && instructions.payer_first_name ? instructions.payer_first_name : ""}
                      maxLength={64}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                      <label className="control-label" id="payer-middlename-label" style={{ fontWeight: '600' }}>
                        <FormattedMessage id="PAYER_MIDDLE_NAME" /></label>
                      <input
                        data-matomo-mask
                        type="text"
                        className="form-control"
                        id="payer-middlename-value"
                        name="payer_patronymic_name"
                        placeholder={fm("PAYER_MIDDLE_NAME_PLACEHOLDER")}
                        onChange={(e) => this.addPayerMiddleName(e.target.value)}
                        value={instructions && instructions.payer_patronymic ? instructions.payer_patronymic : ""}
                        maxLength={64}
                      />
                    </div>
                </div>
              </div>
            </div>
          )
      } else {
        return (
          <div style={{ marginBottom: '20px' }}>
            <label className="control-label" id="validation-email" style={{ fontWeight: '900' }}><FormattedMessage id="PAT_EMAIL"  />
              <br /><span data-matomo-mask data-hj-suppress style={{ fontWeight: '400' }}>{patient.email}</span>
            </label>
          </div>
        )
      }
    }
    return null;
  }

  render() {
    if(this.props.patient.patient_id) {
      let fm = (id) => this.props.intl.formatMessage({id: id});
      let locale = this.props.lang.locale;  
      var patient = this.props.patient;
      var old_instructions = this.props.patient.instructions;
      old_instructions ?
        Object.keys(old_instructions).map((x)=> old_instructions[x] == "None" || old_instructions[x] == "NA" ? old_instructions[x] = "" : null)
        :
        null
      return (
        <div>
          <h3 className="block" id="patient-block-label" style={{ fontWeight: '900' }}><FormattedMessage id="BLOCKHEAD_PAT_INFO" /></h3>
          <div className="row" id="patient-block-body">
            <div className="col-md-4">
              <div className="form-group">
                <label className="control-label" id="patient-lastname-label" style={{ fontWeight: '900' }}><FormattedMessage id="PAT_INFO_LAST_NAME"  />
                  <span className="required" aria-required="true">*</span>
                </label>
                <input data-matomo-mask type="text" className="form-control" id="patient-lastname-value" name="last_name" placeholder={fm("PAT_INFO_LAST_NAME_PLACEHOLDER")} onChange={(e) => this.addLastName(e.target.value)} defaultValue={patient.last_name}/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="control-label" id="patient-firstname-label" style={{ fontWeight: '900' }}><FormattedMessage id="PAT_INFO_FIRST_NAME" />
                  <span className="required" aria-required="true">*</span>
                </label>
                <input data-matomo-mask type="text" className="form-control" id="patient-firstname-value" name="first_name" placeholder={fm("PAT_INFO_FIRST_NAME_PLACEHOLDER")} onChange={(e) => this.addFirstName(e.target.value)} defaultValue={patient.first_name}/>
              </div>
            </div>
            {deployedRussia()
            ? (
              <div className="col-md-4">
              <div className="form-group">
                <label className="control-label" id="patient-middlename-label" style={{ fontWeight: '900' }}><FormattedMessage id="PAT_INFO_MIDDLE_NAME" />
                  <span className="required"></span>
                </label>
                <input data-matomo-mask type="text" className="form-control" id="patient-middlename-value" name="patronymic_name" placeholder={fm("PAT_INFO_MIDDLE_NAME_PLACEHOLDER")} onChange={(e) => this.addMiddleName(e.target.value)} defaultValue={patient.middle_name == "none" || patient.middle_name == "None" ? null : patient.middle_name}/>
              </div>
            </div>
            ) : null
            }
          </div>
          {this.renderPayerFields()}
          <div className="form-group">
            <label className="control-label" id="patient-gender-label" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_SEX" /></label>
            <div className="radio-list">
              <label>
                <div className="radio"><span><input type="radio" id="patient-gender-value-female" name="sex" defaultValue={GENDER_F} onChange={(e) => this.selectSex(e.target.value)} defaultChecked={patient.sex == GENDER_F ? true : false }/></span></div> <FormattedMessage id="SEX_F" /> </label>
              <label>
                <div className="radio"><span><input type="radio" id="patient-gender-value-male" name="sex" defaultValue={GENDER_M} onChange={(e) => this.selectSex(e.target.value)} defaultChecked={patient.sex == GENDER_M ? true : false }/></span></div> <FormattedMessage id="SEX_M" /> </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="control-label" id="patient-bdate-label" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_BDATE" /></label>
                <div>
                <DatePicker
                  locale={locale}
                  className="form-control"
                  id="patient-bdate-value"
                  dateFormat={DATE_FORMAT}
                  minDate={moment('01-01-1900', DATE_FORMAT)}
                  openToDate={moment('01-01-1985', DATE_FORMAT)}
                  selected={this.state.startDate}
                  onSelect={e => this.addBdate(e)}
                  onChangeRaw={e => formatDatetime(e)}
                  onBlur={(e) => {
                    if (e.target.value.length < 10) {
                      e.target.value = '';
                      this.props.removeInstruction({'bdate':null});
                    }
                  }}
                  placeholderText={DATE_FORMAT}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return <Loader />
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientUpdatePatientInfo));
