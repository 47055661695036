import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { addInstructions } from "../../actions/patient_new_instructions";
import { convertToDentalNotation } from "../../common/utils";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInstruction: (data) => dispatch(addInstructions(data)),
  };
};

class PatientTeethItems extends Component {
  constructor() {
    super();
    let quads = [1, 2, 4, 3];
    this.teeth = quads.map((quad) => {
      let arr = [];
      for (let i = 1; i <= 8; i++) {
        arr.push(quad * 10 + i);
      }
      if (quad === 1 || quad === 4) arr.reverse();
      return arr;
    });

    this.inputs = {};
    this.state = {
      disabled: {}, // TODO
    };
    this.onInput = this.onInput.bind(this);
  }

  componentDidMount() {
    let el = this.refs.$el;
    let { name } = this.props;
    if (name) el.name = name;
  }
  // return array of checked teeth in FDI notation
  get value() {
    let value = [];
    for (let k in this.inputs) {
      if (this.inputs[k].checked) value.push(+k);
    }
    return value;
  }

  set value(value) {
    if (!Array.isArray(value))
      throw new Error(`Assertion error: ${this.constructor.name}.value should be only array!`);
    value.forEach((k) => (this.inputs[k].checked = true));
    this.refs.$el.value = value;
  }

  onInput(e) {
    let { target } = e;

    e.target = this.refs.$el;
    e.target.value = this.value;

    if (this.props.onInput) this.props.onInput(e);
    if (this.props.onInputLegacy) this.props.onInputLegacy(target);
  }

  render() {
    const { label } = this.props;

    let allTeeth = this.teeth.map(this.renderQuadrant.bind(this));
    let upperTeeth = allTeeth.slice(0, 2);
    let lowerTeeth = allTeeth.slice(2, 4);

    return (
      <div className="PatientTeethItems form-group" ref="$el" name={this.props.name}>
        {!label ? null : (
          <label className="control-label" style={{ fontWeight: "900" }} id="teeth-item-list">
            <FormattedMessage id={label} />
          </label>
        )}

        <div className="row">
          <div className="col-xs-12">
            <div className="teeth-controls-upper">{upperTeeth}</div>
          </div>
          <div className="col-xs-12">
            <div className="teeth-controls-lower">{lowerTeeth}</div>
          </div>
        </div>
      </div>
    );
  }

  renderQuadrant(quad, i) {
    const { arch } = this.props; // 1 - both jaws, 2 - only top, 3 - only bottom

    let isHidden = false;
    if (i <= 1 && arch === 3) isHidden = true;
    if (i >= 2 && arch === 2) isHidden = true;

    let teeth = quad.map(this.renderTooth.bind(this));
    const isHiddenClass = isHidden ? "teeth-controls--hidden" : "";

    return (
      <div key={i} className={`checkbox-list teeth-controls ${isHiddenClass}`}>
        {teeth}
      </div>
    );
  }

  renderTooth(i) {
    let { user, name, defaultValue, dentalNotation } = this.props;
    let { disabled } = this.state;
    if (!dentalNotation && user.preferences) dentalNotation = user.preferences.dental_notation;

    if (!defaultValue) defaultValue = [];
    if (!Array.isArray(defaultValue)) {
      console.error(`Assertion error: ${this.constructor.name}.value should be only array!`);
      defaultValue = [];
    }

    return (
      <label key={i}>
        {convertToDentalNotation(i, dentalNotation)}
        <br />
        <div className="checker">
          <span>
            <input
              type="checkbox"
              ref={(el) => (this.inputs[i] = el)}
              name={`${name}[${i}]`}
              defaultValue={i} // uses in legacy input event
              defaultChecked={defaultValue.includes(+i)}
              onInput={this.onInput}
              id={`${name.replace(/_/, "-")}-value-${i}`}
            />
          </span>
        </div>
      </label>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTeethItems);

export class PatientTeethItemsReadOnly extends Component {
  constructor(props) {
    super(props);
    const quads = [1, 2, 4, 3];
    this.teeth = quads.map((quad) => {
      let arr = [];
      for (let i = 1; i <= 8; i++) {
        arr.push(quad * 10 + i);
      }
      if (quad === 1 || quad === 4) arr.reverse();
      return arr;
    });
    this.renderInput = this.renderInput.bind(this);
  }

  renderInput(i) {
    return (
      <label key={i}>
        {convertToDentalNotation(i, this.props.notation)}
        <br />
        <div className="checker">
          <label>
            <input
              id={"checkbox"}
              name={this.props.name}
              type="checkbox"
              disabled={true}
              defaultChecked={this.props.defaultValue.includes(+i)}
            />
            <label htmlFor={"checkbox"}></label>
          </label>
        </div>
      </label>
    );
  }

  render() {
    const { defaultValue, label } = this.props;

    return defaultValue && defaultValue.length > 0 ? (
      <div className="PatientTeethItems form-group">
        <label className="control-label" style={{ fontWeight: "900" }} id="teeth-item-list">
          <FormattedMessage id={label} />
        </label>
        <div className="row">
          <div className="col-xs-12">
            <div className="teeth-controls-upper">
              {this.teeth.slice(0, 2).map((quad) => {
                return (
                  <div key={quad} className="checkbox-list teeth-controls">
                    {quad.map(this.renderInput)}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="teeth-controls-lower">
              {this.teeth.slice(2, 4).map((quad) => {
                return (
                  <div key={quad} className="checkbox-list teeth-controls">
                    {quad.map(this.renderInput)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export const PatientElasticsOptionsTeethItemsReadOnly = ({
  name,
  label,
  defaultValue,
  notation,
  instructions,
}) => {
  const quads = [1, 2, 4, 3];
  const teeth = quads.map((quad) => {
    let arr = [];
    for (let i = 1; i <= 8; i++) {
      arr.push(quad * 10 + i);
    }
    if (quad === 1 || quad === 4) arr.reverse();
    return arr;
  });

  const setClassName = (i) => {
    if (instructions.elastics_options[i] === 1) {
      return "button_hook_tip_read_only hook";
    } else if (instructions.elastics_options[i] === 2) {
      return "button_hook_tip_read_only button";
    } else {
      return "input_button";
    }
  };

  const setTooltip = (i) => {
    const elastics_options = instructions ? instructions.elastics_options : null;
    if (elastics_options && elastics_options[i] === 1) {
      return <FormattedMessage id="HOOK" tagName="span" />;
    } else if (elastics_options && elastics_options[i] === 2) {
      return <FormattedMessage id="BUTTON" tagName="span" />;
    }
  };

  const renderInput = (i) => {
    const elastics_options = instructions ? instructions.elastics_options : null;
    return (
      <label key={i}>
        {convertToDentalNotation(i, notation)}
        <br />
        <div className={setClassName(i)}>
          {(elastics_options && elastics_options[i] === 2) ||
          (elastics_options && elastics_options[i] === 1)
            ? setTooltip(i)
            : null}
          <input id="button_hook" name={name} type="checkbox" disabled={true} />
        </div>
      </label>
    );
  };

  return defaultValue && defaultValue.length > 0 ? (
    <div className="PatientTeethItems form-group">
      <label className="control-label" style={{ fontWeight: "900" }} id="teeth-item-list">
        <FormattedMessage id={label} />
      </label>
      <div className="row">
        <div className="col-xs-12">
          <div className="teeth-controls-upper">
            {teeth.slice(0, 2).map((quad) => {
              return (
                <div key={quad} className="checkbox-list teeth-controls">
                  {quad.map(renderInput)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-12">
          <div className="teeth-controls-lower">
            {teeth.slice(2, 4).map((quad) => {
              return (
                <div key={quad} className="checkbox-list teeth-controls">
                  {quad.map(renderInput)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
