import React from "react";
import { NavLink } from "react-router-dom";

export const Banner = (props: { path: string | null; image: string }) => (
  <div data-testid="banner_link">
    <div style={{ marginLeft: 1, marginRight: 1 }}>
      <a href={props.path} rel="noopener noreferrer" target="_blank">
        <div style={{ width: "100%", backgroundColor: "#3b8489" }}>
          <img
            src={props.image}
            width="100%"
            style={{
              width: "100%",
            }}
          />
        </div>
      </a>
    </div>
  </div>
);

export const BannerImage = (props: { image: string }) => (
  <div style={{ background: "#e9edf4", marginLeft: 1, marginRight: 1 }} data-testid="banner_image">
    <div style={{ marginLeft: 1, marginRight: 1 }}>
      <div style={{ width: "100%", backgroundColor: "#3b8489" }}>
        <img
          src={props.image}
          width="100%"
          style={{
            width: "100%",
          }}
        />
      </div>
    </div>
  </div>
);

export const NavLinkBanner = (props) => (
  <div>
    <div style={{ paddingTop: 16, marginLeft: 1, marginRight: 1 }}>
      <NavLink to={props.path} className="nav-link " id="sidebar-meetings">
        <div style={{ width: "100%", backgroundColor: "#3b8489" }}>
          <img
            src={props.image}
            width="100%"
            style={{
              width: "100%",
            }}
          />
        </div>
      </NavLink>
    </div>
  </div>
);
