import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { addInstructions, removeInstructions } from '../../../actions/patient_new_instructions';
import remoteLog from '../../../common/logging';
import { deployedRussia, deployedUSA } from '../../../common/utils';
import {
  PAYERS, PAYERS_TRANSLATION_MAP, INSTALLMENT, PAYMENT_OPTIONS_TRANSLATION_MAP
} from '../../../common/constants';
import Loader from "../../common/loadingInProgress";
import { CASE_RETAINERS } from '../../../common/courses';
import { PO_ADVANCED } from '../../../common/constants';
import { SBCreditComponent } from '../../common/common';


// Default payment option for USA. Other values are not used
const PO_ADVANCED_USA_VALUE = 1;

const PAYMENT_METHOD_CARD = 3;

const payment_method_data = function(data){
  let method;
  if (data == 1) {
    method = "PM_BANK"
  } else if (data == 2) {
    method = "PM_CASH"
  } else if (data == 3) {
    method = "PM_CARD"
  }
  return method;
}

const payment_option_data = function(data){
  let option;
  if (data == 1) {
    option = "PO_ADVANCE"
  } else if (data == 2) {
    option = "PO_SPLIT_2"
  } else if (data == 3) {
    option = "PO_SPLIT_3"
  }
  return option;
}


const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    lang: state.intl,
    instructions: state.instructions,
    courseInstallment: state.courseInstallment,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    addInstruction: (data) => dispatch(addInstructions(data)),
    removeInstructions: (data) => dispatch(removeInstructions(data)),
  }
}


class PatientUpdatePayment extends Component {

  constructor(props) {
    super(props);
    this.fm = this.fm.bind(this);
    this.initPaymentOption = this.initPaymentOption.bind(this);
    this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
    this.selectPaymentOption = this.selectPaymentOption.bind(this);
    this.selectPayer = this.selectPayer.bind(this);
    this.renderPayer = this.renderPayer.bind(this);
    this.setPaymentCreditOption = this.setPaymentCreditOption.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'patient_update_payment');
  }

  fm(id){
    if (deployedUSA()) {
      return this.props.intl.formatMessage({ id: id });
    }
  }

  componentDidMount() {
    const { patient } = this.props;
    if (deployedRussia() === true) {
      !((patient || {}).course || {}).payment_method || this.selectPaymentMethod(({
        PM_BANK: 1,
        PM_CASH: 2,
        PM_CARD: 3,
      })[patient.course.payment_method]);
      !((patient || {}).course || {}).payment_option || this.initPaymentOption(({
        PO_ADVANCE: 1,
        PO_SPLIT_2: 2,
        PO_SPLIT_3: 3,
      })[patient.course.payment_option]);
      if (this.props && this.props.lang && this.props.lang.locale === 'ru') {
        if (patient && patient.payer_id) {
          this.props.addInstruction({
            'payer_id': patient.payer_id,
            'payer_last_name': patient.payer_last_name || null,
            'payer_first_name': patient.payer_first_name || null,
            'payer_patronymic': patient.payer_patronymic || null
          });
        }
      }
    } else {
      !((patient || {}).course || {}).payment_method || this.selectPaymentMethod(({
        PM_BANK: 1,
        PM_CARD: 3,
      })[patient.course.payment_method]);
      !((patient || {}).course || {}).payment_option || this.initPaymentOption(({
        PO_ADVANCE: 1,
      })[patient.course.payment_option]);
    }
    if (deployedRussia() && patient.sber_credit === true) {
      this.setPaymentCreditOption();
    }
  }

  selectPaymentMethod(data) {
    if (deployedUSA() === true && (data === undefined || data === null)) {
      data = PAYMENT_METHOD_CARD;
    }
    this.props.addInstruction({'payment_method_id': parseInt(data)})
    this.props.setDirty('payment_method', payment_method_data(data));
  }

  setPaymentCreditOption() {
    /** Payment option should be Advanced in case if credit option is chosen. On the UI/Interface level we do not show this */
    this.props.addInstruction({ 'sber_credit': true, 'payment_option_id': PO_ADVANCED });
  }

  initPaymentOption(data) {
    this.props.addInstruction({'payment_option_id':parseInt(data)});
  }

  selectPaymentOption(data) {
    const { can_edit_payment_option } = this.props.patient;
    if (can_edit_payment_option) {
      this.props.addInstruction({'payment_option_id':parseInt(data), sber_credit: false});
      this.props.setDirty('payment_option', payment_option_data(data));
    }
  }

  selectPayer(data) {
    const payer_id = parseInt(data);
    this.props.setDirty('payer_id', payer_id);

    if (payer_id === PAYERS['PATIENT']) {
      if (this.props.instructions.payment_method_id === 3) {
        this.props.addInstruction({ 'payer_id': payer_id });
      } else {
        this.props.addInstruction({ 'payer_id': payer_id, 'payment_method_id': null});
      }
    } else if (payer_id === PAYERS['DOCTOR']) {
      if (this.props.instructions.payment_method_id === 3) {
        this.props.addInstruction({ 'payer_id': payer_id, 'email': null, 'payer_last_name': null, 'payer_first_name': null, 'payer_patronymic': null});
      } else {
        this.props.addInstruction({ 'payer_id': payer_id, 'payment_method_id': null, 'email': null, 'payer_last_name': null, 'payer_first_name': null, 'payer_patronymic': null});
      }
      if (this.props.instructions && this.props.instructions.sber_credit === true) {
        this.props.removeInstructions({ 'payment_option_id': null });
      }
      this.props.addInstruction({ 'sber_credit': false });
    } else {
      if (this.props.instructions.payment_method_id === 1) {
        this.props.addInstruction({ 'payer_id': payer_id, 'email': null, 'payer_last_name': null, 'payer_first_name': null, 'payer_patronymic': null});
      } else {
        this.props.addInstruction({ 'payer_id': payer_id, 'payment_method_id': null, 'email': null, 'payer_last_name': null, 'payer_first_name': null, 'payer_patronymic': null});
      }
      if (this.props.instructions && this.props.instructions.sber_credit === true) {
        this.props.removeInstructions({ 'payment_option_id': null });
      }
      this.props.addInstruction({ 'sber_credit': false });
    }
  }

  renderPayer() {
    const {locale} = this.props.lang;

    if (deployedRussia() === true && locale == 'ru') {
      const { patient } = this.props;
      const { instructions } = this.props;

      if (patient.status === "S_UNFILLED_CASE") {
        return (
            <div className="form-group" id="payer-section">
              <label className="control-label" id="payer-label" id="validation-payer_id" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_PAYER" />
                <span className="required" aria-required="true">*</span>
              </label>
              <div className="radio-list">
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        id="payer-value-1"
                        name="payer_id"
                        value={PAYERS['CLINIC']}
                        checked={instructions.payer_id === PAYERS['CLINIC']}
                        onChange={(e) => this.selectPayer(e.target.value)}/>
                    </span>
                  </div> <FormattedMessage id="PAYER_CLINIC" />
                </label>
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        id="payer-value-2"
                        name="payer_id"
                        value={PAYERS['DOCTOR']}
                        checked={instructions.payer_id === PAYERS['DOCTOR']}
                        onChange={(e) => this.selectPayer(e.target.value)}
                      />
                    </span>
                  </div> <FormattedMessage id="PAYER_DOCTOR" />
                </label>
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        id="payer-value-3"
                        name="payer_id"
                        value={PAYERS['PATIENT']}
                        checked={instructions.payer_id === PAYERS['PATIENT']}
                        onChange={(e) => this.selectPayer(e.target.value)}
                      />
                    </span>
                  </div> <FormattedMessage id="PAYER_PATIENT" />
                </label>
              </div>
              <div id="form_payer_error"> </div>
            </div>
          )
        } else {
          if (patient && patient.payer_id) {
            return (
              <div style={{ marginBottom: '20px' }}>
                <label className="control-label" id="payer-label" id="validation-payer_id" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_PAYER" />
                  <br /><br /><span style={{ fontWeight: '400' }}><FormattedMessage id={PAYERS_TRANSLATION_MAP[patient.payer_id]} /></span>
                </label>
              </div>
            )
          }
        }
    }
    return null;
  }

  render() {
    const { locale } = this.props.lang;
    const { instructions } = this.props;
    const course = this.props.instructions.course_id ? this.props.instructions.course_id : this.props.patient.course.course_id;
    const installments = this.props.courseInstallment[course];
    const courseSelected = true ? course !== 'None' : false;
    const sber_credit = (deployedRussia() && this.props.instructions && this.props.instructions.sber_credit === true);

    let payment_option_id = null;
    let old_instructions = this.props.patient.instructions;
    old_instructions ?
      Object.keys(old_instructions).map((x)=> old_instructions[x] == "None" || old_instructions[x] == "NA" ? old_instructions[x] = "" : null)
      :
      null;

    if (this.props.instructions && Object.keys(this.props.instructions).length > 0) {
      payment_option_id = this.props.instructions.payment_option_id;
    }

    if (this.props.patient.patient_id) {
      const { can_edit_payment_option } = this.props.patient;
      return (
        <div>
          <h3 className="block" style={{ fontWeight: '900' }}><FormattedMessage id="BLOCKHEAD_PAYMENT" /></h3>
            {this.renderPayer()}
            <div className="form-group" id="payment-section" data-toggle="tooltip" title={this.fm("tooltip_payment")}>
              <label className="control-label" id="validation-payment_method_id" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_PAYMENT_METHOD" />
                <span className="required" aria-required="true">*</span>
              </label>
              <div className="radio-list">
                {(this.props.instructions.payer_id === PAYERS['DOCTOR'] || this.props.instructions.payer_id === PAYERS['PATIENT'])
                ? null
                : (
                    <label>
                      <div className="radio">
                        <span>
                          <input
                            type="radio"
                            name="payment_method_id"
                            id="payment-method-value-1"
                            value={1}
                            onChange={(e) => this.selectPaymentMethod(e.target.value)}
                            checked={instructions.payment_method_id !== undefined && instructions.payment_method_id == 1 ? true : false }
                          />
                        </span>
                      </div> <FormattedMessage id={deployedRussia() === true ? "PM_BANK" : "PM_INVOICE"} />
                    </label>
                  )
                }
                {
                  (locale=="ru" && deployedRussia() === true && this.props.instructions.payer_id !== PAYERS['CLINIC']) || (deployedUSA() === true) ? (
                    <label>
                      <div className="radio">
                        <span>
                          <input
                            type="radio"
                            name="payment_method_id"
                            id="payment-method-value-3"
                            value={3}
                            onChange={(e) => this.selectPaymentMethod(e.target.value)}
                            checked={instructions.payment_method_id !== undefined && instructions.payment_method_id == 3 ? true : false }
                          />
                        </span>
                      </div> <FormattedMessage id={deployedRussia() === true ? "PM_CARD" : "PM_CARD.usa"} />
                    </label>
                  ) : null
                }
              </div>
              <div id="form_payment_method_error"> </div>
            </div>


            {can_edit_payment_option == true ?
              (
                <div>
                  {deployedRussia() === true ? (
                    <div className="form-group">
                      <label className="control-label" id="validation-payment_option_id" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_PAYMENT_OPTION" />
                        <span className="required" aria-required="true">*</span>
                      </label>
                      <div className="radio-list">
                      {(installments && installments.includes(INSTALLMENT['100PERCENT']) || !courseSelected) ? (
                        <label>
                          <div className="radio">
                            <span>
                              <input
                                type="radio"
                                id="payment-option-value-1"
                                name="payment_option_id"
                                value={1}
                                onChange={(e) => this.selectPaymentOption(e.target.value)}
                                checked={payment_option_id == 1 && !sber_credit}
                              />
                            </span>
                          </div>
                          <FormattedMessage id="PO_ADVANCE"/>
                        </label>
                      ) : null}
                      {(installments && installments.includes(INSTALLMENT['2STEPS']) || !courseSelected) ? (
                        <label>
                          <div className="radio">
                            <span>
                              <input
                                type="radio"
                                id="payment-option-value-2"
                                name="payment_option_id"
                                value={2}
                                onChange={(e) => this.selectPaymentOption(e.target.value)}
                                checked={payment_option_id == 2 && !sber_credit}
                              />
                            </span>
                          </div>
                          <FormattedMessage id="PO_SPLIT_2" />
                        </label>
                      ) : null}
                      {(installments && installments.includes(INSTALLMENT['3STEPS']) || !courseSelected) ? (
                        <label>
                          <div className="radio">
                            <span>
                                <input
                                  type="radio"
                                  id="payment-option-value-3"
                                  name="payment_option_id"
                                  value={3}
                                  onChange={(e) => this.selectPaymentOption(e.target.value)}
                                  checked={payment_option_id == 3 && !sber_credit}
                                />
                            </span>
                          </div>
                          <FormattedMessage id="PO_SPLIT_3"/>
                        </label>
                      ) : null
                      }
                      {(deployedRussia() === true && locale == 'ru' && instructions.payer_id === PAYERS['PATIENT']
                          && instructions.course_id != CASE_RETAINERS
                        ) ? (
                            <label>
                              <div className="radio">
                                <span>
                                  <input
                                    type="radio"
                                    id="payment-option-credit"
                                    name="sber_credit"
                                    checked={sber_credit === true}
                                    onChange={() => this.setPaymentCreditOption()}
                                  />
                                </span>
                              </div>
                              <SBCreditComponent />
                            </label>
                          ) : null
                      }
                      </div>
                      <div id="form_payment_option_error"> </div>
                    </div>
                  ) : (
                    <div>
                      {/* For USA deployment allow only 100% prepayment */}
                      <input hidden type="radio" id="payment-option-value-prepayment" name="payment_option_id" defaultValue={PO_ADVANCED_USA_VALUE} onChange={(e) => this.selectPaymentOption(e.target.value)}/>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {deployedRussia() === true
                    ? (
                        <div style={{ marginBottom: '20px' }}>
                          <label className="control-label" id="validation-payment_option_id-label" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_PAYMENT_OPTION" />
                            <br /><br />
                              <span style={{ fontWeight: '400' }}>
                                {payment_option_id ? <FormattedMessage id={PAYMENT_OPTIONS_TRANSLATION_MAP[payment_option_id]} /> : null}
                              </span>
                          </label>
                        </div>
                    ) : null
                  }
                </div>
              )
            }
        </div>
      )
    }
    return <Loader />
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientUpdatePayment));
