import { deployedRussia, deployedUSA, isDevelopment } from "./utils";

/**
 * Returns true if the english locale should be used in the application.
 */
export function isEnglishLocale(): boolean {
  return (
    deployedUSA() ||
    window.location.hostname.endsWith(".com") ||
    (isDevelopment &&
      // To view english translations on mobile devices by adding lang=en to the query string
      (window.location.search.includes("lang=en") ||
        // To view english translations on desktops, persisting between navigations and refreshes
        window.localStorage.getItem("lang") == "en"))
  );
}

export let buildLocale: "en-GB" | "en-US" | "ru-RU";

if (deployedRussia()) {
  if (isEnglishLocale()) {
    buildLocale = "en-GB";
  } else {
    buildLocale = "ru-RU";
  }
} else {
  buildLocale = "en-US";
}

// NOTE: To be able to toggle the `buildLocale` from our dev tools.
export function setBuildLocale(newBuildLocale: typeof buildLocale) {
  buildLocale = newBuildLocale;
}
