import React from 'react';
import PageHeader from "../page_header";
import PatientMenu from "../patient_menu";
import Notification from "../notification";
import PageFooter from "../page_footer";

const Wrapper = (ChildComponent) => {

    class ComposedComponent extends React.Component {
        render() {
            return (
                <div>
                    <PageHeader {...this.props}/>
                    <div className="container">
                        <div className="page-container">
                            <PatientMenu {...this.props}/>
                            <Notification {...this.props}/>
                            <ChildComponent {...this.props}/>
                        </div>
                        <PageFooter {...this.props}/>
                    </div>
                </div>
            )
        }
    }

    return ComposedComponent;

};

export default Wrapper;