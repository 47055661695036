import React from "react";
import { type RouteComponentProps, withRouter } from "react-router-dom";

import { store } from "./app-store";
import { deployedRussia, deployedUSA } from "./common/utils";

export function requireOfferSign<T>(Component: React.ComponentType<T>) {
  class SignOfferComponent extends React.Component<T & RouteComponentProps> {
    componentDidMount() {
      this.checkIfSigned();
    }

    componentDidUpdate() {
      this.checkIfSigned();
    }

    checkIfSigned() {
      const state = store.getState();
      if (
        (deployedRussia() && state.intl.locale == "ru" && state.user.has_signed_offer === false) ||
        (deployedUSA() && state.user && state.user.has_signed_offer === false)
      ) {
        this.props.history.push("/pages/offer");
      }
    }

    render() {
      const state = store.getState();

      if (
        (deployedRussia() && state.intl.locale == "en" && Object.keys(state.user).length > 0) ||
        (state.user && state.user.has_signed_offer)
      ) {
        return <Component {...this.props} />;
      }

      return null;
    }
  }

  return withRouter(SignOfferComponent);
}
