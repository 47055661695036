import moment from "moment";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { getSmiles } from "../actions/smiles";
import smileColorImg from "../assets/img/bonuses_page/smile_color.svg";
import smileGreyImg from "../assets/img/bonuses_page/smile_grey.svg";
import generalImg from "../assets/img/bonuses_page/smiles_overview_2024.svg";
import calendarImg from "../assets/img/calendar.svg";
import { deployedRussia } from "../common/utils";
import { useDocumentTitle } from "../hooks/use-document-title";
import { useAppDispatch, useAppSelector } from "../store";
import Wrapper from "./hoc/main_wrapper";
import { useServerYear } from "./page_footer";

function SmilesPage() {
  const intl = useIntl();

  useDocumentTitle(intl.formatMessage({ id: "bonuses.page.title"  }));
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const smiles = useAppSelector((state) => state.smiles);

  const serverYear = useServerYear();

  useEffect(() => {
    if (user.account_id != null) {
      dispatch(getSmiles(user.account_id));
    }
  }, [user, dispatch]);

  const showSmiles = deployedRussia() && intl.locale == "ru";

  if (!showSmiles) {
    return null;
  }

  const smilesLoaded = smiles && Object.keys(smiles).length > 0;

  if (!smilesLoaded) {
    return null;
  }

  return (
    <div className="page-content-wrapper">
      <div className="page-content" id="bonuses-page">
        <div className="portlet-title">
          <img
            className="tw-h-auto tw-max-w-full"
            width={945}
            height={477}
            src={generalImg}
            alt={
              `Программа лояльности 3D Smile Store. В ${serverYear} году при заказе каждого ` +
              `пациента Вы получаете 1 смайл`
            }
          />
        </div>

        <div className="portlet light bordered">
          <br />
          <br />
          <div className="caption">
            <img src={calendarImg} style={{ maxWidth: "20px", maxHeight: "20px" }} alt="" />
            <span
              style={{
                marginLeft: "1rem",
                position: "relative",
                top: ".2rem",
              }}
            >
              {`${moment(smiles.start, "YYYY-MM-DD", "en")
                .locale("ru")
                .format("DD MMMM YYYY")} - ${moment(smiles.end, "YYYY-MM-DD", "en")
                .locale("ru")
                .format("DD MMMM YYYY")}`}
            </span>
          </div>
          <br />
          <br />

          <div className="row" style={{ marginLeft: "-20px" }}>
            <div className="col-md-8">
              <div className="bonus">
                <div>
                  <div className="tip" style={{ width: "100px" }}>
                    <span id="t">
                      <FormattedMessage id="bonuses.page.active.bonuses" />
                    </span>
                    <img src={smileColorImg} alt="" />
                  </div>
                  <p
                    className="badge bonuses-smiles-count"
                    style={{
                      backgroundColor: "#82D9D4",
                      display: "inline-block",
                      fontWeight: "bolder",
                      position: "absolute",
                      left: "90px",
                      bottom: "50px",
                    }}
                  >
                    {smiles.current}
                  </p>
                </div>
                <div>
                  <div className="tip" style={{ width: "100px" }}>
                    <img src={smileGreyImg} alt="" />
                    <span>
                      <FormattedMessage id="bonuses.page.non.active.bonuses" />
                    </span>
                  </div>
                  <p
                    className="badge"
                    style={{
                      backgroundColor: "#BDCDDE",
                      display: "inline-block",
                      fontWeight: "bolder",
                      position: "absolute",
                      left: "200px",
                      bottom: "50px",
                    }}
                  >
                    {smiles.total - smiles.current}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col-md-4">
              <FormattedMessage id="bonuses.page.expert.description" />
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default Wrapper(SmilesPage);
