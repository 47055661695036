import React from 'react';
import PatientMenu from '../patient_menu';
import DoctorPaymentDataPage from './doctor_payment_data_page';
import PageFooter from '../page_footer';
import PageHeader from '../page_header';
import Notification from '../notification';
import Tour from '../patient/tour';


const DoctorPaymentDataPageBody = (props) => (
  <div>
    <PageHeader {...props}/>
    <Tour />
    <div className="container">
      <div className="page-container">
        <PatientMenu {...props}/>
        <Notification {...props}/>
        <DoctorPaymentDataPage {...props}/>
      </div>
      <PageFooter {...props}/>
    </div>
  </div>
);

export default DoctorPaymentDataPageBody;
