import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";
import { change, formValueSelector } from "redux-form";

import { MATERIAL_IMPRESSIONS, MATERIAL_SCAN } from "../../common/prescription";
import { useAppDispatch, useAppSelector } from "../../store";

const selector = formValueSelector("correction");

type TMaterial = typeof MATERIAL_SCAN | typeof MATERIAL_IMPRESSIONS;

export default function ImpressionScanOptions() {
  const material = useAppSelector((state) => selector(state, "material") as TMaterial | null);
  const dispatch = useAppDispatch();

  type TOption = { value: number; intlId: MessageDescriptor["id"] };
  const options: TOption[] = [
    { value: MATERIAL_SCAN, intlId: "C_SCAN" },
    { value: MATERIAL_IMPRESSIONS, intlId: "C_IMPRESSIONS" },
  ];

  return (
    <fieldset id="impressions-section" aria-required="true" className="tw-w-full">
      <legend id="validation-material" className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold">
        <FormattedMessage id="ATTACHMENT_INSTRUCTIONS" />
        <span
          aria-hidden="true"
          className="required tw-relative tw-bottom-1 tw-left-1 tw-text-[17px]"
        >
          *
        </span>
      </legend>

      <div className="tw-mb-0 tw-mt-1 tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.value}
            id={`impressions-label-${option.value}`}
            htmlFor={`impressions-value-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`impressions-value-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="material"
              value={option.value}
              checked={material == option.value}
              onChange={(e) => dispatch(change("correction", "material", e.target.value))}
            />
            <FormattedMessage id={option.intlId} />
          </label>
        ))}
      </div>

      <AnimatePresence>{material == MATERIAL_SCAN ? <ScanUploadWarning /> : null}</AnimatePresence>
    </fieldset>
  );
}

function ScanUploadWarning() {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0 },
    animate: { height: "auto", transition: { duration: 0.5 } },
    exit: { height: 0 },
  };

  return (
    <div className="tw-overflow-hidden">
      <motion.p
        className="tw-m-0 tw-font-semibold tw-text-[#ff9c05f7]"
        {...(shouldReduceMotion == true ? {} : animationProps)}
      >
        <span className="tw-block tw-h-4" />
        <FormattedMessage id="SCAN_UPLOAD_SCAN_WARNING" />
      </motion.p>
    </div>
  );
}
