import levenshtein from "js-levenshtein";

export const MIN_PASSWORD_LENGTH = 10;
export const REG_DIGITS = new RegExp("^.*[0-9]+.*$");
export const REG_UPPER_CHARS = new RegExp("^.*[A-Z]+.*$");
export const REG_LOWER_CHARS = new RegExp("^.*[a-z]+.*$");
export const REG_SPEC_CHARS = new RegExp("^.*[!\"#$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~ \\\\]+.*$");
export const SPEC_CHAR_TEXT =
  "!  \"  #  $  %  &  '  (  )  *  +  ,  -  .  /  :  ;  <  =  >  ?  @  [  \\  ]  ^  _  `  {  |  }  ~";
export const PASSWORD_LOGIN_MAX_RATIO = 0.6;

/**
 * Calculates strings similarity in range from 0 to 1.
 */
export function levenshteinRatio(s1: string, s2: string): number {
  const distance = levenshtein(s1, s2);
  const maxDistance = Math.max(s1.length, s2.length);
  const ratio = 1 - distance / maxDistance;
  return ratio;
}
