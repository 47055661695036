import React from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {NavLink} from "react-router-dom";


const mapStateToProps = (state) => {
    return {
        patient: state.patient,
        services: state.services
    }
}


class PatientAdditionalAlignersCard extends React.Component {

    getAdditionalAligners(patient_id) {
        if (this.props.patient.course.correction.slice(-1)[0].order_options.can_order_additional_aligners) {
            return (
                <div>
                    <div className="form-group">
                        <div className="form-block">
                            <NavLink to={'/pages/patient/'+patient_id+'/additional/aligners'} className="btn green">
                                <FormattedMessage id="pat.status.button.aligners.additional"/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const show_aligners = (
            this.props.services && this.props.services.length
            && this.props.services.filter(aligner => aligner.tag === 'ADDITIONAL_ALIGNERS').length > 0
        );

        return (
            <div className="portlet light bordered" id="add-aligners-side-block">
                <div className="portlet-title">
                    <div className="caption" id="add-aligners-side-block-name">
                        <i className="icon-speech font-green"></i>
                        <span className="caption-subject font-green bold uppercase">
                            <FormattedMessage id="pat.additional.aligners.page.header"/>
                        </span>
                    </div>
                </div>
                <div className="portlet-body" id="add-aligners-side-block-body">
                    {this.getAdditionalAligners(this.props.patient.patient_id)}
                    <br/>
                    <br/>
                    {show_aligners ?
                        (
                            <div>
                                <NavLink
                                    style={{position: 'relative',bottom: '10px'}}
                                    to={`/pages/patient/${this.props.patient.patient_id}/aligners/additional`}
                                >
                                    <i><FormattedMessage id="all.additional.aligners"/></i>
                                </NavLink>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        )
    }

}


export default connect(mapStateToProps)(PatientAdditionalAlignersCard);