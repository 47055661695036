import React from 'react';
import { connect } from 'react-redux';

import PatientMenu from '../patient_menu';
import PlanApprove from './3d_plan_approve_page';
import PageFooter from '../page_footer';
import PageHeader from '../page_header';
import Notification from '../notification';
import Tour from '../patient/tour';


const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}


const PlanApproveBody = (props) => (
  <div>
    <PageHeader {...props}/>
    <Tour />
    <Notification {...props}/>
    <div className="container">
      <div className="page-container">
        <PatientMenu {...props}/>
        <PlanApprove {...props}/>
      </div>
      <PageFooter {...props}/>
    </div>
  </div>
);


export default connect(mapStateToProps)(PlanApproveBody);
