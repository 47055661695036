import React, {Component} from 'react';
import { connect } from 'react-redux';

import PatientId from './patient_show/patient_id';
import PatientDeliveries from './patient_show/patient_deliveries';
import { getPatientId } from '../../actions/get_patient_id';
import remoteLog from '../../common/logging';
import Loader from '../../components/common/loadingInProgress';


const mapStateToProps = (state) => {
  return {
    patient: state.patient
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getData: (patient_id) => dispatch(getPatientId(patient_id)),
  }
}


class PatientDeliveryPageBody extends Component {

  UNSAFE_componentWillMount() {
    const patient_id = this.props.match.params.patient_id;
    this.props.getData(patient_id);
  }

  componentDidCatch(e) {
    remoteLog(e, 'patient_delivery_page_body');
  }

  render() {
      if(this.props.patient.patient_id) {
        return (
          <div className="row">
            <div className="col-md-8">
              <PatientDeliveries />
            </div>
            <div className="col-md-4">
              <PatientId/>
            </div>
          </div>
        );
      }
      return <Loader />;
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PatientDeliveryPageBody);
