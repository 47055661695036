import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { change, getFormValues } from "redux-form";

import { courseSelectedAction, selectCourseAction } from "../../../actions/get_course_installment";
import { addInstructions, removeInstructions } from "../../../actions/patient_new_instructions";
import { CASE_RX_TYPE_STANDARD, priceList } from "../../../common/constants";
import {
  CASE_CBCT,
  CASE_FULL,
  CASE_PLAN_3D,
  CASE_RETAINERS,
  CASE_SHORT,
  CASE_SINGLE_ARCH,
  CASE_SUPER_SHORT,
  isCBCTCourse,
  isChildrenCourse,
  isRetainersCourse,
  isPlanCourse,
} from "../../../common/courses";
import remoteLog from "../../../common/logging";
import { ARCH_BOTH } from "../../../common/prescription";
import { deployedUSA } from "../../../common/utils";
import type { RootState } from "../../../store";
import ImpressionScanOptions from "../impression_scan_options";
import { rushCorrectionOn } from "../../../actions/post_patient";
import { PriceList } from "../price_list";

const predict_link = "/api/v1/manuals/document/51";

const mapStateToProps = (state: RootState) => ({
  lang: state.intl,
  instructions: state.instructions,
  courseInstallment: state.courseInstallment,
  // rush_correction: state.rushCorrection,
  formValues: getFormValues("correction")(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    addInstructions: (data) => dispatch(addInstructions(data)),
    removeInstructions: (data) => dispatch(removeInstructions(data)),
    selectCourseAction: (data) => dispatch(selectCourseAction(data)),
    courseSelected: (flag) => dispatch(courseSelectedAction(flag)),
    change: (form, field, value) => dispatch(change(form, field, value)),
    rushCorrection: (data) => dispatch(rushCorrectionOn(data)),
  };
};

type PatientNewInstructionsCourseProps = PropsFromRedux & { intl: IntlShape };

class PatientNewInstructionsCourse extends Component<PatientNewInstructionsCourseProps> {
  constructor(props: PatientNewInstructionsCourseProps) {
    super(props);
    this.state = {
      showPrice: false,
    };
    this.selectCourse = this.selectCourse.bind(this);
    this.addCondition = this.addCondition.bind(this);
    this.selectDeepCBCTAnalysis = this.selectDeepCBCTAnalysis.bind(this);
    this.addComment = this.addComment.bind(this);
    this.addMaterial = this.addMaterial.bind(this);
    this.togglePrice = this.togglePrice.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, "patient_new_instructions_course");
  }

  togglePrice() {
    this.setState({ showPrice: !this.state.showPrice });
  }

  selectCourse(data: string) {
    const course_id = parseInt(data);

    if (this.props.instructions && this.props.instructions.course_id !== course_id) {
      if (
        !this.props.courseInstallment[course_id].includes(this.props.instructions.payment_option_id)
      ) {
        this.props.removeInstructions({ payment_option_id: "" });
        this.props.addInstructions({ payment_option_id: null });
      }
    }
    if (this.props.instructions && course_id == CASE_RETAINERS) {
      this.props.removeInstructions({ sber_credit: false });
    }
    this.props.addInstructions({ course_id: course_id });
    this.props.addInstructions({ deep_cbct: null });

    this.props.setDirty(course_id);
    this.props.selectCourseAction(this.props.courseInstallment[course_id]);
    this.props.courseSelected(true);

    const correctionFormValues = this.props.formValues;
    if (
      correctionFormValues &&
      correctionFormValues.arch &&
      correctionFormValues.arch == ARCH_BOTH
    ) {
      if (course_id == CASE_SINGLE_ARCH || course_id == CASE_SUPER_SHORT) {
        this.props.change("correction", "arch", null);
      }
    }
  }

  selectDeepCBCTAnalysis(data: boolean) {
    this.props.addInstructions({ deep_cbct: data });
  }

  addCondition(data: string) {
    this.props.addInstructions({ condition: data });
    this.props.setDirty("condition", data);
  }

  addComment(data) {
    this.props.change("correction", "comment", data);
  }

  addMaterial(data) {
    this.props.change("correction", "material", data);
  }

  render() {
    const rxTypeId = this.props.rxTypeId;
    const locale = this.props.lang.locale;
    const fm = (id) => this.props.intl.formatMessage({ id: id });

    const course_id = this.props.instructions.course_id || null;
    const show_cbct_option =
      course_id !== null &&
      !isCBCTCourse(course_id) &&
      !isRetainersCourse(course_id) &&
      (deployedUSA() ? !isChildrenCourse(course_id) : true);
    const show_rush_prod = !isPlanCourse(course_id) ? true : false;

    return (
      <div id="course-section" style={{ marginTop: "20px" }}>
        <div className="form-group">
          <label
            className="control-label"
            id="validation-course_id"
            style={{ fontWeight: "900", fontSize: "17px", marginBottom: "10px" }}
          >
            <FormattedMessage id="HEADER_INSTRUCTIONS" />
            <span style={{ marginRight: "4px" }} className="required" aria-required="true">
              *
            </span>
            <span className="prescription_tooltip_show">
              <i style={{ fontSize: "14px" }} className="icon-question" />
              <span className="d-inline-block prescription_tooltip">
                <FormattedMessage id="course.type.tooltip" />
                <a href={predict_link} rel="noopener noreferrer" target="_blank">
                  <FormattedMessage id="tooltip.chart" />
                </a>
                <br />
                <FormattedMessage id="course.type.tooltip.sec" />
              </span>
            </span>
            <button
              className="btn tw-ml-4 tw-rounded-md tw-text-xs"
              onClick={() => this.togglePrice()}
            >
              <FormattedMessage id={this.state.showPrice ? "C_SHOW_PRICE_HIDE" : "C_SHOW_PRICE"} />
            </button>
          </label>
          {this.state.showPrice ? (
            <AnimatePresence>
              <PriceList />
            </AnimatePresence>
          ) : (
            <div className="radio-list">
              <label id="course-label-5">
                <div className="radio">
                  <span>
                    <input
                      style={{ marginTop: "10px" }}
                      type="radio"
                      name="course_id"
                      defaultValue={CASE_CBCT}
                      onChange={(e) => this.selectCourse(e.target.value)}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <img
                    src="/img/deepcbct.png"
                    alt="cbct-icon"
                    style={{ width: "80px", height: "42px" }}
                  />
                  <span className="prescription_tooltip_show tw-px-2">
                    <i style={{ fontSize: "14px" }} className="icon-question" />
                    <span className="d-inline-block prescription_tooltip">
                      <FormattedMessage id="cbct.tooltip" />
                    </span>
                  </span>
                  <br />
                  <FormattedMessage id="C_UNLIMITED_COMMENT_USA" />
                </div>
              </label>

              <label id="course-label-1">
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-1"
                      name="course_id"
                      defaultValue={CASE_FULL}
                      onChange={(e) => this.selectCourse(e.target.value)}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_FULL ? "boldText" : null}>
                    <FormattedMessage id="C_FULL_USA" />
                  </span>
                  <br />
                  <FormattedMessage id="C_FULL_COMMENT_USA" />
                </div>
              </label>

              <label id="course-label-2">
                <div className="radio">
                  <input
                    type="radio"
                    id="course-value-2"
                    name="course_id"
                    defaultValue={CASE_SHORT}
                    onChange={(e) => this.selectCourse(e.target.value)}
                  />
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_SHORT ? "boldText" : null}>
                    <FormattedMessage id="C_SHORT_USA_V2" />
                  </span>
                  <br />
                  <FormattedMessage id="C_SHORT_COMMENT_USA_V2" />
                </div>
              </label>

              <label id="course-label-3">
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-3"
                      name="course_id"
                      defaultValue={CASE_SINGLE_ARCH}
                      onChange={(e) => this.selectCourse(e.target.value)}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_SINGLE_ARCH ? "boldText" : null}>
                    <FormattedMessage id="C_ONE_JAW_USA" />
                  </span>
                  <br />
                  <FormattedMessage id="C_ONE_JAW_COMMENT_USA" />
                </div>
              </label>

              {/* <label id="course-label-4">
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        id="course-value-4"
                        name="course_id"
                        defaultValue={CASE_SUPER_SHORT}
                        onChange={(e) => this.selectCourse(e.target.value)}
                      />
                    </span>
                  </div>
                  <div className="course_title">
                    <span className={course_id === CASE_SUPER_SHORT ? "boldText" : null}>
                      <FormattedMessage id="C_SUPER_SHORT_USA" />
                    </span>
                    <br /><FormattedMessage id="C_SUPER_SHORT_COMMENT_USA" />
                  </div>
                </label> */}

              <label id="course-label-6">
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-6"
                      name="course_id"
                      defaultValue={CASE_PLAN_3D}
                      onChange={(e) => this.selectCourse(e.target.value)}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_PLAN_3D ? "boldText" : null}>
                    <FormattedMessage id="C_3D_PLAN_USA" />
                    <br />
                  </span>
                </div>
              </label>

              <label id="course-label-8">
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-8"
                      name="course_id"
                      defaultValue={CASE_RETAINERS}
                      onChange={(e) => this.selectCourse(e.target.value)}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_RETAINERS ? "boldText" : null}>
                    <FormattedMessage id="C_RETAINERS" />
                  </span>
                  <FormattedMessage id="C_RETAINERS_COMMENT" />
                </div>
              </label>
            </div>
          )}

          <AnimatePresence>
            {isCBCTCourse(course_id) || this.props.instructions.deep_cbct == true ? (
              <ScanUploadCTWarning />
            ) : null}
          </AnimatePresence>

          {/* {deployedRussia() && locale == "ru" && this.props.instructions && is3DPROCourse(this.props.instructions.course_id)
            ? (
              <div className="col-xs-12">
                <div className="alert alert-warning" id="course-alert-warning" style={{ color: "dimgrey" }}>
                  <FormattedMessage id="3D_SMILE_PRO_WARNING" />
                </div>
              </div>
            ) : null
          } */}
          <div id="form_course_error" />
        </div>

        {deployedUSA() && rxTypeId === CASE_RX_TYPE_STANDARD ? (
          <div className="form-group">
            <label
              className="control-label"
              id="validation-condition"
              style={{ fontWeight: "900" }}
            >
              <FormattedMessage id="HEADER_CONDITION" />
            </label>
            <textarea
              data-matomo-mask
              className="form-control"
              id="patient-diagnosis-value"
              rows={5}
              name="diagnos"
              onChange={(e) => this.addCondition(e.target.value)}
            />
          </div>
        ) : null}

        {deployedUSA() === true && rxTypeId === CASE_RX_TYPE_STANDARD ? (
          <div className="form-group">
            <label className="control-label" id="validation-comment" style={{ fontWeight: "900" }}>
              <FormattedMessage id="EXTRA_COMMENTS" />
              <span className="required" aria-required="true">
                *
              </span>
            </label>
            <textarea
              data-matomo-mask
              className="form-control"
              rows={5}
              name="comment"
              onChange={(e) => this.addComment(e.target.value)}
            />
          </div>
        ) : null}

        <ImpressionScanOptions />

        <div id="deep-cbct-section">
          <h5 id="deep-cbct-label" className="block" style={{ fontWeight: 900 }}>
            <FormattedMessage id="ADDITIONAL_INSTRUCTIONS" />
          </h5>

          {show_cbct_option ? (
            <label
              htmlFor="deep-cbct-value-1"
              style={{ display: "flex", alignItems: "center", gap: 8 }}
            >
              <input
                id="deep-cbct-value-1"
                style={{ marginTop: 0 }}
                type="checkbox"
                name="deep_cbct"
                defaultValue={1}
                checked={this.props.instructions.deep_cbct == true}
                onChange={(e) => this.selectDeepCBCTAnalysis(e.currentTarget.checked)}
              />
              <FormattedMessage id="C_DEEP_CBCT_ANALYSIS" />
            </label>
          ) : null}

          {show_rush_prod ? (
            <label htmlFor="rash" style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <input
                id="rash"
                style={{ marginTop: 0 }}
                type="checkbox"
                name="rush_correction"
                onChange={(e) => this.props.rushCorrection(e.currentTarget.checked)}
              />
              <span className="tw-font-bold">
                <FormattedMessage id="additional_service_rush" />{" "}
              </span>
              <span className="tw-text-[12px]">
                <FormattedMessage id="rush_service_comment" />
              </span>
            </label>
          ) : null}
        </div>
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientNewInstructionsCourse));

function ScanUploadCTWarning() {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0 },
    animate: { height: "auto", transition: { duration: 0.5 } },
    exit: { height: 0 },
  };

  return (
    <div className="col-xs-12 tw-overflow-hidden">
      <motion.p className="tw-m-0" {...(shouldReduceMotion ? {} : animationProps)}>
        <span className="alert-warning tw-block tw-p-4">
          <FormattedMessage id="SCAN_UPLOAD_CT_WARNING" />
        </span>
        <span className="tw-block tw-h-5" />
      </motion.p>
    </div>
  );
}
