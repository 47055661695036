import React, { Fragment } from "react";

export const newLineToLineBreakTag = (string) => string.split('\n').map((item, index) => (index === 0) ? item : [<br key={index} />, item]);


export const TextWithLinksFormatter = ({ children }) => {
    const isUrl = (word) => {
      const urlPattern =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
      return word.match(urlPattern) !== null;
    };
  
    const addMarkup = (word, index) => {
      const space = index !== 0 ? ' ' : '';
      return isUrl(word) ? (
        <Fragment>
            {space}
            <a key={index} target="_blank" href={word}>{word}</a>
        </Fragment>
      ) : (
        <Fragment>
            {space}
            <span key={index}>{word}</span>
        </Fragment>
      );
    };
  
    const words = children.split(" ");
    const formattedWords = words.map((w, i) => addMarkup(w, i));
  
    return <>{formattedWords}</>;
  };