import { api, type TYooKassaPaymentStatus } from "../../../api";
import type { EventMeetingType, TEvent } from "../../../reducers/events";

type PaginatedResponse<T> = {
  total: number;
  count: number;
  prev: number | null;
  next: number | null;
  results: T[];
};

export type EventsQueryArgs = {
  endpoint:
    | "offline"
    | "online"
    | "recordings"
    | "enrolled/upcoming"
    | "enrolled/visited"
    | "enrolled/recordings";
  search?: string;
  free?: boolean;
  page?: number;
  per_page?: number;
};

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<PaginatedResponse<TEvent>, EventsQueryArgs>({
      providesTags: ["Event"],
      query: ({ endpoint, ...params }) => {
        const searchParams = new URLSearchParams();

        for (let [key, value] of Object.entries(params)) {
          if (typeof value == "string") {
            value = value.trim();
          }
          if (value === undefined || value === "") {
            continue;
          }
          searchParams.append(key, value.toString());
        }

        return { url: `events/${endpoint}`, params: searchParams.entries() };
      },
    }),
    getUnpaidEvents: builder.query<Record<EventMeetingType, number>, void>({
      query: () => ({ url: "events/unpaid" }),
    }),
    getEventPaymentStatus: builder.query<
      { status: TYooKassaPaymentStatus },
      { transactionId: string }
    >({
      query: (params) => ({ url: `events/payments/${params.transactionId}` }),
    }),
  }),
});

export const { useGetEventsQuery, useGetUnpaidEventsQuery, useGetEventPaymentStatusQuery } =
  extendedApi;
