import React, { Component } from 'react';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import { FormattedMessage } from 'react-intl';

import { getTour } from '../../actions/get_tour'; 


const mapStateToProps = (state) => {
  return {
    tour: state.tour
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTour: (data) =>  dispatch(getTour(data))
  }
}


class Tour extends Component {
  constructor(props){
    super(props)
    this.stopTour = this.stopTour.bind(this);
    this.state = {
      steps: [
        {
          content: <FormattedMessage id="tour.take.tour" />,
          target: '.top-menu',
          placement: 'center',
          locale:{
            next: <FormattedMessage id="bool.true" />
          }
        },
        {
          target: '.dropdown-user',
          content: <FormattedMessage id="tour.account" />,
          placement: 'auto',
          spotlightPadding: 0
        },
        {
          target: '#sidebar-scanners',
          content: <FormattedMessage id="tour.scanners" />,
          placement: 'auto',
          spotlightPadding: 5
        },
        {
          target: '#sidebar-patient-add',
          content: <FormattedMessage id="tour.patient.new" />,
          placement: 'auto',
        },
        {
          target: '#sidebar-patients',
          content: <FormattedMessage id="tour.patients" />,
          placement: 'auto'
        },
        {
          target: '#sidebar-payment',
          content: <FormattedMessage id="tour.payment" />,
          placement: 'auto'
        },
        {
          target: '#zendesk',
          content: <FormattedMessage id="tour.chat" />,
          placement: 'top',
          spotlightPadding: 5,
          offset: 150,
          styles: {
            spotlight : {
              backgroundColor: 'transparent',
              height: '74px'
            },
          }
        }
      ]
    };
  }

  stopTour(data) {
    const { action, step } = data;
    if (action === 'skip' || action === 'reset') {
      document.body.style.overflow = "auto";
      this.props.getTour(false)
    }
  }

  render () {

    const tour = this.props.tour ? this.props.tour : null;
    const { steps } = this.state;

    return (
      <div>
        <Joyride
          callback={(data) => this.stopTour(data)}
          run={!!tour}
          steps={steps}
          showSkipButton
          hideCloseButton
          continuous={true}
          scrollToFirstStep={true}
         // disableScrollParentFix={true}
          showProgress={true} 
          disableOverlayClose={true}
         // disableScrolling={true}
          styles={{
            options: {
              arrowColor: "rgba(255, 255, 255, 1)",
              arrow: {
                fontSize: "32px"
              },
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              beaconSize: 36,
              zIndex: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
            },
            overlayColor: 'rgba(83, 79, 79, 1)',
            overlay: {
              backgroundColor: 'rgba(83, 79, 79, 1)',
             // top: "-25px"
            },
            tooltipContent: {
              color: 'rgba(83, 79, 79, 1)'
            },
            buttonNext: {
              backgroundColor: 'transparent',
              border: 'none',
              outline: 'none',
              color: 'rgba(83, 79, 79, 1)',
              fontSize: '14px'
            },
            buttonBack: {
              marginLeft: 'auto',
              marginRight: 5,
              color: 'rgba(83, 79, 79, 1)',
              fontSize: '14px'
            },
            beacon: {
              display: 'inline-block',
              position: 'relative',
              backgroundColor: '#20D3E2'
            }
          }}
        />
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Tour);

