const enumStatus = [
    "pending",
    "fulfilled",
    "rejected"
];

export default function createExposedPromise() {
    let __resolve, __reject;
    let promise = new Promise((resolve, reject) => {
        __resolve = resolve;
        __reject = reject;
    });

    promise.resolve = __resolve;
    promise.reject = __reject;
    promise.status = 0;

    promise.then(
        (data) => {
            promise.status = 1;
            return data;
        },
        (error) => {
            promise.status = 2;
            throw error;
        }
    );    

    return promise;
}
