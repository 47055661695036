import React, {Component} from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import {FormattedMessage, injectIntl} from 'react-intl';

import remoteLog from '../common/logging';
import { sendPassword } from '../actions/restorePassword';
import Logo from './logo';
import Notification from './notification';


const mapStateToProps = (state) => {
  return {
    errorMessage: state.errorMessage,
    lang: state.intl,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    sendMail: (login,locale) => dispatch(sendPassword(login,locale))
  }
}


function LoginErrorMessage( props ) {
  if (! props.errorMessage) {
    return null
  } else {
    return (
      <div className="alert alert-danger">
        <button className="close" data-close="alert" />
        <span id="login-error-msg"><FormattedMessage id={props.errorMessage} /></span>
      </div>
    )
  }
}


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
      login: null
    }
    this.init = false;
    this.success = false;
    this.setValue = this.setValue.bind(this);
    this.remindPassword = this.remindPassword.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'forgot_password');
  }

  remindPassword(login) {
    this.props.sendMail(login, this.props.lang.locale).then((response) => {
      if (response.status === true) {
        this.success = true;
        this.forceUpdate();
      }
    });
  }

  setValue(event) {
    const value = event.target.value;
    if (value === null || value === undefined || value === '') {
      this.setState({ buttonDisabled: true, login: null });
    } else {
      this.setState({ buttonDisabled: false, login: value });
    }
  }

  render() {
    const { buttonDisabled, login } = this.state;
    let fm = (id) => this.props.intl.formatMessage({id: id});
    return (
      <div>
        <Logo/>
        <Notification {...this.props}/>
        <div className="content">
           {!this.success ?
            (
              <form className="forget-form" method="post" onSubmit={(e) => {e.preventDefault(); this.init = true; this.remindPassword(login)}}>
                <h3><FormattedMessage id="reminder.forgot" /></h3>
                {this.init ? <LoginErrorMessage errorMessage={this.props.errorMessage} /> : ''}
                <p><FormattedMessage id="reminder.email" /></p>
                <div className="form-group">
                    <div className="input-icon">
                        <i style={{margin: '11px 2px 4px 10px'}} className="fa fa-user"/>
                        <input
                          className="form-control placeholder-no-fix"
                          type="text"
                          autoComplete="off"
                          placeholder={fm("reminder.placeholder.login")}
                          name="login"
                          onChange={event => this.setValue(event)}
                        />
                    </div>
                </div>
                <div className="form-actions">
                    <NavLink to="/">
                      <button type="button" id="back-btn" className="btn grey-salsa btn-outline"><FormattedMessage id="reminder.back" /></button>
                    </NavLink>
                    <button
                      type="submit"
                      className="btn green pull-right"
                      disabled={buttonDisabled}
                    >
                        <FormattedMessage id="reminder.submit" />
                    </button>
                </div>
              </form>
            ) : (
              <div>
                <span><FormattedMessage id="reminder.mail_send" /></span><br />
                <NavLink to="/login"><span><FormattedMessage id="reminder.link" /></span></NavLink>
              </div>
            )
           }
        </div>
      </div>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ForgotPassword));
