import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

import { getExtraServices } from '../../../../actions/extra_services';
import { getPatientId } from '../../../../actions/get_patient_id';
import { sendInvoiceRequiest } from '../../../../actions/invoice';
import { PAYERS, PAYMENT_SYSTEM_TYPE, SBERBANK_DISCLAIMER } from '../../../../common/constants';
import remoteLog from '../../../../common/logging';
import { isDevelopment } from '../../../../common/utils';
import type { TPatient } from '../../../../reducers/dashboard';
import type { RootState } from '../../../../store';
import Loader from '../../../common/loadingInProgress';

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    invoice: state.invoice,
    services: state.services,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendInvoiceRequiest: (patient_id, amount) => dispatch(sendInvoiceRequiest(patient_id, amount)),
    getPatient: (patient_id) => dispatch(getPatientId(patient_id)),
    getExtraServices: (patient_id) => dispatch(getExtraServices(patient_id)),
    eraseStateProp: (props) => dispatch(eraseStateProp(props))
  }
}

type PaymentInvoiceProps = PropsFromRedux & RouteComponentProps<{ patient_id: string }>;

type PaymentInvoiceState = {
  patient_id: string | null;
  amount : {
    price: number;
    course: boolean;
    extra: null;
  } | null;
  canSubmit: boolean;
  showLoader: boolean;
};

class PaymentInvoice extends Component<PaymentInvoiceProps, PaymentInvoiceState> {
  constructor(props: PaymentInvoiceProps) {
    super(props);
    this.state = {
      patient_id: null,
      amount: null,
      canSubmit: false,
      showLoader: false,
    }
    this.requestToPay = this.requestToPay.bind(this);
    this.paymentOptions = this.paymentOptions.bind(this);
    this.setAmount = this.setAmount.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "payment_invoice");
  }

  componentDidMount() {
    const { patient_id } = this.props.match.params;
    this.props.getPatient(patient_id);
    this.props.getExtraServices(patient_id);
    this.setState({ patient_id });
  }

  setAmount(amount: PaymentInvoiceState["amount"]) {
   this.setState({ amount });
   this.setState({ canSubmit: true });
  }

  requestToPay() {
    if (this.state.amount) {
      this.setState({ showLoader: true })
      this.setState({ canSubmit: false })
      this.props.sendInvoiceRequiest(this.state.patient_id, this.state.amount);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: PaymentInvoiceProps) {
    if (nextProps.invoice.invoice && nextProps.invoice.invoice.formUrl) {
      window.location.href = nextProps.invoice.invoice.formUrl;
    }
  }

  paymentOptions(course: TPatient["course"]) {
    if (course && course.payments && Object.keys(course.payments).length > 0 && course.payment_method == 'PM_CARD') {
      const { payment_option } = course;
      const { services } = this.props;
      const { payments } = course;
      const remain = payments['remain'];
      const next = payments['next'];
      const cbct = payments['cbct'];
      let extra = null;

      if (cbct == true && Array.isArray(services)) {
        const cbct_service = services.filter(service => service.tag === 'CBCT_ANALYSIS' && service.paid == false);
        if (cbct_service && cbct_service.length > 0) {
          extra = cbct_service.pop();
        }
      }
      if (remain > 0 || next > 0) {
        if (payment_option != "PO_ADVANCE") {
          return (
            <div className="form-group">
              <div className="radio-list">
                <label className="control-label">Доступные оплаты:</label>
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        name="payment_option"
                        value={1}
                        onChange={() => this.setAmount({ price: next, course: true, extra })}
                      />
                    </span>
                  </div>
                  {next} рублей
                </label>
                {next != remain
                  ? (
                  <label>
                    <div className="radio">
                      <span>
                        <input
                          type="radio"
                          name="payment_option"
                          value={2}
                          onChange={() => this.setAmount({ price: remain, course: true, extra })}
                        />
                      </span>
                    </div>
                    Оплатить оставшуюся сумму целиком ({remain} рублей)
                  </label>
                  ) : null
                }
              </div>
            </div>
          )
        } else {
          return(
            <div className="form-group">
              <div>
                <div className="radio-list">
                  <label>
                    <div className="radio">
                      <span>
                        <input
                          type="radio"
                          name="payment_option"
                          value={1}
                          onChange={() => this.setAmount({ price: next, course: true, extra})}
                        />
                      </span>
                    </div>
                    Оплатить сумму {next} рублей
                  </label>
                  <br/>
                </div>
              </div>
            </div>
          )
        }
      }
      return null;
    }
  }

  renderExtraPayments(course: TPatient["course"]) {
    if (course.payments && course.payments.extra && Array.isArray(course.payments.extra) && course.payments.extra.length > 0) {
      const { extra } = course.payments;
      const extra_payments = [];
      extra.forEach((e, index) => {
        extra_payments.push(
          <label key={e.id}>
            <div className="radio">
              <span>
                <input
                  type="radio"
                  name="payment_option"
                  value={index}
                  onChange={() => this.setAmount({ price: e.price, course: false, extra: { ...e }})}
                />
              </span>
            </div>
            <FormattedMessage id={e.tag} /> {e.price} рублей
          </label>
        )
      })
      return (
        <div className="form-group">
          <div className="radio-list">
            <label className="control-label">Оплата дополнительных услуг:</label>
            {extra_payments}
          </div>
        </div>
      )
    }
  }

  render() {
    if (
      (window.location.protocol === "https:" || isDevelopment) &&
      this.props.patient &&
      this.props.patient.patient_id
    ) {
      const { patient } = this.props;
      const { course } = patient;
      if (patient && patient.payer_id == PAYERS['DOCTOR'] && course && course.payment_method == "PM_CARD") {
        const { total_payments } = patient;
        return (
          <div className="page-content-wrapper">
            <div className="page-content" style={{minHeight: 584}}>
              <div className="row">
                <div className="col-md-8">
                  <div className="portlet light bordered">
                    <div className="portlet-title">
                      <div className="caption">
                        <i className="icon-book-open font-green" />
                        <span className="caption-subject font-green bold uppercase">Он-лайн оплата</span>
                      </div>
                    </div>
                    <div className="portlet-body">
                      <div>
                        <label>Оплачено { total_payments.paid } из { total_payments.total } рублей</label>
                      </div>
                      {this.paymentOptions(course)}
                      {this.renderExtraPayments(course)}
                      <div>
                        {PAYMENT_SYSTEM_TYPE == "sberbank" ? (
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              rows={10}
                              readOnly={true}
                              defaultValue={SBERBANK_DISCLAIMER}
                            >
                            </textarea>
                          </div>
                        ) : null}
                        <button
                          id="test-btn"
                          className="btn green"
                          disabled={!this.state.canSubmit}
                          onClick={()=> this.requestToPay()}
                        >
                          Оплатить
                        </button>
                      </div>
                      {this.state.showLoader && <Loader msg="Сейчас вы будете перенаправлены на форму оплаты" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    }
    return null;
  }
}

const connector = connect(mapStateToProps,mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PaymentInvoice);
