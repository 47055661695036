import React from "react";

export default function PageHeader() {
  return (
    <div className="page-header navbar">
      <div className="page-header-inner container" id="top-navbar">
        <div className="page-logo">
          <div id="logo">
            <img
              src="/img/3d_smile_logo_rgb.svg"
              alt="logo"
              className="logo-default"
              style={{ width: "120px", marginTop: "25px" }}
            />
          </div>
          <div className="menu-toggler sidebar-toggler"></div>
        </div>
      </div>
    </div>
  );
}
