import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { isMatching, match, P } from "ts-pattern";

import { useGetInvoicePaymentBatchStatusQuery } from "../../../../api";
import Loader from "../../../common/loadingInProgress";
import { Layout } from "../../../ui/layout";
import { Portlet, PortletTitle } from "../../../ui/portlet";

const isQueryError = isMatching({ status: P.number });

export default function PaymentInvoiceBatchStatus() {
  const { batch_id } = useParams<{ batch_id: string }>();

  const invoiceQuery = useGetInvoicePaymentBatchStatusQuery({ batchId: batch_id });

  let content;

  if (invoiceQuery.isUninitialized || invoiceQuery.isLoading) {
    content = <Loader />;
  } else if (invoiceQuery.isError) {
    if (isQueryError(invoiceQuery.error) && invoiceQuery.error.status == 404) {
      content = <FormattedMessage id="pat.payments.course.status.not_found" />;
    } else {
      content = <FormattedMessage id="pat.payments.course.status.error" />;
    }
  } else if (invoiceQuery.isSuccess) {
    content = match(invoiceQuery.data.status)
      .with("pending", () => <FormattedMessage id="pat.payments.course.status.pending" />)
      .with("canceled", () => <FormattedMessage id="pat.payments.course.status.canceled" />)
      .with("succeeded", () => <FormattedMessage id="pat.payments.course.paid" />)
      .exhaustive();
  }

  return (
    <Layout>
      <Portlet as="main" className="tw-h-full">
        <PortletTitle iconClassName="icon-book-open">
          <FormattedMessage id="pat.payments.online.payment" />
        </PortletTitle>

        <div className="portlet-body">
          {content}
        </div>
      </Portlet>
    </Layout>
  );
}
