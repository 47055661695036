import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getClinics } from '../../../actions/clinics';
import { addInstructions } from '../../../actions/patient_new_instructions';
import remoteLog from '../../../common/logging';
import Loader from "../../common/loadingInProgress";


const mapStateToProps = (state) => {
    return {
        user: state.user,
        clinics: state.clinics,
        patient: state.patient
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getClinics: () => dispatch(getClinics()),
        addInstruction: (data) => dispatch(addInstructions(data))
    }
}


class PatientUpdateDoctorInfo extends Component {
    constructor(props) {
        super(props);
        this.addComments = this.addComments.bind(this);
        this.selectClinic = this.selectClinic.bind(this);
    }


    componentDidCatch(e) {
        remoteLog(e, 'patient_update_doctor_info');
    }

    componentDidMount() {
        const {patient} = this.props;
        this.addComments(patient.logistics_comment && patient.logistics_comment.toLowerCase() !== 'none' ? patient.logistics_comment : '');
        patient.clinic && patient.clinic.clinic_id ? this.selectClinic(patient.clinic.clinic_id) : 0;
    }

    addComments(data) {
        this.props.addInstruction({'logistics_comment': data})
    }

    selectClinic(data, isInt) {
        this.props.addInstruction({'clinic_id': parseInt(data)})
        this.props.setDirty('clinic_id', parseInt(data));
    }

    UNSAFE_componentWillMount() {
        this.props.getClinics();
    }

    render() {
        if (this.props.patient.patient_id) {
            var patient = this.props.patient;
            var old_instructions = this.props.patient.course.correction[0]
            old_instructions ?
                Object.keys(old_instructions).map((x) => old_instructions[x] == "None" || old_instructions[x] == "NA" ? old_instructions[x] = "" : null)
                :
                null
            return (
                <div>
                    <h3 className="block" style={{fontWeight: '900', marginTop: "0"}}><FormattedMessage id="BLOCKHEAD_DOCTOR"/></h3>
                    <div className="form-group" id="clinic-section">
                        <label className="control-label" id="validation-clinic_id"
                               style={{fontWeight: '900'}}><FormattedMessage id="HEADER_CLINIC"/>
                            {this.props.patient.status !== 'S_UNFILLED_CASE' ? null :
                                <span className="required" aria-required="true">*</span>
                            }
                        </label>
                        {patient.status === 'S_UNFILLED_CASE'
                            ? (
                                <div className="radio-list">
                                    {this.props.clinics.map((c) =>
                                        <label key={c.clinic_id}>
                                            <div className="radio">
                                                <span>
                                                    <input
                                                        type="radio" 
                                                        id="clinic-name-value"
                                                        name="clinic"
                                                        key={c.clinic_id} 
                                                        value={c.clinic_id}
                                                        onClick={(e) => this.selectClinic(e.target.value)}
                                                        defaultChecked={patient.clinic && patient.clinic.clinic_id == c.clinic_id ? true : false}
                                                    />
                                                </span>
                                            </div>
                                            {c.title}
                                        </label>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <label>{this.props.patient.clinic ? this.props.patient.clinic.title : ''}</label>
                                </div>
                            )
                        }
                        <div id="form_clinic_error"></div>
                    </div>
                </div>
            )
        }
        return <Loader />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientUpdateDoctorInfo));
