import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, formValueSelector, reduxForm, getFormValues, FieldArray } from "redux-form";

import { isRetainersCourse, isSingleArchCourse, isSuperShortCourse } from '../../common/courses';
import PlanCorrectionTeethCheck from '../3d_plan_correction/3d_plan_correction_teeth_check';
import PlanCorrectionButtonsHooksRight from '../3d_plan_correction/plan_correction_buttons_hooks_right';
import PlanCorrectionButtonsHooksLeft from '../3d_plan_correction/plan_correction_buttons_hooks_left';
import { initializeBaseInstruction } from "../../common/instructions";
import { INITIAL_VALUES_PRESCRIPTION } from '../../common/prescription';
import remoteLog from "../../common/logging";
import CloseAllGapsSpaces from './close_all_gaps_spaces';
import incisal_alignment from '../../assets/img/incisal_alignment.svg';
import gingival_alignment from '../../assets/img/gingival_alignment.svg';


const mapStateToProps = (state) => {
	return {
		user: state.user,
		patient: state.patient,
		comments: state.comments,
		media: state.media,
		media_s3: state.media_s3,
		instructions: state.instructions,
		formValues: getFormValues('correction')(state)
	}
}


class RecipeFormPredict extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: true,
			canine_method_specify: props.canine_method_specify ? props.canine_method_specify : false,
			molars_method_specify: props.molars_method_specify ? props.molars_method_specify : false,
			upper_horizontal_overlap: props.upper_horizontal_overlap ? props.upper_horizontal_overlap : null,
			lower_horizontal_overlap: props.lower_horizontal_overlap ? props.lower_horizontal_overlap : null
		}
		this.fm = this.fm.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.resetElasticsOptionsRight = this.resetElasticsOptionsRight.bind(this);
		this.resetElasticsOptionsLeft = this.resetElasticsOptionsLeft.bind(this);
		this.clearFields = this.clearFields.bind(this);
		this.setCanineMethodSpecify = this.setCanineMethodSpecify.bind(this);
		this.setMolarsMethodSpecify = this.setMolarsMethodSpecify.bind(this);
	}


	fm(id) { return this.props.intl.formatMessage({ id: id }) };

	componentDidCatch(e) {
		remoteLog(e, '3d_plan_correction_form');
	}

	resetForm(...arg) {
		arg.forEach(field => this.props.change(field, false))
	}

	resetElasticsOptionsLeft(e, obj) {
		const elastics_left = this.props.formValues.elastics_options
		if (e.target.checked === false) {
			for (let key in elastics_left) {
				if (key.startsWith('2')) {
					delete elastics_left[key]
				} else if (key.startsWith('3')) {
					delete elastics_left[key]
				}
			}
			this.setState({ checked: false })
			return elastics_left
		}
		this.props.change(obj, elastics_left)
	}

	resetElasticsOptionsRight(e, obj) {
		const elastics_right = this.props.formValues.elastics_options
		if (e.target.checked === false) {
			for (let key in elastics_right) {
				if (key.startsWith('1')) {
					delete elastics_right[key]
				} else if (key.startsWith('4')) {
					delete elastics_right[key]
				}
			}
			return elastics_right
		}
		this.props.change(obj, elastics_right)
	}

	renderExpansionArchOptions(arch) {
		return (
			<div className="row">
				<div className="form-group col-md-offset-2 col-sm-offset-1 col-xs-offset-1">
					<label className="control-label" style={{ fontWeight: '900' }}>
						<FormattedMessage id="TA_U_EXPAND_TYPE_HOWTO" />
					</label>
					<div>
						<div>
							<label>
								<div className="checker">
									<Field
										component="input"
										type="checkbox"
										name={`${arch}_arch_expansion_torque`}
									/>
								</div>
								<FormattedMessage id="TA_U_EXPAND_TYPE_TORQUE" />
							</label>
						</div>
						<label>
							<div className="checker">
								<Field
									component="input"
									type="checkbox"
									name={`${arch}_arch_expansion_bodily`}
								/>
							</div>
							<FormattedMessage id="TA_U_EXPAND_TYPE_BODILY" />
						</label>
					</div>
				</div>
			</div>
		)
	}

	clearFields(ipr, method, type) {
		this.props.change(ipr, false)
		this.props.change(method, null)
		if (type === 'canine') {
			this.setState({ canine_method_specify: false })
		} else {
			this.setState({ molars_method_specify: false })
		}
	}

	setCanineMethodSpecify(e) {
		if (!e.target.checked) {
			this.props.change('canine_method', null);
			this.props.change('canine_ipr', false);
		}
		this.props.change('canine_method_ai', false);
		this.setState({ canine_method_specify: e.target.checked });
	}

	setMolarsMethodSpecify(e) {
		if (!e.target.checked) {
			this.props.change('molars_method', null);
			this.props.change('molars_ipr', false);
		}
		this.props.change('molars_method_ai', false);
		this.setState({ molars_method_specify: e.target.checked });
	}

	render() {
		const {
			change, submitting, upper_arch_action, lower_arch_action, close_all_gaps,
			midline, elastics_recoil_right, elastics_recoil_left,
			vertical_overlap, overjet, canine_method_ai, molars_method_ai } = this.props;
		const { canine_method_specify, molars_method_specify, lower_horizontal_overlap, upper_horizontal_overlap } = this.state;
		const course_id = this.props.course_id;
		const elastics_options = this.props.patient && Object.keys(this.props.patient).length > 0
			&& this.props.patient.course && this.props.patient.course.correction
			&& this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription
			? Object.keys(this.props.patient.course.correction[this.props.patient.course.correction.length - 1]
				.prescription.elastics_options).map(i => parseInt(i)) : [];

		const fm = (id) => this.props.intl.formatMessage({ id: id });

		return (
			<div>
				<div id="treat-arches-section">
					<h3 className="block" style={{ fontWeight: '900' }}><FormattedMessage id="BLOCKHEAD_INSTRUCTIONS" /></h3>
					<h4 className="block" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_ARCHES" /></h4>
					<div className="form-group">
						<label className="control-label" id="validation-arch" style={{ fontWeight: '900' }}>
							<FormattedMessage id="ARCHES_SELECT" />
							<span className="required" aria-required="true">*</span>
						</label>
						<div className="radio-list">
							{(isSingleArchCourse(course_id) || isSuperShortCourse(course_id))
								? null
								: (
									<label>
										<div className="radio">
											<Field
												component="input"
												type="radio"
												id="treat-arches-value-1"
												name="arch"
												value='1'
											/>
										</div>
										<FormattedMessage id="TA_BOTH" />
									</label>
								)
							}
							<label>
								<div className="radio">
									<Field
										component="input"
										type="radio"
										name="arch"
										id="treat-arches-value-2"
										value='2'
									/>
								</div>
								<FormattedMessage id="TA_UPPER" />
							</label>
							<label>
								<div className="radio">
									<Field
										component="input"
										type="radio"
										name="arch"
										id="treat-arches-value-3"
										value='3'
									/>
								</div>
								<FormattedMessage id="TA_LOWER" />
							</label>
						</div>
						<div id="form_tooth_arch_error"></div>
					</div>
					{!isRetainersCourse(course_id)
						? (
							<div className="row">
								<div className="form-group col-md-4" style={{ marginBottom: "20px" }}>
									<label className="control-label" id="validation-upper_arch_action" style={{ fontWeight: '900' }}>
										<FormattedMessage id="ARCHES_U_SELECT" />
									</label>
									<div className="checkbox-list checkbox_list">
										<label>
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="upper-arch-action-value-0"
													name="upper_arch_action"
													format={v => v === 0}
													normalize={v => v ? 0 : null}
													onChange={() => this.resetForm('upper_arch_expansion_torque', 'upper_arch_expansion_bodily')}
												/>
												<label id="checkbox_label" htmlFor="upper-arch-action-value-0"></label>
											</div>
											<FormattedMessage id="TA_U_MAINTAIN" />
										</label>
										<label>
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="upper-arch-action-value-1"
													name="upper_arch_action"
													format={v => v === 1}
													normalize={v => v ? 1 : null}
													onChange={() => this.resetForm('upper_arch_expansion_torque', 'upper_arch_expansion_bodily')}
												/>
												<label id="checkbox_label" htmlFor="upper-arch-action-value-1"></label>
											</div>
											<FormattedMessage id="TA_U_EXPAND" />
										</label>
										{upper_arch_action === 1 ? this.renderExpansionArchOptions('upper') : false}
												<label>
													<div className="checkbox-round checker">
														<Field
															component="input"
															type="checkbox"
															id="upper-arch-action-value-2"
															name="upper_arch_action"
															format={v => v === 2}
															normalize={v => v ? 2 : null}
															onChange={() => this.resetForm('upper_arch_expansion_torque', 'upper_arch_expansion_bodily')}
														/>
														<label id="checkbox_label" htmlFor="upper-arch-action-value-2"></label>
													</div>
													<FormattedMessage id="TA_U_NARROW" />
												</label>
									</div>
								</div>
								<div className="form-group col-md-4">
									<label className="control-label" id="lower-arch-action-label" style={{ fontWeight: '900' }}>
										<FormattedMessage id="ARCHES_L_SELECT" />
									</label>
									<div className="checkbox-list checkbox_list">
										<label>
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="lower-arch-action-value-0"
													name="lower_arch_action"
													format={v => v === 0}
													normalize={v => v ? 0 : null}
													onChange={() => this.resetForm('lower_arch_expansion_torque', 'lower_arch_expansion_bodily')}
												/>
												<label id="checkbox_label" htmlFor="lower-arch-action-value-0"></label>
											</div>
											<FormattedMessage id="TA_L_MAINTAIN" />
										</label>
										<label>
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="lower-arch-action-value-1"
													name="lower_arch_action"
													normalize={v => v ? 1 : null}
													format={v => v === 1}
													onChange={() => this.resetForm('lower_arch_expansion_torque', 'lower_arch_expansion_bodily')}
												/>
												<label id="checkbox_label" htmlFor="lower-arch-action-value-1"></label>
											</div>
											<FormattedMessage id="TA_L_EXPAND" />
										</label>
										{lower_arch_action === 1 ? this.renderExpansionArchOptions('lower') : false}
												<label>
													<div className="checkbox-round checker">
														<Field
															component="input"
															type="checkbox"
															id="lower-arch-action-value-2"
															name="lower_arch_action"
															format={v => v === 2}
															normalize={v => v ? 2 : null}
															onChange={() => this.resetForm('lower_arch_expansion_torque', 'lower_arch_expansion_bodily')}
														/>
														<label id="checkbox_label" htmlFor="lower-arch-action-value-2"></label>
													</div>
													<FormattedMessage id="TA_L_NARROW" /><br /><br />
												</label>
									</div>
								</div>
							</div>
						) : null
					}


					{!isRetainersCourse(course_id)
						? (
							<div className="form-group">
								<label className="control-label" id="teeth-level-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="ARCHES_TLA" />
								</label>
								<div className="checkbox-list checkbox_list">
									<div className="checkbox-round checker">
										<label>
											<Field
												component="input"
												type="checkbox"
												name="teeth_level_align"
												id="teeth-level-value-1"
												format={v => v === 1}
												normalize={v => v ? 1 : null}
											/>
											<label id="checkbox_label" htmlFor="teeth-level-value-1"></label>
											<FormattedMessage id="TLA_G_MARGIN" />
										</label>
										<span className='prescription_tooltip_show'>
											<i style={{ fontSize: "14px", marginLeft: "4px" }} className='icon-question' />
											<span style={{ top: "-140px", position: "absolute" }} className="d-inline-block prescription_tooltip">
												<img style={{ width: "100%" }} src={gingival_alignment} />
											</span>
										</span>
									</div>
									<div className="checkbox-round checker" style={{ display: "block" }}>
										<label>
											<Field
												component="input"
												type="checkbox"
												name="teeth_level_align"
												id="teeth-level-value-2"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
											/>
											<label id="checkbox_label" htmlFor="teeth-level-value-2"></label>
											<FormattedMessage id="TLA_C_EDGE" />
										</label>
										<span className='prescription_tooltip_show'>
											<i style={{ fontSize: "14px", marginLeft: "4px" }} className='icon-question' />
											<span style={{position: "absolute"}} className="d-inline-block prescription_tooltip">
												<img style={{ width: "100%" }} src={incisal_alignment} />
											</span>
										</span>
									</div>
								</div>
							</div>
						) : null
					}
				</div>
				{!isRetainersCourse(course_id) &&
					<div id="incisors-section">
						<h4 className="block" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_INCISORS" /></h4>
						<div className="form-group" style={{ marginBottom: "20px" }}>
							<label className="control-label" id="incisors-vert-overlap-label" style={{ fontWeight: '900' }}>
								<FormattedMessage id="INCISORS_VO" />
								<div className="checkbox-list checkbox_list">
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="incisors-vert-overlap-value-0"
												name="vertical_overlap"
												format={v => v === 0}
												normalize={v => v ? 0 : null}
											/>
											<label id="checkbox_label" htmlFor="incisors-vert-overlap-value-0"></label>
										</div>
										<FormattedMessage id="VO_MAINTAIN" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="incisors-vert-overlap-value-1"
												name="vertical_overlap"
												format={v => v === 1}
												normalize={v => v ? 1 : null}
											/>
											<label id="checkbox_label" htmlFor="incisors-vert-overlap-value-1"></label>
										</div>
										<FormattedMessage id="VO_DECREASE_USA" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="incisors-vert-overlap-value-2"
												name="vertical_overlap"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
											/>
											<label id="checkbox_label" htmlFor="incisors-vert-overlap-value-2"></label>
										</div>
										<FormattedMessage id="VO_INCREASE_USA" />
									</label>
								</div>
							</label>
						</div>
						{vertical_overlap !== null && vertical_overlap !== 0 ?
							(
								<div>
									<div className="form-group">
										<label className="control-label" id="validation-vertical_overlap_comment" style={{ fontWeight: '900' }}>
											<FormattedMessage id="VO_COMMENT" />
											<span className="required" aria-required="true">*</span>
										</label>
										<Field
											style={{ width: "60%" }}
											component="textarea"
											className="form-control"
											id="vertical_overlap_comment"
											rows={5}
											name="vertical_overlap_comment"
											placeholder={fm("VO_COMMENT_PLACEHOLDER")}
										/>
									</div>
								</div>
							) : null
						}
							<div>
								{upper_horizontal_overlap !== null || lower_horizontal_overlap !== null ? (
									<div>
										<label className="control-label" id="incisors-horiz-overlap-label" style={{ fontWeight: '900' }}>
											<FormattedMessage id="INCISORS_HO" />
										</label>
								    	<div className="row">
											<div className="form-group col-md-4" style={{ marginBottom: "20px" }}>
												<label className="control-label" id="incisors-upper-horiz-overlap-label"
													style={{ fontWeight: '900' }}>
													<FormattedMessage id="INCISORS_HO_U" />
												</label>
											    <div className="checkbox-list checkbox_list">
												<label>
													<div className="checkbox-round checker">
														<Field
															component="input"
															type="checkbox"
															id="incisors-upper-horiz-overlap-value-0"
															name="upper_horizontal_overlap"
															format={v => v === 0}
															normalize={v => v ? 0 : null}
														/>
												        <label id="checkbox_label" htmlFor="incisors-upper-horiz-overlap-value-0"></label>
											        </div>
													<FormattedMessage id="HO_U_MAINTAIN" />
												</label>
												<label>
													<div className="checkbox-round checker">
														<Field
															component="input"
															type="checkbox"
															id="incisors-upper-horiz-overlap-value-1"
															name="upper_horizontal_overlap"
															format={v => v === 1}
															normalize={v => v ? 1 : null}
														/>
														<label id="checkbox_label" htmlFor="incisors-upper-horiz-overlap-value-1"></label>
													</div>
													<FormattedMessage id="HO_U_RETROCLINATE" />
												</label>
												<label>
													<div className="checkbox-round checker">
														<Field
															component="input"
															type="checkbox"
															id="incisors-upper-horiz-overlap-value-2"
															name="upper_horizontal_overlap"
															format={v => v === 2}
															normalize={v => v ? 2 : null}
														/>
													    <label id="checkbox_label" htmlFor="incisors-upper-horiz-overlap-value-2"></label>
													</div>
													<FormattedMessage id="HO_U_PROCLINATE" />
												</label>
											    </div>
											</div>
											<div className="form-group col-md-4">
												<label className="control-label" id="incisors-lower-horiz-overlap-label"
													style={{ fontWeight: '900' }}>
													<FormattedMessage id="INCISORS_HO_L" />
												</label>
												<div className="checkbox-list checkbox_list">
													<label>
														<div className="checkbox-round checker">
															<Field
																component="input"
																type="checkbox"
																id="incisors-lower-horiz-overlap-value-0"
																name="lower_horizontal_overlap"
																format={v => v === 0}
																normalize={v => v ? 0 : null}
															/>
														    <label id="checkbox_label" htmlFor="incisors-lower-horiz-overlap-value-0"></label>
														</div>
														<FormattedMessage id="HO_L_MAINTAIN" />
													</label>
													<label>
														<div className="checkbox-round checker">
															<Field
																component="input"
																type="checkbox"
																id="incisors-lower-horiz-overlap-value-1"
																name="lower_horizontal_overlap"
																format={v => v === 1}
																normalize={v => v ? 1 : null}
															/>
															<label id="checkbox_label" htmlFor="incisors-lower-horiz-overlap-value-1"></label>
														</div>
														<FormattedMessage id="HO_L_RETROCLINATE" />
													</label>
													<label>
														<div className="checkbox-round checker">
															<Field
															    component="input"
																type="checkbox"
															    id="incisors-lower-horiz-overlap-value-2"
																name="lower_horizontal_overlap"
																format={v => v === 2}
																normalize={v => v ? 2 : null}
															/>
															<label id="checkbox_label" htmlFor="incisors-lower-horiz-overlap-value-2"></label>
														</div>
														<FormattedMessage id="HO_L_PROCLINATE" /><br /><br />
													</label>
												</div>
											</div>
										</div>
									</div>									
								  ) : null
								}
								<div className='row'>
									<div className="form-group col-md-6">
										<label className="control-label" id="overjet-label" style={{ fontWeight: '900' }}>
											<FormattedMessage id="OVERJET" />
										</label>
										<div className="checkbox-list checkbox_list">
											<label>
												<div className="checkbox-round checker">
													<Field
														component="input"
														type="checkbox"
														id="overjet-value-0"
														name="overjet"
														format={v => v === 0}
														normalize={v => v ? 0 : null}
														onChange={e => this.resetForm(
															'correct_retroclination_upper',
															'correct_proclination_lower',
															'correct_proclination_upper',
															'correct_retroclination_lower')
														}
													/>
													<label id="checkbox_label" htmlFor="overjet-value-0"></label>
												</div>
												<FormattedMessage id="OJ_MAINTAIN" />
											</label>
											{(overjet !== null && overjet === 1) ? (
												<label>
													<div className="checkbox-round checker">
														<Field
															component="input"
															type="checkbox"
															id="overjet-value-1"
															name="overjet"
															format={v => v === 1}
															normalize={v => v ? 1 : null}
															onChange={e => this.resetForm(
																'correct_retroclination_upper',
																'correct_proclination_lower',
																'correct_proclination_upper',
																'correct_retroclination_lower')
															}
														/>
														<label id="checkbox_label" htmlFor="overjet-value-1"></label>
													</div>
													<FormattedMessage id="OJ_CONTACT" />
												</label>
											) : null}
											{(overjet !== null && overjet === 2) ? (
												<label>
													<div className="checkbox-round checker">
														<Field
															component="input"
															type="checkbox"
															id="overjet-value-2"
															name="overjet"
															format={v => v === 2}
															normalize={v => v ? 2 : null}
															onChange={e => this.resetForm(
																'correct_retroclination_upper',
																'correct_proclination_lower',
																'correct_proclination_upper',
																'correct_retroclination_lower')
															}
														/>
														<label id="checkbox_label" htmlFor="overjet-value-2"></label>
													</div>
													<FormattedMessage id="OJ_MAINTAIN_LARGE" /><br />
												</label>
											) : null}
											<div>
												<label>
													<div className="checkbox-round checker">
														<Field
															component="input"
															type="checkbox"
															id="overjet-value-3"
															name="overjet"
															format={v => v === 3}
															normalize={v => v ? 3 : null}
														/>
														<label id="checkbox_label" htmlFor="overjet-value-3"></label>
													</div>
													<FormattedMessage id="OJ_MAINTAIN_CORRECT" /><br />
												</label>
												{overjet !== null && overjet === 3 ? (
													<div style={{ margin: "10px 40px" }}>
														<p style={{ fontWeight: "700" }}><FormattedMessage id="OJ_MAINTAIN_CORRECT_SPECIFY" /></p>
														<label>
															<div className="checkbox-round checker">
																<Field
																	component="input"
																	type="checkbox"
																	id="correct_retroclination_upper"
																	name="correct_retroclination_upper"
																	onChange={() => change('correct_proclination_lower', false)}
																/>
																<label id="checkbox_label" htmlFor="correct_retroclination_upper"></label>
															</div>
															<FormattedMessage id="CORRECT_RETROCLINATION_UPPER" /><br />
														</label>
														<label>
															<div className="checkbox-round checker">
																<Field
																	component="input"
																	type="checkbox"
																	id="correct_proclination_lower"
																	name="correct_proclination_lower"
																	onChange={() => change('correct_retroclination_upper', false)}
																/>
																<label id="checkbox_label" htmlFor="correct_proclination_lower"></label>
															</div>
															<FormattedMessage id="CORRECT_PROCLINATION_LOWER" /><br /><br />
														</label>
														<label>
															<div className="checkbox-round checker">
																<Field
																	component="input"
																	type="checkbox"
																	id="correct_proclination_upper"
																	name="correct_proclination_upper"
																	onChange={() => change('correct_retroclination_lower', false)}
																/>
																<label id="checkbox_label" htmlFor="correct_proclination_upper"></label>
															</div>
															<FormattedMessage id="CORRECT_PROCLINATION_UPPER" /><br />
														</label>
														<label>
															<div className="checkbox-round checker">
																<Field
																	component="input"
																	type="checkbox"
																	id="correct_retroclination_lower"
																	name="correct_retroclination_lower"
																	onChange={() => change('correct_proclination_upper', false)}
																/>
																<label id="checkbox_label" htmlFor="correct_retroclination_lower"></label>
															</div>
															<FormattedMessage id="CORRECT_RETROCLINATION_LOWER" />
														</label>
													</div>
												) : null
												}
											</div>
										</div>
									</div>
								</div>
								</div><br />
								<div className="form-group">
									<label className="control-label" id="validation-midline" style={{ fontWeight: '900' }}>
										<FormattedMessage id="MIDLINE" />
										<span className="required" aria-required="true">*</span>
									</label>
									<div className="radio-list">
										<label>
											<div className="radio">
												<Field
													component="input"
													type="radio"
													id="midline-value-0"
													name="midline"
													value='0'
												/>
											</div>
											<FormattedMessage id="ML_MAINTAIN" />
										</label>
										<label>
											<div className="radio">
												<Field
													component="input"
													type="radio"
													id="midline-value-2"
													name="midline"
													value='2'
												/>
											</div>
											<FormattedMessage id="ML_ADJUST_LOWER_TO_UPPER" />
										</label>
										<label>
											<div className="radio">
												<Field
													component="input"
													type="radio"
													id="midline-value-3"
													name="midline"
													value='3'
												/>
											</div>
											<FormattedMessage id="ML_ADJUST_UPPER_TO_LOWER" />
										</label>
										{(midline !== '' && midline === '1') ? (
											<label>
												<div className="radio">
													<Field
														component="input"
														type="radio"
														id="midline-value-1"
														name="midline"
														value='1'
													/>
												</div>
												<FormattedMessage id="ML_IMPROVE" />
											</label>
										) : null}
										{(midline !== '' && midline === '1') ? (
											<div className="form-inline">
												<Field
													component="input"
													type="text"
													maxLength="512"
													size={32}
													disabled={midline !== '1'}
													className="form-control"
													name="midline_improve_by" />
											</div>
										) : null}
									</div>
								</div>
							</div>
					// </div>
				}
				{!isRetainersCourse(course_id) &&
					<div id="canines-section">
						<h4 className="block" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_CANINE" /></h4>
						<div className="row">
							<div className="form-group col-md-3" style={{ marginBottom: "20px" }}>
								<label className="control-label" id="canines-ratio-right-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="CANINE_RIGHT" />
								</label>
								<div className="checkbox-list checkbox_list">
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="canines-ratio-right-value-1"
												name="canine_ratio_right"
												format={v => v === 1}
												normalize={v => v ? 1 : null}
											/>
											<label id="checkbox_label" htmlFor="canines-ratio-right-value-1"></label>
										</div>
										<FormattedMessage id="CR_R_I_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="canines-ratio-right-value-2"
												name="canine_ratio_right"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
											/>
											<label id="checkbox_label" htmlFor="canines-ratio-right-value-2"></label>
										</div>
										<FormattedMessage id="CR_R_II_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="canines-ratio-right-value-3"
												name="canine_ratio_right"
												format={v => v === 3}
												normalize={v => v ? 3 : null}
											/>
											<label id="checkbox_label" htmlFor="canines-ratio-right-value-3"></label>
										</div>
										<FormattedMessage id="CR_R_III_CLASS" />
									</label>
								</div>
							</div>
							<div className="form-group col-md-3" style={{ marginBottom: "20px" }}>
								<label className="control-label" id="canines-ratio-left-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="CANINE_LEFT" />
								</label>
								<div className="checkbox-list checkbox_list">
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="canines-ratio-left-value-1"
												name="canine_ratio_left"
												format={v => v === 1}
												normalize={v => v ? 1 : null}
											/>
											<label id="checkbox_label" htmlFor="canines-ratio-left-value-1"></label>
										</div>
										<FormattedMessage id="CR_L_I_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="canines-ratio-left-value-2"
												name="canine_ratio_left"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
											/>
											<label id="checkbox_label" htmlFor="canines-ratio-left-value-2"></label>
										</div>
										<FormattedMessage id="CR_L_II_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="canines-ratio-left-value-3"
												name="canine_ratio_left"
												format={v => v === 3}
												normalize={v => v ? 3 : null}
											/>
											<label id="checkbox_label" htmlFor="canines-ratio-left-value-3"></label>
										</div>
										<FormattedMessage id="CR_L_III_CLASS" />
									</label>
								</div>
							</div>
							<div className="form-group col-md-3">
								<label className="control-label" id="canines-ratio-howto-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="CANINE_HOWTO" />
								</label>
								{canine_method_ai ? (
									<div>
									<div className="checkbox-list checkbox_list">
									<label id="canines-ratio-howto-ipr-label">
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="canine_method_ai"
												name="canine_method_ai"
												onChange={() => this.clearFields('canine_ipr', 'canine_method', 'canine')}
											/>
											<label id="checkbox_label" htmlFor="canine_method_ai"></label>
										</div>
										<FormattedMessage id="CRH_AI" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<input
												type="checkbox"
												id="canine_method_ai_false"
												name="canine_method_specify"
												checked={canine_method_specify}
												onChange={(e) => this.setCanineMethodSpecify(e)}
											/>
											<label id="checkbox_label" htmlFor="canine_method_ai_false"></label>
										</div>
										<FormattedMessage id="CRH_SPECIFY" />
									</label>
								</div>
								{canine_method_specify ? (
									<div className='form-group col-md-offset-2 col-sm-offset-1 col-xs-offset-1'>
										<div className="checkbox-list checkbox_list">
											<label>
												<div className="checkbox-round checker">
													<Field
														component="input"
														type="checkbox"
														id="canines-ratio-howto-value-1"
														name="canine_method"
														format={v => v === 1}
														normalize={v => v ? 1 : null}
													/>
													<label id="checkbox_label" htmlFor="canines-ratio-howto-value-1"></label>
												</div>
												<FormattedMessage id="CRH_DISTALIZE" />
											</label>
											<label>
												<div className="checkbox-round checker">
													<Field
														component="input"
														type="checkbox"
														id="canines-ratio-howto-value-2"
														name="canine_method"
														format={v => v === 2}
														normalize={v => v ? 2 : null}
													/>
													<label id="checkbox_label" htmlFor="canines-ratio-howto-value-2"></label>
												</div>
												<FormattedMessage id="CRH_MESIALIZE" />
											</label>
										</div>
										<div className="checkbox-list checkbox_list">
											<label id="canines-ratio-howto-ipr-label">
												<div className="checkbox-round checker">
													<Field
														component="input"
														type="checkbox"
														id="canines-ratio-howto-ipr-value"
														name="canine_ipr"
													/>
													<label id="checkbox_label" htmlFor="canines-ratio-howto-ipr-value"></label>
												</div>
												<FormattedMessage id="CRH_IPR" />
											</label>
										</div>
									</div>
								) : null
								}
									</div>
								) : (
									<div className='form-group'>
									<div className="checkbox-list checkbox_list">
										<label>
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="canines-ratio-howto-value-1"
													name="canine_method"
													format={v => v === 1}
													normalize={v => v ? 1 : null}
												/>
												<label id="checkbox_label" htmlFor="canines-ratio-howto-value-1"></label>
											</div>
											<FormattedMessage id="CRH_DISTALIZE" />
										</label>
										<label>
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="canines-ratio-howto-value-2"
													name="canine_method"
													format={v => v === 2}
													normalize={v => v ? 2 : null}
												/>
												<label id="checkbox_label" htmlFor="canines-ratio-howto-value-2"></label>
											</div>
											<FormattedMessage id="CRH_MESIALIZE" />
										</label>
									</div>
									<div className="checkbox-list checkbox_list">
										<label id="canines-ratio-howto-ipr-label">
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="canines-ratio-howto-ipr-value"
													name="canine_ipr"
												/>
												<label id="checkbox_label" htmlFor="canines-ratio-howto-ipr-value"></label>
											</div>
											<FormattedMessage id="CRH_IPR" />
										</label>
									</div>
								</div>
								)}
							</div>
						</div>
					</div>
				}
				{!isRetainersCourse(course_id) ?
					<div id="molars-section">
						<h4 className="block" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_MOLAR" /></h4>
						<div className="row">
							<div className="form-group col-md-3" style={{ marginBottom: "20px" }}>
								<label className="control-label" id="molars-ratio-right-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="MOLAR_RIGHT" />
								</label>
								<div className="checkbox-list checkbox_list">
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="molars-ratio-right-value-1"
												name="molars_ratio_right"
												format={v => v === 1}
												normalize={v => v ? 1 : null}
											/>
											<label id="checkbox_label" htmlFor="molars-ratio-right-value-1"></label>
										</div>
										<FormattedMessage id="MR_R_I_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="molars-ratio-right-value-2"
												name="molars_ratio_right"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
											/>
											<label id="checkbox_label" htmlFor="molars-ratio-right-value-2"></label>
										</div>
										<FormattedMessage id="MR_R_II_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="molars-ratio-right-value-3"
												name="molars_ratio_right"
												format={v => v === 3}
												normalize={v => v ? 3 : null}
											/>
											<label id="checkbox_label" htmlFor="molars-ratio-right-value-3"></label>
										</div>
										<FormattedMessage id="MR_R_III_CLASS" />
									</label>
								</div>
							</div>
							<div className="form-group col-md-3" style={{ marginBottom: "20px" }}>
								<label className="control-label" id="molars-ratio-left-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="MOLAR_LEFT" />
								</label>
								<div className="checkbox-list checkbox_list">
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="molars-ratio-left-value-1"
												name="molars_ratio_left"
												format={v => v === 1}
												normalize={v => v ? 1 : null}
											/>
											<label id="checkbox_label" htmlFor="molars-ratio-left-value-1"></label>
										</div>
										<FormattedMessage id="MR_L_I_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="molars-ratio-left-value-2"
												name="molars_ratio_left"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
											/>
											<label id="checkbox_label" htmlFor="molars-ratio-left-value-2"></label>
										</div>
										<FormattedMessage id="MR_L_II_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="molars-ratio-left-value-3"
												name="molars_ratio_left"
												format={v => v === 3}
												normalize={v => v ? 3 : null}
											/>
											<label id="checkbox_label" htmlFor="molars-ratio-left-value-3"></label>
										</div>
										<FormattedMessage id="MR_L_III_CLASS" />
									</label>
								</div>
							</div>
							<div className="form-group col-md-3">
								<label className="control-label" id="molars-ratio-howto-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="MOLAR_HOWTO" />
								</label>
								{molars_method_ai ? (
									<div>
																		<div className="checkbox-list checkbox_list">
									<label id="canines-ratio-howto-ipr-label">
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="molars_method_ai"
												name="molars_method_ai"
												onChange={() => this.clearFields('molars_ipr', 'molars_method', 'molars')}
											/>
											<label id="checkbox_label" htmlFor="molars_method_ai"></label>
										</div>
										<FormattedMessage id="MRH_AI" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<input
												type="checkbox"
												id="molars_method_ai_false"
												name="molars_method_specify"
												checked={molars_method_specify}
												//defaultChecked={this.props.molars_method_specify}
												onChange={(e) => this.setMolarsMethodSpecify(e)}
											/>
											<label id="checkbox_label" htmlFor="molars_method_ai_false"></label>
										</div>
										<FormattedMessage id="CRH_SPECIFY" />
									</label>
								</div>
								{molars_method_specify ? (
									<div className='form-group col-md-offset-2 col-sm-offset-1 col-xs-offset-1 transition delay-150 duration-300 ease-in-out'>
										<div className="checkbox-list checkbox_list">
											<label>
												<div className="checkbox-round checker">
													<Field
														component="input"
														type="checkbox"
														id="molars-ratio-howto-value-1"
														name="molars_method"
														format={v => v === 1}
														normalize={v => v ? 1 : null}
													/>
													<label id="checkbox_label" htmlFor="molars-ratio-howto-value-1"></label>
												</div>
												<FormattedMessage id="MRH_DISTALIZE" />
											</label>
											<label>
												<div className="checkbox-round checker">
													<Field
														component="input"
														type="checkbox"
														id="molars-ratio-howto-value-2"
														name="molars_method"
														format={v => v === 2}
														normalize={v => v ? 2 : null}
													/>
													<label id="checkbox_label" htmlFor="molars-ratio-howto-value-2"></label>
												</div>
												<FormattedMessage id="MRH_MESIALIZE" />
											</label>
										</div>
										<div className="checkbox-list checkbox_list">
											<label id="molars-ratio-howto-ipr-label">
												<div className="checkbox-round checker">
													<Field
														component="input"
														type="checkbox"
														id="molars-ratio-howto-ipr-value"
														name="molars_ipr"
													/>
													<label id="checkbox_label" htmlFor="molars-ratio-howto-ipr-value"></label>
													<FormattedMessage id="MRH_IPR" />
												</div>
											</label>
										</div>
									</div>
								) : null
								}
									</div>
								) : (
									<div className='form-group'>
									<div className="checkbox-list checkbox_list">
										<label>
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="molars-ratio-howto-value-1"
													name="molars_method"
													format={v => v === 1}
													normalize={v => v ? 1 : null}
												/>
												<label id="checkbox_label" htmlFor="molars-ratio-howto-value-1"></label>
											</div>
											<FormattedMessage id="MRH_DISTALIZE" />
										</label>
										<label>
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="molars-ratio-howto-value-2"
													name="molars_method"
													format={v => v === 2}
													normalize={v => v ? 2 : null}
												/>
												<label id="checkbox_label" htmlFor="molars-ratio-howto-value-2"></label>
											</div>
											<FormattedMessage id="MRH_MESIALIZE" />
										</label>
									</div>
									<div className="checkbox-list checkbox_list">
										<label id="molars-ratio-howto-ipr-label">
											<div className="checkbox-round checker">
												<Field
													component="input"
													type="checkbox"
													id="molars-ratio-howto-ipr-value"
													name="molars_ipr"
												/>
												<label id="checkbox_label" htmlFor="molars-ratio-howto-ipr-value"></label>
												<FormattedMessage id="MRH_IPR" />
											</div>
										</label>
									</div>
								</div>
								)}
							</div>
						</div>
					</div>
					: null}
				{!isRetainersCourse(course_id) &&
					<div id="close-all-gaps-section" style={{ marginTop: "15px" }}>
						<div className="form-group">
							<label className="control-label" id="close-all-gaps-label" style={{ fontWeight: '900' }}>
								<FormattedMessage id="HEADER_CLOSE_ALL" />
							</label>
							<div className="checkbox-list checkbox_list">
								<label>
									<div className="checkbox-round checker">
										<Field
											component="input"
											type="checkbox"
											id="close-all-gaps-value-1"
											name="close_all_gaps"
											format={v => v === 1}
											normalize={v => v ? 1 : null}
											onChange={() => change('close_all_gaps_value', "")}
										/>
										<label id="checkbox_label" htmlFor="close-all-gaps-value-1"></label>
									</div>
									<FormattedMessage id="CAG_YES" />
								</label>
								{
									close_all_gaps === 2 &&
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="close-all-gaps-value-2"
												name="close_all_gaps"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
												onChange={() => change('close_all_gaps_value', "")}
											/>
											<label id="checkbox_label" htmlFor="close-all-gaps-value-2"></label>
										</div>
										<FormattedMessage id="CAG_MAINTAIN" />
									</label>
								}
								<label>
									<div className="checkbox-round checker">
										<Field
											component="input"
											type="checkbox"
											id="close-all-gaps-value-3"
											name="close_all_gaps"
											format={v => v === 3}
											normalize={v => v ? 3 : null}
											onChange={() => change('close_all_gaps_value', "")}
										/>
										<label id="checkbox_label" htmlFor="close-all-gaps-value-3"></label>
									</div>
									<FormattedMessage id="CAG_LEAVE_SPACE" />
								</label>
								<div style={{ width: "70%" }} >
									<Field
										component="textarea"
										type="text"
										rows={5}
										disabled={close_all_gaps !== 3}
										className="form-control"
										id="close-all-gaps-input"
										name="close_all_gaps_value"
										placeholder={fm("CAG_LEAVE_SPACE_ADD_COMMENT")}
										maxLength={256}
									/>
								</div>
								<br />
								{
									close_all_gaps === 3 &&
									<div>
										<label className="control-label" id="close-all-gaps-label" style={{ fontWeight: '900' }}>
											<FormattedMessage id="CAG_LEAVE_SPACE_FOR" />
										</label>
										<FieldArray
											key={close_all_gaps}
											name="close_all_gaps_spaces"
											component={CloseAllGapsSpaces}
											resetForm={this.props.change}
										/>
									</div>
								}
							</div>
						</div>
					</div>
				}
				{!isRetainersCourse(course_id) &&
					<div>
						<label className="control-label" id="EXTRA_NOMOVE" style={{ fontWeight: '900' }}>
							<FormattedMessage id="EXTRA_NOMOVE" />
						</label>
						<br />
						<PlanCorrectionTeethCheck name="dont_move" />
						<label className="control-label" id="EXTRA_NOLOCK" style={{ fontWeight: '900' }}>
							<FormattedMessage id="EXTRA_NOLOCK" />
						</label>
						<br />
						<PlanCorrectionTeethCheck name="avoid_locks" />
						<label className="control-label" id="EXTRA_EXTRACT" style={{ fontWeight: '900' }}>
							<FormattedMessage id="EXTRA_EXTRACT" />
						</label>
						<br />
						<PlanCorrectionTeethCheck name="extractions" />
					</div>
				}
				{!isRetainersCourse(course_id) &&
					<div id="elastics-section" style={{ marginTop: "15px" }}>
						<label className="control-label" style={{ fontWeight: '900' }}>
							<FormattedMessage id="HEADER_ELASTICS" />
							<span className='prescription_tooltip_show'>
								<i style={{ fontSize: "14px", marginLeft: "4px" }} className='icon-question' />
								<span className="d-inline-block prescription_tooltip">
									<FormattedMessage id="elastics.tooltip" />
								</span>
							</span>
						</label>
						<p><FormattedMessage id='HEADER_ELASTICS_DESCRIPTION' />
							<a rel="noopener noreferrer" target="_blank" href='/api/v1/files/limits.pdf'><FormattedMessage id='tooltip.chart' /></a></p>
						<div className="row">
							<div className="form-group col-md-4" style={{ marginBottom: "20px" }}>
								<label className="control-label" id="elastics-ratio-right-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="ELASTICS_RIGHT" />
								</label>
								<div className="checkbox-list checkbox_list">
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="elastics-ratio-right-value-2"
												name="elastics_recoil_right"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
												onChange={(e) => this.resetElasticsOptionsRight(e, 'elastics_options')}
											/>
											<label id="checkbox_label" htmlFor="elastics-ratio-right-value-2"></label>
										</div>
										<FormattedMessage id="ER_R_II_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="elastics-ratio-right-value-3"
												name="elastics_recoil_right"
												format={v => v === 3}
												normalize={v => v ? 3 : null}
												onChange={(e) => this.resetElasticsOptionsRight(e, 'elastics_options')}
											/>
											<label id="checkbox_label" htmlFor="elastics-ratio-right-value-3"></label>
										</div>
										<FormattedMessage id="ER_R_III_CLASS" />
									</label>
								</div>
							</div>
							<div className="form-group col-md-4">
								<label className="control-label" id="elastics-ratio-left-label" style={{ fontWeight: '900' }}>
									<FormattedMessage id="ELASTICS_LEFT" />
								</label>
								<div className="checkbox-list checkbox_list">
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="elastics-ratio-left-value-2"
												name="elastics_recoil_left"
												format={v => v === 2}
												normalize={v => v ? 2 : null}
												onChange={(e) => this.resetElasticsOptionsLeft(e, 'elastics_options')}
											/>
											<label id="checkbox_label" htmlFor="elastics-ratio-left-value-2"></label>
										</div>
										<FormattedMessage id="ER_L_II_CLASS" />
									</label>
									<label>
										<div className="checkbox-round checker">
											<Field
												component="input"
												type="checkbox"
												id="elastics-ratio-left-value-3"
												name="elastics_recoil_left"
												format={v => v === 3}
												normalize={v => v ? 3 : null}
												onChange={(e) => this.resetElasticsOptionsLeft(e, 'elastics_options')}
											/>
											<label id="checkbox_label" htmlFor="elastics-ratio-left-value-3"></label>
										</div>
										<FormattedMessage id="ER_L_III_CLASS" /> <br /><br />
									</label>
								</div>
							</div>
						</div>
						{elastics_recoil_right || elastics_recoil_left
							? (
								<div>
									<label className="control-label" style={{ fontWeight: '900' }}>
										<FormattedMessage id="BUTTONS_AND_HOOKS" />
									</label>
									<br />
									<div className="button_hook_teeth_check">
										<PlanCorrectionButtonsHooksRight
											defaultValue={elastics_options}
											resetForm={this.resetForm}
											elastics_recoil_right={elastics_recoil_right}
										/>
										<PlanCorrectionButtonsHooksLeft
											defaultValue={elastics_options}
											resetForm={this.resetForm}
											elastics_recoil_left={elastics_recoil_left}
										/>
									</div>
								</div>
							) : null
						}
					</div>
				}
				<br />
				<button disabled={submitting} type="button" style={{ display: 'none' }}>submit</button>
			</div>
		)
	}
}

RecipeFormPredict = reduxForm({
	form: 'correction',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	updateUnregisteredFields: true
})(RecipeFormPredict);

const selector = formValueSelector('correction')

RecipeFormPredict = connect(
	(state) => {
		const upper_arch_action = selector(state, 'upper_arch_action')
		const lower_arch_action = selector(state, 'lower_arch_action')
		const impressions = selector(state, 'impressions')
		const close_all_gaps = selector(state, 'close_all_gaps')
		const elastics_recoil_right = selector(state, 'elastics_recoil_right')
		const elastics_recoil_left = selector(state, 'elastics_recoil_left')
		const close_all_gaps_value = selector(state, 'close_all_gaps_value')
		const upper_arch_expansion_torque = selector(state, 'upper_arch_expansion_torque')
		const upper_arch_expansion_bodily = selector(state, 'upper_arch_expansion_bodily')
		const information_correct = selector(state, 'information_correct')
		const midline = selector(state, 'midline')
		const vertical_overlap = selector(state, 'vertical_overlap')
		const overjet = selector(state, 'overjet')
		const molars_ipr = selector(state, 'molars_ipr')
		const molars_method = selector(state, 'molars_method')
		const canine_method_ai = selector(state, 'canine_method_ai')
		const molars_method_ai = selector(state, 'molars_method_ai')

		return {
			upper_arch_action,
			lower_arch_action,
			impressions,
			vertical_overlap,
			close_all_gaps,
			elastics_recoil_right,
			elastics_recoil_left,
			close_all_gaps_value,
			upper_arch_expansion_bodily,
			upper_arch_expansion_torque,
			information_correct,
			midline,
			overjet,
			molars_method,
			molars_ipr,
			canine_method_ai,
			molars_method_ai,
			initialValues: (
				state.patient && state.patient.course
				&& state.patient.course.correction[state.patient.course.correction.length - 1].prescription
				&& state.patient.course.correction[state.patient.course.correction.length - 1].order_options.can_order_correction === false
			) ? initializeBaseInstruction(state.patient.course.correction[state.patient.course.correction.length - 1].prescription)
				: INITIAL_VALUES_PRESCRIPTION
		}
	}
)(RecipeFormPredict)


export default connect(mapStateToProps)(injectIntl(RecipeFormPredict));
