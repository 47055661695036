import React from "react";

import PageFooter from "../page_footer";
import PageHeader from "../page_header";
import PatientMenu from "../patient_menu";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <PageHeader />
      <div className="container">
        <div className="page-container">
          <PatientMenu />
          <div className="page-content-wrapper">
            <div className="page-content" style={{ minHeight: 584 }}>
              {children}
            </div>
          </div>
        </div>
        <PageFooter />
      </div>
    </>
  );
}
