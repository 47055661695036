import {
    API_3SHAPE_ATTACHES_UPDATE,
    API_3SHAPE_AUTH,
    API_3SHAPE_CASE,
    API_3SHAPE_PATIENT_CASES} from "../config";


export function threeShapeAuth(patient_id) {
     fetch(API_3SHAPE_AUTH, {credentials: 'include'})
        .then((response) => {
            if (response.status === 401) {
                return response.text().then((res) => {
                    window.location = res;
                });
            } else if (response.status === 200) {
                window.location = `/pages/3shape/${patient_id}/cases`
            }
        })
        .catch(() => {})
}

export function threeShapeAuthScanner() {
    return fetch(API_3SHAPE_AUTH, {credentials: 'include'})
    .then((response) => {
        if (response.status === 401) {
            return response.text().then((res) => {
                window.location = res;
            });
        } else if (response.status === 200) {
            return response
        }
    })
    .catch(() => {})
}

export function auth3shapeWithSmile(code) {
    return fetch(API_3SHAPE_AUTH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            code
        })
    })
        .then((response) => {
            if (!response.ok) {
                console.error(`Failed on ${API_3SHAPE_AUTH}`);
                throw Error(response.statusText);
            }
            return response;
        })
}

export function get3ShapeCases(patient_id, page, from, to, searchString) {

    const f = !!from ? `from=${from}` : false
    const search = !!searchString ? `searchString=${searchString}` : false
    const pg = page >= 1 ? `page=${page - 1}` : `page=${0}`
    const t = !!to ? `perPage=${to}` : false

    let url = `${API_3SHAPE_PATIENT_CASES(patient_id)}?${pg}`
    if (f) {
        url += `&${f}`
    }
    if (t) {
        url += `&${t}`
    }
    if (search) {
        url += `&${search}`
    }

    return (dispatch) => {
        dispatch({type: 'LOAD_SPINNER'})
        fetch(url, {credentials: 'include'})
            .then((res) => {
                if (!res.ok) {
                    window.location = `/pages/patient/${patient_id}`
                    console.error(`Failed on ${API_3SHAPE_PATIENT_CASES(patient_id)}`);
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then(json => dispatch({type: 'GET_3SHAPE_CASES', json}))
            .finally(()=> dispatch({type: 'CLEAR_SPINNER'}))
    }
}

export function getThreeShapeCase(patient_id, case_id) {
    return fetch(API_3SHAPE_CASE(patient_id, case_id), {credentials: "include"})
        .then((res) => {
            if (!res.ok) {
                console.error(`Failed on ${API_3SHAPE_CASE(patient_id, case_id)}`);
                throw Error(res.statusText);
            }
            return res;
        })
        .then((res) => res.json())
}

export function uploadThreeShapeFileToSmile(patient_id, case_id, attaches) {
    return fetch(API_3SHAPE_CASE(patient_id, case_id), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            attachments: attaches
        })
    })
        .then((res) => {
            if (!res.ok) {
                console.error(`Failed on ${API_3SHAPE_CASE(patient_id, case_id)}`);
                throw Error(res.statusText);
            }
            return res;
        })
        .then((res) => res)
}

export function updateAttachesStatus(patient_id) {
    return fetch(API_3SHAPE_ATTACHES_UPDATE(patient_id), {credentials: 'include'})
        .then(res => {
            if (!res.ok) {
                console.error(`Failed on ${API_3SHAPE_ATTACHES_UPDATE(patient_id)}`);
                throw Error(res.statusText);
            }
            return res;
        })
        .then(res => res.json())

}