import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, getFormValues, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';


const mapStateToProps = (state) => {
	return {
		intl: state.intl,
		links: getFormValues('links')(state)
	}
}


const validateLink = (value) => {
	if ( value !== '' ) {
		return false
	}
	return true
}


const renderFieldValidation = ({input, meta, ...props}) => {
	const error = meta.touched && meta.error
	return (
		<div className={ error ? 'has-error' : ''}>
			<input {...input} {...props} />
		</div>
	);
};


const renderFields = ({ fields }) => {
	return (
		<div style={{display: "inline-block", width: "100%", marginBottom: "20px"}} >
			{fields.map(RenderField)}
		</div>
	);
}


const RenderField = (item, index, fields ) => {

	const removeField = () => {
		if (fields.length > 1) {
			fields.remove(index);
		}
	}

	const addField = () => {
		fields.push({});
	}

	return (
		<div className="row" key={index} >
			<div className="col-md-10 col-xs-8 col-sm-6">
				<Field
					component={renderFieldValidation}
					name={`${item}link`}
					className="form-control"
					type="text"
					validate={[validateLink]}
					normalize={v =>  v.trim()}
				/>
				<label className="control-label" id="validation-link" style={{fontSize: "12px", fontStyle: "italic"}}>
					(<FormattedMessage id="ADD_LINK_TO_CT" />)
				</label>
			</div>
			<button
				style={{marginRight: "5px"}}
				className="btn btn-danger"
				type="button"
				onClick={removeField}
			>-</button>
			<button className="btn btn-success"	type="button" onClick={addField}>+</button>
		</div>
	)
}


class LinksCT extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div>
				<FieldArray name="links" component={renderFields} />
			</div>
		)
	}
};


LinksCT = reduxForm({ form: 'links', initialValues: { links: [{}]}})(LinksCT);


export default connect(mapStateToProps, null)(injectIntl(LinksCT));
