import type { bannersIsLoadingAction } from "../actions/banners";
import type { userIsLoadingAction, userLoggedInSuccess } from "../actions/login";
import type { getNotificationsAction, notificationWasShownAction } from "../actions/notifications";

export function bannersIsLoading(
  state = false,
  action: ReturnType<typeof bannersIsLoadingAction>,
): boolean {
  switch (action.type) {
    case "BANNERS_IS_LOGGING_IN":
      return action.bannersIsLoading;

    default:
      return state;
  }
}

export function userIsLoading(
  state = true,
  action: ReturnType<typeof userIsLoadingAction>,
): boolean {
  switch (action.type) {
    case "USER_IS_LOGGING_IN":
      return action.userIsLoading;

    default:
      return state;
  }
}

export function notificationWasShown(
  state = true,
  action: ReturnType<typeof notificationWasShownAction>,
): boolean {
  switch (action.type) {
    case "NOTIFICATIONS_WAS_SHOWN":
      return action.notificationWasShown;

    default:
      return state;
  }
}

export type TUser = {
  account_id: number;
  account_type_id: number;
  daily_token: string;
  email: string;
  email_notify: number | null;
  first_name: string;
  has_signed_offer: boolean;
  is_verified: boolean;
  last_name: string;
  login: string;
  middle_name: string;
  phone: string;
  telegram_id: string;
  telegram_notify: number | null;
  privileges: {
    easy_correction_form: 0 | 1;
  };
};

export function user(
  state: Partial<TUser> = {},
  action: ReturnType<typeof userLoggedInSuccess>,
): Partial<TUser> {
  switch (action.type) {
    case "USER_LOGGED_IN_SUCCESS":
      if (action.fields) {
        return action.fields;
      } else {
        return state;
      }

    default:
      return state;
  }
}

export function patient(state = {}, action) {
  switch (action.type) {
    case "GET_PATIENT":
      return action.json.patient;
    case "ERASE_PATIENT":
      return {};

    default:
      return state;
  }
}

export function patients(state = [], action) {
  switch (action.type) {
    case "GET_PATIENTS_SUCCESS":
      return action.json.patients;

    default:
      return state;
  }
}

export function comments(state = {}, action) {
  switch (action.type) {
    case "GET_PATIENT_COMMENTS":
      return action.json;
    case "POST_PATIENT_COMMENTS":
      return action.json;
    case "ERASE_COMMENTS":
      return {};
    default:
      return state;
  }
}

export function errorMessage(state = "", action): string {
  switch (action.type) {
    case "USER_LOGGED_IN_ERROR":
      return action.message;
    case "USER_SEND_PASSWORD_ERROR":
      return action.json.message;
    case "USER_INIT_FAILURE":
      return action.message;

    default:
      return state;
  }
}

export type TNotification = TPaymentNotification;

type TPaymentNotification = {
  id: number;
  type: "waiting_for_payment";
  additional_data: {
    patient_id: number;
  };
};

export function notifications(
  state: TNotification[] = [],
  action: ReturnType<typeof getNotificationsAction>,
): TNotification[] {
  switch (action.type) {
    case "GET_NOTIFICATIONS":
      return action.notifications;

    default:
      return state;
  }
}
