import React from "react";
import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import { FormattedMessage } from "react-intl";
import { priceList } from "../../common/constants";

export function PriceList() {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0 },
    animate: { height: "auto", transition: { duration: 0.5 } },
    exit: { height: 0 },
  };

  return (
    <div className="col-xs-12 tw-overflow-hidden">
      <motion.div className="tw-m-0" {...(shouldReduceMotion ? {} : animationProps)}>
        <div className=" tw-mx-auto tw-py-6">
          <table className="tw-min-w-full tw-rounded-lg tw-bg-white">
            <thead>
              <tr className="tw-bg-slate-200 tw-text-center">
                <th
                  className="tw-px-3 tw-py-3 tw-text-xs tw-font-semibold"
                  style={{ color: "#20D3E2" }}
                >
                  <FormattedMessage id="pricelist.table.service" />
                </th>
                <th
                  className="tw-px-3 tw-py-3  tw-text-center tw-text-xs tw-font-semibold"
                  style={{ color: "#20D3E2" }}
                >
                  <FormattedMessage id="pricelist.table.price" />
                </th>
                <th
                  className="tw-px-3 tw-py-3  tw-text-center tw-text-xs tw-font-semibold"
                  style={{ color: "#20D3E2" }}
                >
                  <FormattedMessage id="pricelist.table.quantity" />
                </th>
                <th
                  className="tw-px-3 tw-py-3 tw-text-center  tw-text-xs tw-font-semibold"
                  style={{ color: "#20D3E2" }}
                >
                  <FormattedMessage id="pricelist.table.corrections" />
                </th>
                <th
                  className="tw-px-3 tw-py-3  tw-text-center tw-text-xs tw-font-semibold"
                  style={{ color: "#20D3E2" }}
                >
                  <FormattedMessage id="pricelist.table.expiration.date" />
                </th>
              </tr>
            </thead>
            <tbody>
              {priceList.map((row, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #e2e8f0" }}>
                  <td className="tw-px-3 tw-py-4 tw-text-sm">
                    <FormattedMessage id={row.caseType} />
                  </td>
                  <td className="tw-px-3 tw-py-4 tw-text-xs tw-text-gray-700">{row.price}</td>
                  <td className="tw-px-3 tw-py-4 tw-text-center tw-text-xs tw-font-semibold">
                    {row.quantity !== "" ? (
                      row.quantity
                    ) : (
                      <i
                        style={{ fontSize: "10px", color: "#92979d" }}
                        className="fa fa-times"
                        aria-hidden="true"
                      ></i>
                    )}
                  </td>
                  <td className="tw-px-3 tw-py-4 tw-text-center tw-text-sm">
                    {row.corrections ? (
                      row.corrections
                    ) : (
                      <i
                        style={{ fontSize: "10px", color: "#92979d" }}
                        className="fa fa-times"
                        aria-hidden="true"
                      ></i>
                    )}
                  </td>
                  <td className="tw-px-3 tw-py-4 tw-text-center tw-text-xs">
                    {row.expiration ? (
                      row.expiration
                    ) : (
                      <i
                        style={{ fontSize: "10px", color: "#92979d" }}
                        className="fa fa-times"
                        aria-hidden="true"
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </motion.div>
    </div>
  );
}
