import { isEnglishLocale } from "./common/locale";
import englishMessages from "./locale/en";
import russianMessages from "./locale/ru";
import configureStore from "./store";

// NOTE: Needs to be in a separate module to avoid circular imports.
export const store = configureStore({
  intl: isEnglishLocale()
    ? { locale: "en", messages: englishMessages }
    : { locale: "ru", messages: russianMessages },
});
