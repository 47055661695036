import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { change, formValueSelector, getFormValues } from "redux-form";

import { sendComment } from "../../actions/comments";
import { eraseStateProp } from "../../actions/dashboard";
import { getPatientId } from "../../actions/get_patient_id";
import { addInstructions } from "../../actions/patient_new_instructions";
import { updateMedia, updatePatient } from "../../actions/post_patient";
import { patientIsSavingSuccess, sendPatientCorrection, updatePatientCorrection } from "../../actions/send_patient_correction";
import { addUserActionNotification } from "../../actions/user_notification";
import { COMMENT_TYPE_CORRECTION } from "../../common/constants";
import { hasDeepCBCT } from "../../common/courses";
import { convertToJSONFullRx } from "../../common/instructions";
import remoteLog from "../../common/logging";
import { setDocumentTitle } from "../../hooks/use-document-title";
import type { RootState } from "../../store";
import Loader from "../common/loadingInProgress";
import { scrollTo } from "../common/ScrollToElement/scrollTo";
import LinksCT from "../patient/addLinks";
import ImpressionScanOptions from "../patient/impression_scan_options";
import PatientUpdateInstructionsNewMedia from "../patient/patient_update/patient_update_instructions_new_media";
import PatientUpdateInstructionsUploadCt from "../patient/patient_update/patient_update_instructions_upload_ct";
import RecipeFormSmile from "../patient/smile_recipe_redux_form";
import PlanCorrectionPhoto from "./3d_plan_correction_photo";
import CorrectionWithCTRadioGroup from "./3d_plan_correction_with_ct";

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  patient: state.patient,
  comments: state.comments,
  media: state.media,
  media_s3: state.media_s3,
  instructions: state.instructions,
  formValues: getFormValues("correction")(state),
  links: getFormValues("links")(state),
  patientSaving: state.patientSaving
});

const mapDispatchToProps = (dispatch) => {
  return {
    sendComment: (patient_id, comment) => dispatch(sendComment(patient_id, comment, COMMENT_TYPE_CORRECTION)),
    updateMedia: (media, patient_id) => dispatch(updateMedia(media, patient_id)),
    eraseStateProp: (props) => dispatch(eraseStateProp(props)),
    getPatient: (patient_id) => dispatch(getPatientId(patient_id)),
    addUserActionNotification: (json) => dispatch(addUserActionNotification(json)),
    addInstruction: (data) => dispatch(addInstructions(data)),
    sendPatientCorrection: (patient_id, media_info, s3_media, links, stage, prescription, correction_with_ct) => dispatch(sendPatientCorrection(patient_id, media_info, s3_media, links, stage, prescription, correction_with_ct)),
    updatePatientCorrection: (patient_id, correction_id, data) => dispatch(updatePatientCorrection(patient_id, correction_id, data)),
    updatePatient: (...args) => dispatch(updatePatient(...args)),
    change: (form, field, value) => dispatch(change(form, field, value)),
    patientIsSaving: (bool) => dispatch(patientIsSavingSuccess(bool))
  }
}

type PlanCancelSmileProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{ patient_id: string }>;

type PlanCancelSmileState = {
  movment_error: boolean;
  confirmation: number;
  correction_length: number | false;
  showLoader: boolean;
  easy_validate: boolean;
  plan_stage: string;
  clicked: boolean;
};

class PlanCancelSmile extends Component<PlanCancelSmileProps, PlanCancelSmileState> {
  constructor(props: PlanCancelSmileProps) {
    super(props);
    this.state = {
      movment_error: false,
      confirmation: 0,
      correction_length: false,
      showLoader: false,
      easy_validate: false,
      plan_stage: "",
      clicked: false,
    }
    this.validateStateValue = this.validateStateValue.bind(this);
    this.submitCorrection = this.submitCorrection.bind(this);
    this.renderCheckList = this.renderCheckList.bind(this);
    this.validateImages = this.validateImages.bind(this);
    this.addComment = this.addComment.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "3d_plan_correction_page_smile");
  }

  componentDidMount() {
    const { patient_id } = this.props.match.params;
    this.props.getPatient(patient_id);
    this.props.eraseStateProp("media");

    if (this.props.user && this.props.user.privileges && this.props.user.privileges.easy_correction_form) {
      this.setState({ easy_validate: true })
    }

    setDocumentTitle(this.props.intl.formatMessage({ id: "treatment.correct.header" }));
  }

  UNSAFE_componentWillReceiveProps(nextProps: PlanCancelSmileProps) {
    const { patient } = nextProps;
    if (patient && patient.course && patient.course.correction.slice(-1)[0].order_options.can_order_correction === false) {
      this.props.history.push("/pages/patients/");
    }
    if (this.state.correction_length == false) {
      if (nextProps.patient && nextProps.patient.course) {
        this.setState({ "correction_length": nextProps.patient.course.correction.length })
      }
    }

    if (nextProps.patient) {
      if (nextProps.patient.course && this.state.correction_length) {
        if (nextProps.patient.course.correction.length > this.state.correction_length && nextProps.patient.patient_id) {
          this.props.history.push("/pages/patients/");
        }
      }
    }

    if (nextProps.user && nextProps.user.privileges && nextProps.user.privileges.easy_correction_form) {
      this.setState({ easy_validate: true })
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.patientSaving === true) {
      this.props.patientIsSaving(false);
      this.props.eraseStateProp("patient");
      this.props.history.push("/pages/patients");
    }
  }

  componentDidUpdate(prevProps) {
    const { patient } = this.props;
    if (prevProps.patient && !Object.keys(prevProps.patient).length && this.props.patient && this.props.patient.course) {
      const plan_stage = patient.course.correction.slice(-1)[0].steps_count_completed;
      if (this.state.plan_stage === 0 || !this.state.plan_stage) {
        if (this.state.plan_stage !== plan_stage && plan_stage > 0) {
          this.setState({ "plan_stage": plan_stage });
        }
      }
    } else {
      if (prevProps.patient && Object.keys(prevProps.patient).length > 0 && prevProps.patient.course && prevProps.patient.course.correction) {
        if (this.state.plan_stage === 0 || !this.state.plan_stage) {
          const plan_stage = prevProps.patient.course.correction.slice(-1)[0].steps_count_completed;
          if (this.state.plan_stage != plan_stage && plan_stage > 0) {
            this.setState({ "plan_stage": plan_stage });
          }
        }
      }
    }
  }

  renderCheckList() {
    const doneStyles = { color: "#82D9D4", fontWeight: 700 };
    const unDoneStyles = { color: "red", fontWeight: 500 };

    const { patient, arch, material, vertical_overlap, vertical_overlap_comment, correction_with_ct } = this.props;
    const lastCorrection = patient.course.correction[patient.course.correction.length - 1];
    const stage = this.state.plan_stage || lastCorrection.steps_count_completed !== 0 ? lastCorrection.steps_count_completed : null;

    return (
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <div className="scroll-block" style={{ marginBottom: "25px" }}>
            <p><FormattedMessage id="plan.cancel.scroll.to.field" /></p>
            <ScrollToButton className="scrollBtn" duration={1500} toId="validation-stage">
              <i className="icon-check" style={stage !== null && stage !== undefined ? doneStyles : unDoneStyles} />&nbsp;&nbsp;
              <FormattedMessage id="recipe.check.reference.steps" />
            </ScrollToButton>
          </div>
          <div className="scroll-block" style={{ marginBottom: "25px" }}>
            <p><FormattedMessage id="plan.cancel.scroll.to.field" /></p>
            <ScrollToButton className="scrollBtn" duration={1500} toId="validation-material">
              <i className="icon-check" style={material !== null ? doneStyles : unDoneStyles} />&nbsp;&nbsp;
              <FormattedMessage id="recipe.check.reference.material" />
            </ScrollToButton>
          </div>
          {hasDeepCBCT(patient) ? (
            <div className="scroll-block" style={{ marginBottom: "25px" }}>
              <p><FormattedMessage id="plan.cancel.scroll.to.field" /></p>
              <ScrollToButton className="scrollBtn" duration={1500} toId="validation-correction-with-ct">
                <i className="icon-check" style={correction_with_ct != null ? doneStyles : unDoneStyles} />&nbsp;&nbsp;
                <FormattedMessage id="recipe.check.reference.correction_with_ct" />
              </ScrollToButton>
            </div>
          ) : null}
          <div className="scroll-block" style={{ marginBottom: "25px" }}>
            <p><FormattedMessage id="plan.cancel.scroll.to.field" /></p>
            <ScrollToButton className="scrollBtn" duration={1500} toId="profile">
              <i className="icon-check" style={this.validateImages() ? doneStyles : unDoneStyles} />&nbsp;&nbsp;
              <FormattedMessage id="recipe.check.reference.photos" />
            </ScrollToButton>
          </div>
          <div className="scroll-block" style={{ marginBottom: "25px" }}>
            <p><FormattedMessage id="plan.cancel.scroll.to.field" /></p>
            <ScrollToButton className="scrollBtn" duration={1500} toId="validation-arch">
              <i className="icon-check" style={arch !== null ? doneStyles : unDoneStyles} />&nbsp;&nbsp;
              <FormattedMessage id="recipe.check.reference.arches" />
            </ScrollToButton>
          </div>
          {
            (vertical_overlap === 1 || vertical_overlap === 2)
              ? (
                <div className="scroll-block" style={{ marginBottom: "25px" }}>
                  <p><FormattedMessage id="plan.cancel.scroll.to.field" /></p>
                  <ScrollToButton className="scrollBtn" duration={1500} toId="incisors-vert-overlap-label">
                    <i className="icon-check" style={vertical_overlap_comment !== "" ? doneStyles : unDoneStyles} />&nbsp;&nbsp;
                    <FormattedMessage id="INCISORS_VO" />
                  </ScrollToButton>
                </div>
              ) : null
          }
        </div>
      </div>
    );
  }

  validateImages() {
    const requiredImages = ["front_view", "full_face_with_smile", "full_face_without_smile", "profile",
      "lateral_view_left", "lateral_view_right", "occlusal_view_lower", "occlusal_view_upper"];
    const req_files = requiredImages.every(
      (item) => { return this.props.media[item] && this.props.media[item]["md5"] }
    );
    return Boolean(req_files)
  }

  validateStateValue(e) {
    let stepsTotal = 0;
    if (this.props.patient && this.props.patient.course && this.props.patient.course.correction) {
      stepsTotal = this.props.patient.course.correction[this.props.patient.course.correction.length - 1].steps_count_total;
    }
    if (e.target.value <= 0 || e.target.value > stepsTotal) {
      e.target.value = null
      this.setState({ "plan_stage": null });
    }
    if (e.target.value !== "") {
      this.setState({ "plan_stage": parseInt(e.target.value) });
    }
  }

  addComment(data) {
    this.props.change("correction", "comment", data);
  }

  submitCorrection() {
    this.setState({ clicked: true });
    const { patient_id } = this.props.match.params;
    const rxTypeId = this.props.patient.rx_type_id;
    const links = [];
    const linksArray = this.props.links && this.props.links.links ? this.props.links.links : [];
    linksArray.forEach(el => links.push(...Object.values(el)));
    const media_info = this.props.media;
    const s3_media = this.props.media_s3;
    const prescription = convertToJSONFullRx(this.props.formValues);
    prescription.rx_type_id = rxTypeId;
    const stage = this.state.plan_stage || this.props.patient.course.correction[this.props.patient.course.correction.length - 1].steps_count_completed;
    this.props.sendPatientCorrection(patient_id, media_info, s3_media, links, stage, prescription, this.props.correction_with_ct);
  }

  render() {
    const { patient } = this.props;
    const patientIsLoading = !patient.patient_id;

    if (patientIsLoading) {
      return <Loader />;
    }

    const correction_num = patient.course ? patient.course.correction.length : undefined;

    let stepsTotal = 0;
    let stepsCompleted = 0;

    if (patient.course && patient.course.correction) {
      const lastCorrection = patient.course.correction[patient.course.correction.length - 1];
      stepsTotal = lastCorrection.steps_count_total;
      stepsCompleted = this.state.plan_stage || lastCorrection.steps_count_completed != 0
        ? lastCorrection.steps_count_completed
        : null;
    }

    const { course_id } = patient.course;
    const { material, arch, vertical_overlap, vertical_overlap_comment } = this.props;
    const isDeepCBCT = hasDeepCBCT(patient);

    return (
      <div className="portlet light bordered" id="correction-section">
        <div className="portlet-title">
          <div className="col-md-8 col-sm-8 col-xs-8 caption">
            <i className="icon-book-open font-green" />
            <span className="caption-subject font-green bold uppercase">
              <FormattedMessage id="treatment.correct.header" />
            </span>
          </div>

          <div className="col-md-4 col-sm-4 col-xs-4" style={{ padding: "8px 0" }}>
            <span className="required mob-title" aria-required="true">* </span>
            <i className="mob-title">
            - <FormattedMessage id="requiredField" />
            </i>
          </div>
        </div>

        <div className="portlet-body">
          <div id="correction-stage-section" className="form-group">
            <label
              id="validation-stage"
              className="control-label"
              style={{ fontWeight: 900 }}
              htmlFor="correction-stage-value"
            >
              <FormattedMessage id="plan.cancel.stage" />
              <span className="required" aria-hidden="true">*</span>
            </label>
            <br />
            <br />
            <input
              id="correction-stage-value"
              style={{ width: 80, display: "inline" }}
              type="number"
              size={2}
              min={1}
              max={stepsTotal}
              className="form-control"
              name="stage"
              defaultValue={stepsCompleted}
              onInput={e => this.validateStateValue(e)}
            />&nbsp;
            <span><FormattedMessage id="pat_table.of" /> {stepsTotal}</span>
          </div>

          <br />
          <ImpressionScanOptions />
          <br />
          {isDeepCBCT ? (
            <>
              <CorrectionWithCTRadioGroup />
              <br />
            </>
           ) : null}
          <PlanCorrectionPhoto correctionNumber={correction_num} />
          <PatientUpdateInstructionsNewMedia has_medit_files={false} />
          <PatientUpdateInstructionsUploadCt />
          <LinksCT />
          <RecipeFormSmile course_id={course_id} />
          {this.renderCheckList()}
          <br />
          <div>
            <button
              disabled={(this.props.instructions.stage === null
                || !this.validateImages()
                || !material
                || !arch
                || ((vertical_overlap === 1 || vertical_overlap === 2) && vertical_overlap_comment === ""))
                || (isDeepCBCT ? this.props.correction_with_ct == null: false)
                && !this.state.clicked}
              type="submit"
              className="btn green"
              id="submit-pacient-btn"
              style={{ margin: 2 }}
              onClick={() => this.submitCorrection()}
            >
              <FormattedMessage id="plan.cancel.btn" />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const selector = formValueSelector("correction")

PlanCancelSmile = connect(
  (state) => {
    const material = selector(state, "material")
    const arch = selector(state, "arch")
    const vertical_overlap = selector(state, "vertical_overlap")
    const vertical_overlap_comment = selector(state, "vertical_overlap_comment")
    const correction_with_ct = selector(state, "correction_with_ct");

    return {
      material,
      arch,
      vertical_overlap,
      vertical_overlap_comment,
      correction_with_ct,
    }
  }
)(PlanCancelSmile)


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PlanCancelSmile));

function ScrollToButton({
  toId,
  duration,
  children,
  className,
}: {
  toId: string;
  duration: number;
  className: string;
  children: React.ReactNode;
}) {
  const handleClick = () => scrollTo({ id: toId, duration });

  return (
    <button className={className} onClick={handleClick}>
      {children}
    </button>
  );
}
