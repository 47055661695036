import clsx from "clsx";
import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";

import { eraseStateProp } from "../../actions/dashboard";
import { getPatientId } from "../../actions/get_patient_id";
import { orderRetainers } from "../../actions/order_retainers";
import { rushAdditionalServiceOn, updateMedia } from "../../actions/post_patient";
import {
  SO_BOTH_ARCHES,
  SO_IMPRESSIONS,
  SO_LAST_STAGE,
  SO_LOWER_ARCH,
  SO_UPPER_ARCH,
  SO_USE_SCAN,
  type TArch,
  type TMaterial,
} from "../../common/constants";
import remoteLog from "../../common/logging";
import { deployedRussia, deployedUSA } from "../../common/utils";
import { setDocumentTitle } from "../../hooks/use-document-title";
import type { RootState } from "../../store";
import Loader from "../common/loadingInProgress";
import PatientNewInstructionsFiles from "../patient/patient_new/patient_new_instructions_files";
import { Spinner } from "../ui/spinner";

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  patient: state.patient,
  media: state.media,
  rush_additional_service: state.rushAdditionalService,
  services: state.services,
});

// const mapDispatchToProps = {
//   updateMedia,
//   eraseStateProp,
//   getPatientId,
//   orderRetainers
// };

const mapDispatchToProps = (dispatch) => {
  return {
    updateMedia: (...args) => dispatch(updateMedia(...args)),
    eraseStateProp: (...args) => dispatch(eraseStateProp(...args)),
    getPatientId: (...args) => dispatch(getPatientId(...args)),
    orderRetainers: (...args) => dispatch(orderRetainers(...args)),
    rushAdditionalService: (data) => dispatch(rushAdditionalServiceOn(data)),
  };
};

type PlanRetainersProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{
    patient_id: string;
  }>;

type PlanRetainersState = {
  isSubmitting: boolean;
  material: TMaterial | null;
  existingScanUploaded: string | null;
  treatArchesOption: TArch | null;
  quantity: number | null;
  comment: string | null;
};

class PlanRetainers extends Component<PlanRetainersProps, PlanRetainersState> {
  constructor(props: PlanRetainersProps) {
    super(props);
    this.state = {
      isSubmitting: false,
      material: null,
      existingScanUploaded: null,
      treatArchesOption: null,
      quantity: null,
      comment: "",
      // paymentMethod: this.props.patient.course.payment_method,
    };
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "3d_plan_retainers_page_body");
  }

  componentDidMount() {
    const { user } = this.props;

    this.props.getPatientId(this.props.match.params.patient_id);
    this.props.eraseStateProp("media");

    setDocumentTitle(
      this.props.intl.formatMessage({
        id: deployedRussia() ? "pat.retainers.page.header" : "pat.retainers.page.header.usa",
      }),
    );
  }

  componentDidUpdate(nextProps) {
    if (this.props.services.length !== nextProps.services.length) {
      const paymentMethod = this.state.payment_method;
      if (paymentMethod === "PM_CARD") {
        nextProps.history.push("/pages/payments");
      } else {
        nextProps.history.push("/pages/patients");
      }
    }
  }

  async orderRetainersSubmit() {
    const { material, treatArchesOption, quantity, comment } = this.state;

    if ($("#submit-pacient-btn").attr("disabled")) {
      return;
    }

    if (!this.validateInstructions()) {
      $("#submit-pacient-btn").removeClass("-error");
      setTimeout(() => {
        $("#submit-pacient-btn").addClass("-error");
      }, 0);
      return;
    }

    this.setState({ isSubmitting: true });
    this.props.updateMedia(this.props.match.params.patient_id, { media: this.props.media });

    try {
      await this.props.orderRetainers(
        this.props.match.params.patient_id,
        material,
        treatArchesOption,
        quantity,
        comment,
        this.props.rush_additional_service,
      );
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  validateInstructions() {
    const { material, existingScanUploaded, treatArchesOption, quantity } = this.state;

    const useScanClicked = material == SO_USE_SCAN || existingScanUploaded ? true : false;
    const mediaKeys = Object.keys(this.props.media);
    const mediaAttachments = mediaKeys.map((elm) => this.props.media[elm].user_filename);
    const scanUploaded =
      existingScanUploaded ||
      !!mediaAttachments.filter((elmm) => {
        if (elmm) {
          const cond = elmm.includes("stl");
          return cond;
        }
      }).length;
    const quantityIsValid = deployedUSA() || (deployedRussia() && Boolean(quantity));

    $("#instruction-files").css({
      color: !useScanClicked ? "#34495e" : scanUploaded ? "#34495e" : "red",
    });

    $("#retainers-label").css({
      color: material != null ? "#34495e" : "red",
    });

    $("#treat-arches-id-label").css({
      color: treatArchesOption !== null ? "#34495e" : "red",
    });

    $("#quantity-label").css({
      color: quantityIsValid ? "#34495e" : "red",
    });

    return (
      Boolean(material != null ? (useScanClicked ? scanUploaded : true) : false) &&
      treatArchesOption !== null &&
      quantityIsValid
    );
  }

  render() {
    if (!this.props.patient.patient_id) {
      return <Loader />;
    }

    const { material, existingScanUploaded, isSubmitting } = this.state;

    return (
      <div id="retainers-section-head" className="portlet light bordered">
        <div className="portlet-title">
          <div className="caption">
            <i className="icon-book-open font-green" />
            <span className="caption-subject font-green bold uppercase">
              <FormattedMessage
                id={
                  deployedRussia() ? "pat.retainers.page.header" : "pat.retainers.page.header.usa"
                }
              />
            </span>
          </div>
        </div>

        <div className="portlet-body">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.orderRetainersSubmit();
            }}
          >
            <MaterialRadioGroup
              material={this.state.material}
              onMaterialChange={(newMaterial) => this.setState({ material: newMaterial })}
            />

            <AnimatePresence>
              {material == SO_USE_SCAN ? (
                <ScanUploadRoot>
                  <PatientNewInstructionsFiles style={{ paddingBottom: 20 }} />

                  <div style={{ paddingBottom: 20 }} className="checkbox-list checkbox_list">
                    <label>
                      <div className="checkbox-round checker">
                        <input
                          type="checkbox"
                          id="retainers-use-itero-scan-label"
                          name="existingScanUploaded"
                          value="itero"
                          checked={existingScanUploaded === "itero"}
                          onChange={(e) =>
                            e.target.checked
                              ? this.setState({
                                  existingScanUploaded: e.target.value,
                                })
                              : this.setState({ existingScanUploaded: null })
                          }
                        />
                        <label id="checkbox_label" htmlFor="retainers-use-itero-scan-label"></label>
                      </div>
                      <FormattedMessage id="UPLOASES_FILES_EXIST" />
                    </label>
                    <label>
                      <div className="checkbox-round checker">
                        <input
                          type="checkbox"
                          id="retainers-use-3shape-scan-value"
                          name="existingScanUploaded"
                          value="shape"
                          checked={existingScanUploaded === "shape"}
                          onChange={(e) =>
                            e.target.checked
                              ? this.setState({
                                  existingScanUploaded: e.target.value,
                                })
                              : this.setState({ existingScanUploaded: null })
                          }
                        />
                        <label
                          id="checkbox_label"
                          htmlFor="retainers-use-3shape-scan-value"
                        ></label>
                      </div>
                      <FormattedMessage id="UPLOASES_FILES_EXIST_3SHAPE" />
                    </label>
                    <label>
                      <div className="checkbox-round checker">
                        <input
                          type="checkbox"
                          id="retainers-use-medit-scan-label"
                          name="existingScanUploaded"
                          value="medit"
                          checked={existingScanUploaded === "medit"}
                          onChange={(e) =>
                            e.target.checked
                              ? this.setState({
                                  existingScanUploaded: e.target.value,
                                })
                              : this.setState({ existingScanUploaded: null })
                          }
                        />
                        <label id="checkbox_label" htmlFor="retainers-use-medit-scan-label"></label>
                      </div>
                      <FormattedMessage id="UPLOASES_FILES_EXIST_MEDIT" />
                    </label>
                  </div>

                  <div id="form_retainers_error"> </div>
                </ScanUploadRoot>
              ) : null}
            </AnimatePresence>

            <ArchRadioGroup
              arch={this.state.treatArchesOption}
              onArchChange={(newArch) => this.setState({ treatArchesOption: newArch })}
            />

            {deployedRussia() ? (
              <QuantityRadioGroup
                quantity={this.state.quantity}
                onQuantityChange={(newQuantity) => this.setState({ quantity: newQuantity })}
              />
            ) : null}

            {deployedUSA() ? (
              <div className="form-group" id="retainers-section">
                <label id="retainers-label" className="control-label" style={{ fontWeight: "900" }}>
                  <FormattedMessage id="RETAINERS_COMMENT" />
                </label>
                <textarea
                  className="form-control"
                  rows={5}
                  id="retainers-comment-value"
                  name="comment"
                  onChange={(e) => this.setState({ comment: e.target.value })}
                />
              </div>
            ) : null}

            <div className="form-group tw-ml-1 tw-mt-4">
              <label htmlFor="rash" style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <input
                  id="rash"
                  style={{ marginTop: 0 }}
                  type="checkbox"
                  name="rush_correction"
                  onChange={(e) => this.props.rushAdditionalService(e.currentTarget.checked)}
                />
                <span className="tw-font-bold">
                  <FormattedMessage id="additional_service_rush" />{" "}
                </span>
                <span className="tw-text-[12px]">
                  <FormattedMessage id="additional_service_rush_comment" />
                </span>
              </label>
            </div>

            <button
              id="submit-pacient-btn"
              className={clsx(
                "btn green tw-relative",
                "focus-visible:tw-ring-2 focus-visible:tw-ring-blue-600 focus-visible:tw-ring-offset-1",
              )}
              disabled={isSubmitting}
            >
              <span className={clsx({ "tw-invisible": this.state.isSubmitting })}>
                <FormattedMessage id="pat.payments.buttons.submit" />
              </span>

              {this.state.isSubmitting ? (
                <div className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
                  <Spinner />
                </div>
              ) : null}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PlanRetainers));

function MaterialRadioGroup({
  material,
  onMaterialChange,
}: {
  material: TMaterial | null;
  onMaterialChange(newMaterial: TMaterial): void;
}) {
  type TOption = { value: TMaterial; intlId: MessageDescriptor["id"] };

  const options: TOption[] = [
    { value: SO_LAST_STAGE, intlId: "EXTRA_SERVICE_OPTION_LAST_STAGE" },
    { value: SO_USE_SCAN, intlId: "EXTRA_SERVICE_OPTION_USE_SCAN" },
    { value: SO_IMPRESSIONS, intlId: "EXTRA_SERVICE_OPTION_USE_IMPRESSIONS" },
  ];

  return (
    <fieldset className="tw-mb-4 tw-space-y-1" aria-required="true">
      <legend id="retainers-label" className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold">
        <FormattedMessage id="ATTACHMENT_INSTRUCTIONS" />
        <RequiredIndicator />
      </legend>

      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`retainers-material-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`retainers-material-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="material"
              value={option.value}
              checked={option.value == material}
              onChange={() => onMaterialChange(option.value)}
            />
            <FormattedMessage id={option.intlId} />
          </label>
        ))}
      </div>
    </fieldset>
  );
}

function ArchRadioGroup({
  arch,
  onArchChange,
}: {
  arch: TArch | null;
  onArchChange(newArch: TArch): void;
}) {
  type TOption = { value: TArch; intlId: MessageDescriptor["id"] };

  const options: TOption[] = [
    { value: SO_BOTH_ARCHES, intlId: deployedRussia() ? "TA_BOTH" : "TA_BOTH_RETAINERS_USA" },
    { value: SO_UPPER_ARCH, intlId: deployedRussia() ? "TA_UPPER" : "TA_UPPER_RETAINERS_USA" },
    { value: SO_LOWER_ARCH, intlId: deployedRussia() ? "TA_LOWER" : "TA_LOWER_RETAINERS_USA" },
  ];

  return (
    <fieldset className="tw-mb-4 tw-space-y-1" aria-required="true">
      <legend id="treat-arches-id-label" className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold">
        <FormattedMessage id="ARCHES_SELECT" />
        <RequiredIndicator />
      </legend>

      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`treat-arches-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`treat-arches-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="treat_arches_id"
              value={option.value}
              checked={option.value == arch}
              onChange={() => onArchChange(option.value)}
            />
            <FormattedMessage id={option.intlId} />
          </label>
        ))}
      </div>

      <div id="form_tooth_arch_error"> </div>
    </fieldset>
  );
}

function QuantityRadioGroup({
  quantity,
  onQuantityChange,
}: {
  quantity: number | null;
  onQuantityChange(newQuantity: number): void;
}) {
  const options = [
    { value: 1, text: "1" },
    { value: 2, text: "2" },
  ] as const;

  return (
    <fieldset className="tw-mb-4 tw-space-y-1" aria-required="true">
      <legend id="quantity-label" className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold">
        <FormattedMessage id="RETAINERS_QUANTITY" />
        <RequiredIndicator />
      </legend>

      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`retainers-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`retainers-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="retainers"
              value={option.value}
              checked={option.value == quantity}
              onChange={() => onQuantityChange(option.value)}
            />
            {option.text}
          </label>
        ))}
      </div>
    </fieldset>
  );
}

function RequiredIndicator() {
  return (
    <span aria-hidden="true" className="required tw-relative tw-bottom-1 tw-left-1 tw-text-[17px]">
      *
    </span>
  );
}

function ScanUploadRoot({ children }: { children: React.ReactNode }) {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0 },
    animate: { height: "auto", transition: { duration: 0.5 } },
    exit: { height: 0 },
  };

  return (
    <>
      <motion.div
        // PatientNewInstruionsFiles h4 adds a margin-top and padding-top that causes
        // the extra vertical space. Had to remove it from here.
        className="tw-overflow-hidden [&_h4]:tw-mt-0 [&_h4]:tw-pt-0"
        {...(shouldReduceMotion ? {} : animationProps)}
      >
        {children}
      </motion.div>
    </>
  );
}
