import React, {Component} from 'react';
import { connect } from 'react-redux';
import { addInstructions, removeInstructions } from '../../../actions/patient_new_instructions';
import {FormattedMessage, injectIntl} from 'react-intl';

import remoteLog from '../../../common/logging';
import { deployedUSA, deployedRussia } from '../../../common/utils';
import {
  PAYERS, INSTALLMENT, PM_INVOICE, PM_CARD, PO_ADVANCED,
  PO_2_STEPS, PO_3_STEPS
} from '../../../common/constants';
import { CASE_RETAINERS } from '../../../common/courses';
import { SBCreditComponent } from '../../common/common';


const mapStateToProps = (state) => {
  return {
    user: state.user,
    lang: state.intl,
    intl: state.intl,
    instructions: state.instructions,
    selectedCourseInstallment: state.selectCourseReducer,
    courseSelected: state.courseSelectedReducer,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    addInstruction: (data) => dispatch(addInstructions(data)),
    removeInstructions: (data) => dispatch(removeInstructions(data)),
  }
}


class PatientNewPayment extends Component {

  constructor(props) {
    super(props);
    this.pOptionAdvancedRef;
    this.pOptionTwoRef;
    this.pOptionThreeRef;
    this.pOptionCredit;

    this.fm = this.fm.bind(this);
    this.selectPayer = this.selectPayer.bind(this);
    this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
    this.selectPaymentOption = this.selectPaymentOption.bind(this);
    this.setPaymentCreditOption = this.setPaymentCreditOption.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'patient_new_payment');
  }

  fm(id){
    if (deployedUSA()) {
      return this.props.intl.formatMessage({ id: id });
    }
  }

  componentDidMount() {
    if (deployedUSA() === true) {
      this.props.addInstruction({'payment_option_id': PO_ADVANCED});
      const { user: { preferences } } = this.props;
      if (preferences && Object.keys(preferences).length > 0) {
        if (preferences.default_payment_method !== null && [PM_INVOICE, PM_CARD].includes(preferences.default_payment_method)) {
          this.props.addInstruction({'payment_method_id': preferences.default_payment_method});
        }
      }
    }
  }

  selectPaymentMethod(data) {
    this.props.addInstruction({'payment_method_id':parseInt(data)});
    this.props.setDirty(data);
  }

  selectPaymentOption(data) {
    this.props.addInstruction({'payment_option_id':parseInt(data)});
    this.props.setDirty(data);
    this.props.addInstruction({ 'sber_credit': false });
    if (this.pOptionCredit && this.pOptionCredit.checked) {
      this.pOptionCredit.checked = false;
    }
  }

  setPaymentCreditOption() {
    /** Payment option should be Advanced in case if credit option is chosen. On the UI/Interface level we do not show this */
    this.props.addInstruction({ 'sber_credit': true });
    this.props.addInstruction({ 'payment_option_id': PO_ADVANCED });

    this.pOptionAdvancedRef.checked = false;
    this.pOptionTwoRef.checked = false;
    this.pOptionThreeRef.checked = false;
  }

  selectPayer(data) {
    const payer_id = parseInt(data);
    this.props.setDirty(data);

    if (payer_id === PAYERS['PATIENT']) {
      if (this.props.instructions.payment_method_id === 3) {
        this.props.addInstruction({ 'payer_id': payer_id });
      } else {
        this.props.addInstruction({ 'payer_id': payer_id });
        this.props.removeInstructions({ 'payment_method_id': null });
      }
    } else if (payer_id === PAYERS['DOCTOR']) {
      if (this.props.instructions.payment_method_id === 3) {
        this.props.addInstruction({ 'payer_id': payer_id });
        this.props.removeInstructions({ 'email': null, 'payer_last_name': null, 'payer_first_name': null, 'payer_patronymic': null });
      } else {
        this.props.addInstruction({ 'payer_id': payer_id });
        this.props.removeInstructions({ 'payment_method_id': null, 'email': null, 'payer_last_name': null, 'payer_first_name': null, 'payer_patronymic': null });
      }
      if (this.props.instructions && this.props.instructions.sber_credit === true) {
        this.props.removeInstructions({ 'payment_option_id': null });
      }
      this.props.addInstruction({ 'sber_credit': false });
    } else {
      if (this.props.instructions.payment_method_id === 1) {
        this.props.addInstruction({ 'payer_id': payer_id });
        this.props.removeInstructions({ 'email': null, 'payer_last_name': null, 'payer_first_name': null, 'payer_patronymic': null });
      } else {
        this.props.addInstruction({ 'payer_id': payer_id });
        this.props.removeInstructions({ 'payment_method_id': null, 'email': null, 'payer_last_name': null, 'payer_first_name': null, 'payer_patronymic': null });
      }
      if (this.props.instructions && this.props.instructions.sber_credit === true) {
        this.props.removeInstructions({ 'payment_option_id': null });
      }
      this.props.addInstruction({ 'sber_credit': false });
    }
  }

  render() {
    const {locale} = this.props.lang;
    /**
     * Default payment method is used only for 3D Predict (USA)
     */
    let default_payment_method = null;
    const { user: { preferences } } = this.props;
    if (preferences && Object.keys(preferences).length > 0) {
      if (preferences.default_payment_method !== null && [PM_INVOICE, PM_CARD].includes(preferences.default_payment_method)) {
        default_payment_method = preferences.default_payment_method;
      }
    }
    return (
      <div>
        <h3 className="block" style={{ fontWeight: '900' }}><FormattedMessage id="BLOCKHEAD_PAYMENT" /></h3>
        {deployedRussia() === true && locale == 'ru'
          ? (
              <div className="form-group" id="payer-section">
                <label className="control-label" id="payer-label" id="validation-payer_id" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_PAYER" />
                  <span className="required" aria-required="true">*</span>
                </label>
                <div className="radio-list">
                  <label>
                    <div className="radio">
                      <span><input type="radio" id="payer-value-1" name="payer_id"  defaultValue={1} onChange={(e) => this.selectPayer(e.target.value)}/></span>
                    </div> <FormattedMessage id="PAYER_CLINIC" />
                  </label>
                  <label>
                    <div className="radio">
                      <span><input type="radio" id="payer-value-2" name="payer_id"  defaultValue={2} onChange={(e) => this.selectPayer(e.target.value)}/></span>
                    </div> <FormattedMessage id="PAYER_DOCTOR" />
                  </label>
                  <label>
                    <div className="radio">
                      <span><input type="radio" id="payer-value-3" name="payer_id"  defaultValue={3} onChange={(e) => this.selectPayer(e.target.value)}/></span>
                    </div> <FormattedMessage id="PAYER_PATIENT" />
                  </label>
                </div>
                <div id="form_payer_error"> </div>
              </div>
            ) : null
          }
          <div className="form-group" id="payment-section" data-toggle="tooltip" title={this.fm("tooltip_payment")}>
            <label className="control-label" id="payment-method-label" id="validation-payment_method_id" style={{ fontWeight: '900' }}>
              <FormattedMessage id="HEADER_PAYMENT_METHOD" />
              <span className="required" aria-required="true">*</span>
            </label>
            <div className="radio-list">
              {deployedRussia() && this.props.intl.locale === 'ru' && this.props.instructions &&
                (this.props.instructions.payer_id === PAYERS['DOCTOR'] || this.props.instructions.payer_id === PAYERS['PATIENT'])
                ? null
                : (
                  <label>
                    <div className="radio">
                      <span>
                        <input
                          type="radio"
                          id="payment-method-value-1"
                          name="payment_method_id"
                          defaultValue={PM_INVOICE}
                          onChange={(e) => this.selectPaymentMethod(e.target.value)}
                          defaultChecked={(deployedUSA() === true && default_payment_method === PM_INVOICE) ? true : false}
                        />
                      </span>
                    </div> <FormattedMessage id={deployedRussia() === true ? "PM_BANK" : "PM_INVOICE"} />
                  </label>
                )
              }
              {(locale=="ru" && deployedRussia() === true && this.props.instructions.payer_id !== PAYERS['CLINIC']) || (deployedUSA() === true)
                ? (
                  <label>
                    <div className="radio">
                      <span>
                        <input
                          type="radio"
                          name="payment_method_id"
                          id="payment-method-value-3"
                          defaultValue={PM_CARD}
                          onChange={(e) => this.selectPaymentMethod(e.target.value)}
                          defaultChecked={(deployedUSA() === true && default_payment_method === PM_CARD) ? true : false}
                        />
                      </span>
                    </div> <FormattedMessage id={deployedRussia() === true ? "PM_CARD" : "PM_CARD.usa"} />
                  </label>
                ) : null
              }
            </div>
            <div id="form_payment_method_error"> </div>
          </div>
          {deployedRussia()
            ? (
              <div className="form-group">
                <label className="control-label" id="validation-payment_option_id" style={{ fontWeight: '900' }}><FormattedMessage id="HEADER_PAYMENT_OPTION" />
                  <span className="required" aria-required="true">*</span>
                </label>
                <div className="radio-list">
                  {deployedRussia() && ((this.props.selectedCourseInstallment && this.props.selectedCourseInstallment.includes(INSTALLMENT['100PERCENT']) || !this.props.courseSelected)) ? (
                    <label>
                      <div className="radio">
                        <span>
                          <input
                            type="radio"
                            id="payment-option-value-1"
                            name="payment_option_id"
                            defaultValue={PO_ADVANCED}
                            onChange={(e) => this.selectPaymentOption(e.target.value)}
                            ref={(el) => this.pOptionAdvancedRef = el}
                          />
                        </span>
                      </div>
                      <FormattedMessage id="PO_ADVANCE"/>
                    </label>
                  ) : null }
                  {deployedRussia() && ((this.props.selectedCourseInstallment && this.props.selectedCourseInstallment.includes(INSTALLMENT['2STEPS']) || !this.props.courseSelected)) ? (
                    <label>
                      <div className="radio">
                        <span>
                          <input
                            type="radio"
                            id="payment-option-value-2"
                            name="payment_option_id"
                            defaultValue={PO_2_STEPS}
                            onChange={(e) => this.selectPaymentOption(e.target.value)}
                            ref={(el) => this.pOptionTwoRef = el}
                          />
                        </span>
                      </div> <FormattedMessage id="PO_SPLIT_2" />
                    </label>
                    ) : null }
                  {deployedRussia() && ((this.props.selectedCourseInstallment && this.props.selectedCourseInstallment.includes(INSTALLMENT['3STEPS']) || !this.props.courseSelected))
                    ? (
                        <label>
                          <div className="radio">
                            <span>
                              <input
                                type="radio"
                                id="payment-option-value-3"
                                name="payment_option_id"
                                defaultValue={PO_3_STEPS}
                                onChange={(e) => this.selectPaymentOption(e.target.value)}
                                ref={(el) => this.pOptionThreeRef = el}
                              />
                            </span>
                          </div>
                          <FormattedMessage id="PO_SPLIT_3"/>
                        </label>
                      ) : null
                  }
                  {(
                      deployedRussia() === true && locale == 'ru' && this.props.instructions.payer_id === PAYERS['PATIENT']
                      && this.props.instructions.course_id != CASE_RETAINERS
                    ) ? (
                        <label>
                          <div className="radio">
                            <span>
                              <input
                                type="radio"
                                id="payment-option-credit"
                                name="sber_credit"
                                defaultValue={false}
                                onChange={() => this.setPaymentCreditOption()}
                                ref={(el) => this.pOptionCredit = el}
                              />
                            </span>
                          </div>
                          <SBCreditComponent />
                        </label>
                      ) : null
                  }
                </div>
                <div id="form_payment_option_error"> </div>
              </div>
            ) : (
              <div>
                  {/* For USA deployment allow only 100% prepayment */}
                  <input
                    hidden
                    type="radio"
                    id="payment-option-value-prepayment"
                    name="payment_option_id"
                    defaultValue={PO_ADVANCED}
                    onChange={(e) => this.selectPaymentOption(e.target.value)}
                  />
              </div>
            )
          }
      </div>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientNewPayment));
