import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { searchPatientsData, meditAuth } from "../../actions/medit";
import { threeShapeAuthScanner } from "../../actions/3shape";
import IntegrationCard from "./integration_card";
import scanners from '../../assets/img/scanner-blue.svg';
import meditLogo from '../../assets/img/medit.svg';
import shapeLogo from  '../../assets/img/3shape.svg';
import prexionLogo from '../../assets/img/prexion.png';
import itero from '../../assets/img/itero.png';


const mapDispatchToProps = (dispatch) => {
    return {
        meditAuth : () => dispatch(meditAuth()),
        searchPatients: (search) => dispatch(searchPatientsData(search))
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        patient: state.patient
    }
}


class IntegrationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shape_patient_id: null,
            patients: []
        }
        this.fm = this.fm.bind(this);
        this.connectToShape = this.connectToShape.bind(this);
        this.searchPatient = this.searchPatient.bind(this);
        this.setPatient = this.setPatient.bind(this);
        this.redirectTo3Shape = this.redirectTo3Shape.bind(this);
    }

    connectToShape(patient_id) {
        threeShapeAuthScanner(patient_id)
        .then(res => {
            if (res.status === 200) {
               $('#selectPatient').modal('show')
            }
        })
    }

    searchPatient(v) {
        const value = v.target.value;
        if (value.length >= 2){
            this.props.searchPatients(value)
            .then(res => this.setState({patients: res}))
        }
    }

    setPatient(v) {
        this.setState({shape_patient_id: v.value})
    }

    redirectTo3Shape(patient_id) {
        window.location = `/pages/3shape/${patient_id}/cases`;
    }

    fm(id) {
        return this.props.intl.formatMessage({ id: id });
    }

    render() {
        const patient_id = this.props.patient.patient_id;
        const { patients, shape_patient_id } = this.state;
        const animatedComponents = makeAnimated();
        const options = [];
        patients.map(p => options.push({ 'value': `${p.patient_id}`, 'label': `${p.first_name} ${p.last_name}   (${p.patient_id})`}));

        return (
          <div className="page-content-wrapper">
              <div className="page-content" id="instructions-block">
                  <div className="portlet light bordered">
                      <div className="portlet-title">
                          <div className="caption">
                              <img src={scanners} style={{width: "35px", marginLeft: "-8px"}}/>
                              <span style={{marginLeft: "2px"}} className="caption-subject font-green bold uppercase" id="manual-list-page">
                                    <FormattedMessage id="integrations.my.scanners"/>
                              </span>
                          </div>
                      </div>
                      <div><p><FormattedMessage id="integration.my.scanners.description" values={{ labelTitle: WEBPACK_LABEL_TITLE }} /></p></div>
                      <div data-backdrop="static" data-keyboard="false" className="modal fade" id="selectPatient" tabIndex="-1" role="dialog"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                  <div className="modal-header">
                                      <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                      <h3 className="modal-title" id="exampleModalLabel">
                                          <FormattedMessage id="UPLOADED_3SHAPE"/>
                                      </h3>
                                  </div>
                                  <div className="modal-body">
                                      <br/>
                                      <div data-matomo-mask data-hj-suppress className="form-group">
                                          <label className="col-form-label">
                                              <FormattedMessage id="th.medit.action.сhoose.patient" values={{ labelTitle: WEBPACK_LABEL_TITLE }}/>
                                          </label>
                                          <Select
                                            isSearchable
                                            components={animatedComponents}
                                            onKeyDown={(v) => this.searchPatient(v)}
                                            onChange={(v) => this.setPatient(v)}
                                            options={options}
                                            id="shape-patient"
                                            name="shape_patient"
                                            placeholder={this.fm("patient.search.placeholder")}
                                          />
                                      </div>
                                  </div>
                                  <div className="modal-footer">
                                      <button
                                        disabled={!shape_patient_id}
                                        type="button"
                                        className="btn green"
                                        onClick={() => this.redirectTo3Shape(shape_patient_id)}
                                      >
                                          <FormattedMessage id="BTN_SUBMIT" />
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="portlet-body">
                          <div className="instructions-block">
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <IntegrationCard
                                      title="UPLOADED_3SHAPE"
                                      link_title="LINK_TO_MANUAL"
                                      link="/api/v1/files/3shape.manual.pdf"
                                      img={shapeLogo}
                                      onClick={() => this.connectToShape(patient_id)}
                                      hoverBottom="hover"
                                      hoverTop="hover-top"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <IntegrationCard
                                      title="UPLOADED_MEDIT"
                                      link_title="LINK_TO_MANUAL"
                                      link="/api/v1/files/medit.manual.webm"
                                      img={meditLogo}
                                      patient_id={patient_id}
                                      onClick={() => meditAuth(patient_id)}
                                      hoverBottom="hover"
                                      hoverTop="hover-top"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <IntegrationCard
                                      title="UPLOADED_PREXION"
                                      link_title="LINK_TO_MANUAL"
                                      link="/api/v1/files/prexion.manual.pdf"
                                      img={prexionLogo}
                                      hoverBottom="hover"
                                      hoverTop="hover-top"
                                      imgStyle={{height: '110px', width: '130px'}}
                                    />
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-4 col-sm-12">
                                    <IntegrationCard
                                      title="UPLOADED_ITERO"
                                      link="/api/v1/files/itero.pdf"
                                      link_title="LINK_TO_MANUAL"
                                      itero_link_title="ITERO_SUPPORT"
                                      values={{br: <br />}}
                                      itero_phone="ITERO_PHONE"
                                      itero_id="ITERO_ID"
                                      img={itero}
                                      hoverBottom="hover"
                                      hoverTop="hover-top"
                                    />
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IntegrationList));
