import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";

import { deployedRussia, deployedUSA } from "../../common/utils";
import { getBonuses } from "../../actions/doctor_bonuses";
import BarChartHorizontal from "../charts/bar_chart_horizontal";


const mapStateToProps = (state) => {
    return {
      user: state.user,
      spinner: state.spinner,
      bonuses: state.bonuses,
      lang: state.intl
    };
};
  
  
const mapDispatchToProps = (dispatch) => {
    return {
      getBonuses: (doctor_id) => dispatch(getBonuses(doctor_id))
    };
};


class PatientBonuses extends Component {
    constructor(props) {
        super(props)

        this.fm = this.fm.bind(this);
    }


    fm(id) {
        return this.props.intl.formatMessage({ id: id });
    }

    componentDidMount() {
        this.props.getBonuses(this.props.user.account_id);
    }

    renderFullCoins() {
        const arr = [];
        if (this.props.bonuses.available > 0) {
          for (let i = 0; i < this.props.bonuses.available; i++) {
            arr.push(
              <div
                className="tip"
                style={{ width: "70px", textColor: "black" }}
                key={i}
              >
                <span>{this.fm("bonus.full.coin.title")}</span>
                <img
                  src={deployedUSA() ? "/img/5_tiffani.svg" : "/img/10_tiffani.svg"}
                />
              </div>
            );
          }
        }
        return arr;
    }
    
    renderSemiCoins() {
        if (this.props.bonuses.used > 0) {
          const arr = [];
          for (let i = 0; i < this.props.bonuses.used; i++) {
            arr.push(
              <div className="tip" style={{ width: "70px" }} key={i}>
                <span>{this.fm("bonus.case.chosen")}</span>
                <img
                  src={deployedUSA() ? "/img/5_grey.svg" : "/img/10_grey.svg"}
                  alt=""
                />
              </div>
            );
          }
          return arr;
        }
        return null;
    }
    
    renderColoredCoins() {
        if (this.props.bonuses.current > 0) {
          const { current, target } = this.props.bonuses;
          if (deployedRussia() === true) {
            return (
              <div className="tip" style={{ width: "70px" }}>
                <img src="/img/10_tiffani_stroke.svg" alt="" />
                <span>
                  {this.fm("bonus.cases.left")} {current}{" "}
                  {this.fm("bonus.cases.left.1")} {target}{" "}
                  {this.fm("bonus.cases.left.2")}
                </span>
              </div>
            );
          }
          return (
            <div className="tip" style={{ width: "70px" }}>
              <span>
                {current} {this.fm("bonus.cases.left")}
              </span>
              <img src="/img/5_tiffani_stroke.svg" alt="" />
            </div>
          );
        }
    }

    currentMessage(current) {
        const { locale } = this.props.intl;
        let translationId = "bonus.patient.activation";
        if (locale === "ru") {
          if (current === 1) {
            translationId = "bonus.patient.activation.one";
          } else if (current >= 2 && current < 5) {
            translationId = "bonus.patient.activation.more";
          } else {
            translationId = "bonus.patient.activation.a.lot";
          }
        } else {
          if (current === 1) {
            translationId = "bonus.patient.activation.one";
          } else {
            translationId = "bonus.patient.activation.more";
          }
        }
        return this.fm(translationId);
    }


    render() {
      const { bonuses } = this.props;

      let current = null;
      if (this.props.bonuses && Object.keys(this.props.bonuses).length > 0) {
        current = this.props.bonuses.target - this.props.bonuses.current;
      }

      if (this.props.bonuses && Object.keys(this.props.bonuses).length > 0) {
        return (
            <div>
            <div className="caption">
              {this.props.bonuses.start !== null && this.props.bonuses.end !== null
                ? <img src="/img/calendar.svg" style={{ maxWidth: "20px", maxHeight: "20px" }} alt=""/>
                : null
              }
              {this.props.bonuses.start !== null && this.props.bonuses.end !== null
                ? (
                  <span
                    style={{
                      marginLeft: "1rem",
                      position: "relative",
                      top: ".2rem",
                    }}
                  >
                    {deployedRussia() && this.props.intl.locale === "ru"
                      ? `${moment(this.props.bonuses.start, "YYYY-MM-DD", "en")
                          .locale("ru")
                          .format("DD MMMM YYYY")} - ${moment(
                          this.props.bonuses.end,
                          "YYYY-MM-DD",
                          "en"
                        )
                          .locale("ru")
                          .format("DD MMMM YYYY")}`
                      : `${moment(this.props.bonuses.start, "YYYY-MM-DD", "en")
                          .locale("en")
                          .format("MMMM DD, YYYY")} - ${moment(
                          this.props.bonuses.end,
                          "YYYY-MM-DD",
                          "en"
                        )
                          .locale("en")
                          .format("MMMM DD, YYYY")}`
                    }
                  </span>
                ) : null
            }
            </div>
            {this.props?.bonuses?.current > 0 ? (
              <div
                className="portlet-title"
                style={{
                  position: "relative",
                  width: "80%",
                  paddingBottom: "100px",
                  marginLeft: "-25px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                  }}
                >
                  <BarChartHorizontal data={[{ ...bonuses }]} />
                </div>
              </div>
            ) : null}

            <div
              className="row"
              style={{ marginTop: "-15px", marginLeft: "10px" }}
            >
              <div className="col-md-3">
                <div className="bonus">
                  <div className="tip">
                    <h1 className="caption-subject font-green bold">
                      {this.props.bonuses.months !== null
                        ? this.props.bonuses.months
                        : (
                            this.props.bonuses.days !== null && this.props.bonuses.days > 0
                            ? this.props.bonuses.days
                            : null
                        )
                      }
                    </h1>
                    <span>
                      {this.props.bonuses.months !== null
                        ? this.fm("bonus.period.end.months")
                        : this.fm("bonus.period.end.days")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="bonus">
                  <div className="tip">
                    <h1 className="caption-subject font-green bold">
                      {current !== null && current > 0 ? current : null}
                    </h1>
                    <span>{this.currentMessage(current)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginLeft: "10px" }}>
              <div className="col-md-8">
                <div className="bonus">
                  {this.renderSemiCoins()}
                  {this.renderFullCoins()}
                  {this.renderColoredCoins()}
                </div>
              </div>
            </div>
            <div className="row" style={{ marginLeft: "10px" }}>
              <div className="col-md-6">
                <div className="bonus">
                  <div style={{ width: "3rem" }}>
                    <img src="/img/client.svg" />
                  </div>
                  <div
                    className="caption-subject bold uppercase"
                    style={{
                      position: "relative",
                      bottom: "-5px",
                    }}
                  >
                    <FormattedMessage id="bonus.patients.total" />{" "}
                    {this.props.bonuses.total}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        )
    } else {
        return null
    }
}
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientBonuses));