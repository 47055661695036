import React from "react";
import { Redirect } from "react-router-dom";

import { useAppSelector } from "../store";
import Loader from "./common/loadingInProgress";

export default function PublicOnly({ children }: { children: React.ReactElement }) {
  const user = useAppSelector((state) => state.user);
  const userIsLoading = useAppSelector((state) => state.userIsLoading);

  if (userIsLoading) {
    return <Loader />;
  }

  if (Object.keys(user).length > 0) {
    return <Redirect to="/pages/patients" />;
  }

  return children;
}
