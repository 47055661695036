import {API_REMINDER} from '../config';
import { addUserActionNotification } from './user_notification';


export function passwordReset(bool) {
  return {
    type: 'PASSWORD_IS_RESET',
    passwordIsReset: bool
  }
}


export function sendPassword(login, locale) {
	return (dispatch) => {
		return fetch(API_REMINDER, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				login_or_email: login,
				locale: locale
	    	})
		})
	    .then((response) => {
      		if (!response.ok) {
				throw new Error(`Error occurred trying to fetch user: ${response.statusText}`);
			}
			const NOTIFICATION_MSG_SUCCESS = {
				message: 'notify.send_password.SENDING_RESTORE_SUCCESS',
				level: 'info',
				position: 'tc',
				autoDismiss: '5',
			}
			dispatch(addUserActionNotification(NOTIFICATION_MSG_SUCCESS));
      		return { status: true };
    	})
		.catch((e) => {
			const NOTIFICATION_MSG_ERROR = {
				message: 'notify.send_password.SENDING_ERROR',
				level: 'error',
				position: 'tc',
				autoDismiss: '5',
			}
			dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
			return { status: false };
		});
	}
}
