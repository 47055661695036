import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from "redux-form";
import { FormattedMessage, injectIntl } from 'react-intl';

import { addInstructions } from '../../actions/patient_new_instructions';


const mapStateToProps = (state) => {
  return {
    patient: state.patient,
    patientSaving: state.patientSaving,
    instructions: state.instructions,
    media: state.media,
    media_s3: state.media_s3,
    formValues: getFormValues('correction')(state),
    links: getFormValues('links')(state),
    lang: state.intl
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    addInstruction: (data) => dispatch(addInstructions(data)),
  }
}


class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showRequiredFields: false
    }

    this.addFirstName = this.addFirstName.bind(this);
    this.addLastName = this.addLastName.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  addFirstName(data) {
    this.props.addInstruction({'first_name':data});
  }

  addLastName(data) {
    this.props.addInstruction({'last_name':data});
  }

  saveChanges() {
    const { instructions, saveAsADraft } = this.props;
    if (saveAsADraft) {
      if (instructions.hasOwnProperty('last_name') && instructions['last_name'] !== "" 
      && instructions.hasOwnProperty('first_name') && instructions['first_name'] !== "") {
         this.props.saveChanges();
      } else {
       this.setState({ showRequiredFields: true });
      }
    } else {
      this.closeModal()
      this.props.saveChanges();
    }
  }

  closeModal() {
    this.props.onCancel();
    this.setState({ showRequiredFields: false });
  }


  render() {
    const fm = (id) => this.props.intl.formatMessage({id: id});
    const { showRequiredFields } = this.state;
    const {saveAsADraft, instructions:{last_name, first_name}} = this.props;
    
    if (this.props.visible) {
      return (
        <div className='col-md-6 col-md-offset-4 col-sm-4 col-sm-offset-4' >
          {!showRequiredFields 
            ? (
              <div className='custom-modal'>
                <div className='modal-header'>
                <button
                    onClick={() => this.closeModal()}
                    type="button"
                    className="close">
                  <span aria-hidden="true">&times;</span>
                </button>
                  <h5>{ saveAsADraft ? <FormattedMessage id="BTN_SAVE_DRAFT_CONTINUE_LATER" /> : <FormattedMessage id="BTN_SAVE_CONTINUE_LATER" /> } </h5>
                </div>
                <div className='modal-footer'>
                  <button type="button" className="btn btn-secondary" onClick={() => this.props.onConfirm()}><FormattedMessage id="bool.false" /></button>
                  <button type="button" className="btn btn-primary" onClick={() => this.saveChanges()}><FormattedMessage id="bool.true" /></button>
                </div>
              </div>
            ) : null 
          }
          {(showRequiredFields === true)
            ? (
              <div className='custom-modal'>
                <div className='modal-header'>
                  <button
                      onClick={() => this.closeModal()}
                      type="button"
                      className="close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span style={{color: "red"}}><FormattedMessage id="REQUIRED_FIELDS_FOR_A_DRAFT" /></span>
                </div>
                <div className='modal-body'>
                  <div className="row" id="patient-block-body">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label style={{fontWeight: '900', color: last_name ? "" : "red"}} className="control-label" id="validation-last_name"><FormattedMessage id="PAT_INFO_LAST_NAME"  />
                          <span className="required" aria-required="true">*</span>
                        </label>
                        <input data-matomo-mask type="text" className="form-control" defaultValue={last_name? last_name : ""} id="patient-lastname-value" name="last_name"  placeholder={fm("PAT_INFO_LAST_NAME_PLACEHOLDER")} onChange={(e) => this.addLastName(e.target.value)}/>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label style={{fontWeight: '900', color: first_name ? "" : "red"}} className="control-label" id="validation-first_name"><FormattedMessage id="PAT_INFO_FIRST_NAME" />
                          <span className="required" aria-required="true">*</span>
                        </label>
                        <input data-matomo-mask type="text" className="form-control" defaultValue={first_name ? first_name : ""} id="patient-firstname-value" name="first_name"  placeholder={fm("PAT_INFO_FIRST_NAME_PLACEHOLDER")} onChange={(e) => this.addFirstName(e.target.value)}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                    <button type="button" className="btn btn-secondary" onClick={() => this.props.onConfirm()}><FormattedMessage id="bool.false" /></button>
                    <button type="button" className="btn btn-primary" disabled={!(last_name && first_name)} onClick={() => this.saveChanges()}><FormattedMessage id="BTN_SAVE_DRAFT" /></button>
                </div>
              </div>
            ) : null
          }
        </div>
      )
    } else {
      return null;
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Modal));

