import React, { Component } from "react";

import type { TBanner } from "../../reducers/dashboard";
import { Banner, BannerImage } from "./index";

type RenderBannersProps = {
  banners: TBanner[];
  mobile?: boolean;
};

class RenderBanners extends Component<RenderBannersProps> {
  componentDidMount() {
    if (this.props.mobile == true) {
      $("#carousel").carousel();
    }
  }

  render() {
    const path = window.location.pathname;

    if (this.props.banners.length !== 0 && this.props.mobile === true) {
      return (
        <div
          id="carousel"
          className="carousel slide"
          data-ride="carousel"
          data-interval={4000}
          data-testid="carousel"
        >
          <div className="carousel-inner" role="listbox">
            {this.props.banners.map((b, i) => {
              return (
                <div key={i} className={i === 0 ? `item active` : "item"}>
                  {b.type === "empty" ? (
                    <BannerImage
                      image={b.icon_url}
                      style={{ padding: "10px" }}
                      data-track-content
                      data-content-name="banners"
                      data-content-piece={
                        b.dataMatomoContentPieceName ? b.dataMatomoContentPieceName : null
                      }
                    />
                  ) : (
                    <Banner
                      path={b.link !== "" ? b.link : b.banner_url}
                      image={b.icon_url}
                      style={{ padding: "10px" }}
                      data-track-content
                      data-content-name="banners"
                      data-content-piece={
                        b.dataMatomoContentPieceName ? b.dataMatomoContentPieceName : null
                      }
                    />
                  )}
                </div>
              );
            })}
            <a
              className="left carousel-control"
              href="#carousel"
              data-slide="prev"
              role="button"
              style={{ background: "transparent" }}
            >
              <span
                className="glyphicon glyphicon-chevron-left"
                aria-hidden="true"
                style={{ marginLeft: "-26px", fontSize: "28px" }}
              ></span>
            </a>
            <a
              className="right carousel-control"
              href="#carousel"
              data-slide="next"
              role="button"
              style={{ background: "transparent" }}
            >
              <span
                className="glyphicon glyphicon-chevron-right"
                aria-hidden="true"
                style={{ marginRight: "-26px", fontSize: "28px" }}
              ></span>
            </a>
          </div>
        </div>
      );
    }
    return (
      <div>
        {this.props.banners.length !== 0 &&
          this.props.banners.map((b, i) => {
            if (path !== "/pages/home") {
              return (
                <div
                  data-testid="left_sidebar_banners"
                  data-track-content
                  data-content-name="banners"
                  data-content-piece={
                    b.dataMatomoContentPieceName ? b.dataMatomoContentPieceName : null
                  }
                  key={i}
                  style={{
                    background: "#e9edf4",
                    marginLeft: -16,
                    marginRight: -16,
                    paddingTop: "16px",
                  }}
                >
                  {b.type === "empty" ? (
                    <BannerImage
                      image={b.icon_url}
                      style={{ padding: "10px" }}
                      data-track-content
                      data-content-name="banners"
                      data-content-piece={
                        b.dataMatomoContentPieceName ? b.dataMatomoContentPieceName : null
                      }
                    />
                  ) : (
                    <Banner
                      path={b.link !== "" ? b.link : b.banner_url}
                      image={b.icon_url}
                      style={{ padding: "10px" }}
                      data-track-content
                      data-content-name="banners"
                      data-content-piece={
                        b.dataMatomoContentPieceName ? b.dataMatomoContentPieceName : null
                      }
                    />
                  )}
                </div>
              );
            }
          })}
      </div>
    );
  }
}

export default RenderBanners;
