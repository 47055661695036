import React, {Component} from 'react';
import { connect } from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import { tryToReworkPlan } from '../../actions/rework_plan';
import Loader from '../common/loadingInProgress';
import remoteLog from '../../common/logging';
import { getPatientId } from '../../actions/get_patient_id';
import { sendComment } from '../../actions/comments';
import { COMMENT_TYPE_REWORK } from '../../common/constants';


const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getPatient: (patient_id) => dispatch(getPatientId(patient_id)),
    planRework: (patient_id,case_id) => dispatch(tryToReworkPlan(patient_id,case_id)),
    sendComment: (patient_id, case_id, comment) => dispatch(sendComment(patient_id, comment, COMMENT_TYPE_REWORK, case_id))
  }
}

class PlanRework extends Component {
  constructor(props) {
    super(props);
    this.state = {
      case_id: null,
      patient_id: null,
      canSubmit: false,
      showLoader: false,
      loading: true,
      comment: null
    }
    this.planRework = this.planRework.bind(this);
    this.setComment = this.setComment.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, '3d_plan_rework_page');
  }

  UNSAFE_componentWillMount() {
    let case_id = this.props.match.params.case_id;
    let patient_id = this.props.match.params.patient_id;
    this.setState({case_id: case_id, patient_id: patient_id})
    this.props.getPatient(patient_id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { patient_id } = this.state;
    if (
      nextProps && nextProps.patient && Object.keys(nextProps).length
      && String(nextProps.patient.patient_id) === String(patient_id)
    ) {
      if (nextProps.patient.status !== 'S_3D_PLAN_APPROVAL') {
        this.props.history.push('/pages/patients');
      } else {
        this.setState({ loading: false })
      }
    }
  }

  setComment(e) {
    const comment = e.target.value;
    const canSubmit = comment !== undefined && comment !== null && comment !== '';
    this.setState({ comment: e.target.value, canSubmit });
  }

  planRework() {
    const { comment } = this.state;
    this.setState({ canSubmit: false });
    this.setState({ showLoader: true });

    /**
     * If planRework method fails so at least we have commentary from Doctor.
     * sendComment method must not depend on response status of planRework method. 
     */
    this.props.planRework(this.state.patient_id,this.state.case_id);
    this.props.sendComment(this.state.patient_id, this.state.case_id, comment);
  }

  render() {
    const { loading } = this.state;

    if (loading === true) {
      return <div><Loader /></div>;
    }

    return (
      <div className="page-content-wrapper">
        <div className="page-content" style={{minHeight: 584}}>
          <div className="row">
            <div className="col-md-8">
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption">
                    <i className="icon-book-open font-green" />
                    <span className="caption-subject font-green bold uppercase">
                      <FormattedMessage id="plan.rework.header" />
                    </span>
                  </div>
                </div>
                <div className="portlet-body">
                  <div>
                    <h4><FormattedMessage id="plan.rework.body" /> { this.state.case_id }</h4><br/><br/>
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      <strong><FormattedMessage id="pat.comment.rework.header" /></strong>
                    </label>
                    <textarea
                      style={{ minHeight: '350px', resize: "vertical" }}
                      className="form-control"
                      rows={5}
                      name="comment"
                      defaultValue=""
                      onChange={e => this.setComment(e)}
                    />
                  </div>
                  <div>
                    <button
                      onClick={()=>{this.planRework()}}
                      className="btn green"
                      id="test-btn"
                      ref="toggle-button"
                      disabled={!this.state.canSubmit}
                    >
                      <FormattedMessage id="plan.rework.btn" />
                    </button>
                    { this.state.showLoader && <Loader/> }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(injectIntl(PlanRework));
