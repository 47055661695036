import React from 'react';

import PatientMenu from '../patient_menu';
import PatientsPayList from './patients_pay_list';
import PageFooter from '../page_footer';
import PageHeader from '../page_header';
import Notification from '../notification';
import { notificationCustomStyle } from "../notifications/notification_styles";
import Tour from './tour';


const PatientsTaskPageBody = (props) => (
  <div>
    <PageHeader {...props}/>
    <Tour />
    <div className="container">
      <div className="page-container">
        <PatientMenu {...props}/>
        <Notification {...props} style={notificationCustomStyle}/>
        <PatientsPayList {...props}/>
      </div>
      <PageFooter {...props}/>
    </div>
  </div>
);


export default PatientsTaskPageBody;
