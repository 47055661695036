import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { deployedUSA } from "../common/utils";
import { Badge } from "./ui/badge";

export function AlertMenuItem({ payBell }: { payBell: number }) {
  const intl = useIntl();

  return (
    <li className="nav-item start">
      <NavLink
        data-toggle="tooltip"
        title={deployedUSA() ? intl.formatMessage({ id: "tooltip_payment" }) : undefined}
        to="/pages/payments"
        className="nav-link"
        id="sidebar-payment"
      >
        <i aria-hidden className="icon-wallet" />
        <span className="title">
          <FormattedMessage id="left.menu.my_payments" />
        </span>
        {payBell > 0 ? <Badge id="tasks-count">{payBell}</Badge> : null}
      </NavLink>
    </li>
  );
}
