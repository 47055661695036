import { useEffect } from "react";

export function useDocumentTitle(title: string) {
  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);
}

export function setDocumentTitle(title: string) {
  document.title = WEBPACK_LABEL_TITLE + " | " + title;
}
