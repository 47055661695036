import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';
import { connect } from 'react-redux';

import {API_MANUALS_GET} from '../../config';
import ManualsCard from './manuals_card'
import Loader from '../../components/common/loadingInProgress';


const style = {
    'display': 'flex',
    'flexDirection': 'row',
    'flexWrap': 'wrap',
    'maxWidth': '900px',
    'width': '100%',
    'height': '100%',
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}


class ManualsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manuals: [],
            loading: true,
        };
    }

    componentDidMount() {
        const { user } = this.props;

        fetch(API_MANUALS_GET, { credentials: 'include' })
        .then(response => {
            if(!response.ok) {
                throw new Error('Error occurred trying to fetch manuals');
            }
            return response.text()
        })
        .then(response => JSON.parse(response))
        .then(json => this.setState({ manuals: json, loading: false }))
        .catch(() => this.setState({ loading: false }));
    }

    renderCards(manuals){
        const { loading } = this.state;
        if (loading === false) {
            if (manuals && Array.isArray(manuals) && manuals.length > 0) {
                return manuals.map(item => <ManualsCard  data={item} key={item.manual_id} />)
            } else {
                return (
                    <div>
                        <span><FormattedMessage id="manuals.empty.query" /></span>
                    </div>
                )
            }
        }
        return <div><Loader /></div>;
    }

    render() {
        return (
            <div className="page-content-wrapper">
                <div className="page-content" id="instructions-block">
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className="icon-users font-green" />
                                <span className="caption-subject font-green bold uppercase" id="manual-list-page">
                                    <FormattedMessage id="manuals.title" />
                                </span>
                            </div>
                        </div>
                        <div className="portlet-body">
                            <div className="instructions-block" style={style}>
                                {this.renderCards(this.state.manuals)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default connect(mapStateToProps)(ManualsList);
