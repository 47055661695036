import React, { lazy, Suspense } from "react";

import Notification from "../notification";
import PageFooter from "../page_footer";
import PageHeader from "../page_header";
import Tour from "../patient/tour";

const LazySignOfferBody = lazy(() => import("./sign_offer_body"));

export default function SignOffer() {
  return (
    <div>
      <PageHeader />
      <Tour />
      <div className="container">
        <div className="page-container">
          <Suspense fallback={null}>
            <LazySignOfferBody />
          </Suspense>
          <Notification />
        </div>
      </div>
      <PageFooter center />
    </div>
  );
}
