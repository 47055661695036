import React, { Component, createRef } from "react";
import { injectIntl, type IntlShape } from "react-intl";
import NotificationSystem from "react-notification-system";
import { connect, type ConnectedProps } from "react-redux";

import { eraseUserActionNotification } from "../actions/user_notification";
import remoteLog from "../common/logging";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { RootState } from "../store";

const mapStateToProps = (state: RootState) => ({
  userActionNotification: state.userActionNotification,
});

const mapDispatchToProps = {
  eraseUserActionNotification,
};

const style = {
  Dismiss: {
    DefaultStyle: {
      display: "none",
    },
  },
  NotificationItem: {
    success: {
      borderTop: "2px solid " + "#96dfdb",
      backgroundColor: "#e9ecf3",
      color: "#007a7f",
      WebkitBoxShadow: "0 0 1px  rgba(150,223,219,0.9)",
      MozBoxShadow: "0 0 1px  rgba(150,223,219,0.9)",
      boxShadow: "0 0 1px rgba(150,223,219,0.9)",
    },
    info: {
      borderTop: "2px solid " + "#FFFFFF",
      backgroundColor: "#FFFFFF",
      color: "#007a7f",
      WebkitBoxShadow: "0 0 1px  rgba(150,223,219,0.9)",
      MozBoxShadow: "0 0 1px  rgba(150,223,219,0.9)",
      boxShadow: "0 0 1px rgba(150,223,219,0.9)",
    },
  },
} satisfies NotificationSystem.Style;

type NotificationProps = PropsFromRedux & { intl: IntlShape } & {
  style?: NotificationSystem.Style;
};

// WARN: please be careful when refactoring this component.
// I tried to replace `UNSAFE_componentWillReceiveProps` with `componentDidUpdate` or rewrite
// it into a functional component with one `useEffect`, and both resulted in duplicated notifications.
class Notification extends Component<NotificationProps> {
  notificationSystemRef = createRef<NotificationSystem>();

  componentDidCatch(e: Error) {
    remoteLog(e, "notification");
  }

  componentDidMount() {
    if (this.props.userActionNotification) {
      this.addNotification(this.props.userActionNotification);
      this.props.eraseUserActionNotification();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: NotificationProps) {
    if (nextProps.userActionNotification) {
      this.addNotification(nextProps.userActionNotification);
      this.props.eraseUserActionNotification();
    }
  }

  addNotification(notification: TUserActionNotification) {
    const translatedNotification = {
      ...notification,
      message: this.props.intl.formatMessage({ id: notification.message }),
    };
    this.notificationSystemRef.current?.addNotification(translatedNotification);
  }

  render() {
    return (
      <NotificationSystem ref={this.notificationSystemRef} style={this.props.style ?? style} />
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(Notification));
