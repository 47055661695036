const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export default {
  strongPassword(values, value) {
    if (typeof value !== 'undefined' && !value.match(new RegExp('^[A-Za-z0-9]{6,}$'))) {
      return false;
    }
    return true;
  },
  isPhoneNumber(values, value) {
    var phoneNumberPattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
    if (typeof value !== 'undefined' && !phoneNumberPattern.test(value)) {
      return false;
    }
    return true;
  },
  isLogisticPhoneNumber(values, value) {
    if (typeof value !== 'undefined' && !value.match(new RegExp('^([+]{1}[0-9]{0,14}|[0-9]{0,15})$'))) {
      return false;
    }
    return true;
  },
  validateZIPCode(values, value) {
    const reg = /^[a-z0-9\-\s\[\]]{1,10}$/i;
    if (!value || (typeof value === 'string' && !reg.test(value))) {
      return false;
    }
    return true;
  },
  validateEmail(value) {
    if (typeof value !== 'undefined' && !value.match(new RegExp(EMAIL_REGEX))) {
      return false;
    }
    return true;
  }
}