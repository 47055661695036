import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';

import PageHeader from './page_header';
import PageFooter from './page_footer';
import { deployedRussia } from '../../common/utils';
import Tour from '../patient/tour';


class PatientPaymentError extends Component {

    render() {
        if (deployedRussia()) {
            return (
                <div>
                    <PageHeader {...this.props}/>
                    <Tour />
                    <div className="container">
                        <div className="page-container">
                            <div className="page-content-wrapper">
                                <div className="page-content" style={{minHeight: 584}}>
                                <div className="row">
                                    <div className="col-md-8">
                                    <div className="portlet light bordered">
                                        <div className="portlet-title">
                                            <div className="caption">
                                                <i className="icon-book-open font-green" />
                                                <span className="caption-subject font-green bold uppercase"><FormattedMessage id="pat.payments.online.payment" /></span>
                                            </div>
                                            </div>
                                            <div className="portlet-body">
                                                <div className="form-body">
                                                    <div className="row fileupload-buttonbar">
                                                        <div className="col-lg-7">
                                                            <FormattedMessage id="pat.payments.course.paid.error" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PageFooter/>
                </div>
            )
        }
        return null;
    }
}


export default (PatientPaymentError);
