import React, { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type RouteComponentProps, withRouter } from "react-router-dom";

import Loader from "../components/common/loadingInProgress";
import type { RootState } from "../store";

export const PREV_PATHNAME_STORAGE_KEY = "prev_pathname";

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  errorMessage: state.errorMessage,
});

type WelcomeProps = PropsFromRedux & RouteComponentProps;

class Welcome extends Component<WelcomeProps> {
  generateUrl(props: WelcomeProps) {
    let url = props.location.pathname;
    if (props.location.search) {
      url = url + props.location.search;
    }
    return url;
  }

  componentDidUpdate(prevProps: WelcomeProps) {
    if (this.props.user.account_id) {
      if (prevProps.location && prevProps.location.pathname) {
        this.props.history.replace(this.generateUrl(prevProps));
      }
    } else if (this.props.errorMessage) {
      if (prevProps.location && prevProps.location.pathname) {
        window.localStorage.setItem(PREV_PATHNAME_STORAGE_KEY, this.generateUrl(prevProps));
      }
      this.props.history.replace("/login");
    }
  }

  render() {
    return <Loader />;
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withRouter(Welcome));
