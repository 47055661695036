import type NotificationSystem  from "react-notification-system";

export const notificationCustomStyle = {
    Containers: {
        tc: {
            top: '50%'
        }
    },
    Dismiss: {
        DefaultStyle: {
            display: 'none',
        }
    },
    NotificationItem: {
        success: {
            visibility: 'hidden',
            width: '367px',
            background: 'transparent !important'
        },
        info: {
            opacity: '0'
        },
    },
    MessageWrapper: {
        DefaultStyle: {
            display: 'none'
        }
    },
} satisfies NotificationSystem.Style;
