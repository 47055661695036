import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import { connect } from "react-redux";

import remoteLog from '../../common/logging';
import { convertToDentalNotation } from '../../common/utils';


const mapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch) => ({
  change: (value) => dispatch(change(value))
});


class PlanCorrectionTeethCheck extends Component {
  constructor(props) {
    super(props);
    let quads = [1, 2, 4, 3];
    this.teeth = quads.map((quad) => {
      let arr = [];
      for (let i = 1; i <= 8; i++) {
        arr.push(quad*10 + i);
      }
      if (quad === 1 || quad === 4) arr.reverse();
      return arr;
    });
  }


  componentDidCatch(e) {
    remoteLog(e, '3d_plan_correction_teeth_check');
  }

  render() {
    let allTeeth = this.teeth.map(this.renderQuadrant.bind(this));
    let upperTeeth = allTeeth.slice(0, 2);
    let lowerTeeth = allTeeth.slice(2, 4);

    return (
      <div className="PatientTeethItems form-group"  name={this.props.name}>

        <div className="row">
          <div className="col-xs-12">
            <div className="teeth-controls-upper">
              {upperTeeth}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="teeth-controls-lower">
              {lowerTeeth}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderQuadrant(quad, i) {

    let teeth = quad.map(this.renderTooth.bind(this));

    return (
      <div key={i} className={`checkbox-list teeth-controls `}>
        {teeth}
      </div>
    )
  }

  renderTooth(i) {

    let {user, name, defaultValue, dentalNotation} = this.props;
    if (!dentalNotation && user.preferences) dentalNotation = user.preferences.dental_notation;

    if (!defaultValue) defaultValue = [];
    if (!Array.isArray(defaultValue)) {
      console.error(`Assertion error: ${this.constructor.name}.value should be only array!`);
    };
    const {input } = this.props;
    return (
      <label key={i}>
        {convertToDentalNotation(i, dentalNotation)}
        <br/>
        <div className="checkbox-teeth">
          <Field
            {...input}
            component="input"
            type="checkbox"
            name={`${name}_${i}`}
            id={`${name}teeth_check${i}`}
          />
        </div>
      </label>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)( PlanCorrectionTeethCheck);
