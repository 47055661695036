import React from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import bellIcon from "../assets/img/bell.svg";
import { deployedUSA } from "../common/utils";
import { useAppSelector } from "../store";

export function AlertBell({ style }: { style?: React.CSSProperties }) {
  const intl = useIntl();
  const bell = useAppSelector((state) => state.doctorTasks.patients.length);

  return (
    <li
      className="dropdown dropdown-extended dropdown-notification tip"
      id="header_notification_bar"
    >
      <NavLink
        data-toggle="tooltip"
        title={deployedUSA() ? intl.formatMessage({ id: "tooltip_notifications" }) : undefined}
        style={style}
        to="/pages/tasks"
        className="dropdown-toggle"
        data-hover="dropdown"
        data-close-others="true"
        id="tasks-bar"
      >
        <img src={bellIcon} alt="" />
        {bell > 0 ? (
          <span className="badge badge-danger" id="tasks-count">
            {bell}
          </span>
        ) : null}
      </NavLink>
    </li>
  );
}
