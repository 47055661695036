import React from 'react';


const Contacts = () => (
  <div>
    <div className="page-header navbar navbar-fixed-top">
        <div className="page-header-inner container" id="top-navbar">
          <div className="page-logo">
              <img src="/img/3d_smile_logo_rgb.svg" alt="logo" className="logo-default" style={{ width: '120px', marginTop: '25px' }} />
          </div>
      </div>
    </div>
    <div className="container">
      <div className="page-container">
		      <div className="page-content">
		        <div className="portlet light bordered">
		          <div className="portlet-title">
		            <div className="caption">
		              <i className="icon-user-follow font-green" />
		              <span className="caption-subject font-green bold uppercase">Контактные данные организации</span>
		            </div>
		          </div>
		          <div className="portlet-body form">
		          <h3>Адрес и телефон:</h3>
	            <p>
					121205, Москва<br/>
								Территория Инновационного Центра «Сколково»<br/>
								Большой бульвар, 42, корп. 1<br/>
								Лаборатория "3D Smile"<br/>
								<br/>
								+7 (495) 211-44-54<br/>
								<br/>
								Электронная почта:<br/>
								info@3d-smile.ru<br/>
	            </p>
	            <h3>Реквизиты:</h3>
	            <p>
	            	Общество с ограниченной ответственностью «Доммар»<br/>
								ОГРН 1147746363368<br/>
								ИНН/КПП 7709951104/773101001<br/>
								Юридический адрес: 121205, Москва, Территория Инновационного Центра «Сколково», Технопарк, Большой бульвар, 42, корп. 1<br/>
	            </p>
	            </div>
	          </div>
	        </div>
      </div>
    </div>

  </div>
);


export default Contacts;
