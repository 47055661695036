import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import FormatDate from "../../common/FormatDate";
import { PLAN_LANG_OPTION_MAP, DN_MAP_PALMER } from "../../../common/constants";

const PLAN_LINK_V2 = (patient_id, token, lang) =>
  `//${process.env.PLAN3D_API}/show-${patient_id}-secure-${token}-lang-${lang}-version=2`;

const ProgressBar = ({
  patient_status,
  deliveryEstimate,
  user,
  token,
  patient_id,
  deliveries,
  notes,
  corrections,
  approvalPlanId,
}) => {
  const statuses = [
    { value: 0, label: "S_UNFILLED_CASE" },
    { value: 1, label: "S_INSTRUCTIONS_PROCESSING" },
    { value: 2, label: "S_RETAKE_IMPRESSIONS" },
    { value: 3, label: "S_WAITING_FOR_A_PAYMENT" },
    { value: 4, label: "S_3D_SCANNING" },
    { value: 5, label: "S_3D_PLAN_MODELING" },
    { value: 6, label: "S_3D_PLAN_APPROVAL" },
    { value: 7, label: "S_3D_PLAN_MODELS_BUILDING" },
    { value: 8, label: "S_PRODUCTION_PRINTING" },
    { value: 9, label: "S_PRODUCTION_FABRICATING" },
    { value: 10, label: "S_FINAL_STAGE_READY" },
  ];

  const langLocale =
    PLAN_LANG_OPTION_MAP[user.preferences.dental_notation] || PLAN_LANG_OPTION_MAP[DN_MAP_PALMER];

  const href_open_plan = PLAN_LINK_V2(patient_id, token, langLocale);

  const tracking_number = deliveries && deliveries.length > 0 ? deliveries[0].track_number : null;

  const showProgress = () => {
    return statuses.some((s) => s.label === patient_status);
  };

  const correctionIndex = corrections.length - 1;

  const [currentStatus, setCurrentStatus] = useState(patient_status);

  const steps = [
    {
      label: currentStatus === "S_UNFILLED_CASE" ? "draft" : "submitted",
      statuses: statuses.filter((status) =>
        approvalPlanId === null
          ? [0, 1, 2, 3].includes(status.value)
          : [0, 1, 2].includes(status.value),
      ),
    },
    {
      label: "planning",
      statuses: statuses.filter((status) => [4, 5].includes(status.value)),
    },
    {
      label: "approval",
      statuses: statuses.filter((status) => [6].includes(status.value)),
    },
    {
      label: "production",
      statuses: statuses.filter((status) => [7, 8, 9].includes(status.value)),
    },
    {
      label: "shipped",
      statuses: statuses.filter((status) =>
        approvalPlanId !== null ? [3, 10].includes(status.value) : [10].includes(status.value),
      ),
    },
  ];

  const currentStepIndex = steps.findIndex((step) =>
    step.statuses.some((status) => status.label === currentStatus),
  );

  const isStepCompleted = (stepIndex) => stepIndex < currentStepIndex;

  const seeMoreMessage = (step) => {
    if (step === "submitted") {
      return <FormattedMessage id="submitted.desc" />;
    } else if (step === "draft") {
      return <FormattedMessage id="draft.desc" />;
    } else if (step === "planning") {
      return <FormattedMessage id="planning.desc" />;
    } else if (step === "approval") {
      return (
        <span>
          <FormattedMessage id="approval.desc" />
          <a className="tw-text-white tw-underline" href={href_open_plan}>
            <FormattedMessage id="click.here" />
          </a>{" "}
          <FormattedMessage id="approval.desc.2" />
        </span>
      );
    } else if (step === "production") {
      return (
        <span>
          {deliveryEstimate !== null ? (
            <span>
              <FormattedMessage id="production.desc" values={{ br: <br /> }} />
              <FormatDate value={deliveryEstimate} date />
            </span>
          ) : (
            <span>
              <FormattedMessage id="production.desc" values={{ br: <br /> }} />
              <FormattedMessage id="production.desc.eta" />
            </span>
          )}
        </span>
      );
    } else if (step === "shipped") {
      return (
        <FormattedMessage
          id="shipped.desc"
          values={{ trackNum: tracking_number ? tracking_number : null, br: <br /> }}
        />
      );
    }
  };

  if (showProgress()) {
    return (
      <div className="portlet light bordered">
        <div className="portlet-title">
          <div className="caption">
            <i className="fa fa-spinner font-green" />
            <span className="caption-subject font-green bold uppercase">
              <FormattedMessage id="timeline.title" />
              {correctionIndex === 0 ? (
                <FormattedMessage id="main.treatment" />
              ) : (
                <span>
                  <FormattedMessage id="correction" /> {correctionIndex}
                </span>
              )}
            </span>
          </div>
        </div>
        <div style={{ padding: "40px 0 20px 0" }} className="portlet-body">
          <div className="timeline-container">
            <div className="timeline-progress">
              {steps.map((step, index) => (
                <div
                  key={step.label}
                  className={`timeline-step ${isStepCompleted(index) ? "completed" : null} ${
                    index === currentStepIndex ? "current" : null
                  } `}
                  id={step.label}
                >
                  <div className="step-circle">
                    <span className="step-number">{index + 1}</span>
                  </div>
                  {index === currentStepIndex &&
                    (currentStepIndex === 1 || currentStepIndex === 3) && (
                      <div className="step-description-see-more step-description-top tw-absolute tw-flex tw-w-[200px] tw-transform tw-flex-col tw-items-center tw-transition">
                        <span className="step-description -tw-mb-4 tw-transform tw-rounded tw-bg-gray-700 tw-px-2 tw-py-1 tw-text-center tw-text-[10px] tw-text-white tw-transition">
                          {seeMoreMessage(step.label)}
                        </span>
                        <i className="fa fa-arrow-up tw-text-[#26a1ab]" />
                        <span className="step-description-see-more tw-opacity-1 tw-text-[8px]">
                          <FormattedMessage id="timeline.see.more" />
                        </span>
                      </div>
                    )}
                  {index === currentStepIndex &&
                    (currentStepIndex === 0 ||
                      currentStepIndex === 2 ||
                      currentStepIndex === 4) && (
                      <div className="step-description-see-more step-description-bottom tw-absolute tw-flex tw-w-[200px] tw-transform tw-flex-col tw-items-center tw-transition">
                        <span className=" tw-opacity-1 tw-text-[8px]">
                          <FormattedMessage id="timeline.see.more" />
                        </span>
                        <i className="fa fa-arrow-down tw-text-[#26a1ab]" />
                        <span className="step-description -tw-mt-4 tw-transform tw-rounded tw-bg-gray-700 tw-px-2 tw-py-1 tw-text-center tw-text-[10px] tw-text-white tw-transition">
                          {seeMoreMessage(step.label)}
                        </span>
                      </div>
                    )}
                  <span className="step-label">
                    <FormattedMessage id={step.label} />
                  </span>

                  {index < steps.length - 1 ? <div className="timeline-line"></div> : null}

                  {currentStatus === "S_RETAKE_IMPRESSIONS" && step.label === "submitted" ? (
                    <div className="red-flag-container">
                      <span className="red-flag-label">
                        {notes.length > 0 ? notes[0].title : null}
                      </span>
                      <div className="red-flag">
                        <i className="fa fa-flag" />
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default ProgressBar;
