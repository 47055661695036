import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";

import paymentPopup from "../../assets/img/wpb-popup-ico-4.png";


export const invoiceNotification = () => {
    return (
      <div>
          <InvoiceNotificationComponent />
      </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        lang: state.intl,
    }
}

export class InvoiceNotification extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { last_name } = this.props.user;
        return (
          <div style={{visibility: 'visible'}} className="wpb-popup-wr">
              <div className="wpb-popup wpb-popup--big">
                  <a 
                    href="#" 
                    className="wpb-popup__close">
                  </a>
                  <div className="wpb-popup__ico">
                      <img src={paymentPopup} width="275" height="218" alt="" />
                  </div>
                  <div className="wpb-popup__body">
                      <p><FormattedMessage id="pat.payments.dear.doctor" /><span>{last_name},</span></p>
                      <p><FormattedMessage id="pat.payments.invoice" /></p>
                      <p><strong><FormattedMessage id="pat.payments.thanks" /></strong></p>
                      <div className="wpb-popup__btn-ok">
                          <button><FormattedMessage id="notify.done" /></button>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}


const InvoiceNotificationComponent = connect(mapStateToProps)(InvoiceNotification);

