import React, {Component} from 'react';

import img from './default_thumbnail.png';
import {API_MANUALS_GET_STATIC} from '../../config';


const SOURCE_TYPE_FILE = 1;
const SOURCE_TYPE_URL = 2;


const cardBodyStyle = {
    'display': 'flex',
    'width': '200px',
    'backgroundColor': '#ffffff',
    'allignItems': 'center',
    'textAlign': 'center',
    'borderStyle':'solid 0.5px',
    'margin': '0px 10px 10px 10px',
    'flexDirection': 'column',
    'marginBottom': '10px',
}


const linkStyle = {
    'textDecoration': 'none',
}


const imgStyle = {
    'width': '130px',
    'height': '130px',
    'objectFit': 'cover',
}


const textStyle = {
    'marginTop': '5px',
    'transition': 'color 0.5s ease',
}


class ManualsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      cardBodyStyle: cardBodyStyle,
      imgStyle: imgStyle,
      textStyle: textStyle,
      imgSrc: '',
    };

    this.linkRender = this.linkRender.bind(this);
  }

    linkRender(source_type, url, props) {
        if (source_type == SOURCE_TYPE_URL) {
            return url;
        }
        return API_MANUALS_GET_STATIC('document', props.data.manual_id)
    }

    render() {
        return (
            <div className="card-body" id="manuals-card-body" style={this.state.cardBodyStyle}>
                <a
                    href={this.linkRender(this.props.data.source_type, this.props.data.url, this.props)}
                    rel="noopener noreferrer"
                    target="_blank"
                    id="manuals-card-link"
                    style={linkStyle}
                >
                    <div className="pic" id="manuals-card-img">
                        <img
                            src={API_MANUALS_GET_STATIC('thumbnail', this.props.data.manual_id)}
                            style={this.state.imgStyle}
                            onError={(e) => {e.target.onError = null; e.target.src=img}}
                        />
                    </div>
                    <div className="text" id="manuals-card-text" style={this.state.textStyle}>
                        {this.props.data.title}
                    </div>
                </a>
            </div>
        );
    }
}


export default ManualsCard;
