import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import remoteLog from '../../common/logging';
import { getClinic, setAddress, getClinics, unlinkClinic } from '../../actions/clinics';
import { TranslationMap } from './schedule';
import Loader from "../common/loadingInProgress";
import { deployedRussia } from "../../common/utils";


const mapStateToProps = (state) => {
  return {
    clinic: state.clinic,
    clinics: state.clinics
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getClinic: (clinic_id) => dispatch(getClinic(clinic_id)),
    getClinics: () => dispatch(getClinics()),
    unlinkClinic: (clinic_id) => dispatch(unlinkClinic(clinic_id))
  }
}


class Clinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: props.clinic.schedule_json || {},
      isLoading: false
    }
    this.unlinkClinicButton = this.unlinkClinicButton.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'clinic_page');
  }

  componentDidMount() {
      this.setState({ isLoading: true });
      const clinic_id = this.props.match.params.clinic_id;
      this.props.getClinic(clinic_id);
  }

  componentDidUpdate(prevProps) {
    const { clinic, clinics } = this.props;
    if (clinic !== prevProps.clinic) {
      this.setState({ schedule: { ...clinic.schedule_json } });
      this.setState({ isLoading: false });
    }
    if (prevProps.clinics.length !== clinics.length) {
      this.props.history.push('/pages/doctor/clinics');
    }
  }

  unlinkClinicButton() {
    const fm = (id) => this.props.intl.formatMessage({ id: id });
    if (confirm(fm('clin_view.confirm'))) {
      this.props.unlinkClinic(this.props.match.params.clinic_id);
    }
  }

  render() {
    const { schedule } = this.state;

    return(
      <div className="page-content-wrapper">
        <div className="page-content" style={{minHeight: 584}}>
          <div className="row">
            <div className="col-md-8">
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption">
                    <i className="icon-book-open font-green" />
                    <span className="caption-subject font-green bold uppercase"><FormattedMessage id="clin_view.name" /></span>
                  </div>
                </div>
                {!this.state.isLoading
                  ? (
                      <div className="portlet-body">
                          <h3 className="block"><FormattedMessage id="clin_view.header" /></h3>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.name" /></strong></label>
                        <p>{ this.props.clinic.title }</p>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.email" /></strong></label>
                        <p>{ this.props.clinic.email }</p>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.address" /></strong></label>
                        <p>{ setAddress(this.props.clinic) }</p>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.zip" /></strong></label>
                        <p>{ this.props.clinic.zip_code }</p>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.phone" /></strong></label>
                        <p>{ this.props.clinic.phone }</p>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.contacts" /></strong></label>
                        <p>{ this.props.clinic.contact }</p>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.logistic_phone" /></strong></label>
                        <p>{ this.props.clinic.logistic_phone }</p>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.logistic_contact" /></strong></label>
                        <p>{ this.props.clinic.logistic_contact }</p>
                        <label className="control-label"><strong><FormattedMessage id="clin_view.schedule.schedule" /></strong></label>
                        {
                          Object.keys(schedule).map((weekday) => {
                            return (
                              <div key={weekday} style={{marginBottom: '10px'}}>
                                <FormattedMessage id={`clin_view.schedule.${TranslationMap[weekday]}`} />
                                  : {`${schedule[weekday].from} - ${schedule[weekday].to}`}
                              </div>
                            );
                          })
                        }
                        { deployedRussia() === true
                        ? (
                            <div className="form-actions">
                              <button
                                className="btn green"
                                onClick={()=>{this.unlinkClinicButton()}}
                              ><FormattedMessage id="clin_view.detach" />
                              </button>
                            </div>
                          ) : null
                        }
                      </div>
                    ) : <Loader />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Clinic));
