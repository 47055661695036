import { API_GET_PATIENT } from "../config";
import type { TPatient } from "../reducers/dashboard";
import type { AppDispatch } from "../store";
import { getDeliveries } from "./deliveries";

export function getPatientId(patient_id: TPatient["patient_id"], calls = false) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_GET_PATIENT(patient_id), { credentials: "include" });
      if (!response.ok) {
        throw new Error("Error occurred trying to fetch patient");
      }
      const json = await response.json();
      dispatch({ type: "GET_PATIENT_SUCCESS", json });
      if (calls) {
        /** Transfer this dispatch from that method */
        dispatch(getDeliveries(patient_id));
      }
    } catch {}
  };
}
