import { API_POST_PATIENT, API_RUSH_CORRECTION, API_RUSH_ADDITIONAL_SERVICES } from "../config";
import { API_PUT_UNCOMPLETE_PATIENT } from "../config";
import { API_PUT_PATIENT } from "../config";
import { API_SUBMIT_PATIENT } from "../config";
import { submitPatientNotification } from "../components/notifications/submit_patient_notification";
import { saveDraftPatientNotification } from "../components/notifications/save_patient_draft";
import { addUserActionNotification } from "./user_notification";
import { getExtraServices } from "./extra_services";

export function patientIsSavingSuccess(bool) {
  return {
    type: "PATIENT_SAVE_SUCCESSFULL",
    patientIsSaving: bool,
  };
}

export function rushCorrectionOn(bool) {
  return {
    type: "RUSH_CORRECTION_ON",
    rush_correction: bool,
  };
}

export function rushAdditionalServiceOn(bool) {
  return {
    type: "RUSH_ADDITIONAL_SERVICE_ON",
    rush_additional_service: bool,
  };
}

export function rushAdditionalService(patient_id, patient_service_id) {
  const NOTIFICATION_MSG_OK = {
    message: "notify.patient.rush.additional.service.ok",
    level: "success",
    position: "tl",
    autoDismiss: "10",
  };

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.patient.rush.additional.service.error",
    level: "error",
    position: "tl",
    autoDismiss: "4",
  };
  return (dispatch) => {
    fetch(API_RUSH_ADDITIONAL_SERVICES(patient_id, patient_service_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ tag: "RUSH_PRODUCTION" }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error trying to create patient");
        }
        return response;
      })
      .then(() => {
        dispatch({ type: "RUSH_ADDITIONAL_SERVICE_OFF" });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .then(() => {
        dispatch(getExtraServices(patient_id));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

export function rushCorrection(patient_id) {
  const NOTIFICATION_MSG_OK = {
    message: "notify.patient.rush.additional.service.ok",
    level: "success",
    position: "tl",
    autoDismiss: "10",
  };
  const NOTIFICATION_MSG_ERROR = {
    message: "notify.patient.rush.additional.service.error",
    level: "error",
    position: "tl",
    autoDismiss: "4",
  };

  return (dispatch) => {
    fetch(API_RUSH_CORRECTION(patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ tag: "RUSH_PRODUCTION" }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error trying to create patient");
        }
        return response;
      })
      .then(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
        dispatch({ type: "RUSH_CORRECTION_OFF" });
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

export function createAndSubmitPatient(instructions, media, media_s3, rush) {
  const NOTIFICATION_MSG_OK = {
    message: "notify.patient.create.ok",
    level: "success",
    position: "tc",
    autoDismiss: "10",
    children: submitPatientNotification(),
  };

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.patient.create.error",
    level: "error",
    position: "tl",
    autoDismiss: "4",
  };

  instructions["media_info"] = media;
  instructions["s3_media"] = media_s3;

  return (dispatch) => {
    fetch(API_POST_PATIENT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(instructions),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error trying to create patient");
        }
        return response;
      })
      .then((response) => response.json())
      .then((response) =>
        fetch(API_SUBMIT_PATIENT(response.patient_id), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
        }),
      )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error trying to submit patient");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch(patientIsSavingSuccess(true));
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
        dispatch({ type: "ADD_PATIENT_SUCCESSFULL", json });
        dispatch(patientIsSavingSuccess(false));
        if (rush) {
          dispatch(rushCorrection(json.patient_id));
        }
      })
      .catch(() => {
        dispatch(patientIsSavingSuccess(false));
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

export function createPatient(instructions, media, media_s3, rush) {
  const NOTIFICATION_MSG_OK = {
    message: "notify.patient.create.draft.ok",
    level: "success",
    position: "tc",
    autoDismiss: "0",
    children: saveDraftPatientNotification(),
  };

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.patient.create.error",
    level: "error",
    position: "tl",
    autoDismiss: "4",
  };

  instructions["media_info"] = media;
  instructions["s3_media"] = media_s3;

  return (dispatch) => {
    fetch(API_POST_PATIENT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(instructions),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to create patient");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch(patientIsSavingSuccess(true));
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
        dispatch({ type: "ADD_PATIENT_SUCCESSFULL", json });
        dispatch(patientIsSavingSuccess(false));
        if (rush) {
          dispatch(rushCorrection(json.patient_id));
        }
      })
      .catch(() => {
        dispatch(patientIsSavingSuccess(false));
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

export function updatePatient(patient_id, { instructions, media, media_s3 }, rush) {
  const NOTIFICATION_MSG_ERROR = {
    message: "notify.patient.update.error",
    level: "error",
    position: "tl",
    autoDismiss: "4",
  };

  instructions["media_info"] = media;
  instructions["s3_media"] = media_s3;

  return (dispatch) => {
    fetch(API_PUT_UNCOMPLETE_PATIENT(patient_id), {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(instructions),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to update patient");
        }
        return response;
      })
      .then(() => {
        dispatch(patientIsSavingSuccess(true));
        if (rush) {
          dispatch(rushCorrection(patient_id));
        }
        dispatch(patientIsSavingSuccess(false));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

export function updateAndSubmitPatient(patient_id, { instructions, media, media_s3 }, rush) {
  const NOTIFICATION_MSG_OK = {
    message: "notify.patient.create.ok",
    level: "success",
    position: "tc",
    autoDismiss: "10",
    children: submitPatientNotification(),
  };

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.patient.update.error",
    level: "error",
    position: "tl",
    autoDismiss: "4",
  };

  instructions["media_info"] = media;
  instructions["s3_media"] = media_s3;

  return (dispatch) => {
    fetch(API_PUT_PATIENT(patient_id), {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      credentials: "include",
      body: JSON.stringify(instructions),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to update patient");
        }
        return response;
      })
      .then((response) => response.json())
      .then((response) =>
        fetch(API_SUBMIT_PATIENT(response.patient_id), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
        }),
      )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to submit patient");
        }
        return response;
      })
      .then(() => {
        dispatch(patientIsSavingSuccess(true));
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
        dispatch(patientIsSavingSuccess(false));
        if (rush) {
          dispatch(rushCorrection(patient_id));
        }
      })
      .catch(() => {
        dispatch(patientIsSavingSuccess(false));
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

export function updateMedia(patient_id, { media, media_s3, links = null }) {
  let instructions = {};
  instructions["media_info"] = media;
  instructions["s3_media"] = media_s3;
  instructions["links"] = links;

  return (dispatch) => {
    fetch(API_PUT_PATIENT(patient_id), {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(instructions),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred tryint to update media");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "UPDATE_PATIENT_SUCCESSFULL", json });
      })
      .catch(() => {});
  };
}
