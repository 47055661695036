import React, {Component} from 'react';
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl';
import { addUserActionNotification } from '../../../actions/user_notification';
import CopyToClipboard from 'react-copy-to-clipboard';
import Loader from '../../common/loadingInProgress';
import remoteLog from '../../../common/logging';
import { deployedUSA } from '../../../common/utils';
import { PLAN_LANG_OPTION_MAP, DN_MAP_PALMER } from '../../../common/constants';
import {API_PUBLIC_LINK} from '../../../config';


const PLAN_LINK_V2 = (patient_id,token,lang) => `//${ process.env.PLAN3D_API }/show-${patient_id}-secure-${token}-lang-${lang}-version=2`
const PLAN_LINK_V2_RU = (patient_id,token,lang) => `//${ process.env.PLAN3D_API_RU }/show-${patient_id}-secure-${token}-lang-${lang}-version=2`


const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    lang: state.intl,
    token: state.token
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    addUserActionNotification: (json) => dispatch(addUserActionNotification(json)),
  }
}


class Patient3DPlan extends Component {

  constructor(props) {
    super(props);
    this.state = {
      public_link: null,
      showLoader: false,
      canSubmit: true,
    };
    this.open3dPlan = this.open3dPlan.bind(this);
    this.sharePublicLink = this.sharePublicLink.bind(this);
    this.sharePublicLinkNotification = this.sharePublicLinkNotification.bind(this);
    this.copyPublicLink = this.copyPublicLink.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'patient_3d_plan');
  }

  open3dPlan() {
    const { user } = this.props;
    let langLocale = this.props.lang.locale;
    if (deployedUSA() === true) {
      langLocale = PLAN_LANG_OPTION_MAP[user.preferences.dental_notation] || PLAN_LANG_OPTION_MAP[DN_MAP_PALMER];
    }
    if (window.location.hostname.endsWith(".com")) {
      window.open(PLAN_LINK_V2(this.props.patient.patient_id, this.props.token, langLocale))
    } else {
      window.open(PLAN_LINK_V2_RU(this.props.patient.patient_id, this.props.token, langLocale))
    }
  }

  sharePublicLink() {
    this.setState({'showLoader':true});
    this.setState({'canSubmit':false});
    const { patient } = this.props;

    if (patient && Object.keys(patient).length > 0) {
      fetch(
        API_PUBLIC_LINK(patient.patient_id),
        {credentials: 'include'}
      )
      .then(response => {
        if (!response.ok) {
          throw new Error();
        }
        return response;
      })
      .then(response => response.json())
      .then((response) => {
        this.setState({ public_link: response, showLoader: false });
        this.sharePublicLinkNotification();
      }).catch(error => {
        const NOTIFICATION_LINK = {
          message: "public.plan.error",
          level: 'error',
          position: 'tl',
          autoDismiss: '4',
        };
        this.props.addUserActionNotification(NOTIFICATION_LINK);
        this.setState({ public_link: null, showLoader: false, canSubmit: true });
      })
    }
  }

  sharePublicLinkNotification() {
    if (this.props.user.email_notify || this.props.user.telegram_notify) {
      let NOTIFICATION_LINK = {
        message: "",
        level: 'success',
        position: 'tl',
        autoDismiss: '4',
      };

      if (this.props.user.email_notify && this.props.user.telegram_notify) {
        NOTIFICATION_LINK.message = "notify.link_tg_email"
      } else if (this.props.user.email_notify) {
        NOTIFICATION_LINK.message = "notify.link_email"
      } else if (this.props.user.telegram_notify) {
        NOTIFICATION_LINK.message = "notify.link_tg"
      }
      this.props.addUserActionNotification(NOTIFICATION_LINK)
    }
  }

  copyPublicLink() {
    const NOTIFICATION_LINK_COPY = {
      message: "notify.link_copy",
      level: 'success',
      position: 'tl',
      autoDismiss: '4',
    };
    this.props.addUserActionNotification(NOTIFICATION_LINK_COPY)
  }

  render() {
    return (
      <div className="portlet light bordered">
        <div className="portlet-title">
          <div className="caption">
            <i className="icon-docs font-green" />
            <span className="caption-subject font-green bold uppercase"><FormattedMessage id="pat.plan.header" /></span>
          </div>
        </div>
        <div className="portlet-body">
            <div className="form-group">
              <div className="form-block">
                <button
                  onClick={() => this.open3dPlan()}
                  className="btn green"
                  id="test-btn">
                  <FormattedMessage id="pat.plan.button" />
                </button>
              </div>
            </div>
            { !this.state.public_link &&
              <div className="form-group">
                <div className="form-block">
                  <button
                    type="button"
                    className="btn green"
                    onClick={() => this.sharePublicLink()}
                    disabled={!this.state.canSubmit}>
                    <FormattedMessage id="pat.plan.link"/>
                  </button>
                </div>
              </div>
            }
            { this.state.showLoader && <Loader/> }
            { this.state.public_link &&
              <div>
                <div className="form-group">
                  <div className="form-block">
                    <button className="btn green">
                      <CopyToClipboard text={this.state.public_link ? this.state.public_link : ''}
                        onCopy={() => this.copyPublicLink()}>
                        <span><FormattedMessage id="pat.plan.copy_link" /></span>
                      </CopyToClipboard>
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-block">
                    <textarea
                      className="form-control"
                      readOnly
                      rows={3}
                      value={this.state.public_link}
                      ref={input => this.link = input}
                      onClick={() => this.link.select()}
                    />
                  </div>
                </div>
              </div>
            }
        </div>
      </div>
    );
	}
}


export default connect(mapStateToProps,mapDispatchToProps)(Patient3DPlan);
