import { useEffect, useRef } from "react";

export function useUpdateEffect(
  effect: React.EffectCallback,
  deps?: React.DependencyList
) {
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current) {
      return effect();
    }
    isMountedRef.current = true;
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
