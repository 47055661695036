import clsx from "clsx";
import React from "react";

export function Badge({
  children,
  srOnlyText,
  ...props
}: {
  children: number;
  srOnlyText: string;
  id?: string;
  className?: string;
}) {
  return (
    <span {...props} className={clsx("badge badge-danger", props.className)}>
      {children}
      <span className="sr-only">{srOnlyText}</span>
    </span>
  );
}
