import { API_POST_STATUS_END } from '../config';
import { addUserActionNotification } from './user_notification';


export function endTreatment(patient_id) {

  const NOTIFICATION_MSG_OK = {
    message: "notify.status_end.ok",
    level: 'success',
    position: 'tl',
    autoDismiss: '4',
  };
  
  const NOTIFICATION_MSG_ERROR = {
    message: "notify.status_end.error",
    level: 'error',
    position: 'tl',
    autoDismiss: '4',
  }

  return (dispatch) => {
    fetch(API_POST_STATUS_END(patient_id), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Error occurred trying to end treatment!')
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
      dispatch({type: 'POST_STATUS_END', json});
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
    })
    .catch(() => {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    })
  }
}
