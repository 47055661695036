import React, {Component} from 'react';
import { connect } from 'react-redux';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import remoteLog from '../../../common/logging';
import { deployedRussia, deployedUSA, isDevelopment } from '../../../common/utils';
import { PAYERS } from '../../../common/constants';


const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    comments: state.comments,
    intl: state.intl
  }
}

class PatientInvoice extends Component {
  componentDidCatch(e) {
    remoteLog(e, 'patient_invoice');
  }

  showPayments() {
    let allow = false;
    if (
      this.props.patient &&
      Object.keys(this.props.patient).length > 0 &&
      (window.location.protocol == "https:" || isDevelopment) &&
      this.props.patient.status != 'S_UNFILLED_CASE'
    ) {
      const { patient } = this.props;
      const { payment_available } = this.props.user;
      const { payment_method } = patient.course;
      if (payment_available && payment_method == "PM_CARD") {

        const { payments } = patient.course;
        const not_paid_full = payments.remain > 0 || payments.next > 0 || (payments.extra && payments.extra.length > 0);
        if (deployedRussia() && this.props.intl.locale == 'ru' && not_paid_full) {
          if (PAYERS['DOCTOR'] === patient.payer_id) {
            allow = true;
          }
        } else if (deployedUSA() && not_paid_full) {
          allow = true;
        }
      }
    }
    return allow;
  }

	render() {
    if(this.props.patient && this.props.patient.patient_id) {
      const showPayments = this.showPayments();

      if (showPayments) {
        return (
          <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <i className="icon-docs font-green" />
                <span className="caption-subject font-green bold uppercase"><FormattedMessage id="pat.payments.course.pay.course" /></span>
              </div>
            </div>
            <div className="portlet-body">
                <div className="form-body">
                  <div className="row fileupload-buttonbar">
                    <div className="col-lg-7">
                      <NavLink to={"/pages/patient/"+this.props.patient.patient_id+"/payment/"} className="nav-link ">
                        <button type="button" className="btn green"><FormattedMessage id="pat.payments.buttons.pay" /></button>
                      </NavLink>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div>
          loading...
        </div>
      )
    }
    return null;
  }
}

export default connect(mapStateToProps)(PatientInvoice);
