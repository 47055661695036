import React from "react";

import Notification from "../notification";
import Tour from "../patient/tour";
import PageContent from "./page_content";
import PageFooter from "./page_footer";
import PageHeader from "./page_header";

export default function PatientArea() {
  return (
    <div>
      <PageHeader />
      <Tour />
      <div className="container">
        <div className="page-container">
          <PageContent />
        </div>
        <Notification />
      </div>
      <PageFooter />
    </div>
  );
}
