import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import TimeRange from 'react-time-range';


const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;
const SUNDAY = 7;
const OPENING_DEFAULT_FROM = "2020-01-21T09:00:00"
const OPENING_DEFAULT_TO = "2020-01-21T18:00:00"

export const TranslationMap = {
    [MONDAY]: "monday",
    [TUESDAY]: "tuesday",
    [WEDNESDAY]: "wednesday",
    [THURSDAY]: "thursday",
    [FRIDAY]: "friday",
    [SATURDAY]: "saturday",
    [SUNDAY]: "sunday"
}

export const DEFAULT_SCHEDULE = {
    [MONDAY]: { checked: true, from: OPENING_DEFAULT_FROM, to: OPENING_DEFAULT_TO },
    [TUESDAY]: { checked: true, from: OPENING_DEFAULT_FROM, to: OPENING_DEFAULT_TO },
    [WEDNESDAY]: { checked: true, from: OPENING_DEFAULT_FROM, to: OPENING_DEFAULT_TO },
    [THURSDAY]: { checked: true, from: OPENING_DEFAULT_FROM, to: OPENING_DEFAULT_TO },
    [FRIDAY]: { checked: true, from: OPENING_DEFAULT_FROM, to: OPENING_DEFAULT_TO },
    [SATURDAY]: { checked: false, from: OPENING_DEFAULT_FROM, to: OPENING_DEFAULT_TO },
    [SUNDAY]: { checked: false, from: OPENING_DEFAULT_FROM, to: OPENING_DEFAULT_TO }
}

const Index = ({workingDays, ...props}) => {
    return (
      <div>
          {
              Object.keys(workingDays).map((weekday) => {
                  return (
                    <div key={weekday}>
                        <input
                          name={weekday}
                          checked={workingDays[weekday].checked}
                          type="checkbox"
                          onChange={props.onWorkingDayStatusChanged}
                        />
                        &nbsp;<FormattedMessage id={`clin_view.schedule.${TranslationMap[weekday]}`}/>
                        <TimeRange
                          onStartTimeChange={time => props.onStartTimeChanged(weekday, time)}
                          onEndTimeChange={time => props.onEndTimeChanged(weekday, time)}
                          startMoment={workingDays[weekday].from}
                          endMoment={workingDays[weekday].to}
                          minuteIncrement={15}
                          use24Hours
                          startLabel=""
                          endLabel=""
                        />
                    </div>
                  )
              })
          }
      </div>
    )
}

export default injectIntl(Index);