import React from 'react';
import cards_img from './cards_img.jpg'


const PaymentDetails = () => (
  <div>
    <div className="page-header navbar navbar-fixed-top">
        <div className="page-header-inner container" id="top-navbar">
          <div className="page-logo">
              <img src="/img/3d_smile_logo_rgb.svg" alt="logo" className="logo-default" style={{ width: '120px', marginTop: '25px' }} />
          </div>
      </div>
    </div>
    <div className="container">
      <div className="page-container">
		      <div className="page-content">
		        <div className="portlet light bordered">
		          <div className="portlet-title">
		            <div className="caption">
		              <i className="icon-user-follow font-green" />
		              <span className="caption-subject font-green bold uppercase">Информация об оплате</span>
		            </div>
		          </div>
		          <div className="portlet-body form">
		          <h3>Описание сферы деятельности, информация о товаре, услуге</h3>
		          <p>
		          ООО "Доммар" разработчик и производитель невидимой системы выравнивания зубов 3D Smile. <br/>
Услуги по моделированию, анализу КТ и изготовлению кап для выравнивания зубов осуществляются в соответствии с 3D моделированием. Количество кап определяется индивидуально для каждого пациента в соответствии с 3D планом. Услуги оказываются только врачам. Каждый заказ индивидуален по стоимости.

		          </p>
		          
		          <h3>Способы оплаты</h3>
		          <p>
		          	Произвести оплату ортодонтических кап можно следующим удобным для Вас способом:
		          	<br/>
		          	<br/>
		          	<ul>
		          		<li>Безналичный расчет</li>
		          		<p></p>
		          		<li>Банковская карта</li>
		          		<p>Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку «Оплата заказа банковской картой». Оплата происходит через ПАО СБЕРБАНК с использованием Банковских карт следующих платежных систем:
			          	</p>
			          		<ul>
			          			<li>МИР</li>
			          			<li>VISA International</li>
			          			<li>Mastercard Worldwide</li>
			          			<img src={cards_img} style={{width: 100}}/>
			          		</ul>
		          	</ul>
		          </p>
		          <h3>Доставка</h3>
		          <p>Доставка осуществляется курьерской службой согласно адресу указанному в рецепте.</p>
		          <h3>Возврат средств</h3>
		          <p>Если был совершен ошибочный платеж - напишите письмо на адрес info@3d-smile.ru, сообщите об ошибке и попросите вернуть средства обратно на ваш счет.</p>
		          <h3>Описание процесса передачи данных</h3>
		          <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard SecureCode для проведения платежа также может потребоваться ввод специального пароля. Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем МИР, Visa Int. и MasterCard Europe Sprl.</p>
	            <h3>Контактные данные организации</h3>
	            <p>
					121205, Москва<br/>
								территория Инновационного Центра «Сколково»<br/>
								Большой бульвар, 42, корп. 1<br/>
								Лаборатория "3D Smile"<br/>
								Телефон<br/>
								+7 (495) 211-44-54<br/>
								Электронная почта<br/>
								info@3d-smile.ru<br/>
	            </p>
	            <h3>Реквизиты</h3>
	            <p>
	            	Общество с ограниченной ответственностью «Доммар»<br/>
								ОГРН 1147746363368<br/>
								ИНН/КПП 7709951104/773101001<br/>
								Юридический адрес: 121205, Москва, Территория Инновационного Центра «Сколково», Технопарк, Большой бульвар, 42, корп. 1<br/>
	            </p>
	            </div>
	          </div>
	        </div>
      </div>
    </div>
  </div>
);


export default PaymentDetails;
