import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";
import { change, formValueSelector } from "redux-form";

import { useAppDispatch, useAppSelector } from "../../store";

const selector = formValueSelector("correction");

export default function CorrectionWithCTRadioGroup() {
  const dispatch = useAppDispatch();
  const correction_with_ct = useAppSelector(
    (state) => selector(state, "correction_with_ct") as boolean | undefined,
  );

  type TOption = { value: boolean; intlId: MessageDescriptor["id"] };
  const options: TOption[] = [
    { value: true, intlId: "C_YES_CT_CORRECTION" },
    { value: false, intlId: "C_NO_CT_CORRECTION" },
  ];

  return (
    <fieldset className="tw-space-y-1" aria-required="true">
      <legend
        id="validation-correction-with-ct"
        className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold"
      >
        <FormattedMessage id="ATTACHMENT_INSTRUCTIONS" />
        <span
          aria-hidden="true"
          className="required tw-relative tw-bottom-1 tw-left-1 tw-text-[17px]"
        >
          *
        </span>
      </legend>

      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.intlId}
            htmlFor={`correction-with-ct-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`correction-with-ct-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="correction_with_ct"
              checked={option.value == correction_with_ct}
              onChange={() => dispatch(change("correction", "correction_with_ct", option.value))}
            />
            <FormattedMessage id={option.intlId} />
          </label>
        ))}
      </div>
    </fieldset>
  );
}
