import React from "react";
import { connect } from 'react-redux';
import { deployedRussia } from "../../../common/utils";


const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
};


const PersonName = (props) => {
    return <span data-matomo-mask data-hj-suppress>{formatPersonName(props)}</span>
}


export default connect(mapStateToProps)(PersonName);


export function formatPersonName({person, useMiddleName, isDoctor, user}) {
    if (!person) return "";
    let {first_name, middle_name, last_name} = person;
    if (!middle_name || !useMiddleName || middle_name.toLowerCase() === "none") middle_name = "";

    if (!isDoctor && user) {
        isDoctor = person.account_id === user.account_id;
    }

    let name = [];
    if (deployedRussia()) {
        name = [last_name, first_name, middle_name].filter(v => v);
    } else {
        name = [first_name, middle_name, last_name].filter(v => v);
        if (isDoctor) name.unshift("Dr.");
    }

    return name.join(" ");
}
