import React, {Component} from 'react';
import PatientMenu from '../../../patient_menu';
import PageFooter from '../../../page_footer';
import PageHeader from '../../../page_header';
import PaymentInvoiceError from './payment_invoice_error';
import remoteLog from '../../../../common/logging';
import Tour from '../../../patient/tour';


class PaymentInvoiceErrorBody extends Component {
  componentDidCatch(e) {
    remoteLog(e, 'payment_invoice_error_body');
  }

  render() {
    return (
      <div>
        <PageHeader {...this.props}/>
        <Tour />
        <div className="container">
          <div className="page-container">
            <PatientMenu {...this.props}/>
            <PaymentInvoiceError {...this.props}/>
          </div>
          <PageFooter {...this.props}/>
        </div>
      </div>
    )
  }
}

export default PaymentInvoiceErrorBody;
