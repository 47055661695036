import React from "react";
import { NavLink } from "react-router-dom";

import type { TSmiles } from "../reducers/dashboard";

const AlertBadgeStyles = {
  maxWidth: "20px",
  fontSize: "20px",
  top: "-1px",
  position: "relative",
  display: "inline-block",
  width: "1.5em",
  textAlign: "center",
  left: "-3px",
} as const;

export function AlertSmilesBell({
  smiles,
  enableLink = false,
}: {
  smiles: Partial<TSmiles>;
  enableLink?: boolean;
}) {
  return (
    <li className="dropdown dropdown-extended dropdown-notification" id="header_notification_bar">
      <NavLink
        to={enableLink ? "/pages/smiles" : "#"}
        className="dropdown-toggle"
        data-hover="dropdown"
        data-close-others="true"
        id="smiles-bar"
      >
        <img src="/img/smile.svg" style={AlertBadgeStyles} alt="" />
        {smiles && Object.keys(smiles).length > 0 ? (
          <span className="badge" style={{ backgroundColor: "#82D9D4" }} id="smiles-count">
            {smiles.current}
          </span>
        ) : null}
      </NavLink>
    </li>
  );
}
