import React, {Component} from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import FileUpload from './../file_upload';
import remoteLog from '../../common/logging';


const mapStateToProps = (state) => {
  return {
    user: state.user,
    images: state.images,
    patient: state.patient,
  }
}

class PlanCorrectionPhoto extends Component {


  componentDidCatch(e) {
    remoteLog(e, '3d_plan_correction_photo');
  }

  onChange(e) {
    const { input: { onChange } } = this.props
    onChange(e.target.files[0])
  }

  render() {
    return (
      <div data-matomo-mask data-hj-suppress className="form-body" id="photo-protocol-block">
        <div className="row">
          <div className="form-group col-md-4">
            <label className="control-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_PROFILE"/>
              { !this.props.easy_validate && <span className="required" aria-required="true">*</span> }
            </label>
            <br />
            <FileUpload name="profile" preview id="profile" semantics="profile" patient_id={0} correctionNum={this.props.correctionNumber}/>
          </div>
          <div className="form-group col-md-4">
            <label className="control-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_SMILE"/>
              { !this.props.easy_validate && <span className="required" aria-required="true">*</span> }
            </label>
            <br />
            <FileUpload name="full_face_with_smile" preview id="full_face_with_smile" semantics="full_face_with_smile" patient_id={0} correctionNum={this.props.correctionNumber}/>
          </div>
          <div className="form-group col-md-4">
            <label className="control-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_PORTRATE"/>
              { !this.props.easy_validate && <span className="required" aria-required="true">*</span> }
            </label>
            <br />
            <FileUpload name="full_face_without_smile" preview id="full_face_without_smile" semantics="full_face_without_smile" patient_id={0} correctionNum={this.props.correctionNumber}/>
          </div>
      </div>
      <div className="row" style={{marginBottom: 10}}>
        <div className="form-group col-md-4" style={{marginBottom: 10}}>
          <label className="control-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_UPPER_OCCLUSAL"/>
            { !this.props.easy_validate && <span className="required" aria-required="true">*</span> }
          </label>
          <br />
          <FileUpload name="occlusal_view_upper" preview id="occlusal_view_upper" semantics="occlusal_view_upper" patient_id={0} correctionNum={this.props.correctionNumber}/>
        </div>
        <div className="form-group col-md-4">
        </div>
        <div className="form-group col-md-4">
          <label className="control-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_LOWER_OCCLUSAL"/>
            { !this.props.easy_validate && <span className="required" aria-required="true">*</span> }
          </label>
          <br />
          <FileUpload name="occlusal_view_lower" preview id="occlusal_view_lower" semantics="occlusal_view_lower" patient_id={0} correctionNum={this.props.correctionNumber}/>
        </div>
      </div>
      <div className="row" style={{marginBottom: 10}}>
        <div className="form-group col-md-4">
          <label className="control-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_RIGHT"/>
            { !this.props.easy_validate && <span className="required" aria-required="true">*</span> }
          </label>
          <br />
          <FileUpload name="lateral_view_right" preview id="lateral_view_right" semantics="lateral_view_right" patient_id={0} correctionNum={this.props.correctionNumber}/>
        </div>
        <div className="form-group col-md-4">
          <label className="control-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_FRONT"/>
            { !this.props.easy_validate && <span className="required" aria-required="true">*</span> }
          </label>
          <br />
          <FileUpload name="front_view" preview id="front_view" semantics="front_view" patient_id={0} correctionNum={this.props.correctionNumber}/>
        </div>
        <div className="form-group col-md-4">
          <label className="control-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_LEFT"/>
            { !this.props.easy_validate && <span className="required" aria-required="true">*</span> }
          </label>
          <br />
          <FileUpload name="lateral_view_left" preview id="lateral_view_left" semantics="lateral_view_left" patient_id={0} correctionNum={this.props.correctionNumber}/>
        </div>
      </div>
    </div>
    )
  }
}


export default connect(mapStateToProps)(PlanCorrectionPhoto);
