import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import FileUpload from './../../file_upload';
import remoteLog from '../../../common/logging';
import { deployedUSA } from '../../../common/utils'

const mapStateToProps = (state) => {
    return {
        user: state.user,
        images: state.images,
        patient: state.patient,
    }
}


class PatientNewInstructionsImages extends Component {


    componentDidCatch(e) {
        remoteLog(e, 'patient_new_instructions_images');
    }

    render() {

        const fm = (id) => this.props.intl.formatMessage({ id: id });

        return (
            <div data-matomo-mask data-hj-suppress className="form-body" id="photo-protocol-block">
                <label className="control-label" id="validation-course_id" style={{ fontWeight: '900', fontSize: '17px', marginBottom: '10px' }}>
                    <FormattedMessage id="UPLOAD_FHOTOS" />
                    <span className="required" aria-required="true">*</span>
                    <span className='prescription_tooltip_show'>
                        <i style={{ fontSize: "14px", marginLeft: "4px" }} className='icon-question' />
                        <span  className="d-inline-block prescription_tooltip">
                            <FormattedMessage id="upload.photos.tooltip" />
                            {deployedUSA() === true ? <a rel="noopener noreferrer" target="_blank" href="/api/v1/manuals/document/43"><FormattedMessage id="upload.photos.manual.tooltip" /></a> : null}
                        </span>
                    </span>
                </label>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label className="control-label" id="photo-profile-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_PROFILE" />
                            <span className="required">*</span>
                        </label>
                        <br />
                        <FileUpload preview id="profile" semantics="profile" patient_id={0} />
                    </div>
                    <div className="form-group col-md-4">
                        <label className="control-label" id="photo-front-smile-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_SMILE" />
                            <span className="required">*</span>
                        </label>
                        <br />
                        <FileUpload preview id="full_face_with_smile" semantics="full_face_with_smile" patient_id={0} />
                    </div>
                    <div className="form-group col-md-4">
                        <label className="control-label" id="photo-front-no-smile-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_PORTRATE" />
                            <span className="required">*</span>
                        </label>
                        <br />
                        <FileUpload preview id="full_face_without_smile" semantics="full_face_without_smile" patient_id={0} />
                    </div>
                </div>
                <div className="row" style={{ marginBottom: 10 }}>
                    <div className="form-group col-md-4" style={{ marginBottom: 10 }}>
                        <label className="control-label" id="photo-upper-occlusal-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_UPPER_OCCLUSAL" />
                            <span className="required">*</span>
                        </label>
                        <br />
                        <FileUpload preview id="occlusal_view_upper" semantics="occlusal_view_upper" patient_id={0} />
                    </div>
                    <div className="form-group col-md-4">
                    </div>
                    <div className="form-group col-md-4">
                        <label className="control-label" id="photo-lower-occlusal-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_LOWER_OCCLUSAL" />
                            <span className="required">*</span>
                        </label>
                        <br />
                        <FileUpload preview id="occlusal_view_lower" semantics="occlusal_view_lower" patient_id={0} />
                    </div>
                </div>
                <div className="row" style={{ marginBottom: 10 }}>
                    <div className="form-group col-md-4">
                        <label className="control-label" id="photo-lateral-right-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_RIGHT" />
                            <span className="required">*</span>
                        </label>
                        <br />
                        <FileUpload preview id="lateral_view_right" semantics="lateral_view_right" patient_id={0} />
                    </div>
                    <div className="form-group col-md-4">
                        <label className="control-label" id="photo-intraoral-front-label" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_FRONT" />
                            <span className="required">*</span>
                        </label>
                        <br />
                        <FileUpload preview id="front_view" semantics="front_view" patient_id={0} />
                    </div>
                    <div className="form-group col-md-4">
                        <label className="control-label" id="photo-lateral-left" style={{ fontWeight: '900' }}><FormattedMessage id="PHOTO_LEFT" />
                            <span className="required">*</span>
                        </label>
                        <br />
                        <FileUpload preview id="lateral_view_left" semantics="lateral_view_left" patient_id={0} />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(injectIntl(PatientNewInstructionsImages));
