import React, { useEffect } from "react";
import type { RouteComponentProps } from "react-router-dom";

import { getToken } from "../../actions/get_token";
import { deployedUSA } from "../../common/utils";
import { useAppDispatch, useAppSelector } from "../../store";
import PageFooter from "../page_footer";
import PageHeader from "../page_header";
import Tour from "../patient/tour";
import PatientMenu from "../patient_menu";
import PlanCancel from "./3d_plan_correction_page";
import PlanCancelSmile from "./smile_correction_page";

export default function PlanCancelBody(props: RouteComponentProps<{ patient_id: string }>) {
  const user = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {

    dispatch(getToken());
  }, [user, dispatch]);

  return (
    <div>
      <PageHeader />
      <Tour />
      <div className="container">
        <div className="page-container">
          <PatientMenu />
          <div className="page-content-wrapper">
            <div className="page-content" style={{ minHeight: 584 }}>
              <div className="row">
                <div className="col-md-12">
                  {deployedUSA() ? <PlanCancel {...props} /> : <PlanCancelSmile {...props} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageFooter />
      </div>
    </div>
  );
}
