import { API_BANNERS } from "../config";
import type { TBanner } from "../reducers/dashboard";
import type { AppDispatch } from "../store";

export function getBannersAction(json: TBanner[]) {
  return { type: "GET_BANNERS", json } as const;
}

export function bannersIsLoadingAction(bool: boolean) {
  return { type: "BANNERS_IS_LOGGING_IN", bannersIsLoading: bool } as const;
}

export function getBanners() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_BANNERS, { credentials: "include" });
      if (!response.ok) {
        throw new Error(`Error occurred trying to fetch banners: ${response.statusText}`);
      }
      const json = await response.json();
      dispatch(getBannersAction(json));
      dispatch(bannersIsLoadingAction(false));
    } catch {
      dispatch(getBannersAction([]));
    }
  };
}
