import {
  DEPLOY_RUSSIA,
  DEPLOY_USA,
  DN_MAP_PALMER,
  DN_MAP_UNIVERSAL,
  DN_TYPE_PALMER,
  DN_TYPE_UNIVERSAL,
} from "./constants";

export const isDevelopment = process.env.NODE_ENV == "development";

export function deployedUSA(): boolean {
  return process.env.DEPLOY_COUNTRY == DEPLOY_USA;
}

export function deployedRussia(): boolean {
  return process.env.DEPLOY_COUNTRY == DEPLOY_RUSSIA;
}

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function convertToDentalNotation(
  tooth: number,
  dentalNotation: typeof DN_TYPE_PALMER | typeof DN_TYPE_UNIVERSAL,
) {
  if (dentalNotation === DN_TYPE_PALMER) {
    return DN_MAP_PALMER[tooth];
  } else if (dentalNotation === DN_TYPE_UNIVERSAL) {
    return DN_MAP_UNIVERSAL[tooth];
  }
  return tooth;
}
