import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { clearExtraServices, getExtraServices } from "../../actions/extra_services";
import { ARCHES } from "../../common/constants";
import { getPatientId } from "../../actions/get_patient_id";

const ELASTICS_ID_TO_TRANSLATION = {
  2: "ER_R_II_CLASS",
  3: "ER_R_III_CLASS",
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    services: state.services,
    patient: state.patient,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExtraServices: (patient_id) => dispatch(getExtraServices(patient_id)),
    clearExtraServices: () => dispatch(clearExtraServices()),
    getPatient: (patient_id, call) => dispatch(getPatientId(patient_id)),
  };
};

class AdditionalAlignersTable extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    let patient_id = this.props.match.params.patient_id;
    this.props.getPatient(patient_id);
    this.props.clearExtraServices();
    this.props.getExtraServices(patient_id);
  }

  renderElastics(service) {
    if (service && service.option) {
      const { elastics_left, elastics_right } = service.option;
      if (elastics_left !== null && elastics_right !== null) {
        return (
          <div>
            <FormattedMessage id="ELASTICS_RIGHT" />
            &nbsp;
            <FormattedMessage id={ELASTICS_ID_TO_TRANSLATION[elastics_right]} />
            <br />
            <FormattedMessage id="ELASTICS_LEFT" />
            &nbsp;
            <FormattedMessage id={ELASTICS_ID_TO_TRANSLATION[elastics_left]} />
          </div>
        );
      } else if (elastics_left !== null) {
        return (
          <div>
            <FormattedMessage id="ELASTICS_LEFT" />
            &nbsp;
            <FormattedMessage id={ELASTICS_ID_TO_TRANSLATION[elastics_left]} />
          </div>
        );
      } else if (elastics_right !== null) {
        return (
          <div>
            <FormattedMessage id="ELASTICS_RIGHT" />
            &nbsp;
            <FormattedMessage id={ELASTICS_ID_TO_TRANSLATION[elastics_right]} />
          </div>
        );
      }
    }
    return null;
  }

  render() {
    if (
      this.props.patient?.course?.correction?.slice(-1)[0]?.order_options
        ?.can_order_additional_aligners
    ) {
      return (
        <div>
          <div className="page-content-wrapper">
            <div className="page-content" id="additional-aligners-table">
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption">
                    <i className="icon-users font-green" />
                    <span
                      className="caption-subject font-green bold uppercase"
                      id="patients-table-block-title"
                    >
                      <FormattedMessage id="pat.additional.aligners.page.header" />
                    </span>
                  </div>
                </div>
                <div className="portlet-body">
                  <div
                    id="additional-aligners_table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="table-responsive">
                      <table
                        className="table table-bordered table-hover dataTable no-footer"
                        id="additional_aligners_table"
                        role="grid"
                      >
                        <thead id="additional_aligners-head">
                          <tr role="row">
                            <th>
                              <FormattedMessage id="additional_aligners_stage" />
                            </th>
                            <th>
                              <FormattedMessage id="th.option" />
                            </th>
                            <th>
                              <FormattedMessage id="AMOUNT" />
                            </th>
                            <th>
                              <FormattedMessage id="HEADER_ELASTICS" />
                            </th>
                            <th>
                              <FormattedMessage id="th.status" />
                            </th>
                          </tr>
                        </thead>
                        <tbody id="additional-aligners-body">
                          {this.props.services && this.props.services.length
                            ? this.props.services
                                .filter((aligner) => aligner.tag == "ADDITIONAL_ALIGNERS")
                                .map((service, index) => {
                                  return (
                                    <tr key={`service${index}`}>
                                      <td>{service.option.stage}</td>
                                      <td>
                                        <FormattedMessage
                                          id={ARCHES[service.option.treat_arch_id]}
                                        />
                                      </td>
                                      <td>{service.option.amount}</td>
                                      <td>{this.renderElastics(service)}</td>
                                      <td>
                                        <FormattedMessage id={service.option.status} />
                                      </td>
                                    </tr>
                                  );
                                })
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalAlignersTable);
