import React from "react";
import { connect } from "react-redux";

import PatientMenu from "../patient_menu";
import PatientPageBody from "./patient_page_body";
import PageFooter from "../page_footer";
import PageHeader from "../page_header";
import Notification from "../notification";
import Tour from "./tour";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const PatientPage = (props) => (
  <div>
    <PageHeader {...props} />
    <Tour />
    <div className="container">
      <div className="page-container">
        <PatientMenu {...props} />
        <Notification {...props} />
        <div className="page-content-wrapper">
          <div className="page-content" style={{ minHeight: 584 }}>
            <PatientPageBody {...props} />
          </div>
        </div>
      </div>
      <PageFooter {...props} />
    </div>
  </div>
);

export default connect(mapStateToProps)(PatientPage);
