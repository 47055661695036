import {deployedUSA} from './utils';
import moment from 'moment';

export const DATE_FORMAT = deployedUSA() ? "MM-DD-YYYY" : "DD-MM-YYYY";

export function formatDatetime(e) {
    const usaFormat = deployedUSA() === true;
    const etv = e.target.value.replace(/[^0-9]/g, '');
    let dd = etv.slice(0, 2);
    let mm = etv.slice(2, 4);
    let yyyy = etv.slice(4, 8);

    if (usaFormat) {
        [dd, mm] = [mm, dd]
    }

    yyyy.length ? yyyy = `-${yyyy}` : 0;

    if (dd.length === 2 && parseInt(dd) > 31) {
        dd = '31';
    } else if (dd.length === 2 && parseInt(dd) === 0) {
        dd = '01';
    }
    if (mm.replace(/[^0-9]/g, '').length === 2 && parseInt(mm.replace(/[^0-9]/g, '')) > 12) {
        mm = '12';
    } else if (mm.replace(/[^0-9]/g, '').length === 2 && parseInt(mm.replace(/[^0-9]/g, '')) === 0) {
        mm = '01';
    }
    if (yyyy.replace(/[^0-9]/g, '').length === 4 && parseInt(yyyy.replace(/[^0-9]/g, '')) < 1900) {
        yyyy = '-1900';
    }

    if (usaFormat) {
        dd = dd && dd[0] !== '-' ? `-${dd}` : dd;
    } else {
        mm = mm && mm[0] !== '-' ? `-${mm}` : mm;
    }
    e.target.value = usaFormat === false ? `${dd}${mm}${yyyy}` : `${mm}${dd}${yyyy}`;
}

export function formatDate(date) {
    let fullDate = new Date(date)
    let day = fullDate.getDate() < 10 ? `0${fullDate.getDate()}` : fullDate.getDate();
    let month = fullDate.getMonth() + 1
    let year = fullDate.getFullYear()

    return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
}
