import React from 'react';
import { connect } from 'react-redux';

import Homepage from './homepage';
import PatientMenu from '../patient_menu';
import PageFooter from '../page_footer';
import PageHeader from '../page_header';
import Notification from '../notification';
import { notificationCustomStyle } from "../notifications/notification_styles";
import Tour from '../patient/tour';


const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}


const HomePageBody = (props) => (
  <div>
    <PageHeader {...props}/>
    <Tour />
    <div className="container">
      <div className="page-container">
        <PatientMenu {...props}/>
        <Notification style={notificationCustomStyle}/>
        <Homepage {...props}/>
      </div>
      <PageFooter {...props}/>
    </div>
  </div>
);


export default connect(mapStateToProps)(HomePageBody);