import React, { Component } from 'react';
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import Wrapper from "../../hoc/main_wrapper";
import remoteLog from "../../../common/logging";
import { authorizeMeditCode } from "../../../actions/medit";
import { addUserActionNotification } from '../../../actions/user_notification';


const json = {
    message: "medit.auth.err",
    level: 'error',
    position: 'tl',
    autoDismiss: '8',
}


const mapDispatchToProps = (dispatch) =>{
    return {
        setErrMessage : () => dispatch(addUserActionNotification(json))
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}


class MeditAuthPage extends Component {

    componentDidCatch(e) {
        remoteLog(e, 'medit_auth_page');
    }

    componentDidMount() {
        const code = this.props.location.search.split('=')[1].replace('&scope', '')
        authorizeMeditCode(code).then(res => {
            if (res.status === 200) {
                this.props.history.push('/pages/integrations/medit/cases')
            } else {
                this.props.setErrMessage()
            }
        });
    }

    render() {
        return (
            <div className="page-content-wrapper">
                <div className="page-content" style={{minHeight: 584}}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="portlet light bordered">
                                <div className="portlet-title">
                                    <div className="caption">
                                        <i className="icon-book-open font-green"/>
                                        <span className="caption-subject font-green bold uppercase">
                                            <FormattedMessage id="pat.medit.auth"/>
                                        </span>
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <FormattedMessage id="pat.medit.auth.success"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(MeditAuthPage));
