import React, {Component} from 'react';
import { FormattedMessage, injectIntl  } from 'react-intl';
import { connect } from 'react-redux';

import PatientMenu from '../patient_menu';
import PageFooter from '../page_footer';
import PageHeader from '../page_header';
import Notification from '../notification';
import { deployedUSA } from '../../common/utils';
import { PREDICT_SUPPORT_PHONE, PREDICT_SUPPORT_EMAIL } from '../../common/constants';
import Tour from '../patient/tour';


function clipboardCopy(value) {
     /* Copy the text inside the text field */
    navigator.clipboard.writeText(value.replace(/\s/g, ''));
}


const mapStateToProps = (state) => {
  return {
      user: state.user,
  }
}



class SupportPage extends Component {

  render() {
    const { user } = this.props;

    return (
      <div>
        <PageHeader {...this.props}/>
        <Tour />
        <div className="container">
          <div className="page-container">
            <PatientMenu/>
            <Notification {...this.props}/>
            <div className="page-content-wrapper">
              <div className="page-content" style={{minHeight: 584}}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                            <i className="icon-call-out font-green" />
                            <span className="caption-subject font-green bold uppercase"><FormattedMessage id="support.page.header"/></span>
                            </div>
                        </div>
                        <div className="portlet-body" style={{ "textAlign": "center"}}>
                            {deployedUSA() === true
                                ? (
                                    <div>
                                        <label className="control-label" style={{ fontWeight: '700', fontSize: '14px' }}>
                                            <FormattedMessage id="support.page.contacts.header" />
                                        </label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <a href={`tel:${PREDICT_SUPPORT_PHONE}`}>
                                                    <img src="/img/iconPhone.png" width="100" height="100"/>
                                                </a>
                                                <a href={`tel:${PREDICT_SUPPORT_PHONE}`}
                                                   className="contact_link"
                                                   onClick={() => clipboardCopy(PREDICT_SUPPORT_PHONE)}
                                                   title={this.props.intl.formatMessage({ id: "click.to.copy" })}
                                                >
                                                        {PREDICT_SUPPORT_PHONE}
                                                </a>
                                                <div><i><FormattedMessage id="support.page.toll.free" /></i></div>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={`mailto:${PREDICT_SUPPORT_EMAIL}`}>
                                                    <img src="/img/iconEmail.png" width="100" height="100" />
                                                </a>
                                                <a href={`mailto:${PREDICT_SUPPORT_EMAIL}`}
                                                   className="contact_link"
                                                   onClick={() => clipboardCopy(PREDICT_SUPPORT_EMAIL)}
                                                   title={this.props.intl.formatMessage({ id: "click.to.copy" })}
                                                >
                                                    {PREDICT_SUPPORT_EMAIL}
                                                </a>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row" style={{ alignContent: "center", marginTop: '15px' }}>
                                            <p><FormattedMessage id="support.page.zoom.call.title" /></p>
                                            <a 
                                              href={process.env.CALENDLY_LINK}
                                              target="_black"
                                              rel="noopener noreferrer"
                                              data-toggle="tooltip" 
                                              title={this.props.intl.formatMessage({ id: "support.page.onbording.btn.tooltip" })}
                                              className='btn green'
                                            >
                                            <FormattedMessage id="support.page.onbording.btn" />
                                            </a>
                                        </div>
                                        <hr/>
                                        <div className="row" style={{ alignContent: "center", marginTop: '15px' }}>
                                          <i><FormattedMessage id="support.page.operating.hours" /></i>
                                        </div>
                                    </div>
                                ) : (
                                    <label className="control-label" style={{ fontWeight: '700', fontSize: '14px' }}>
                                        <FormattedMessage id="not.found" />
                                    </label>
                                )
                            }
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PageFooter/>
        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps)(injectIntl(SupportPage));
