import { GET_OFFER, GET_OFFER_ERROR, GET_OFFER_SUCCESS } from "../actions/offer";
import type { QueryState } from "../types/redux";

export type TOffer = {
  offer_id: number;
  private_filename: string | null;
  public_filename: string | null;
  text: string | null;
};

type OfferState = QueryState<TOffer>;

export type OfferAction =
  | { type: typeof GET_OFFER }
  | { type: typeof GET_OFFER_SUCCESS; fields: { offer: TOffer } }
  | { type: typeof GET_OFFER_ERROR; error: Error };

const initialState: OfferState = {
  status: "idle",
  data: null,
  error: null,
};

export function offer(state: OfferState = initialState, action: OfferAction): OfferState {
  switch (action.type) {
    case GET_OFFER:
      return { status: "loading", data: null, error: null };

    case GET_OFFER_SUCCESS:
      return { status: "success", data: action.fields.offer, error: null };

    case GET_OFFER_ERROR:
      return { status: "error", data: null, error: action.error };

    default:
      return state;
  }
}
