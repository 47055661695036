import {API_TOKEN} from '../config';


export function getToken() {
  return (dispatch) => {
    fetch(API_TOKEN, {credentials: 'include'})
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to fetch token');
      }
      return response;
    })
    .then(response => response.json())
    .then(fields => dispatch({type: 'GET_TOKEN_SUCCESS', fields}))
    .catch(() => {});
  }
}
