import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import { connect } from 'react-redux';
import {API_PATIENT_IMAGE} from '../../../config';
import remoteLog from '../../../common/logging';

const mapStateToProps = (state) => {
  return {
    patient: state.patient,
    lang: state.intl
  }
}

const CorrectionPhoto = ({prefix, view}) => {
  if (view) {
    return <a href={prefix + view.linkto} target="_blank"><img src={prefix + view.thumbnail + `?${Number(new Date())}`} width="100%" alt="" /></a>
  } else {return null;}
}

class PatientCorrectionPhoto extends Component {

	componentDidCatch(e) {
    remoteLog(e, 'patient_corretion_photo');
  }

	render () {
		let p = this.props.patient;
    let correction_media = p.media.correction_media;
    let pfx = API_PATIENT_IMAGE(p.patient_id, '');

		return (
			<div>
			{ Object.keys(correction_media).map((num)=>{
				return (
				<div key={num}>
					<h3 className="block"><FormattedMessage id="PHOTO_CORRECTION"/> {num}</h3>
			    <div className="row">
			      <div className="col-md-4">
			        <div className="form-group">
			          <label className="control-label"><FormattedMessage id="PHOTO_PROFILE"/></label>
			          <br />
		            <CorrectionPhoto prefix={pfx} view={correction_media[num].profile}/>
			        </div>
			      </div>
			      <div className="col-md-4">
			        <div className="form-group">
			          <label className="control-label"><FormattedMessage id="PHOTO_SMILE"/></label>
			          <br />
		            <CorrectionPhoto prefix={pfx} view={correction_media[num].full_face_with_smile}/>
			        </div>
			      </div>
			      <div className="col-md-4">
			        <div className="form-group">
			          <label className="control-label"><FormattedMessage id="PHOTO_PORTRATE"/></label>
			          <br />
		            <CorrectionPhoto prefix={pfx} view={correction_media[num].full_face_without_smile}/>
			        </div>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col-md-4">
			        <div className="form-group">
			          <label className="control-label"><FormattedMessage id="PHOTO_UPPER_OCCLUSAL"/></label>
			          <br />
		            <CorrectionPhoto prefix={pfx} view={correction_media[num].occlusal_view_upper}/>
			        </div>
			      </div>
			      <div className="col-md-4" />
			      <div className="col-md-4">
			        <div className="form-group">
			          <label className="control-label"><FormattedMessage id="PHOTO_LOWER_OCCLUSAL"/></label>
			          <br />
		            <CorrectionPhoto prefix={pfx} view={correction_media[num].occlusal_view_lower}/>
			        </div>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col-md-4">
			        <div className="form-group">
			          <label className="control-label"><FormattedMessage id="PHOTO_RIGHT"/></label>
			          <br />
		            <CorrectionPhoto prefix={pfx} view={correction_media[num].lateral_view_right}/>
			        </div>
			      </div>
			      <div className="col-md-4">
			        <div className="form-group">
			          <label className="control-label">
			            <br /><FormattedMessage id="PHOTO_FRONT"/></label>
			          <br />
		            <CorrectionPhoto prefix={pfx} view={correction_media[num].front_view}/>
			        </div>
			      </div>
			      <div className="col-md-4">
			        <div className="form-group">
			          <label className="control-label"><FormattedMessage id="PHOTO_LEFT"/></label>
			          <br />
		            <CorrectionPhoto prefix={pfx} view={correction_media[num].lateral_view_left}/>
			        </div>
			      </div>
			    </div>
			  </div>)
				})
			}
			</div>
		)
	}
}

export default connect(mapStateToProps)(PatientCorrectionPhoto);
