import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { API_PATIENT_IMAGE } from '../../../config';
import {
  getShipment, pickup, DELIVERY_DESTINATION_LABORATORY,
  UPS_TRACK_LOCALE_MAP, trackingLink
} from '../../../actions/deliveries';
import remoteLog from '../../../common/logging';
import FormatDate from '../../common/FormatDate';
import Loader from '../../../components/common/loadingInProgress';


const mapStateToProps = (state) => {
  return {
    lang: state.intl,
    patient: state.patient,
    deliveries: state.delivery.deliveries,
    loading: state.delivery.loading,
  }
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    pickup,
    getShipment,
  }, dispatch);
}


class PatientDelivery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false,
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'patient_delivery');
  }

  getLatestDeliveryForPickup() {
    const { deliveries } = this.props;
    if (deliveries && Array.isArray(deliveries) && deliveries.length) {
      const filtered = deliveries.filter(
        delivery => (
          delivery.track_number && !delivery.prn && !delivery.called_by_phone && delivery.destination === DELIVERY_DESTINATION_LABORATORY
        )
      );
      if (filtered.length) {
        return filtered[0];
      }
    }
    return null;
  }

  getLatestPickedUpDelivery() {
    const { deliveries } = this.props;
    if (deliveries && Array.isArray(deliveries) && deliveries.length) {
      const filtered = deliveries.filter(
        delivery => delivery.track_number && (delivery.prn !== null || delivery.called_by_phone)
          && delivery.destination === DELIVERY_DESTINATION_LABORATORY
      );
      if (filtered.length) {
        return filtered[0];
      }
    }
    return null;
  }

  openModal() {
    this.setState({ isModalOpened: true });
  }

  closeModal() {
    this.setState({ isModalOpened: false });
  }

  callCourierBtn(delivery, pickupDeliveryExists) {
    const {
      patient, getShipment, pickup, loading,
    } = this.props;

    if (delivery !== null) {
      return (
        <div>
          <div className="form-group">
            <div className="form-block">
              <button className="btn green" id="add-comment-btnnn delivery-call-courier-btn" onClick={() => pickup(patient.patient_id, delivery.delivery_id)} disabled={loading}>
                <FormattedMessage id="pat.deliveries.button.callACourier" />
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="form-group">
          <div className="form-block">
            <button className="btn green" id="add-comment-btnnn delivery-get-new-waybill-btn" onClick={() => getShipment(patient.patient_id)} disabled={loading} style={{ whiteSpace: "normal" }}>
              <FormattedMessage id={!pickupDeliveryExists ? "pat.deliveries.button.getNewWaybill" : "pat.deliveries.button.getWaybill"} />
            </button>
          </div>
        </div>
      )
    }
  }

  renderPickedUp(pickedup_delivery) {
    const { patient, lang, intl } = this.props;
    const upsLocale = UPS_TRACK_LOCALE_MAP[lang.locale];

    if (pickedup_delivery) {
      return (
          <div>
            <br />
            <label><strong> <FormattedMessage id="pat.block.deliveries.current.pickups" /> </strong></label><br /><br />
            <label><strong>{pickedup_delivery.track_number}</strong></label>&nbsp;&nbsp;
            <a
              href={trackingLink(pickedup_delivery.track_number, pickedup_delivery.type, upsLocale)}
              rel="noopener noreferrer"
              target="_blank"
            >
                <i
                  className="icon-info font-green"
                  title={intl.formatMessage({ id: "pat.block.deliveries.ups.site.track" })}
                />
            </a><br /><br />
            <a href={`${API_PATIENT_IMAGE(patient.patient_id, pickedup_delivery.filename)}`} rel="noopener noreferrer" target="_blank" id="delivery-print-waybill-link">
              <b id="delivery-print-waybill"><FormattedMessage id="pat.block.deliveries.see.waybill"/></b>
            </a><br /><br />
            {!pickedup_delivery.called_by_phone
              ? (
                <p>
                  <label id="delivery-date-label"><strong><FormattedMessage id="pat.block.deliveries.estimated.pickup_date" /></strong></label>
                  <br /><FormatDate id="delivery-date-value" value={pickedup_delivery.pickup_date} date/>
                </p>
              ) : null
            }
          </div>
      );
    }
    return null;
  }

  renderNoDelivery(pickedup_delivery, canOrderDelivery, shouldFillDeliveryInfo) {
    if (!pickedup_delivery && canOrderDelivery && !shouldFillDeliveryInfo) {
      return <FormattedMessage id="pat.deliveries.button.noWaybills" />;
    } else if (shouldFillDeliveryInfo === true) {
      return <FormattedMessage id="pat.deliveries.contact.to.manager" />;
    }
    return null
  }

  render () {
    const { patient, deliveries, loading } = this.props;
    const delivery = this.getLatestDeliveryForPickup();
    const pickedup_delivery = this.getLatestPickedUpDelivery();
    const pickupDeliveryExists = pickedup_delivery === null;
    const canOrderDelivery = patient.course.correction.slice(-1)[0].order_options.can_order_delivery;
    const shouldFillDeliveryInfo = patient.course.correction.slice(-1)[0].order_options.should_fill_delivery_info;
    const upsPrivilege = patient.course.correction.slice(-1)[0].order_options.ups_deliveries_privilege;

    const showOtherDeliveriesText = deliveries && Array.isArray(deliveries) && deliveries.length > 0;

    if (upsPrivilege) {
      return (
        <div className="portlet light bordered" id="deliveries-side-block">
          {/* delivery !== null ? this.renderModal(delivery) : null */}
          <div className="portlet-title">
            <div className="caption">
              <i className="icon-envelope font-green"></i>
              <NavLink className="open" to={`/pages/patient/${patient.patient_id}/deliveries`} style={{ fontSize: 16 }}>
                <span id="deliveries-side-block-name" className="caption-subject bold uppercase"><FormattedMessage id="pat.block.impression.delivery.header" /></span>
              </NavLink>
            </div>
            </div>
            <div className="portlet-body" id="deliveries-side-block-body">
              { this.renderPickedUp(pickedup_delivery) }
              <div>
                {delivery !== null && canOrderDelivery === true
                  ? (
                    <div>
                      <label id="delivery-tracking-numbe-label">
                        <strong id="delivery-tracking-number-value"> <FormattedMessage id="pat.block.deliveries.waybills" /> </strong>
                      </label><br />
                      {delivery.track_number}<br />
                      <a href={`${API_PATIENT_IMAGE(patient.patient_id, delivery.filename)}`} rel="noopener noreferrer" target="_blank" id="delivery-print-waybill-link" >
                        <span id="delivery-print-waybill"><FormattedMessage id="pat.block.deliveries.see.waybill"/></span>
                      </a>
                    </div>
                  ) : (
                    <p id="no-delivery-data-alert">{this.renderNoDelivery(pickedup_delivery, canOrderDelivery, shouldFillDeliveryInfo)}</p>
                  )
                }
              </div>
            </div>
            <div>
              <br />
              { canOrderDelivery ? this.callCourierBtn(delivery, pickupDeliveryExists) : null }
            </div>
            {loading ? <Loader /> : null}
            <div>
              <br />
              {showOtherDeliveriesText === true
                ? (
                    <NavLink className="open" to={`/pages/patient/${patient.patient_id}/deliveries`}>
                      <span id="deliveries-side-block-other">
                          <i><FormattedMessage id="pat.block.impression.delivery.all" /></i>
                      </span>
                    </NavLink>
                ) : null
              }
            </div>
        </div>
      )
    }
    return null;
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientDelivery));
