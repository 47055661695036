import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {getAllShipments, UPS_TRACK_LOCALE_MAP, trackingLink} from '../../actions/deliveries';
import remoteLog from '../../common/logging';
import PersonName from '../common/PersonName';
import DatePicker from 'react-datepicker';
import {formatDate, DATE_FORMAT} from "../../common/datetime";
import FormatDate from '../common/FormatDate';
import Loader from "../common/loadingInProgress";
import { deployedUSA } from '../../common/utils';


const mapStateToProps = (state) => {
    return {
        user: state.user,
        lang: state.intl,
        shipments: state.shipments
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getShipments: (from, without_tracking_number) => dispatch(getAllShipments(from, without_tracking_number))
    }
}


class ShipmentsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate : null,
            isLoading: false,
            without_tracking_number: deployedUSA() ? true : false
        }
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    componentDidCatch(e) {
        remoteLog(e, 'shipments_page');
    }

    componentDidMount() {
        const { user } = this.props;
        if (deployedUSA()) {
            const without_tracking_number = this.state.without_tracking_number;
            this.props.getShipments(null, without_tracking_number);
        } else {
            this.props.getShipments();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.shipments !== prevProps.shipments){
            this.setState({isLoading: false});
        }
    }

    onChangeDate(e) {
        const without_tracking_number = this.state.without_tracking_number;
        this.setState({isLoading: true});
        const from = e && e._d ? formatDate(e._d)._i : null;
        this.setState({startDate: e && e._d ? formatDate(e._d) : null});
        this.props.getShipments(from, without_tracking_number);
    }

    render() {
        const fm = (id) => this.props.intl.formatMessage({id: id});
        const { isLoading } = this.state;
        const { shipments, lang } = this.props;
        const upsLocale = UPS_TRACK_LOCALE_MAP[lang.locale];

        return (
            <div className="page-content-wrapper">
                <div className="page-content" id="patients-table">
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className="icon-users font-green"/>
                                <span className="caption-subject font-green bold uppercase"
                                      id="patients-table-block-title">
                                   <FormattedMessage id="pat.shipments.header"/>
                                </span>
                            </div>
                        </div>
                        <div><p><FormattedMessage id="pat.shipments.description" /></p></div>
                            <label>
                                <FormattedMessage id="pat.shipments.selected.date"/>
                            </label>
                        <div>
                            <DatePicker
                              className="form-control"
                              dateFormat={DATE_FORMAT}
                              selected={this.state.startDate}
                              onSelect={(e) => this.onChangeDate(e)}
                              placeholderText={fm("date")}
                            />
                        </div>
                        <div className="portlet-body">
                            <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="dt-buttons">
                                        </div>
                                    </div>
                                </div>
                                <div className="table-scrollable">
                                    { !isLoading
                                       ? <table className="table table-bordered table-hover dataTable no-footer"
                                               id="doctor_pacients_table" role="grid">
                                            <thead id="patients-table-head">
                                            <tr role="row">
                                                <th style={{width: 14}}>#</th>
                                                <th style={{width: 111}}><FormattedMessage id="pat_table.name"/></th>
                                                {deployedUSA() === true ? (
                                                    <th style={{width: 150}}><FormattedMessage id="pat.shipments.eta"/></th>
                                                ) : (
                                                    <th style={{width: 150}}><FormattedMessage id="pat.shipments.tracking_number"/></th>
                                                ) }
                                            </tr>
                                            </thead>
                                            <tbody id="patients-table-body">
                                            {shipments.map((p, i) => {
                                                return (
                                                    <tr key={'p' + i}
                                                        className={'clickable-row ' + (i % 2 ? 'even' : 'odd')}
                                                        role="row">
                                                        <td className="sorting_1">{p.patient.patient_id}</td>
                                                        <td><NavLink
                                                            to={`/pages/patient/${p.patient.patient_id}`}><PersonName
                                                            person={p.patient}/></NavLink></td>
                                                        {deployedUSA() === true ? (
                                                            <td>
                                                                {p.tracking_number !== null ? (
                                                                    <a  href={trackingLink(p.tracking_number, p.type, upsLocale)}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank">
                                                                        <FormatDate value={p.pickup_date} date />
                                                                    </a>
                                                                ) : (
                                                                    <FormatDate value={p.pickup_date} date />
                                                                )}
                                                           </td>
                                                        ) : (
                                                            <td>
                                                            <a
                                                               href={trackingLink(p.tracking_number, p.type, upsLocale)}
                                                               rel="noopener noreferrer"
                                                               target="_blank"
                                                            >
                                                                {p.tracking_number}
                                                            </a>
                                                        </td>
                                                        ) }
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                        : <Loader/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShipmentsList));
