import {API_COURSE_INSTALLMENT_GET} from '../config';

export function getCourseInstallment() {
    return (dispatch) => {
      fetch(API_COURSE_INSTALLMENT_GET, {credentials: 'include'})
      .then((response) => {
        if (!response.ok) {
          console.error("Failed loading { GET_COURSE_INSTALLMENT_FAILURE }: " + response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then(
        json => dispatch({type: 'GET_COURSE_INSTALLMENT_SUCCESS', json})
      )
    }
}

export function selectCourseAction(data){
    return {
      type: 'SELECT_COURSE',
      data
    }
}

export function courseSelectedAction(data) {
    return {
      type: 'COURSE_SELECTED_TRIGGER',
      data
    }
}
