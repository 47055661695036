import * as Toggle from "@radix-ui/react-toggle";
import { Decimal } from "decimal.js";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

import { getDoctorsPays, getDoctorsTasks } from "../../actions/dashboard";
import { doctorPaymentBatch } from "../../actions/invoice";
import { S_3D_PLAN_APPROVAL, S_RETAKE_IMPRESSIONS, S_UNFILLED_CASE, S_WAITING_FOR_A_PAYMENT } from "../../common/courses";
import remoteLog from "../../common/logging";
import { deployedRussia, deployedUSA, isDevelopment } from "../../common/utils";
import FormatNumber, { formatPrice } from "../common/FormatNumber";
import Loader from "../common/loadingInProgress";
import PersonName from "../common/PersonName";
import { sendInvoiceRequest } from "../../actions/notifications";


const mapStateToProps = (state) => {
    return {
        user: state.user,
        pays: state.doctorPays,
        tasks: state.doctorTasks,
        invoice: state.invoice,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getPays: () => dispatch(getDoctorsPays()),
        getTasks: () => dispatch(getDoctorsTasks()),
        doctorPaymentBatch: (patients, total, payment_account) => dispatch(doctorPaymentBatch(patients, total, payment_account)),
        sendInvoice: (cases) => dispatch(sendInvoiceRequest(cases))
    }
}


const MenuList = props => {
    return (
        <components.MenuList {...props}>
            {props.children}
        </components.MenuList>
    );
};

const customStyles = {

    control: (styles) => ({
        ...styles,
        width: 200
    }),
    menu: (styles) => {
        return {
            ...styles,
            position: 'unset'
        };
    },
}

const animatedComponents = makeAnimated();

class PatientsPayList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pays: props.pays,
            paymentState: false,
            payment_alert: false,
            sort: {
                order: 'desc',
                name: 'patient_id'
            },
            patients: {},
            total: 0,
            showLoader: false,
            payment_account: false,
            selectedValues: {},
            clicked: false,
            cases: []
        }

        this.togglePayment = this.togglePayment.bind(this);
        this.renderPrice = this.renderPrice.bind(this);
        this.fm = this.fm.bind(this);
        this.statusColor = this.statusColor.bind(this);
        this.selectSendInvoiceBtn = this.selectSendInvoiceBtn.bind(this);
        this.sendInvoicesBtn = this.sendInvoicesBtn.bind(this);
    }


    componentDidCatch(e) {
        remoteLog(e, 'patients_pay_list');
    }

    fm(id) {
        return this.props.intl.formatMessage({id: id})
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if (this.props.user.account_id && !next_props.user.account_id) {
            this.props.history.push('/');
        }
        this.setState({pays: next_props.pays})

        if (deployedRussia() && this.props.intl.locale === 'ru' && (location.protocol === 'https:' || isDevelopment)) {
            if (next_props.invoice.link && next_props.invoice.link.formUrl) {
                window.location.href = next_props.invoice.link.formUrl;
            }
        }

        if (deployedUSA() && this.props.intl.locale === 'en' && location.protocol === 'https:') {
            if (next_props.invoice && next_props.invoice.link && next_props.invoice.link.token && next_props.invoice.link.payment_endpoint) {
                this.acceptHostedAction(next_props.invoice.link.payment_endpoint, next_props.invoice.link.token);
            }
        }

        let arr = {}
        for (let i = 0; i < next_props.pays.length; i++) {
            arr = {...arr, [i]: []}
        }
        this.setState({selectedValues: arr})
    }

    sortedPayments() {
        if (this.state.pays.length < 2) {
            return this.state.pays;
        }

        let fm = (id) => this.props.intl.formatMessage({id: id});
        let sort_order = this.state.sort.order === 'asc' ? 1 : -1;
        return this.state.pays.sort((p1, p2) => {
            if (this.state.sort.name === 'patient_id') {
                return sort_order * (p1.patient_id - p2.patient_id);
            } else if (this.state.sort.name === 'latest_correction') {
                let p1t = fm(p1.status)
                let p2t = fm(p2.status)
                return sort_order * (p1t.localeCompare(p2t));
            } else {
                return sort_order * p1[this.state.sort.name].localeCompare(p2[this.state.sort.name]);
            }
        })
    }

    componentDidMount() {
        const { user } = this.props;
        if (deployedRussia() && this.props.intl.locale !== 'ru') this.props.history.push('/pages/patients');
        this.props.getPays();
        this.props.getTasks();
        window.scrollTo(0, 0)
    }

    statusColor(status) {
        if (status === "S_TREATMENT_FINISHED"
            || status === "S_CANCEL_TREATMENT") {
            return "label label-sm bg-grey"
        } else if (status === S_WAITING_FOR_A_PAYMENT
            || status === S_UNFILLED_CASE
            || status === S_3D_PLAN_APPROVAL
            || status === S_RETAKE_IMPRESSIONS) {
            return "label label-sm bg-red"
        } else {
            return "label label-sm bg-green"
        }
    }

    togglePayment() {
        this.setState({
            paymentState: !this.state.paymentState
        });
    }

    acceptHostedAction(path, token) {
        const form = document.createElement('form');
        form.method = "post";
        form.action = path;

        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = "token";
        hiddenField.value = token;
        form.appendChild(hiddenField);

        document.body.appendChild(form);
        form.submit();
    }

    renderPrice(payments) {
        if (!payments) {
            return '';
        }
        return (
            <div>
                <FormatNumber value={payments.paid}/> {this.fm("pat_table.of")} <FormatNumber value={payments.total}/>
            </div>)
    }

    selectSendInvoiceBtn(value, patient_id) {
        if (value) {
            this.setState(prevState => ({
                cases: [...prevState.cases, patient_id]
            }))
        } else {
            const selectedCases = this.state.cases.filter(item => item !== patient_id)
            this.setState({cases: selectedCases})
        }
    }

    sendInvoicesBtn() {
        const cases = this.state.cases;
        this.props.sendInvoice(cases)
        this.setState({cases: []})
    }

    reformatPaymentsArray(payments, patient_id, course) {
        const {next, remain, extra, cbct} = payments;
        let mainOptions = [];
        let extraOptions = [];
        if (extra) {
            extra.map(x => {
                extraOptions.push({
                    value: x.id,
                    label: `${this.fm(x.tag)} ${formatPrice(x.price)}${this.fm('pat.block.payment.currency.curr')}`,
                    price: x.price,
                    patient_id,
                    id: x.id,
                    type: 'extra'
                })
            })
        }
        if (deployedUSA() && this.props.intl.locale === 'en') {
            if (remain !== 0) {
                mainOptions.push({
                    value: remain,
                    label: `${this.fm(`${course}_USA`)} ${this.fm('pat.block.payment.currency.curr')} ${formatPrice(remain)}`,
                    patient_id,
                    remain,
                    type: 'course',
                    price: remain
                })
            }
        } else if (deployedRussia() && this.props.intl.locale === 'ru') {
            if (next > 0 && remain > 0 && next !== remain) {
                mainOptions.push({
                        value: next,
                        label: `${this.fm(course)} ${formatPrice(next)} ${this.fm('pat.block.payment.currency.curr')}`,
                        next,
                        type: 'course',
                        patient_id,
                        price: next
                    },
                    {
                        value: remain,
                        label: `${this.fm('pat.block.payment.pay.all')} ${formatPrice(remain)} ${this.fm('pat.block.payment.currency.curr')}`,
                        remain,
                        type: 'course',
                        patient_id,
                        price: remain
                    })
            } else if (next === remain && next > 0 && remain > 0) {
                mainOptions.push({
                    value: next,
                    label: `${this.fm(course)} ${formatPrice(next)} ${this.fm('pat.block.payment.currency.curr')}`,
                    next,
                    type: 'course',
                    patient_id,
                    price: next
                })
            }
        }

        if (cbct) {
            mainOptions.map(x => {
                x.cbct = true
            })
        }

        return [
            {
                label: this.fm('pat.block.payment.payments'),
                options: mainOptions
            },
            {
                label: this.fm('pat.block.payment.extras'),
                options: extraOptions
            }
        ]
    }

    formatPayObject(e, pid, i) {
        this.filterSelect(e, i)
        let patientsObj = {};
        let id = pid;
        e.map(x => {
            const {cbct} = x;
            if (x.remain && x.type === 'course') {
                patientsObj[x.patient_id] = {...patientsObj[x.patient_id], ...{'course': {price: x.remain}}}
            } else if (x.next && x.type === 'course' && !patientsObj[x.patient_id]?.course?.price) {
                patientsObj[x.patient_id] = {...patientsObj[x.patient_id], ...{'course': {price: x.next}}}
            }


            if (x.cbct) {
                patientsObj[x.patient_id] = {
                    ...patientsObj[x.patient_id], ...{
                        'course': {
                            ...patientsObj[x.patient_id].course,
                            cbct
                        }
                    }
                }

            }

            if (patientsObj[x.patient_id]?.extra && x.type === 'extra') {
                patientsObj[x.patient_id] ['extra'].push({
                    price: x.price,
                    id: x.id
                })
            } else if (x.type && x.type === 'extra') {
                patientsObj[x.patient_id] = {
                    ...patientsObj[x.patient_id], ...{
                        'extra': [{
                            price: x.price,
                            id: x.id
                        }]
                    }
                }
            }
        })
        if (e.length === 0) {
            delete this.state.patients[id]
        }
        this.setState({patients: {...this.state.patients, ...patientsObj}})
        setTimeout(() => {
            this.setTotal();
        })
    }

    filterSelect(e, i) {
        let obj = {};
        let valuesArr;
        e.forEach(x => {
            if (x.remain) {
                obj = {...obj, remain: x.remain}
            } else if (x.next) {
                obj = {...obj, next: x.next}
            }
        })

        if (obj.remain && !obj.next) {
            valuesArr = e.filter(x => x.remain || x.type === 'extra')
        } else if (obj.next && !obj.remain) {
            valuesArr = e.filter(x => x.next || x.type === 'extra')
        } else {
            valuesArr = e.filter(x => x.type === 'extra')
        }

        let objectArr = this.state.selectedValues
        objectArr[i] = valuesArr

        setTimeout(() => {
            this.setState({selectedValues: {...this.state.selectedValues, ...objectArr}})

        })
    }

    setPatientTotal(id) {
        let pricesArr = [];
        let coursePrice = 0;
        let total;

        if (this.state.patients[id] && this.state.patients[id].course) {
            coursePrice = this.state.patients[id].course.price
        }

        if (this.state.patients[id] && this.state.patients[id].extra) {
            pricesArr = this.state.patients[id].extra.map(x => x.price)
        }

        pricesArr.push(coursePrice)
        total = pricesArr.reduce((acc, curr) => new Decimal(acc).plus(new Decimal(curr))).toString()

        return formatPrice(parseFloat(total));
    }

    setTotal() {
        let patientsArr = Object.values(this.state.patients)
        let coursePrices = [];
        let extraPrices = [];
        let finalArr;
        let total = 0;
        if (patientsArr.length === 0) {
            this.setState({total})
        } else {
            patientsArr.forEach(x => {
                if (x?.course) {
                    coursePrices.push(x.course.price)
                }
                if (x?.extra) {
                    x.extra.map(x => {
                        extraPrices.push(x.price)
                    })

                }
            })

            finalArr = coursePrices.concat(extraPrices)

            if (finalArr.length > 0) {
                total = finalArr.reduce((x, y) => new Decimal(x).plus(new Decimal(y))).toString()
            }
            this.setState({total: total})
        }
    }

    submitPayment() {
        this.setState({showLoader: true})
        this.setState({clicked: true})
        this.props.doctorPaymentBatch(this.state.patients, this.state.total, this.state.payment_account)
    }

    onPaymentAccountChecked() {
        if (!this.state.payment_account) {
            this.setState({payment_account: true})
        } else {
            this.setState({payment_account: false})
        }
    }

    render() {
        const cases = this.state.cases;
        if (window.location.protocol == "https:" || isDevelopment) {
            return (
                <div className="page-content-wrapper">
                    { /* pacient table */}
                    <div className="page-content" id="patients-table">
                        <div className="portlet light bordered">
                            <div className="portlet-title">
                                <div className="caption">
                                    <i className="icon-wallet font-green"/>
                                    <span className="caption-subject font-green bold uppercase"
                                          id="patients-table-block-title">
                                   <FormattedMessage id="pat_table_tasks.block_payments"/>
                                </span>
                                </div>
                            </div>
                            <div className="portlet-body">
                                <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
                                    <div className="tw-flex">
                                        <div className="tw-mr-1">
                                            <Toggle.Root asChild>
                                                <button
                                                  id="test-btn"
                                                  className={this.state.payment_alert ? "btn red" : "btn green"}
                                                  // NOTE: minWidth is set to avoid the button size change on toggle because of the text change
                                                  style={{ marginBottom: 13, minWidth: 152 }}
                                                  onClick={this.togglePayment}
                                                >
                                                    <FormattedMessage id={this.state.paymentState ? "general.payment.close" : "general.payment.show"} />
                                                </button>
                                            </Toggle.Root>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                                            <Toggle.Root asChild>
                                                <button
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Select cases you want to get the invoice for"
                                                  disabled={cases.length === 0}
                                                  id="test-btn"
                                                  className="btn"
                                                  style={{ marginBottom: 13, minWidth: 152 }}
                                                  onClick={this.sendInvoicesBtn}
                                                >
                                                <FormattedMessage id="general.payment.send.invoice" />
                                                </button>
                                            </Toggle.Root>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover dataTable no-footer"
                                               id="doctor_pacients_table" role="grid">
                                            <thead id="patients-table-head">
                                            <tr role="row">
                                                <th style={{width: 14}}> #</th>
                                                <th style={{width: 110}}>
                                                    <FormattedMessage id="pat_table.name"/></th>
                                                    <th style={{width: 120}}>
                                                    Action</th>
                                                <th style={{width: 80}}><FormattedMessage
                                                    id="pat_table.payments"/></th>
                                                <th style={{width: 100}}>
                                                    <FormattedMessage id="pat_table.status"/></th>
                                                <th style={{width: 200}}><FormattedMessage id="pat_table.services"/>
                                                </th>
                                                <th style={{width: 60}}><FormattedMessage id="pat_table.total"/>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody id="patients-table-body">
                                            {this.sortedPayments().map((p, i) => {
                                                    return (
                                                        <tr key={'p' + i}
                                                            className={'clickable-row ' + (i % 2 ? 'even' : 'odd')}
                                                            role="row">
                                                            <td className="sorting_1">{p.patient_id}</td>
                                                            <td><NavLink
                                                                to={`/pages/patient/${p.patient_id}`}><PersonName
                                                                person={p}/></NavLink>
                                                                <span className="tw-text-[12px] tw-block"><FormattedMessage id={`payment.${p.payment_method}`}/></span>
                                                                </td>
                                                                <td>
                                                                    <div className="checkbox tw-text-[12px]"
                                                                        style={{padding: "0px 0px 0px 25px", margin: "0 0 0 0"}}>
                                                                        <input type="checkbox" id="payment_invoice" name="payment_invoice"
                                                                            value="payment_invoice"
                                                                            onClick={(e) => this.selectSendInvoiceBtn(e.target.checked, p.patient_id)}
                                                                            checked={cases.includes(p.patient_id)}
                                                                             />
                                                                        <label htmlFor="payment_invoice">Send invoice</label>
                                                                    </div>
                                                                </td>
                                                            <td>{this.state.paymentState ? this.renderPrice(p.total_payments) : ''}</td>
                                                            <td><span className={this.statusColor(p.status)}>
                                                        {p.status &&
                                                        <FormattedMessage id={p.status}/>}</span></td>
                                                            <td data-matomo-mask data-hj-suppress>
                                                                <Select
                                                                    styles={customStyles}
                                                                    closeMenuOnSelect={false}
                                                                    components={{animatedComponents, MenuList}}
                                                                    isMulti
                                                                    options={this.reformatPaymentsArray(p.payments, p.patient_id, p.course)}
                                                                    onChange={(e) => this.formatPayObject(e, p.patient_id, i)}
                                                                    placeholder={this.fm('select.option')}
                                                                    value={this.state.selectedValues[i]}
                                                                />
                                                            </td>
                                                            <td data-matomo-mask data-hj-suppress>{this.setPatientTotal(p.patient_id)}</td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <br/>
                                    {deployedUSA() ?
                                        (<div><b><FormattedMessage
                                            id="payments.doctor.total"/></b> <FormattedMessage
                                            id="pat.block.payment.currency.curr"/> {formatPrice(parseFloat(this.state.total))}
                                        </div>)
                                        : (<div><b><FormattedMessage
                                            id="payments.doctor.total"/></b> {formatPrice(parseFloat(this.state.total))}&nbsp;
                                            <FormattedMessage id="pat.block.payment.currency.curr"/>
                                        </div>)
                                    }
                                    <br/>
                                    {deployedUSA() && !this.props.user.payment_account ?
                                        <div className="checkbox"
                                             style={{padding: "0px 0 0px 20px", margin: "0 0 0 0"}}>
                                            <input type="checkbox" id="payment_account" name="payment_account"
                                                   value="payment_account"
                                                   checked={this.state.payment_account}
                                                   onClick={() => this.onPaymentAccountChecked()}/>
                                            <label htmlFor="payment_account">Save my payment information</label>
                                        </div>
                                        : null
                                    }
                                    <br/>
                                    <div>
                                        <button className="btn blue"
                                                disabled={this.state.total <= 0 || this.state.clicked}
                                                onClick={() => this.submitPayment()}>
                                            <FormattedMessage id="pat.payments.buttons.pay"/>
                                        </button>
                                    </div>
                                    {this.state.showLoader ?
                                        <Loader msg={this.fm("pat.payments.you.will.be.redirected")}/> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    { /* pacient table */}
                </div>
            )
        }
        return null
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientsPayList));
