import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import remoteLog from '../common/logging';


class ProbablyFormattedMessage extends Component {
  componentDidCatch(e) {
    remoteLog(e, 'probably_formatted_message');
  }

	render() {
		if(this.props.id) {
			return (
				<FormattedMessage id={ this.props.id }/>
			)
		} else {
			return (<FormattedMessage id="None"/>)
		}
	}
}

export default ProbablyFormattedMessage;
