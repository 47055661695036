import { invoiceNotification } from "../components/notifications/invoice_notification";
import { paymentReminder } from "../components/notifications/payment_reminder";
import { API_NOTIFICATIONS, API_NOTIFICATIONS_CLERK_INVOICE } from "../config";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { TNotification } from "../reducers/login";
import type { AppDispatch, RootState } from "../store";
import { addUserActionNotification } from "./user_notification";

export function getNotificationsAction(notifications: TNotification[]) {
  return { type: "GET_NOTIFICATIONS", notifications } as const;
}

export function notificationWasShownAction(bool: boolean) {
  return { type: "NOTIFICATIONS_WAS_SHOWN", notificationWasShown: bool } as const;
}

export const PAYMENT_REMINDER_NOTIFICATION: TUserActionNotification = {
  message: "pat.payments.invoice",
  level: "success",
  position: "tl",
  autoDismiss: 0,
  children: paymentReminder(),
};

export function getNotifications() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    fetch(API_NOTIFICATIONS, { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to fetch notifications");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        const { notifications } = json as { notifications: TNotification[] };
        const { user } = getState();

        if (
          user.has_signed_offer == true &&
          notifications.some((n) => n.type == "waiting_for_payment")
        ) {
          dispatch(addUserActionNotification(PAYMENT_REMINDER_NOTIFICATION));
        }

        dispatch(getNotificationsAction(notifications));
      })
      .catch(() => {
        dispatch(getNotificationsAction([]));
      });
  };
}

export function sendInvoiceRequest(body) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "pat.payments.invoice",
    level: "success",
    position: "tc",
    autoDismiss: 0,
    children: invoiceNotification(),
  };

  return (dispatch: AppDispatch) => {
    fetch(API_NOTIFICATIONS_CLERK_INVOICE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({"cases": [...body]}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to fetch smiles");
        }
        return response;
      })
      .then(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .catch(() => {
        dispatch(getNotificationsAction([]));
      });
  };
}
