import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import { deployedUSA } from '../../common/utils';
import PersonName from '../common/PersonName';


export const saveDraftPatientNotification = () => {
    return (
      <div>
          <SubmitNotificationComponent />
      </div>
    )
}

const mapStateToProps = (state) => {
    return {
        instructions: state.instructions,
        lang: state.intl,
    }
}


export class saveDraftNotification extends Component {

    render() {

        return (
            <div style={{borderRadius: '8px !important', visibility: 'visible'}} className="wpb-popup-wr">
            <div className="wpb-popup wpb-popup--small">
                <a style={{width: "10px", height: "10px", top: "4px", right: "8px", opacity: "0.8"}} href="#" className="wpb-popup__close"></a>
                <div className="wpb-popup__body">
                    <FormattedMessage id="notify.patient.create.draft.patient" />
                    <PersonName person={this.props.instructions} />
                    <FormattedMessage id="notify.patient.create.draft.ok" />
                </div>
            </div>
        </div>
        )
    }
}


const SubmitNotificationComponent = connect(mapStateToProps)(saveDraftNotification);

