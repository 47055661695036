import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import { deployedUSA } from '../../common/utils';
import { getTour } from '../../actions/get_tour';
import tourIcon from '../../assets/img/tour_icon.svg';


const mapStateToProps = (state) => {
    return {
        user: state.user,
        tour: state.tour
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTour: (data) => dispatch(getTour(data))
    }
}


const TourBtn = (props) => {
    if (!isMobile && deployedUSA() === true) {
        return (
            <button 
              data-track-content 
              data-content-name="call tour btn" 
              style={props.user.account_id === undefined ? {visibility: 'hidden'} : {}} 
              className="btn btn_tour" 
              onClick={(e) => {
                props.getTour(!props.tour)
                document.body.style.overflow = "hidden";
                }}>
              <img src={tourIcon} />
            </button>
        )
    } else {
        return null
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TourBtn);

