import React from 'react';
import { FormattedMessage } from "react-intl";

import popUp from "../../assets/img/wpb-popup-ico-1.png";


export const submitPatientNotification = () => {
    return (
      <div>
          <SubmitNotificationComponent />
      </div>
    )
}


export const submitNotification = () => {
    return (
      <div style={{borderRadius: "15px !important", visibility: 'visible'}} className="wpb-popup-wr">
          <div className="wpb-popup wpb-popup--small">
              <a 
                style={{width: "10px", height: "10px", top: "4px", right: "8px", opacity: "0.8"}} 
                href="#" 
                className="wpb-popup__close">
              </a>
              <div className="wpb-popup__ico">
                  <img src={popUp} width="55" height="70" alt=""/>
              </div>
              <div className="wpb-popup__body">
                  <FormattedMessage id="notify.patient.create.ok" />
              </div>
              <div className="wpb-popup__btn-ok">
                  <button><FormattedMessage id="notify.ok" /></button>
              </div>
          </div>
      </div>
    )
}


const SubmitNotificationComponent = submitNotification;