import type { TCourse, TPatient } from "../reducers/dashboard";
import { deployedRussia, deployedUSA } from "./utils";

export const COURSE_VERSION_V1 = 1;
export const COURSE_VERSION_V2 = 2;

export const CASE_FULL = 1;
export const CASE_SHORT = 2;
export const CASE_SINGLE_ARCH = 3;
export const CASE_SUPER_SHORT = 4;
export const CASE_CBCT = 5;
export const CASE_PLAN_3D = 6;
export const CASE_CHILDREN = 7;
export const CASE_RETAINERS = 8;
export const CASE_PRO = 9;
export const CASE_TEEN = 10;

export const CO_TAG_FULL_3_STEPS_CBCT = "FULL_3_STEPS_CBCT";
export const CO_TAG_TEEN_3_STEPS_CBCT = "TEEN_3_STEPS_CBCT";

const SMILE_COURSES = {
  FULL: 1,
  SHORT: 2,
  SINGLE_ARCH: 3,
  SUPER_SHORT: 4,
  CBCT: 5,
  PLAN_3D: 6,
  CHILDREN: 7,
  RETAINERS: 8,
  PRO: 9,
  TEEN: 10,
};

const PREDICT_COURSES = {
  FULL: 1,
  SHORT: 2,
  SINGLE_ARCH: 3,
  SUPER_SHORT: 4,
  CBCT: 5,
  PLAN_3D: 6,
  RETAINERS: 8,
};

// Alert constants
export const S_WAITING_FOR_A_PAYMENT = "S_WAITING_FOR_A_PAYMENT";
export const S_UNFILLED_CASE = "S_UNFILLED_CASE";
export const S_RETAKE_IMPRESSIONS = "S_RETAKE_IMPRESSIONS";
export const S_3D_PLAN_APPROVAL = "S_3D_PLAN_APPROVAL";

export function isRetainersCourse(course_id: TCourse["course_id"]) {
  return deployedUSA() ? PREDICT_COURSES["RETAINERS"] == course_id : SMILE_COURSES["RETAINERS"] == course_id;
}

export function isChildrenCourse(course_id: TCourse["course_id"]) {
  return deployedRussia() ? SMILE_COURSES["CHILDREN"] == course_id : false;
}

export function isPlanCourse(course_id: TCourse["course_id"]) {
  return deployedRussia() ? SMILE_COURSES["PLAN_3D"] == course_id : PREDICT_COURSES["PLAN_3D"] == course_id;
}

export function isCBCTCourse(course_id: TCourse["course_id"]) {
  return deployedUSA() ? PREDICT_COURSES["CBCT"] == course_id : SMILE_COURSES["CBCT"] == course_id;
}

export function isFullCourse(course_id: TCourse["course_id"]) {
  return deployedUSA() ? PREDICT_COURSES["FULL"] == course_id : SMILE_COURSES["FULL"] == course_id;
}

export function isShortCourse(course_id: TCourse["course_id"]) {
  return deployedUSA() ? PREDICT_COURSES["SHORT"] == course_id : SMILE_COURSES["SHORT"] == course_id;
}

export function isSingleArchCourse(course_id: TCourse["course_id"]) {
  return deployedUSA() ? PREDICT_COURSES["SINGLE_ARCH"] == course_id : SMILE_COURSES["SINGLE_ARCH"] == course_id;
}

export function isSuperShortCourse(course_id: TCourse["course_id"]) {
  return deployedUSA() ? PREDICT_COURSES["SUPER_SHORT"] == course_id : SMILE_COURSES["SUPER_SHORT"] == course_id;
}

export function is3DPROCourse(course_id: TCourse["course_id"]) {
  return deployedUSA() ? false : SMILE_COURSES["PRO"] == course_id;
}

export function isTeenCourse(course_id: TCourse["course_id"]) {
  return deployedUSA() ? false : SMILE_COURSES["TEEN"] == course_id;
}

export function hasDeepCBCT(patient: TPatient) {
  const isDeepCBCTService = patient.deep_cbct == true;
  return isCBCTCourse(patient.course.course_id) || isDeepCBCTService;
}
