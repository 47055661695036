import { API_POST_ADDITIONAL_ALIGNERS } from "../config";
import { addUserActionNotification } from "./user_notification";
import { rushAdditionalService } from "./post_patient";
import { getExtraServices } from "./extra_services";

export function orderAdditionalAligners(
  patient_id,
  stage,
  amount,
  treat_arch_id,
  elastics_left,
  elastics_right,
  paymentMethod,
  rush,
) {
  const NOTIFICATION_MSG_OK = {
    message: "notify.additional.aligners.ok",
    level: "success",
    position: "tl",
    autoDismiss: "7",
  };

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.additional.aligners.error",
    level: "error",
    position: "tl",
    autoDismiss: "7",
  };

  return (dispatch) => {
    fetch(API_POST_ADDITIONAL_ALIGNERS(patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stage: stage,
        amount: amount,
        treat_arch_id: treat_arch_id,
        elastics_left: elastics_left,
        elastics_right: elastics_right,
      }),
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to order additional aligners!");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        if (rush) {
          dispatch(rushAdditionalService(patient_id, json.id));
        } else {
          dispatch(getExtraServices(patient_id));
        }
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}
