import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, FormSection, getFormValues } from 'redux-form';
import { FormattedMessage } from "react-intl";

import { convertToDentalNotation } from '../../common/utils';


const mapStateToProps = (state) => {
	return {
		user: state.user,
		patient: state.patient,
		formValues: getFormValues('correction')(state)
	}
};


class PlanCorrectionButtonsHooksLeft extends Component {
	constructor(props) {
		super(props);
		let quads = [1, 2, 4, 3];
		this.teeth = quads.map((quad) => {
			let arr = [];
			for (let i = 1; i <= 8; i++) {
				arr.push(quad*10 + i);
			}
			if (quad === 1 || quad === 4) arr.reverse();
			return arr;
		});
		this.state = {
			name: null,
			isChecked: false
		}
		this.setClassName = this.setClassName.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}


	handleChange(e, i) {
		if (e.target.checked === true) {
			this.setState({name: e.target.name, isChecked: true})
		}	else if (e.target.checked === false) {
			this.setState({name: null, isChecked: !this.state.isChecked})
			this.props.resetForm(`elastics_options.${i}`)
		}
	}

	setClassName(i) {
		const elastics_options = this.props.patient.course && this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription
			? this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription.elastics_options
			: null
		if ( this.props.formValues.elastics_options &&  this.props.formValues.elastics_options[i] === '2' ) {
			return "button_hook_tip button"
		} else if (this.props.formValues.elastics_options && this.props.formValues.elastics_options[i] === '1' ) {
			return "button_hook_tip hook"
		} else if ( this.props.formValues.elastics_options && this.props.formValues.elastics_options[i] === 2
			&& elastics_options && elastics_options[i] === 2 ) {
			return "button_hook_tip button"
		} else if (this.props.formValues.elastics_options && this.props.formValues.elastics_options[i] === 1
			&& elastics_options && elastics_options[i] === 1) {
			return "button_hook_tip hook"
		} else if (!this.props.elastics_recoil_left) {
			return "button_hook_tip input_button elastic_disabled"
		} else {
			return "button_hook_tip input_button"
		}
	}

	render(i) {

		let allTeeth = this.teeth.map(this.renderQuadrant.bind(this));
		let upperTeeth = allTeeth.slice(1, 2);
		let lowerTeeth = allTeeth.slice(3, 4);

		return (
			<div >
					<div style={{marginBottom: "0.5rem"}} className="teeth-controls-upper checkbox-list">
						{ upperTeeth }
					</div>
					<div className="teeth-controls-lower checkbox-list">
						{  lowerTeeth }
					</div>
			</div>
		)
	}

	renderQuadrant(quad, i) {
		const {arch} = this.props;

		let isHidden = false;
		if (i <= 1 && arch === 3) isHidden = true;
		if (i >= 2 && arch === 2) isHidden = true;

		let teeth = quad.map(this.renderTooth.bind(this));
		const isHiddenClass = isHidden ? "teeth-controls--hidden" : "";

		return (
			<div key={i} style={{borderRight: "none"}} className={`checkbox-list teeth-controls ${isHiddenClass}`}>
				{teeth}
			</div>
		)
	}

	renderTooth(i) {
		const { name, isChecked } = this.state
		let { user, defaultValue, dentalNotation, elastics_recoil_left } = this.props;
		if (!dentalNotation && user.preferences) dentalNotation = user.preferences.dental_notation;

		if (!defaultValue) defaultValue = [];
		if (!Array.isArray(defaultValue)) {
			console.error(`Assertion error: ${this.constructor.name}.value should be only array!`);
		};

		return (
			<label key={i}>
				{convertToDentalNotation(i, dentalNotation)}
				<div className={ this.setClassName(i) }>
					{ this.props.formValues.elastics_options && this.props.formValues.elastics_options[i] === 2 ||
					this.props.formValues.elastics_options && this.props.formValues.elastics_options[i] === 1 ||
					this.props.formValues.elastics_options && this.props.formValues.elastics_options[i] === '2' ||
					this.props.formValues.elastics_options && this.props.formValues.elastics_options[i] === '1'
						? <FormattedMessage id="CANCEL" tagName="span" />
						: null
					}
					<input
						disabled={elastics_recoil_left ? false : true}
						type="checkbox"
						name={`options_${i}`}
						onChange={ e => this.handleChange(e, i) }
						defaultChecked={defaultValue ? defaultValue.includes(i) : []}
					/>
				</div>
				{ name === `options_${i}` && isChecked && elastics_recoil_left ?
					<FormSection name="elastics_options" className="button_hook" >
						<div>
							<Field
								component="input"
								name={`${i}`}
								type="radio"
								id={`button${i}`}
								value={2}
								onChange={e => this.setState({name: e.target.name})}
							/>&nbsp;<label htmlFor={`button${i}`}><FormattedMessage id="BUTTON" tagName="span"/></label>
						</div>
						<div>
							<Field
								component="input"
								name={`${i}`}
								type="radio"
								id={`hook${i}`}
								value={1}
								onChange={e => this.setState({name: e.target.name})}
							/>&nbsp;<label htmlFor={`hook${i}`} ><FormattedMessage id="HOOK" tagName="span"/></label>
						</div>
					</FormSection>
					: null }
			</label>
		)
	}
};


export default connect(mapStateToProps, null)(PlanCorrectionButtonsHooksLeft);
