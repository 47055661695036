import React from 'react';
import { FormattedMessage } from 'react-intl';

const  ClinicInput = ({input, meta, message, ...props}) => {
  const error = meta.touched && meta.error
    if (props.type === 'textarea') {
        return (
            <div>
                <textarea {...input} {...props}/>
            </div>
        );
    }
        return (
            <div className={ error && message ? 'has-error' : ''}>
                <input {...input} {...props}/>
                {
                  error
                    ? <span>
                        {message && <FormattedMessage id={message} /> }
                      </span>
                    : null
                }
            </div>
        );
};

export default ClinicInput;