import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import FileUpload from './../../file_upload';
import remoteLog from '../../../common/logging';


const formatFileName = (filename) => {
  let fileName = filename.split("/");
  fileName = fileName[fileName.length - 1];
  return fileName
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    images: state.images,
  }
}


class PatientUpdateInstructionsNewMedia extends Component {
	constructor(props) {
    super(props);    
    this.state = {
        files_counter: 1,
        has_medit_files: props.has_medit_files || false
    };
    this.addFileUpload = this.addFileUpload.bind(this);
    this.renders = [ (<FileUpload number={0} patient_id={0} onFileUploadEnd={(data)=> this.addFileUpload(data)} key={`recipe_${0}`} id={`recipe_${0}`} semantics="recipe"/>) ];
  }


  componentDidCatch(e) {
    remoteLog(e, 'patient_update_instructions_new_media');
  }

  addFileUpload(file) {
    const next = this.renders.length;
    this.renders.push(<FileUpload number={next} patient_id={0} onFileUploadEnd={(data)=> this.addFileUpload(data)} key={`recipe_${next}`} id={`recipe_${next}`} semantics="recipe"/>);
    this.forceUpdate();
  }


  render() {
    const filesUploaded = this.props.patient.media && this.props.patient.media.optional_media.length > 0 && this.props.showUploadedFiles;
    const pmo = filesUploaded ? this.props.patient.media.optional_media : [];
    return (
      <div className="form-group">
          <div data-matomo-mask data-hj-suppress className="form-body" id="extra-media-block">
            <h4 className="block" id="instruction-files" style={{ fontWeight: '900' }}><FormattedMessage id="UPLOAD_FILES"/></h4>
          </div>
          <div className="row">
                <div className="col-xs-4">
                    {this.renders}
                </div>
                {filesUploaded ? (
                    <div data-matomo-mask data-hj-suppress style={{marginTop: "12px"}} className="col-xs-12 col-sm-12 col-md-12">
                        <h6 style={{ fontWeight: 900 }}><FormattedMessage id="CBCT_UPLOADED_HEADER" /></h6>
                        <table 
                          role="presentation" 
                          className="table table-striped clearfix"
                          style={{ wordBreak: 'break-all' }} cellSpacing={0} cellPadding={0}
                        >
                            <tbody className="files">
                              {this.state.has_medit_files === true ? (
                                <tr><td><p>{<FormattedMessage id="th.medit.upload_success" />}</p></td></tr>
                              ) : null}
                                {pmo.map((m) => (
                                    <tr key={m.linkto} className="template-download fade in">
                                      <td><p>{formatFileName(m.linkto)}</p></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}
          </div>
      </div>
    )
	}
}

export default connect(mapStateToProps)(PatientUpdateInstructionsNewMedia);
