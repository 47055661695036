import React from 'react';
import { connect } from 'react-redux';

import PatientMenu from '../patient_menu';
import PageFooter from '../page_footer';
import PageHeader from '../page_header';
import Notification from '../notification';
import ShipmentsList from './shipments';
import Tour from '../patient/tour';


const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}


const ShipmentsPageBody = (props) => (
  <div>
    <PageHeader {...props}/>
    <Tour />
    <div className="container">
      <div className="page-container">
        <PatientMenu {...props}/>
        <Notification {...props}/>
        <ShipmentsList {...props}/>
      </div>
      <PageFooter {...props}/>
    </div>
  </div>
);


export default connect(mapStateToProps)(ShipmentsPageBody);
