import React from 'react'
import { Prompt, Redirect } from 'react-router-dom'

import Modal from './modal';


class RouteLeavingGuard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
            location: null
        }

        this.handleConfirmNavigationClick = this.handleConfirmNavigationClick.bind(this);
        this.handleBlockedNavigation = this.handleBlockedNavigation.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }


    showModal = (location) => this.setState({
        modalVisible: true,
        lastLocation: location
    })

    closeModal = (callback) => this.setState({
        modalVisible: false
    }, callback)

    handleBlockedNavigation = (nextLocation) => {
        
        const {shouldBlockNavigation, currentPath} = this.props
        if ( shouldBlockNavigation()){
            if (currentPath !== nextLocation.pathname) {
                this.setState({ location: nextLocation})
                window.history.forward();
            }
            this.setState({modalVisible: true})
            this.showModal(nextLocation)
            return false
        }
        return true
    }

    handleConfirmNavigationClick = () => this.closeModal(() => {
        const { lastLocation } = this.state;
        if (lastLocation) {  
            this.setState({
                confirmedNavigation: true
             })
        }
    })


    render() {
        const { when, showRequiredFieldsNote } = this.props
        const { modalVisible, confirmedNavigation, location } = this.state

        return (
            <div>
                { !confirmedNavigation ?
                    (
                        <div>
                            <Prompt
                                when={when}
                                message={this.handleBlockedNavigation}
                            />
                            <Modal
                                visible={modalVisible}
                                onCancel={this.closeModal}
                                saveChanges={this.props.saveChanges}
                                onConfirm={this.handleConfirmNavigationClick}
                                showRequiredFieldsNote={showRequiredFieldsNote}
                                saveAsADraft={this.props.saveAsADraft}
                            />
                        </div>
                    ) : <Redirect to={location.pathname} />
                }
            </div>
        )
    }
}


export default RouteLeavingGuard;

