import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { setAddress, getClinics } from '../../actions/clinics';
import remoteLog from '../../common/logging';
import Loader from "../common/loadingInProgress";


const mapStateToProps = (state) => {
  return {
    user: state.user,
    clinics: state.clinics,
    spinner: state.spinner
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getClinics: () => dispatch(getClinics())
  }
}


class ClinicsList extends Component {

  componentDidMount(){
    const { user } = this.props;
    window.scrollTo(0, 0)
    this.props.getClinics()
  }

  componentDidCatch(e) {
    remoteLog(e, 'clinic_list');
  }

  render() {
    const { clinics, spinner } = this.props;
    return (
      <div className="page-content-wrapper">
        <div className="page-content" style={ {  minHeight: 584} }>
          {spinner === false
            ? (
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption">
                    <i className="icon-layers font-green" />
                    <span className="caption-subject font-green bold uppercase">
                      <FormattedMessage id="clin_table.header" />
                    </span>
                  </div>
                </div>
                {clinics.length > 0
                  ? (
                        <div className="portlet-body">
                          <div className="table-scrollable">
                            <table className="table table-bordered table-hover" id="doctor-clinics">
                              <thead>
                                <tr>
                                  <th> # </th>
                                  <th><FormattedMessage id="clin_table.name" /></th>
                                  <th><FormattedMessage id="clin_table.address" /></th>
                                  <th><FormattedMessage id="clin_table.phone" /></th>
                                  <th><FormattedMessage id="clin_table.email" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {clinics.map((c, i) =>
                                  <tr key={c.clinic_id}>
                                    <td> {i + 1} </td>
                                    <td> <NavLink to={`/pages/doctor/clinics/${c.clinic_id}`}>{c.title}</NavLink></td>
                                    <td> {setAddress(c)} </td>
                                    <td> {c.phone} </td>
                                    <td> {c.email} </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                    ) : <FormattedMessage id="clinics_not_found"/>
                }
              </div>
            ) : <Loader/>
          }
        </div>
      </div>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ClinicsList);
