import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";


const cardBodyStyle = {
    'maxWidth': '200px',
    'backgroundColor': 'rgb(242 246 249)',
    'allignItems': 'center',
    'textAlign': 'center',
    'borderStyle':'solid 0.5px',
    'marginBottom': '10px',
    'padding': '10px',
    'boxSizing': 'borderBox'
}

const linkStyle = {
    'border': 'none',
    'backgroundColor': 'transparent'
}

const imgStyle = {
    'width': '130px',
    'height': '130px'
}


class IntegrationCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className="card-body">
              <div id="integration-card-body" style={cardBodyStyle} className={this.props.hoverTop}>
                  <button onClick={this.props.onClick} id="integration-card-link" style={linkStyle}>
                      <div className="pic" id="integration-card-img">
                        <img src={this.props.img} style={this.props.imgStyle ? this.props.imgStyle : imgStyle}/>
                      </div>
                      <div className="text" id="integration-card-text">
                          <FormattedMessage id={this.props.title}/>
                      </div>
                      {this.props.itero_phone 
                      ? (
                        <div>
                          <span style={{fontWeight: "900"}}>
                            <FormattedMessage values={this.props.values} id={this.props.itero_phone} />
                          </span>
                            <FormattedMessage id={this.props.itero_link_title} values={this.props.values} />
                          <span style={{fontWeight: "900"}}>
                            <FormattedMessage id={this.props.itero_id} />
                          </span>
                        </div>
                        ) 
                      : null 
                      }
                  </button>
              </div>
              <div>
                  <div className={this.props.hoverBottom} style={cardBodyStyle}>
                      {this.props.link !== null
                      ? (
                          <a target="_blank" rel="noopener noreferrer" href={this.props.link}
                             style={{textDecoration: 'none', color: '#33485e'}}>
                              <FormattedMessage id={this.props.link_title} />
                          </a>
                        )
                      : (
                          <button style={linkStyle} >
                              <FormattedMessage id={this.props.link_title} />
                          </button>
                        )
                      }
                  </div>
              </div>
          </div>
        );
    }
}


export default IntegrationCard;
