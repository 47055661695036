import {
  API_DELIVERIES,
  API_DELIVERY_SHIPMENT,
  API_DELIVERY_PICKUP,
  API_DELIVERY_PICKUP_BY_PHONE,
  API_SHIPMENTS,
} from '../config';
import { getPatientId } from './get_patient_id';
import { addUserActionNotification } from './user_notification';

export const DELIVERY_DESTINATION_LABORATORY = 'laboratory';
export const DELIVERY_DESTINATION_CLINIC = 'clinic';
export const COURIER_PHONE_NUMBER = '+7 (495) 961-22-11';

export const GET_DELIVERIES = 'GET_DELIVERIES';
export const GET_DELIVERIES_ERROR = 'GET_DELIVERIES_ERROR';
export const GET_DELIVERIES_SUCCESS = 'GET_DELIVERIES_SUCCESS';
export const UPDATE_DELIVERY_INFO = 'UPDATE_DELIVERY_INFO';
export const CREATE_SHIPMENT = 'CREATE_SHIPMENT';
export const CREATE_SHIPMENT_ERROR = 'CREATE_SHIPMENT_ERROR';
export const CREATE_SHIPMENT_SUCCESS = 'CREATE_SHIPMENT_SUCCESS';
export const PICKUP = 'PICKUP';
export const PICKUP_ERROR = 'PICKUP_BY_ERROR';
export const PICKUP_SUCCESS = 'PICKUP_BY_SUCCESS';

export const UPS_TRACK_LOCALE_MAP = {
  ru: "ru_RU",
  en: "en_US"
}

const E_INVALID_FROM = "E_INVALID_FROM"
const E_UNHANDLED_ERROR = "E_UNHANDLED_ERROR"
const E_SERVICE_UNAVAILABLE = "E_SERVICE_UNAVAILABLE"

const E_HANDLED_ERRORS = [
  E_INVALID_FROM,
  E_UNHANDLED_ERROR,
  E_SERVICE_UNAVAILABLE,
];

export const SERVICE_UPS = 'UPS';
export const SERVICE_ME = 'Major Express';


export function trackingLink(tracking, _type, locale = null) {
  let link = null;
  if (_type == SERVICE_UPS) {
    link = `https://www.ups.com/track?loc=${locale}&tracknum=${tracking}&requester=WT/`
  } else if (_type == SERVICE_ME) {
    link = `https://www.major-express.ru/trace.aspx?wbnumber=${tracking}&product=1&type=1`
  }
  return link;
}



export function getDeliveries(patient_id) {
  return (dispatch) => {
    dispatch({ type: GET_DELIVERIES });
    fetch(API_DELIVERIES(patient_id), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to fetch deliveries');
      }
      return response;
    })
    .then(response => response.json())
    .then(fields => dispatch({ type: GET_DELIVERIES_SUCCESS, fields }))
    .catch(e => dispatch({ type: GET_DELIVERIES_ERROR, e }));
  }
}

export function getShipment(patient_id) {
  
  const NOTIFICATION_MSG_ERROR = {
    message: "notify.doctor.update.error",
    level: 'error',
    position: 'tl',
    autoDismiss: '4',
  }

  return (dispatch) => {
    dispatch({ type: CREATE_SHIPMENT });
    fetch(API_DELIVERY_SHIPMENT, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ patient_id })
    })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    })
    .then((response => response.json()))
    .then((fields) => {
      dispatch({ type: CREATE_SHIPMENT_SUCCESS, fields });
    })
    .catch(error => {
      error.json().then((e) => {
        const message = E_HANDLED_ERRORS.includes(e.error)
          ? `pat.deliveries.error.${e.error}`
          : `pat.deliveries.error.${E_SERVICE_UNAVAILABLE}`;
        dispatch(addUserActionNotification({ ...NOTIFICATION_MSG_ERROR, message }));
      })
    });
  }
}

export function pickupByPhone(patient_id, delivery_id) {

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.doctor.update.error",
    level: 'error',
    position: 'tl',
    autoDismiss: '4',
  }

  return (dispatch) => {
    dispatch({ type: PICKUP });
    fetch(API_DELIVERY_PICKUP_BY_PHONE, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ patient_id, delivery_id })
    })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    })
    .then(response => response.json())
    .then((fields) => {
      dispatch({ type: PICKUP_SUCCESS });
      dispatch(getPatientId(patient_id));
    })
    .catch(error => {
      error.json().then((e) => {
        const message = E_HANDLED_ERRORS.includes(e.error)
          ? `pat.deliveries.error.${e.error}`
          : `pat.deliveries.error.${E_SERVICE_UNAVAILABLE}`;
        dispatch(addUserActionNotification({ ...NOTIFICATION_MSG_ERROR, message }));
      })
    });
  }
}

export function pickup(patient_id, delivery_id) {

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.doctor.update.error",
    level: 'error',
    position: 'tl',
    autoDismiss: '4',
  }

  return (dispatch) => {
    dispatch({ type: PICKUP });
    fetch(API_DELIVERY_PICKUP, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ patient_id, delivery_id })
    })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    })
    .then(response => response.json())
    .then((fields) => {
      dispatch({ type: PICKUP_SUCCESS });
      dispatch(getPatientId(patient_id, true));
    })
    .catch(error => {
      error.json().then((e) => {
        const message = E_HANDLED_ERRORS.includes(e.error)
          ? `pat.deliveries.error.${e.error}`
          : `pat.deliveries.error.${E_SERVICE_UNAVAILABLE}`;
        dispatch(addUserActionNotification({ ...NOTIFICATION_MSG_ERROR, message }));
      })
    });
  }
}

export function getAllShipments(from = null, without_tracking_number=false) {
  const f = `start=${from}`
  const t = without_tracking_number ?  `without_tracking_number=${without_tracking_number}` :  null
  let url = `${API_SHIPMENTS}`
  if (without_tracking_number) {
    url = `${API_SHIPMENTS}?${t}`
  } 
  if (from !== null) {
    url = `${API_SHIPMENTS}?${f}`
  }

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.shipments.error",
    level: 'error',
    position: 'tl',
    autoDismiss: '4',
  }

  return (dispatch) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to fetch shipments');
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
      dispatch({ type: 'SHIPMENTS_SUCCESS', json });
    })
    .catch(() => {
      dispatch(addUserActionNotification({ ...NOTIFICATION_MSG_ERROR }));
    });
  }
}
