import type { MessageDescriptor } from "react-intl";
import type { Notification as ReactNotificationType } from "react-notification-system";
import { v4 as uuidv4 } from "uuid";

import type { getBannersAction } from "../actions/banners";
import {
  CREATE_SHIPMENT,
  CREATE_SHIPMENT_ERROR,
  CREATE_SHIPMENT_SUCCESS,
  GET_DELIVERIES,
  GET_DELIVERIES_ERROR,
  GET_DELIVERIES_SUCCESS,
  PICKUP,
  PICKUP_ERROR,
  PICKUP_SUCCESS,
} from '../actions/deliveries';
import { GET_PATIENT_PAYMENT_SUCCESS } from '../actions/invoice';
import {
  AUTHENTICATE_PATIENT,
  AUTHENTICATE_PATIENT_SUCCESS,
  SIGN_PATIENT_OFFER,
  SIGN_PATIENT_OFFER_SUCCESS,
} from '../actions/offer';
import type { getSmilesAction } from "../actions/smiles";
import type { addUserActionNotification, eraseUserActionNotification } from "../actions/user_notification";
import type { CASE_RX_TYPE_SHORT, CASE_RX_TYPE_STANDARD, PAYERS } from "../common/constants";
import type { TOffer } from "./offer";

type TInvoice = {
  link: string
} | {
  invoice: {
    formUrl: string;
  }
};

export function invoice(state: Partial<TInvoice> = {}, action): Partial<TInvoice> {
  switch (action.type) {
    case "GET_INVOICE_DATA":
      return Object.assign({}, state, action.json)

    case "ERASE_INVOICE_DATA":
      return {}

    default:
      return state
  }
}

export function url(state = "/", action) {
    switch (action.type) {
        case 'ERASE_URL':
            return state

        default:
            return state
    }
}

export type TUserActionNotification = ReactNotificationType & { message: MessageDescriptor["id"] };
type UserActionNotificationState = TUserActionNotification | null;
type UserActionNotificationAction =
    ReturnType<typeof addUserActionNotification | typeof eraseUserActionNotification>;

export function userActionNotification(
  state: UserActionNotificationState = null,
  action: UserActionNotificationAction
): UserActionNotificationState {
  switch (action.type) {
    case "ADD_USER_ACTION_NOTIFICATION":
      return { ...state, ...action.json };

    case "ERASE_USER_ACTION_NOTIFICATION":
      return null;

    default:
      return state;
  }
}

export function clinic(state = [], action) {
    switch (action.type) {
      case 'GET_CLINIC_SUCCESS':
        return action.json;

      case 'POST_CLINIC_SUCCESS':
        return action.json;

      default:
        return state;
    }
}


export function clinics(state = [], action) {
    switch (action.type) {
        case 'GET_CLINICS_SUCCESS':
            return action.json;

        case 'UNLINK_CLINICS_SUCCESS':
            return state.filter((x) => x.clinic_id !== parseInt(action.clinic_id));

        default:
            return state;
    }
}


export function patientSaving(state = false, action): boolean {
    switch (action.type) {
        case 'PATIENT_SAVE_SUCCESSFULL':
            return action.patientIsSaving

        default:
            return state
    }
}


export function fileUploaded(state = false, action) {
    switch (action.type) {
        case 'FILE_UPLOAD_SUCCESSFULL':
            return action.fileUploaded

        default:
            return state
    }
}

export type TCourse = {
  course_id: number;
};

type TCorrection = {
  order_options: {
    can_order_retainers: boolean;
  };
  steps_count_total: number;
  steps_count_completed: number;
};

export type TPatient = {
  patient_id: number;
  course: {
    course_id: TCourse["course_id"];
    correction: TCorrection[];
    payment_method: "PM_CARD" | "PM_BANK";
    payment_option: "PO_ADVANCE" | "PO_SPLIT_2" | "PO_SPLIT_3";
    payments: {
      next: number;
      remain: number;
      total: number;
      cbct?: boolean;
    }
  };
  total_payments: {
    paid: number;
    total: number;
  };
  payer_id: typeof PAYERS[keyof typeof PAYERS];
  deep_cbct: boolean | null;
  rx_type_id: typeof CASE_RX_TYPE_STANDARD | typeof CASE_RX_TYPE_SHORT;
};

export function patient(state: Partial<TPatient> = {}, action): Partial<TPatient> {
    switch (action.type) {
        case 'GET_PATIENT_SUCCESS':
            return action.json;
        case 'ADD_PATIENT_SUCCESSFULL':
            return action.json;
        case 'UPDATE_PATIENT_SUCCESSFULL':
            return action.json;
        case 'POST_NEW_MIDTERM_CORRECTION':
            return action.json;
        case 'POST_STATUS_END':
            return action.json;
        case 'POST_STATUS_RETAINERS':
            return action.json;
        case 'POST_PLAN_APPROVED':
            return action.json;
        case 'POST_PLAN_SEND_TO_REWORK':
            return action.json;
        case 'POST_BONUS_PATIENT':
            return action.json;
        case 'ERASE_PATIENT':
            return Object.assign({});

        default:
            return state;
    }
}

type TToken = string | Record<string, unknown>;

export function token(state: TToken = {}, action): TToken {
  switch (action.type) {
    case "GET_TOKEN_SUCCESS":
      return action.fields.token

    default:
      return state
  }
}


const INITIAL_STATE = {
    patients: [],
    payment_alert: false,
    total: 0,
    all: 0
};


export function patients(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'GET_PATIENTS_SUCCESS':
            return {...state, ...action.json}
        case 'LOAD_SPINNER':
            return {...state, ...{...action.json, patients: []}}
        default:
            return state
    }
}


export function meditPatients(state = [], action) {
    switch (action.type) {
        case 'GET_MEDIT_PATIENTS_SUCCESS':
            return action.json
        default:
            return state
    }
}

type TDoctorTasks = typeof INITIAL_STATE;

export function doctorTasks(state: TDoctorTasks = INITIAL_STATE, action): TDoctorTasks {
    switch (action.type) {
        case 'GET_DOCTORS_TASKS_SUCCESS':
            return {...state, ...action.json}
        default:
            return state
    }
}

export function doctorPays(state: unknown[] = [], action): unknown[] {
    switch (action.type) {
        case 'GET_DOCTORS_PAYS_SUCCESS':
            return action.json
        default:
            return state
    }
}

export type TInstructions = {
  clinic_id: number;
  doctor_id: number;
  rx_type_id: number;
  course_id?: number;
  payment_option_id?: PaymentOption | null;
  deep_cbct?: boolean | null;
  sber_credit?: boolean;
  condition?: string;
};

export function instructions(state: Partial<TInstructions> = {}, action): Partial<TInstructions> {
  switch (action.type) {
    case "ADD_PATIENT_INSTRUCTION":
      return Object.assign({}, state, action.json);

    case "REMOVE_PATIENT_INSTRUCTION":
      Object.keys(action.json).forEach(key => {
        delete state[key];
      });
      return state;

    case "CREATE_PATIENT_INSTRUCTION":
      return Object.assign({});

    case "ERASE_INSTRUCTIONS":
      return Object.assign({});

    default:
      return state;
  }
}

export function savedSubmitPatientLocalFormData(state = {}, action) {
    switch (action.type) {
        case 'ADD_PATINET_INSTRUCTION_LOCAL':
            return Object.assign({}, state, action.json)
        case 'REMOVE_PATINET_INSTRUCTION_LOCAL':
            Object.keys(action.json).forEach(key => {
                delete state[key];
            })
            return state

        case 'CREATE_PATINET_INSTRUCTION_LOCAL':
            return Object.assign({})
        case 'ERASE_INSTRUCTIONS_LOCAL':
            return Object.assign({})

        default:
            return state
    }
}


export function media(state = {}, action) {
    switch (action.type) {
        case 'PATIENT_IMAGE_PREVIEW':
            return Object.assign({}, state, {[action.image.id]: action.image})

        case 'PATIENT_IMAGE_UPLOAD_SUCCESS':
            let ci = Object.assign({}, state[action.json.id], action.json)
            return Object.assign({}, state, {[action.json.id]: ci})

        case 'PATIENT_IMAGE_UPLOAD_ERROR':
            return Object.assign({}, state, {[action.json.id]: null, message: action.json.message})

        case 'ADD_PATIENT_SUCCESSFULL':
            return {}

        case 'ERASE_MEDIA':
            return {}

        default:
            return state
    }
}


export function media_s3(state = {}, action) {
    switch (action.type) {
        case 'media_s3/clear':
            return {uuid: uuidv4(), files: []}

        case 'CBCT_UPLOAD_SUCCESS':
            return {...state, files: action.payload}

        default:
            return state
    }
}

export function rushCorrection(state = false, action) {
  switch (action.type) {
    case "RUSH_CORRECTION_ON":
        return action.rush_correction;
    case "RUSH_CORRECTION_OFF":
        return false

    default:
      return state;
  }
}
export function rushAdditionalService(state = false, action) {
  switch (action.type) {
    case "RUSH_ADDITIONAL_SERVICE_ON":
      return action.rush_additional_service;
    case "RUSH_ADDITIONAL_SERVICE_OFF":
        return false
    default:
      return state;
  }
}


export function doctor(state = {}, action) {
    switch (action.type) {
        case 'EDIT_DOCTOR_SUCCESS':
            return action.fields;
        case 'ERASE_DOCTOR':
            return {}
        default:
            return state
    }
}


export function delivery(state = {deliveries: [], loading: false}, action) {
    switch (action.type) {

        case GET_DELIVERIES:
            return {...state, loading: true};
        case GET_DELIVERIES_ERROR:
            return {...state, deliveries: [], loading: false};
        case GET_DELIVERIES_SUCCESS:
            return {...state, deliveries: action.fields, loading: false};

        case PICKUP:
            return {...state, loading: true};
        case PICKUP_ERROR:
            return {...state, loading: false};
        case PICKUP_SUCCESS:
            return {...state, loading: false};

        case CREATE_SHIPMENT:
            return {...state, loading: true};
        case CREATE_SHIPMENT_SUCCESS:
            return {deliveries: [action.fields, ...state.deliveries], loading: false};
        case CREATE_SHIPMENT_ERROR:
            return {deliveries: [], loading: false};

        /*
        case UPDATE_DELIVERY_INFO:
          return { deliveries: action.fields, loading: false };
        */

        default:
            return state;
    }
}

type TPatientData = {
  offer: TOffer;
  patient_id: TPatient["patient_id"];
  signed: boolean;
};


export function patientData(state: Partial<TPatientData> = {}, action): Partial<TPatientData> {
    switch (action.type) {
        case AUTHENTICATE_PATIENT_SUCCESS:
        case SIGN_PATIENT_OFFER_SUCCESS:
        case GET_PATIENT_PAYMENT_SUCCESS:
            if (action.fields) {
                return {
                    ...action.fields
                }
            }
        case AUTHENTICATE_PATIENT:
        case SIGN_PATIENT_OFFER:
            return {}
        default:
            return state
    }
}

enum PaymentOption {
  ADVANCE = 1,
  SPLIT_2 = 2,
  SPLIT_3 = 3,
}

type TCourseInstallment = Record<number, PaymentOption[]>;

export function courseInstallment(
  state: Partial<TCourseInstallment> = {},
  action
): Partial<TCourseInstallment> {
  switch (action.type) {
    case "GET_COURSE_INSTALLMENT_SUCCESS":
      return action.json;
    default:
      return state;
  }
}

export function selectCourseReducer(state = [], action) {
    switch (action.type) {
        case 'SELECT_COURSE':
            return action.data
        default:
            return state
    }
}


export function courseSelectedReducer(state = false, action) {
    switch (action.type) {
        case 'COURSE_SELECTED_TRIGGER':
            return action.data
        default:
            return state
    }
}


type TService = {
  tag:
  | "RETAINERS"
  | "CBCT_ANALYSIS"
  | "DELIVERY"
  | "RETAINERS_UPPER_ARCH"
  | "RETAINERS_LOWER_ARCH"
  | "ADDITIONAL_ALIGNERS"
  | "CBCT_ANALYSIS_3D_PLAN"
  paid: boolean;
};

type TServices = Record<string, unknown> | TService[];

export function services(state: TServices = {}, action): TServices {
  switch (action.type) {
    case "GET_EXTRA_SERVICES":
      return action.json;

    case "CLEAR_EXTRA_SERVICES":
      return {};

    default:
      return state;
  }
}

export function spinner(state = null, action) {
    switch (action.type) {
        case 'LOAD_SPINNER':
            return true
        case 'CLEAR_SPINNER':
            return false
        default:
            return state;
    }
}

export function fileSpinner(state = null, action) {
    switch (action.type) {
        case 'LOAD_FILE_SPINNER':
            return true
        case 'CLEAR_FILE_SPINNER':
            return false
        default:
            return state;
    }
}

export function fileUploadError(state = null, action) {
    switch (action.type) {
        case 'UPLOAD_ERROR':
            return true
        case 'NO_ERROR':
            return false
        default:
            return state;
    }
}


const CASES_STATE = {
    cases: [],
    count: null
}


export function threeShapeCases(state = CASES_STATE, action) {
    switch (action.type) {
        case 'GET_3SHAPE_CASES':
            return {...state, ...action.json}
        default:
            return state;
    }
}

export function meditCases(state = [], action) {
    switch (action.type) {
        case 'GET_MEDIT_CASES':
            return { ...action.json}
        default:
            return state;
    }
}

export function meditCase(state = [], action) {
    switch (action.type) {
        case 'GET_MEDIT_CASE':
            return { ...action.json}
        default:
            return state;
    }
}


export function bonuses(state = {}, action) {
    switch (action.type) {
        case 'GET_BONUSES':
            return action.json || {};
        case 'CLEAR_BONUSES':
            return state;
        default:
            return state;
    }
}

export type TSmiles = {
  start: string;
  end: string;
  current: number;
  total: number;
}

export function smiles(
  state: Partial<TSmiles> = {},
  action: ReturnType<typeof getSmilesAction>
): Partial<TSmiles> {
  switch (action.type) {
    case "GET_SMILES":
      return action.json;

    default:
      return state;
  }
}

export type TBanner = {
  id: number;
  type: "link" | "private_file" | "public_file" | "empty";
  position: number;
  align: "left" | "center" | "center_and_left";
  icon_url: string;
  banner_url: string | null;
  link: string | null;
}

export function banners(
  state: TBanner[] = [],
  action: ReturnType<typeof getBannersAction>
): TBanner[] {
  switch (action.type) {
    case "GET_BANNERS":
      return action.json;

    default:
      return state;
  }
}

type TShipments = unknown[];

export function shipments(state: TShipments = [], action): TShipments {
    switch (action.type) {
        case 'SHIPMENTS_SUCCESS':
            return action.json;

        default:
            return state;
    }
}

export function tour(state = false, action): boolean {
    switch (action.type) {
        case 'GET_TOUR':
            return action.data
        default:
            return state;
    }
}
