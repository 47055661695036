import { API_REWORK_PLAN } from '../config';
import {rework3DPlanNotification} from "../components/notifications/rework_3d_plan_notification";
import { addUserActionNotification } from './user_notification';


export function tryToReworkPlan(patient_id, case_id) {
  
  const NOTIFICATION_MSG_OK = {
    message: "notify.rework.ok",
    level: 'success',
    position: 'tc',
    autoDismiss: '10',
    children: rework3DPlanNotification()
  };
  
  const NOTIFICATION_MSG_ERROR = {
    message: "notify.rework.error",
    level: 'error',
    position: 'tl',
    autoDismiss: '4',
  }

  return (dispatch) => {
    fetch(API_REWORK_PLAN(patient_id), {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        plan_id:parseInt(case_id)
      })
    })
    .then((response) => {
      if (!response.ok) {
        throw Error('Error occurred trying to proceed plan adjusting!')
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
        dispatch({ type: 'POST_PLAN_SEND_TO_REWORK', json: json.patient });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
    }).catch(() => {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    })
  }
}
