import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import PageFooter from "../page_footer";
import PageHeader from "../page_header";
import Tour from "../patient/tour";
import PatientMenu from "../patient_menu";
import PlanRetainers from "./3d_plan_retainers_page";

export default function PlanRetainersBody(props: RouteComponentProps<{ patient_id: string }>) {
  return (
    <div>
      <PageHeader />
      <Tour />
      <div className="container">
        <div className="page-container">
          <PatientMenu />
          <div className="page-content-wrapper">
            <div className="page-content" style={{ minHeight: 584 }}>
              <div className="row">
                <div className="col-md-12">
                  <PlanRetainers {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageFooter />
      </div>
    </div>
  );
}
