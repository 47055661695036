import { deployedUSA } from "../common/utils";
import { API_AUTHENTICATE_PATIENT, API_OFFER, API_PATIENTS_OFFER } from "../config";
import type { TPatient, TUserActionNotification } from "../reducers/dashboard";
import type { TOffer } from "../reducers/offer";
import type { AppDispatch, RootState } from "../store";
import { initializeUser } from "./login";
import { PAYMENT_REMINDER_NOTIFICATION } from "./notifications";
import { addUserActionNotification } from "./user_notification";

export const GET_OFFER = "GET_OFFER";
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS";
export const GET_OFFER_ERROR = "GET_OFFER_ERROR";

export const SIGN_OFFER = "SIGN_OFFER";
export const SIGN_OFFER_SUCCESS = "SIGN_OFFER_SUCCESS";
export const SIGN_OFFER_ERROR = "SIGN_OFFER_ERROR";

export const AUTHENTICATE_PATIENT = "AUTHENTICATE_PATIENT";
export const AUTHENTICATE_PATIENT_ERROR = "AUTHENTICATE_PATIENT_ERROR";
export const AUTHENTICATE_PATIENT_SUCCESS = "AUTHENTICATE_PATIENT_SUCCESS";

export const SIGN_PATIENT_OFFER = "SIGN_PATIENT_OFFER";
export const SIGN_PATIENT_OFFER_SUCCESS = "SIGN_PATIENT_SUCCESS";
export const SIGN_PATIENT_OFFER_ERROR = "SIGN_PATIENT_ERROR";

export function getOffer() {
  return (dispatch: AppDispatch) => {
    dispatch({ type: GET_OFFER });
    return fetch(API_OFFER, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((fields) => {
        dispatch({ type: GET_OFFER_SUCCESS, fields });
      })
      .catch((e) => {
        dispatch({ type: GET_OFFER_ERROR, error: e });
      });
  };
}

export function signOffer(offer_id: TOffer["offer_id"]) {
  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.doctor.offer.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  const NOTIFICATION_MSG_SUCCESS: TUserActionNotification = {
    message: "notify.doctor.offer.success",
    level: "success",
    position: "tl",
    autoDismiss: 6,
  };

  return (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: SIGN_OFFER });
    return fetch(API_OFFER, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ offer_id }),
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response;
      })
      .then(() => {
        dispatch({ type: SIGN_OFFER_SUCCESS });
        dispatch(initializeUser());
        dispatch(addUserActionNotification(NOTIFICATION_MSG_SUCCESS));

        if (deployedUSA()) {
          const { notifications } = getState();
          if (notifications.some((n) => n.type == "waiting_for_payment")) {
            dispatch(addUserActionNotification(PAYMENT_REMINDER_NOTIFICATION));
          }
        }
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      });
  };
}

export function authenticatePatient(patient_id: TPatient["patient_id"], token: string) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: AUTHENTICATE_PATIENT });
    return fetch(API_AUTHENTICATE_PATIENT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ patient_id, token }),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((fields) => {
        dispatch({ type: AUTHENTICATE_PATIENT_SUCCESS, fields });
      })
      .catch((e) => {
        dispatch({ type: AUTHENTICATE_PATIENT_ERROR, e });
        throw e;
      });
  };
}

export function signPatientsOffer(
  offer_id: TOffer["offer_id"],
  patient_id: TPatient["patient_id"],
  token: string,
) {
  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.doctor.offer.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  const NOTIFICATION_MSG_SUCCESS: TUserActionNotification = {
    message: "notify.doctor.offer.success",
    level: "success",
    position: "tl",
    autoDismiss: 6,
  };

  return (dispatch: AppDispatch) => {
    return fetch(API_PATIENTS_OFFER, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ offer_id, patient_id, token }),
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response;
      })
      .then((response) => response.json())
      .then((fields) => {
        dispatch({ type: SIGN_PATIENT_OFFER_SUCCESS, fields });
        dispatch(addUserActionNotification({ ...NOTIFICATION_MSG_SUCCESS }));
      })
      .catch(() => {
        dispatch(addUserActionNotification({ ...NOTIFICATION_MSG_ERROR }));
      });
  };
}
