import React, {Component} from "react";
import { connect } from "react-redux";


export const FILE_TYPE_3SHAPE = '3shape';
export const FILE_TYPE_DICOM = 'dicom';
export const FILE_TYPE_PREXION = 'prexion';
export const FILE_TYPE_MEDIT = 'medit';


const mapStateToProps = (state) => {
    return {
        patient: state.patient
    }
}


class PatientFiles extends Component {
    async downloadFile(file, e) {
        let target = e && e.target;
        
        if (true || !target) {
            target = $(`<a download="${file.file_name}"></a>`)[0];
        }
        let url = target.href;
        if (!url || url === "javascript:void(0)") {
            let fileData = await this.getFileUrl(file);
            url = fileData[0].url;
            target.href = url;
        }
        target.click();
    }

    async getFileUrl(file) {
        const {patient} = this.props;
        let params = {
            patient_id: patient.patient_id,
            file_id: [file.file_id],
        };

        return fetch(
            `${process.env.API}/patients/media?${$.param(params)}`,
            {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                  },
            }).then((res) => res.json())
        ;
    }

    render() {
        const {patient} = this.props;
        if (!patient.s3_media) return null;

        const rows = patient.s3_media.filter(file=> file.file_type === this.props.type).map((file, i) => {
            return (
                <tr key={i}>
                    {this.props.type !== FILE_TYPE_3SHAPE ? <td>{i + 1}</td> : null}
                    {this.props.type === FILE_TYPE_3SHAPE ? <td style={{width: '40%'}}>
                        <div>
                            <img
                                src={'/img/upload_placeholders/THUMBNAIL_STL.svg'}
                                style={{width: '60%'}}/>
                        </div>
                    </td> : null
                    }
                    <td>
                        <a
                            href="javascript:void(0)"
                            download={file.file_name}
                            onClick={(e) => this.downloadFile(file, e)}
                            data-matomo-mask
                            data-hj-suppress
                            style={{wordBreak: "break-word"}}
                        >
                            {file.file_name}
                        </a>
                    </td>
                </tr>
            );
        });
        
        if (rows && Array.isArray(rows) && rows.length === 0) {
            return null;
        }

        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th width="50"></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        )
    }
}


export default connect(mapStateToProps)(PatientFiles);
