import React from "react";

import { buildLocale } from "../../../common/locale";

export default function FormatNumber(props) {
  const value = formatNumber(props);
  return (
    <span {...props} data-matomo-mask>
      {value}
    </span>
  );
}

export function formatNumber({ value, currency, options }) {
  if (currency) return formatCurrency(...arguments);
  return value.toLocaleString(buildLocale, options);
}

export function formatCurrency({ value, currency, options }) {
  options = { style: "currency", currency, ...options };
  return value.toLocaleString(buildLocale, options).replace("₽", "руб.");
}

export function formatPrice(price: number) {
  return price.toLocaleString(buildLocale);
}
