import React from "react";
import { FormattedMessage, type MessageDescriptor, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import shipmentsIcon from "../assets/img/shipment_1.svg";
import { deployedUSA } from "../common/utils";
import { useAppSelector } from "../store";

export function AlertShipments({
  titleLabel,
  style,
}: {
  titleLabel?: MessageDescriptor["id"];
  style?: React.CSSProperties;
}) {
  const intl = useIntl();
  const shipments = useAppSelector((state) => state.shipments.length);

  return (
    <li
      className="dropdown dropdown-extended dropdown-notification tip"
      id="header_notification_bar"
    >
      <NavLink
        data-toggle="tooltip"
        title={deployedUSA() ? intl.formatMessage({ id: "tooltip_shipment" }) : undefined}
        style={style}
        to={"/pages/shipments"}
        className="dropdown-toggle"
        data-hover="dropdown"
        data-close-others="true"
        id="shipments-bar"
      >
        <img style={{ width: 30 }} src={shipmentsIcon} alt="" />
        {titleLabel ? (
          <span className="title">
            <FormattedMessage id={titleLabel} />
          </span>
        ) : null}
        {shipments > 0 ? (
          <span className="badge badge-danger" id="shipments-count">
            {shipments}
          </span>
        ) : null}
      </NavLink>
    </li>
  );
}
