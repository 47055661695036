import type { IntlConfig } from "react-intl";
import { updateIntl } from "react-intl-redux";

import { deployedUSA } from "../common/utils";
import { API_LOGIN, API_WHOAMI } from "../config";
import localEnMessages from "../locale/en";
import localRuMessages from "../locale/ru";
import type { TUser } from "../reducers/login";
import type { AppDispatch } from "../store";
import { bannersIsLoadingAction, getBanners } from "./banners";
import { getNotifications } from "./notifications";

export function userIsLoadingAction(bool: boolean) {
  return { type: "USER_IS_LOGGING_IN", userIsLoading: bool } as const;
}

export function userLoggedInSuccess(fields: TUser) {
  return { type: "USER_LOGGED_IN_SUCCESS", fields } as const;
}

export function userLoggedInFailure() {
  return {
    type: "USER_LOGGED_IN_ERROR",
    message: "login.error",
  };
}

export function updateLocale(locale: IntlConfig["locale"]) {
  const updated = {
    locale: locale,
    messages: locale == "ru" ? localRuMessages : localEnMessages,
  };
  return (dispatch: AppDispatch) => {
    dispatch(updateIntl(updated));
  };
}

export function initializeUser() {
  return (dispatch: AppDispatch) => {
    dispatch(userIsLoadingAction(true));
    fetch(API_WHOAMI, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error occurred trying to fetch user: ${response.statusText}`);
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch(userLoggedInSuccess(json));
        dispatch(userIsLoadingAction(false)); // Order matters. Must be after userLoggedInSuccess for CatchAll route to work correctly.
      })
      .catch((e) => {
        dispatch({ type: "USER_INIT_FAILURE", message: e.message });
        dispatch(userIsLoadingAction(false));
        dispatch(userLoggedInFailure());
      });
  };
}

export function loginUser(login: string, password: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: "ERASE_URL" });

    const response = await fetch(API_LOGIN, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ login: login, password: password }),
    });

    if (!response.ok) {
      throw new Error(`Error occurred trying to fetch user: ${response.statusText}`);
    }

    const json = await response.json();
    dispatch(userLoggedInSuccess(json));

    dispatch(bannersIsLoadingAction(true));
    await dispatch(getBanners());

    if (deployedUSA()) {
      dispatch(getNotifications());
    }
  };
}
