import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {openPopupWidget} from 'react-calendly'

import Loader from '../common/loadingInProgress';
import remoteLog from '../../common/logging';
import { API_MEETINGS } from '../../config';
import { formatPersonName } from "../common/PersonName";
import { deployedUSA } from '../../common/utils';


const buttonStyles = {
    background: "none",
    border: "none",
    padding: "0!important",
    fontFamily: "arial, sans-serif",
    color: "#069",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "larger"
};


const CustomButton = ({ url, prefill, pageSettings, utm, personName }) => {

    const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

    return (
        <button style={buttonStyles} onClick={onClick}>
            {personName}
        </button>
    );
};


class MeetingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        links: []
    }
    this.renderExperts = this.renderExperts.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'meetings_page');
  }

  componentDidMount() {
    fetch(API_MEETINGS, { credentials: 'include' })
    .then(response => {
        if(!response.ok) {
            throw new Error('Error occurred trying to fetch manuals');
        }
        return response.text()
    })
    .then(response => JSON.parse(response))
    .then(json => this.setState({ links: json, loading: false }))
    .catch(() => this.setState({ loading: false }));
  }

  renderExperts() {
      const { links } = this.state;
      const rows = [];
      if (links && Object.keys(links).length > 0) {
        Object.keys(links).forEach((key) => {
            rows.push(
                <div key={key}>
                    <br />
                    {/*<i className="icon-user-follow"></i>&nbsp;&nbsp;*/}
                    {/*<a href={links[key]['link']} style={{ fontSize: '16px' }} target="_blank" rel="noopener noreferrer">
                        <PersonName person={links[key]} useMiddleName isDoctor/>
                    </a>*/}
                    <CustomButton
                        url={links[key]['link']}
                        pageSettings={{}}
                        prefill={{}}
                        utm={{}}
                        personName={formatPersonName({person: links[key], useMiddleName: true, isDoctor: true})}
                    />
                    <br />
                </div>
            );
        })
        return rows;
      }
      return <div className="portlet-body"><FormattedMessage id="meetings.not.found"/></div>;
  }

  render() {
      const { loading } = this.state;
      if (loading === false) {
        return (
            <div className="portlet light bordered" id="retainers-section-head">
                <div className="portlet-title">
                    <div className="caption">
                    <i className="icon-calendar font-green" />
                    <span className="caption-subject font-green bold uppercase"><FormattedMessage id="meetings.title"/></span>
                    </div>
                </div>
                <div className="portlet-body">
                    <label className="control-label" id="clinic-name-label" style={{ fontWeight: '900', fontSize: '16px' }}>
                        <FormattedMessage id={deployedUSA() ? "meetings.choose.expert.predict" : "meetings.choose.expert"} />
                    </label>
                    {this.renderExperts()}
                    {/*deployedUSA() ?
                        (
                            <div
                                className="caption"
                                style={{ bottom: '5px', marginTop: '20px', textAlign: 'right' }}
                            >
                                <span style={{ color: 'red', fontSize: '18px', fontWeight: 'bold' }}>*</span>
                                <span style={{ fontWeight: '600' }}>
                                    <i><FormattedMessage id="meetings.choose.expert.comment"/></i>
                                </span>
                            </div>
                        ) : null
                        */}
                </div>
            </div>
        )
    }
    return <div><Loader /></div>;
  }

}


export default injectIntl(MeetingsPage);
