import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";

import { getPatientId } from "../../actions/get_patient_id";
import { orderAdditionalAligners } from "../../actions/additional_aligners";
import remoteLog from "../../common/logging";
import Loader from "../common/loadingInProgress";
import { deployedRussia } from "../../common/utils";
import Modal from "react-modal";
import { rushAdditionalServiceOn } from "../../actions/post_patient";

const formInitialValues = {
  stage: "",
  amount: 1,
  treat_arch_id: "",
  elastics_left: null,
  elastics_right: null,
};

const validate = (values) => {
  const errors = {};
  const alignersArrayErrors = [];
  values &&
    values.aligners.forEach((aligner, alignerIndex) => {
      const alignersErrors = {};
      if (!aligner || !aligner.stage) {
        alignersErrors.stage = "error";
        alignersArrayErrors[alignerIndex] = alignersErrors;
      }
      if (!aligner || !aligner.amount) {
        alignersErrors.amount = "error";
        alignersArrayErrors[alignerIndex] = alignersErrors;
      }
      if (!aligner || !aligner.treat_arch_id) {
        alignersErrors.treat_arch_id = "error";
        alignersArrayErrors[alignerIndex] = alignersErrors;
      }
    });
  if (alignersArrayErrors.length) {
    errors.aligners = alignersArrayErrors;
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    formValues: getFormValues("additional_aligners")(state),
    rush_additional_service: state.rushAdditionalService,
    services: state.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPatient: (patient_id) => dispatch(getPatientId(patient_id)),
    orderAdditionalAligners: (
      patient_id,
      stage,
      amount,
      treat_arch_id,
      elastics_left,
      elastics_right,
      paymentMethod,
      rush_additional_service,
    ) =>
      dispatch(
        orderAdditionalAligners(
          patient_id,
          stage,
          amount,
          treat_arch_id,
          elastics_left,
          elastics_right,
          paymentMethod,
          rush_additional_service,
        ),
      ),
    rushAdditionalService: (data) => dispatch(rushAdditionalServiceOn(data)),
  };
};

class AdditionalAligners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      submitBtn: null,
      showModal: false,
    };
    this.renderForm = this.renderForm.bind(this);
    this.orderAdditionalAlignersSubmit = this.orderAdditionalAlignersSubmit.bind(this);
    this.required = this.required.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, "additional_aligners_page_body");
  }

  componentDidMount() {
    const { user } = this.props;
    window.scrollTo(0, 0);
    this.props.getPatient(this.props.match.params.patient_id);
  }

  componentDidUpdate(nextProps) {
    if (this.props.services.length !== nextProps.services.length) {
      const paymentMethod = this.props.patient.course.payment_method;
      if (paymentMethod === "PM_CARD") {
        nextProps.history.push("/pages/payments");
      } else {
        nextProps.history.push("/pages/patients");
      }
    }
  }

  required(i, e) {
    if (this.props.formValues && this.props.formValues.aligners[i][`${e}`]) {
      return false;
    }
    return true;
  }

  orderAdditionalAlignersSubmit() {
    const values = this.props.formValues.aligners;
    const paymentMethod = this.props.patient.course.payment_method;
    if (values.length > 0) {
      this.setState({ showLoader: true });
      values.forEach((e, i) =>
        this.props.orderAdditionalAligners(
          this.props.patient.patient_id,
          parseInt(e.stage),
          parseInt(e.amount),
          parseInt(e.treat_arch_id),
          e.elastics_left,
          e.elastics_right,
          paymentMethod,
          this.props.rush_additional_service,
        ),
      );
    }
  }

  renderElastics(f, i) {
    return (
      <div key={i} id="additional-aligners-elastics-section">
        <label className="control-label" style={{ fontWeight: "900" }}>
          <FormattedMessage id="HEADER_ELASTICS" />
        </label>
        <div className="row">
          <div className="form-group col-md-5 col-sm-3">
            <label
              className="control-label"
              id="elastics-ratio-left-label"
              style={{ fontWeight: "900" }}
            >
              <FormattedMessage id="ELASTICS_LEFT" />
            </label>
            <div className="checkbox-list checkbox_list">
              <label>
                <div className="checkbox-round checker">
                  <Field
                    component="input"
                    type="checkbox"
                    name={`${f}elastics_left`}
                    format={(v) => v === 2}
                    normalize={(v) => (v ? 2 : null)}
                    id={`${f}elastic-recoil-left-value-2`}
                  />
                  <label id="checkbox_label" htmlFor={`${f}elastic-recoil-left-value-2`}></label>
                </div>
                <FormattedMessage id="ER_L_II_CLASS" />
              </label>
              <label>
                <div className="checkbox-round checker">
                  <Field
                    component="input"
                    type="checkbox"
                    name={`${f}elastics_left`}
                    format={(v) => v === 3}
                    normalize={(v) => (v ? 3 : null)}
                    id={`${f}elastic-recoil-left-value-3`}
                  />
                  <label id="checkbox_label" htmlFor={`${f}elastic-recoil-left-value-3`}></label>
                </div>
                <FormattedMessage id="ER_L_III_CLASS" />
              </label>
            </div>
          </div>
          <div className="form-group col-md-5 col-sm-3">
            <label className="control-label" style={{ fontWeight: "900" }}>
              <FormattedMessage id="ELASTICS_RIGHT" />
            </label>
            <div className="checkbox-list checkbox_list">
              <label>
                <div className="checkbox-round checker">
                  <Field
                    component="input"
                    type="checkbox"
                    name={`${f}elastics_right`}
                    format={(v) => v === 2}
                    normalize={(v) => (v ? 2 : null)}
                    id={`${f}elastic-recoil-right-value-2`}
                  />
                  <label id="checkbox_label" htmlFor={`${f}elastic-recoil-right-value-2`}></label>
                </div>
                <FormattedMessage id="ER_R_II_CLASS" />
              </label>
              <label>
                <div className="checkbox-round checker">
                  <Field
                    component="input"
                    type="checkbox"
                    name={`${f}elastics_right`}
                    format={(v) => v === 3}
                    normalize={(v) => (v ? 3 : null)}
                    id={`${f}elastic-recoil-right-value-3`}
                  />
                  <label id="checkbox_label" htmlFor={`${f}elastic-recoil-right-value-3`}></label>
                </div>
                <FormattedMessage id="ER_R_III_CLASS" />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm({ fields, meta: { submitFailed } }) {
    return (
      <div>
        {fields.map((f, i) => (
          <div key={i} className="portlet-body">
            <div className="form-group">
              <div className="tw-flex tw-items-baseline">
                <label
                  htmlFor="stage"
                  id={`stage${i}`}
                  className={this.required(i, "stage") && submitFailed ? "required" : ""}
                  style={{ fontWeight: "900", fontSize: "14px", padding: "0" }}
                >
                  <FormattedMessage id="additional_aligners_stage" />
                  <span className="required" aria-required="true">
                    *
                  </span>
                  <button
                    className="btn tw-ml-4"
                    id="add-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      fields.push(formInitialValues);
                    }}
                  >
                    <FormattedMessage id="pat.additional.aligners.add.stage" />
                  </button>
                  {i > 0 ? (
                    <i
                      onClick={() => fields.remove(i)}
                      style={{ color: "#ff0000", fontSize: "16px", marginLeft: "10px" }}
                      className="icon-trash"
                    />
                  ) : null}
                </label>
              </div>
              <div className="radio">
                <Field
                  component="input"
                  className="input-medium form-control"
                  type="number"
                  min="1"
                  name={`${f}stage`}
                  id="stage"
                  normalize={(v) => (v >= 1 ? v : null)}
                />
              </div>
            </div>{" "}
            <br />
            <div className="form-group">
              <label
                id={`treat_arch_id${i}`}
                className={this.required(i, "treat_arch_id") && submitFailed ? "required" : ""}
                style={{ fontWeight: "900", fontSize: "14px", padding: "0" }}
              >
                <FormattedMessage id="additional_aligners_arches" />
                <span className="required" aria-required="true">
                  *
                </span>
              </label>
              <div className="radio-list">
                <label>
                  <div className="radio">
                    <Field
                      component="input"
                      type="radio"
                      name={`${f}treat_arch_id`}
                      id="treat-arches-both-value"
                      value="1"
                    />
                  </div>
                  <FormattedMessage id={deployedRussia() ? "TA_BOTH" : "TA_BOTH_ADDITIONAL_USA"} />
                  &nbsp;&nbsp;
                  <FormattedMessage id="additional.aligners.both.comment" />
                </label>
                <label>
                  <div className="radio">
                    <Field
                      component="input"
                      type="radio"
                      name={`${f}treat_arch_id`}
                      id="treat-arches-upper-value"
                      value="2"
                    />
                  </div>
                  <FormattedMessage id={deployedRussia() ? "TA_UPPER" : "TA_UPPER_ADDITIONA_USA"} />
                </label>
                <label>
                  <div className="radio">
                    <Field
                      component="input"
                      type="radio"
                      name={`${f}treat_arch_id`}
                      id="treat-arches-lower-value"
                      value="3"
                    />
                  </div>
                  <FormattedMessage id={deployedRussia() ? "TA_LOWER" : "TA_LOWER_ADDITIONA_USA"} />
                </label>
              </div>
              {/* <div className="form-group">
                <label
                  id={`amount${i}`}
                  htmlFor="amount"
                  className={this.required(i, "amount") && submitFailed ? "required" : ""}
                  style={{ fontWeight: "900", fontSize: "14px", padding: "0" }}
                >
                  <FormattedMessage id="AMOUNT" />
                  <span className="required" aria-required="true">
                    *
                  </span>
                </label>
                {this.props.formValues &&
                this.props.formValues.aligners[i].treat_arch_id === "1" ? (
                  <div className="row">
                    <div className="col-md-2" style={{ width: "auto" }}>
                      <FormattedMessage id="UPPER_ARCH" />
                      <Field
                        component="input"
                        className="input-medium form-control"
                        type="number"
                        min="1"
                        name={`${f}amount`}
                        id="amount_upper"
                        normalize={(v) => (v >= 1 ? v : null)}
                      />
                    </div>
                    <div className="col-md-4">
                      <FormattedMessage id="LOWER_ARCH" />
                      <Field
                        component="input"
                        className="input-medium form-control"
                        type="number"
                        min="1"
                        name={`${f}amount`}
                        id="amount_lower"
                        normalize={(v) => (v >= 1 ? v : null)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="radio">
                    <Field
                      component="input"
                      className="input-medium form-control"
                      type="number"
                      min="1"
                      name={`${f}amount`}
                      id="amount_both"
                      normalize={(v) => (v >= 1 ? v : null)}
                    />
                  </div>
                )}
              </div> */}
              {this.renderElastics(f)}

              <div className="form-group tw-ml-1 tw-mt-4">
                <label htmlFor="rash" style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <input
                    id="rash"
                    style={{ marginTop: 0 }}
                    type="checkbox"
                    name="rush_correction"
                    onChange={(e) => this.props.rushAdditionalService(e.currentTarget.checked)}
                  />
                  <span className="tw-font-bold">
                    <FormattedMessage id="additional_service_rush" />{" "}
                  </span>
                  <span className="tw-text-[12px]">
                    <FormattedMessage id="additional_service_rush_comment" />
                  </span>
                </label>
              </div>
            </div>
          </div>
        ))}
        {/* <button
          style={{ marginBottom: "10px" }}
          className="btn"
          id="add-btn"
          onClick={(e) => {
            e.preventDefault();
            fields.push(formInitialValues);
          }}
        >
          <FormattedMessage id="pat.additional.aligners.add.stage" />
        </button> */}
      </div>
    );
  }

  openModal(invalid) {
    if (invalid) {
      this.setState({ showModal: true });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    if (
      this.props.patient?.course?.correction?.slice(-1)[0]?.order_options
        ?.can_order_additional_aligners
    ) {
      if (this.props.patient.patient_id) {
        const { handleSubmit, submitting, invalid, formValues } = this.props;
        const message =
          formValues && formValues.aligners.length > 1
            ? "pat.additional.aligners.required.fields.remove.stage"
            : "pat.additional.aligners.required.fields";
        return (
          <form
            onSubmit={handleSubmit(this.orderAdditionalAlignersSubmit)}
            className="portlet light bordered"
          >
            <div className="portlet-title">
              <div className="caption">
                <i className="icon-book-open font-green" />
                <span className="caption-subject font-green bold uppercase">
                  <FormattedMessage
                    id={
                      deployedRussia()
                        ? "pat.additional.aligners.page.header"
                        : "pat.additional.aligners.page.header.usa"
                    }
                  />
                </span>
              </div>
            </div>
            <Modal
              isOpen={this.state.showModal}
              ariaHideApp={false}
              className="question-modal"
              onRequestClose={() => this.closeModal()}
              disableAutoFocus={true}
              style={{
                overlay: {
                  backgroundColor: "#787d7d7d",
                },
                content: {
                  outline: "none",
                },
              }}
            >
              <div
                style={{ outline: "none", border: "1px solid #32c5d2" }}
                className="portlet-body form"
                id="comment-question-modal"
              >
                <div className="form-body">
                  <div className="form-group">
                    <label className="control-label" id="comment-question-modal-label">
                      <FormattedMessage id={message} />
                    </label>
                    <button onClick={() => this.closeModal()} type="button" className="close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            <FieldArray name="aligners" component={this.renderForm} />
            {this.state.showLoader && <Loader />}
            <div>
              <button
                className="btn green"
                id="submit-pacient-btn"
                type="submit"
                disabled={submitting}
                onClick={(e) => this.openModal(invalid)}
              >
                <FormattedMessage id="pat.payments.buttons.submit" />
              </button>
            </div>
          </form>
        );
      } else {
        return (
          <div>
            <Loader />
          </div>
        );
      }
    }
    return null;
  }
}

AdditionalAligners = reduxForm({
  form: "additional_aligners",
  initialValues: { aligners: [formInitialValues] },
  validate,
  enableReinitialize: true,
})(AdditionalAligners);

AdditionalAligners = connect((state) => ({
  formValues: getFormValues("additional_aligners")(state),
  shouldValidate: () => true,
}))(AdditionalAligners);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdditionalAligners));
