import { API_EXTRA_SERVICES } from "../config";
import type { TPatient } from "../reducers/dashboard";
import type { AppDispatch } from "../store";

export function clearExtraServices() {
  return { type: "CLEAR_EXTRA_SERVICES" };
}

export function getExtraServices(patient_id: TPatient["patient_id"]) {
  return async (dispatch: AppDispatch) => {
    const response = await fetch(API_EXTRA_SERVICES(patient_id), { credentials: "include" });
    if (!response.ok) {
      console.error("Failed loading { GET_EXTRA_SERVICES }: " + response.statusText);
      throw new Error(response.statusText);
    }
    const json = await response.json();
    dispatch({ type: "GET_EXTRA_SERVICES", json });
  };
}
