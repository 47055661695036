import React from "react";
import { FormattedMessage } from "react-intl";

import loading from "./loading.gif";

export default function Loader({
  msg,
  srOnlyMsg,
  className,
  style,
}: {
  msg?: string;
  srOnlyMsg?: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <div
      role="status"
      className={className}
      style={{ display: "flex", justifyContent: "flex-start", padding: 10, ...style }}
    >
      <span>{msg}</span>
      <span className="sr-only">{srOnlyMsg || <FormattedMessage id="loading" />}</span>
      <div style={{ paddingLeft: 5 }}>
        <img src={loading} alt="" />
      </div>
    </div>
  );
}
