import React from 'react';
import { injectIntl } from 'react-intl';
import {
    Bar, CartesianGrid, ComposedChart, Line,
    ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts';

import {deployedRussia} from '../../common/utils';


const toolTipStyle = {
    backgroundColor: '#fff',
    border: '1px solid #82D9D4',
    margin: 0,
    marginTop: '20px',
    padding: 5,
};


class CustomizedDot extends React.Component {
    render() {
        const {cx, cy, height} = this.props;
        const {current} = this.props.payload;
        return (
            <svg x={Math.floor(cx - height)} y={`-${cy}`} width={160} height={160}>
                <image x={5} y={0} width={160} height={160} xlinkHref={'/img/drop.svg'}/>
                <text x={80} y={80} fill="white" width="10%" fontWeight="bold">{current}</text>
            </svg>
        );
    }
}


class BarChartHorizontal extends React.Component {

    constructor(props) {
        super(props);
        this.fm = this.fm.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
    }

    fm(id) {
        return this.props.intl.formatMessage({id});
    }

    renderTooltip(props) {
        const {active, payload} = props;

        if (active && payload && payload.length) {
            const values = payload[0].payload;
            if (deployedRussia() === true) {
                return <div style={toolTipStyle}>
                    <span>{this.fm('bonus.cases.left')} {values.current} {this.fm('bonus.cases.left.1')} {values.target} {this.fm('bonus.cases.left.2')}</span>
                </div>;
            }
            return <div style={toolTipStyle}><span>{values.current} {this.fm('bonus.cases.left')}</span>
            </div>;
        }
        return null;
    }

    render() {
        const { data } = this.props;
        return (
            <ResponsiveContainer>
                <ComposedChart
                    data={data}
                    margin={{
                        top: 10, right: 20, left: 40, bottom: 5,
                    }}
                    layout="vertical"
                    barSize={10}>
                    <CartesianGrid vertical={false} horizontal={false}/>
                    <XAxis hide={true} type="number" domain={[0, data[0].target]}/>
                    <YAxis hide={true} dataKey="name" type="category"/>
                    <Tooltip
                        wrapperStyle={{zIndex: 100, top: '-50px'}}
                        content={this.renderTooltip}
                        cursor={false}
                    />
                    <Bar dataKey="current" fill="#82D9D4" background={{fill: '#c4cbcb', radius: 10}}
                         radius={[10, 0, 0, 10]}/>
                    <Line type="monotone" dataKey="current" stroke="#82D9D4" dot={<CustomizedDot/>}/>
                </ComposedChart>
            </ResponsiveContainer>
        )
    }
}


export default injectIntl(BarChartHorizontal);
