import {API_DOCTOR_PAYMENT_ACCOUNT} from '../config';
import { addUserActionNotification } from './user_notification';

const json = {
    message: "payments.account.delete.err",
    level: 'error',
    position: 'tl',
    autoDismiss: '4',
}

export function getPaymentData() {
    return fetch(API_DOCTOR_PAYMENT_ACCOUNT, {credentials: 'include'})
        .then((response) => {
            if (!response.ok) {
                console.error(`Failed on ${API_DOCTOR_PAYMENT_ACCOUNT}`);
                throw Error(response.statusText);
            }
            return response;
        })
        .then((response) => response.json())
}

export function deletePaymentsData() {
    return (dispatch) => {
        return fetch(API_DOCTOR_PAYMENT_ACCOUNT, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
            .then((response) => {
                if (!response || !response.ok) {
                    console.error(`Failed on ${API_DOCTOR_PAYMENT_ACCOUNT}`);
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .catch(() => dispatch(addUserActionNotification(json)))

    }
}